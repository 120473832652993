import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { sqlite, existingConn } from '../../App';
export const createTableScript: string = `
    CREATE TABLE IF NOT EXISTS user (
    id INTEGER PRIMARY KEY NOT NULL,
    authToken TEXT,
    refreshToken TEXT
    );
`;

export async function createDatabase(): Promise<void> {
    try {
        let isConn = await sqlite.isConnection("db");
        let db: SQLiteDBConnection;
        if(!isConn.result) {
            db = await sqlite.createConnection("db");
        } else {
            db = await sqlite.retrieveConnection("db");
        }
        await db.open();
        let ret: any = await db.execute(createTableScript);
        await db.close();
        await sqlite.closeAllConnections();
        return Promise.resolve();
    } catch (err) {
        console.log("---DB Create Error---");
        console.log(err);
        await sqlite.closeAllConnections();
        return Promise.reject(err);
    }
}

export async function deleteUserDetails(): Promise<void> {
    try {
        let isConn = await sqlite.isConnection("db");
        let db: SQLiteDBConnection;
        if(!isConn.result) {
            db = await sqlite.createConnection("db");
        } else {
            db = await sqlite.retrieveConnection("db");
        }
        await db.open();
        let sqlcmd = `DELETE FROM user`;
        await db.query(sqlcmd);
        await db.close();
        await sqlite.closeAllConnections();
        return Promise.resolve();
    } catch (err) {
        console.log("---USER Table Delete Error---");
        console.log(err);
        await sqlite.closeAllConnections();
        return Promise.resolve();
    }
} 

export async function addUserDetails(accessToken: string, refreshToken: string = ""): Promise<void> {
    try {
        await deleteUserDetails();
        let isConn = await sqlite.isConnection("db");
        let db: SQLiteDBConnection;
        if(!isConn.result) {
            db = await sqlite.createConnection("db");
        } else {
            db = await sqlite.retrieveConnection("db");
        }
        await db.open();
        let sqlcmd: string = 
                "INSERT INTO user (authToken, refreshToken) VALUES (?,?)";
        let values: Array<any>  = [accessToken, refreshToken];
        await db.run(sqlcmd,values);
        await db.close();
        await sqlite.closeAllConnections();
        return Promise.resolve();
    } catch (err) {
        console.log("---USER TABLE INSERT Error---");
        console.log(err);
        await sqlite.closeAllConnections();
        return Promise.reject(err);
    }
}

export async function getUserDetails(): Promise<any> {
    try {
        // await createDatabase();
        // await addUserDetails("eyJhbGciOiJSUzI1NiIsImtpZCI6InBpbmctb2F1dGgiLCJwaS5hdG0iOiJyY2tvIn0.eyJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIG9mZmxpbmVfYWNjZXNzIiwiY2xpZW50X2lkIjoiYWJidmllLnAzNjB0ZXh0aW5nYXBwIiwic3ViIjoiMTUwNDg3NzgiLCJhZExvZ29uIjoiZGFsdmlheCIsImVtYWlsIjoiYW1vbC5kYWx2aUBhYmJ2aWUuY29tIiwidXBpIjoiMTUwNDg3NzgiLCJ1c2VybmFtZSI6ImFtb2wuZGFsdmlAYWJidmllLmNvbSIsImV4cCI6MTY3MzA1MDc4MX0.dlCpG9t0jqWSvp0OsYX7P6dp9HW3MuWA4ntI3qyu4tuO6yjR1Ml7p6jghxc1ztshPooKrMOPO_vPD6RNNpMw4hH_Z_ucxXzWj2zWP1GaEa5rhBkJo8J93a4pqy95pfDQYWAT5frj_EYExPMf8X69LRg6qtWt3Wb-rmLVwZh2WWPvpHz0YuBMf_ut5bLmSGGQgFRtneWpqmJQ31mz_sGURa_dGeD9kUZ4zcbuntJ8TYhCsY-X1OyqB9-TNzfFugjzx_T3edhTepnPs82yn_YKM46U0wleUi83Y6EavxY1oSa4mT1H9n8TDChidN7D5rlT5oEvebdGV7WMRyt9_voG7g", "");
        let isConn = await sqlite.isConnection("db");
        let db: SQLiteDBConnection;
        if(!isConn.result) {
            db = await sqlite.createConnection("db");
        } else {
            db = await sqlite.retrieveConnection("db");
        }
        await db.open();
        let sqlcmd = "SELECT * FROM user";
        const user = await db.query(sqlcmd);
        await db.close();
        await sqlite.closeAllConnections();
        return Promise.resolve(user.values);
    } catch (err) {
        console.log("---USER TABLE SELECT Error---");
        console.log(err);
        await sqlite.closeAllConnections();
        return Promise.reject(err);
    }
}

export async function deleteDatabase(db: SQLiteDBConnection): Promise<void> {
    try {
      let ret: any = await db.isExists();
      if(ret.result) {
        const dbName = db.getConnectionDBName();
        console.log("$$$ database " + dbName + " before delete");
        await db.delete();
        console.log("$$$ database " + dbName + " after delete " + ret.result);
        return Promise.resolve();
      } else {
        return Promise.resolve();
      }
    } catch (err) {
      return Promise.reject(err);
    }
}