import axios from "axios";
import config  from '../../env.json';
function getMsgReadAt(payload, callback){
  
    try {        
        axios.post(config.REACT_APP_MESSAGE_API_BASE+"/messageReadAt?prescOrAssocId="+payload.prescOrAssocId+"&prescOrAssocType="+payload.prescOrAssocType)
        .then(res=>{ 
          callback(res)
            console.log(res)
          // callback(res.data);
        }).catch(()=>{
            console.log("error")
          // callback(null)
        })
    } catch (error) {
        // callback(null)
    }
   
}
 




export {getMsgReadAt}