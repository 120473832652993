import axios from "axios";
import config  from '../../env.json';
function getCampaignsData(params,callback){
  let {page,pageSize}=params;
    try {
        axios.get(config.REACT_APP_CAMPAIGN_API_BASE+"/campaign/hierarchy")
        .then(res=>{ 
          
          callback(res.data);
        }).catch(()=>{
          
          callback([])
        })
    } catch (error) {
        callback([])
    }
}



export {getCampaignsData}