import axios from "axios";
import config  from '../../env.json';
function getlangTransData(params,callback){

    try {
        axios.get(config.REACT_APP_AUTH_API_BASE+'/employeepersonalisation',{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}})
        .then(res=>{ 
          debugger;
          callback(res);
        }).catch(()=>{
          debugger;
          callback([])
        })
    } catch (error) {
      debugger;
        callback([])
        
    }
  //   try {
  //     axios.get(config.REACT_APP_AUTH_API_BASE+'/employeepersonalisation',{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}})
  //     .then(res=>{ 
  //       debugger;
        
  //     callback(getval);
  //       // callback(res);
  //     }).catch(()=>{
  //       debugger;
  //       callback([])
  //     })
  // } catch (error) {
  //   debugger;
  //     callback([])
      
  // }
    
}

function getval(){
  let trans={"hi": {
    "translation": {
      "Contacts": "संपर्क"
      ,"Call Log":"कॉल लॉग"
      ,"Assets":"संपत्ति"
      ,"Templates":"तेम्प्लेत्स"
      ,"Video":"वीडियो"
      ,"Calendar":"पंचांग"
      ,"Campaign":"अभियान"
      ,"Chat":"चेट"  
    }
  }
}
return trans;
}

export {getlangTransData}