import { createSlice,PayloadAction } from '@reduxjs/toolkit';


interface userInfoDetails {
    // Id:String,
    // EmployeeV_Nbr: String,
    // EmployeeEmail_Adr: String,
    // EmployeePh_Nbr: String,
    // EmployeeVCard_Url:String,
    // EmployeeQR_Cd:String,
    // EmployeeFull_Nm:String,
    // EmployeeMsgorWhatsAppChannelType:boolean,
    timeZone:String,
    language:String,
    locale:String,
    personalisationId:String,
    callType:String
    showCallType:Boolean,
    notificationType:String,
    bottomNavBarFlag:String,
    showSuccessSnackBar:boolean,
    showErrorSnackBar:boolean,
    optOutKeywords:any,
    optInKeywords:any,
    EmployeeTerritoryId:String,
    campaignName:String
  }
const initialState: userInfoDetails = {  
    timeZone:'',
    language:'',
    locale:'',
    personalisationId:'',
    callType:'',
    showCallType:false,
    notificationType:'',
    bottomNavBarFlag:'Y',
    showSuccessSnackBar:false,
    showErrorSnackBar:false,
    optOutKeywords:[],
    optInKeywords:[],
    EmployeeTerritoryId:'',
    campaignName:''
    
};
export const personalisation = createSlice({
  name: 'employeepersonalisation',
  initialState: initialState,
  reducers: {
     
      setUserPersonalisationDeatils: (state,action: PayloadAction<any>) => { 
        // state.EmployeeV_Nbr=action.payload.EmployeeV_Nbr
        // state.Id =action.payload.Id
        state.timeZone=action.payload.timeZone
        state.language=action.payload.language
        state.locale=action.payload.locale
        state.personalisationId=action.payload.personalisationId
        state.callType=action.payload.callType
        state.notificationType=action.payload.notificationType
        state.bottomNavBarFlag=action.payload.bottomNavBarFlag
        state.campaignName=action.payload.campaignName
      },
      
      setUserCallType: (state,action: PayloadAction<any>)=>{
        state.showCallType=action.payload
      },
      setUserTerritoryId: (state,action: PayloadAction<any>)=>{
        state.EmployeeTerritoryId=action.payload
      },

      setShowSuccessSnackBar: (state,action: PayloadAction<any>)=>{
        state.showSuccessSnackBar=action.payload
      },

      setShowErrorSnackBar: (state,action: PayloadAction<any>)=>{
        state.showErrorSnackBar=action.payload
      },

      setOptOutKeywords: (state,action: PayloadAction<any>)=>{
        state.optOutKeywords=action.payload
      },

      setOptInKeywords: (state,action: PayloadAction<any>)=>{
        state.optInKeywords=action.payload
      },

    }
});

// this is for dispatch
export const { setUserPersonalisationDeatils,setUserCallType,setShowSuccessSnackBar,setUserTerritoryId,setShowErrorSnackBar,setOptOutKeywords,setOptInKeywords} = personalisation.actions;

// this is for configureStore
export default personalisation.reducer;