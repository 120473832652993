import axios from "axios";
import config  from '../../env.json';
function getAllConversationsApi(params,callback){
  let {page,limit,search}=params;

    try {
        axios.get( config.REACT_APP_MESSAGE_API_BASE +"/conversations/list?page="+page+"&limit="+limit+"&searchquery=" + search)
        .then(res=>{ 
          callback(res.data);
        }).catch(()=>{
          callback([])
        })
    } catch (error) {
        callback([])
    }   
}

function getConversationDetailsApi(params,callback){
    let {page,limit,id,type}=params;
    try {
        //axios.get(config.REACT_APP_MESSAGE_API_BASE+"/conversations/details?&page="+page+"&limit="+limit+"&recipientId="+id)
       axios.get(config.REACT_APP_MESSAGE_API_BASE+"/conversations/detailsNew?recepientId="+id+"&recepientType="+type)
        .then(res=>{
          callback(res.data);
        }).catch(()=>{
          callback([])
        })
    } catch (error) {
        callback([])
    }
} 

export {getAllConversationsApi,getConversationDetailsApi}