import React,{useEffect,useState} from 'react'
import { unshortenUrl } from '../../apis/chat/messageApi'
import { useTranslation } from "react-i18next";
import './PreviewComponent.css'


function PreviewComponet(props:any) {
  const { t } = useTranslation();
  const [data,setData]=useState<any>('Tap to preview')
  const [isVideo,setIsVideo]=useState(false)
  const [iframeView,setIframeView]=useState(false)
  const [isAudio,setIsAudio]=useState(false)
  const [videoData,setVideoData]=useState<any>(null)
  const [noPreview,setNoPreview]=useState(false)

  const [mobileView,setMobileView]=useState(false)
  
  useEffect(()=>{
    console.log(window.innerWidth)
    if(window.innerWidth<1200){
      setMobileView(true)
    }
  },[props])
  const getType=()=>{
    setData('Loading...')
    unshortenUrl({
                  "urls":[ props.value.trimStart()]
                },(res:any)=>{
      
                  if(res){
                    if(res.length>0){
                      if(res[0].type=='video'|| res[0].type=='audio'){
                        setIsVideo(true)
                      } 
                      // else if(res[0].type=='image' || res[0].type=='pdf'){
                      //   debugger
                      //   console.log("imge")
                      //   setIframeView(true)
                      //   // setData('')
                      // }
                      else {
                        setNoPreview(true)
                      }
                      // if(res[0].type== null){
                      // } 
                    }
                    else{
                      setNoPreview(true)
                    }
                    
                  }
                  else     
                  setNoPreview(true) 
                })
      
  }
  if(isVideo){
    return (
      <div>
      
        <div className={mobileView?"preview-video-mobile-container":"preview-video-container"}>
          <video  width="100%" height="100%" controls preload='metadata'>
            <source src={(props.value.trimStart().indexOf('https://')>=0 || props.value.trimStart().indexOf('http://')>=0)?props.value.trimStart():"https://"+props.value.trimStart()}/>{t('Your browser does not support the video tag.')}
          </video>

        </div>

      </div>
    ) 
  } 
  else if(iframeView){
  
   <div>
     <iframe src={(props.value.trimStart().indexOf('https://')>=0 || props.value.trimStart().indexOf('http://')>=0)?props.value.trimStart():"https://"+props.value.trimStart()} width="200px" height="250px"></iframe>
   </div>
  } else if(isAudio){
    <div>
        {/* <audio controls>
        <source src="horse.ogg" type="audio/ogg">
        <source src="horse.mp3" type="audio/mpeg">
        Your browser does not support the audio element.
      </audio> */}
    </div>
  }
  else if(noPreview)
  return (
    <div className={mobileView?'preview-tab-mobile-button':'preview-tab-button'} >{t('No preview available')}</div>
  )
  return (
    <div className={mobileView?'preview-tab-mobile-button':'preview-tab-button'} onClick={()=>{getType()}}>{data}</div>
  )
}

export default PreviewComponet