export function isImage(type) {
    const types = [
      "image/jpeg",
      "image/gif",
      "image/png",
      "image/bmp",
      "image/tiff",
      "image/jpg",
    ];
    return types.includes(type);
  }
export function isOtherVideo(type) {
    const types = [
      "video/3gpp",
      "video/3gpp2",
      "video/3gpp-tt",
      "video/mpeg",
    ];
    return types.includes(type);
  }
 export function isVideo(type) {
    const types = [
      "video/mp4",
      "video/quicktime",
      "video/webm",
      "video/H261",
      "video/H263",
      "video/H263-1998",
      "video/H263-2000",
      "video/H264",
    ];
    return types.includes(type);
  }
  export function isOtherAudio(type) {
    console.log('other audio')
    const types = [
      "audio/basic",
      "audio/L24",
      "audio/vorbis",
      "audio/vnd.rn-realaudio",
      "audio/3gpp",
      "audio/3gpp2",
      "audio/ac3",
      "audio/webm",
      "audio/amr-nb",
      "audio/amr",
    ];
    return types.includes(type);
  }
  export function isAudio(type) {
    console.log('audio')
    const types = [
      "audio/basic",
      "audio/L24",
      "audio/mp4",
      "audio/mpeg",
      "audio/ogg",
      "audio/vorbis",
      "audio/vnd.rn-realaudio",
      "audio/vnd.wave",
      "audio/3gpp",
      "audio/3gpp2",
      "audio/ac3",
      "audio/vnd.wave",
      "audio/webm",
      "audio/amr-nb",
      "audio/amr",
    ];
    return types.includes(type);
  }
  export function  isVcard(type) {
    const types = ["text/vcard", "text/x-vcard", "application/vcard"];
    return types.includes(type);
  }
  export function isPdf(type) {
    const types = [
      "application/pdf",
     
    ];
    return types.includes(type);
  }

  export function isTextFile(type){
    const types=[ "text/csv",
    "text/rtf",
    "text/richtext",
    "text/calendar",
    "text/directory",
  ]
  return types.includes(type);
  }