import moment from "moment";
export function formatDateIntoTodayYesterdayFormat(input) {
    const today = moment(new Date()).format("MM/DD/YYYY");
    var yesterdayDate = new Date(today);
    const yesterday = moment(
      yesterdayDate.setDate(yesterdayDate.getDate() - 1)
    ).format("MM/DD/YYYY");

    var inputDate = moment(input).format("MM/DD/YYYY");

    if (today == inputDate) {
      return "Today";
    } else if (yesterday == inputDate) {
      return "Yesterday";
    } else {
      return inputDate;
    }
  }

  export function getDateFormat(input:any){
    return moment(input).format("YYYY-MM-DD")
  }
  export function getTimeFormat(input:any){
    return moment(input).format("mm:ss A")
  }
  export function formatDuration(time:any) {
    let duration = time;
    let finalDuration:any;
    let d = Number(duration);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " mins, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : "";
    finalDuration = hDisplay + mDisplay + sDisplay;
    return finalDuration;
  }