import axios from "axios";
import config  from '../../env.json';
function autopilotApi(payload,callback){
  

  let url=config.REACT_APP_AUTH_API_BASE.substring(0,config.REACT_APP_AUTH_API_BASE.indexOf('/api'))
    payload= {

        "customerId": payload.Id.toString(),
        "customerType": "PRES",
        "handOffType": "E",
        "handoffOn": payload.state
    }

    try {
        axios.post(url+"/bot/PostBotHandOff",payload)
        .then(res=>{ 
          callback(true);
        }).catch(()=>{
          callback(false)
        })
    } catch (error) {
        callback(false)
    }
   
}
 
function setOutOfOffice(payload,callback){
  let url=config.REACT_APP_AUTH_API_BASE.substring(0,config.REACT_APP_AUTH_API_BASE.indexOf('/api'))
    payload= {

      "OutofOfficeStartTime":payload.newavailstrt,
      "OutofOfficeEndTime":payload.newavailend
    }

    try {
        axios.post(url+"/bot/PostEmployeeOutOfOffice",payload)
        .then(res=>{ 
          callback(true);
        }).catch(()=>{
          callback(false)
        })
    } catch (error) {
        callback(false)
    }
   
}
 
function getAutoPilot(callback){
  let url=config.REACT_APP_AUTH_API_BASE.substring(0,config.REACT_APP_AUTH_API_BASE.indexOf('/api'))

  try {
      axios.get(url+"/bot/GetBotHandOff")
      .then(res=>{ 
        callback(res.data);
      }).catch(()=>{
        callback(false)
      })
  } catch (error) {
      callback(false)
  }
 
}





export {autopilotApi,getAutoPilot,setOutOfOffice}