import React,{Component,useEffect } from 'react';
// import Avatar from '@mui/material/Avatar';
import InfiniteScroll from "react-infinite-scroll-component";
import "./Forms.css"
import { useGetContactsQuery } from '../../utils/ApiContacts';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { Search } from '@mui/icons-material';
import AddContact from '../../components/contact/AddContact'
import Tooltip from '@mui/material/Tooltip';
import ContactDetialsModal from '../../components/contact/ContactDetialsModal';
import SearchInput from '../../layouts/searchInput/SearchInput';
// import {wformatPhoneNumber,getTenDigitPhoneNumber} from '../../utils/phoneNumberUtil';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
//import { getLoginToken } from '../../app/loginReducer';
import { RootState } from '../../app/store';
import config from '../../env.json';
import { connect,ConnectedProps } from 'react-redux';
// import ContactAddPopup from '../../components/contact/ContactAddPopup';
import { setCallModalView } from '../../app/slice/callModalSlice';
import Spinner from '../../layouts/spinner/Spinner';
import Avatar from "../../components/Avatar/Avatar";
import FormsModal from '../../components/forms/FormsModal';
import { getAllFormsHistory, getFormRequestsList } from '../../apis/forms/formsapi';
import { getForm } from '../../apis/forms/formsapi';
import { UpdateForm } from '../../apis/forms/formsapi';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import moment from 'moment';
// import { isMobile } from 'react-device-detect';
// import Tab from "components/Tab";
import LogoutModal from '../../components/logoutModal/LogoutModal';
import { useTranslation, withTranslation } from "react-i18next";
const CancelToken = axios.CancelToken;
let cancel;



interface MyComponentProps {
  token: string;
}


 
class Forms extends Component<any,any> {

  constructor(props: any) {
    super(props);
    this.state = {
       formList:[],
       page:0,
       pageSize:10,
       search:'',
       show:false,
       showDetails:false,
       contact:[],
       hasMore:true,
       tempValue:'',
       gridView: false,
       token:'',
       infiniteScrollHeight:10,
      //  showCallModal: false,
       callDetails: {},
       CancelToken:null,
       loading:false,
       selectedContactIndex:0,
       msgOrCallIcon: false,
       modalShow:false,
       titlename:'Create',
       viewform:false,
       ID:'',
       deleteModal:false,
       FormId:'',
       formtab:'Tab1',
       value:'1',
      //  mobileStatus:false,
      formRequestList:[],
      Id:undefined
      };
  }
 
// const [showCallModal, setshowCallModal] = useState(false);
  componentDidMount(){

    document.documentElement.style.setProperty('--visibility', 'hidden');
    console.log(this.props.token);
    document.getElementById("page-name")?.setAttribute('data-content',this.props.t(this.props.pageTitle));
    this.getData();

    let doc:any=document;
    let formstitles:any=doc.getElementsByClassName('formlist-title')?doc.getElementsByClassName('formlist-title'):null
    if(formstitles)
    this.setState({
      infiniteScrollHeight:window.innerHeight - (formstitles[0].offsetTop+formstitles[0].offsetHeight)-20
    })
  }
  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>): void {


    document
      .getElementById("page-name")
      ?.setAttribute("data-content", this.props.t(this.props.pageTitle));
  }
  
  // showContactDetials =(item:any,i:number)=>{
  //   this.setState({contact:item,showDetails:true,selectedContactIndex:i},()=>{
  //     // this.setState({showDetails:true})
  //   });
  // } 
  // callMakerModal =(item:any)=>{
  //   this.props.dispatch(setCallModalView(true));
  //   this.setState({contact:item},()=>{
  //     // this.setState({showDetails:true})
  //   });
  // } 
   handleClickOpen = (event) => {
    this.setState({deleteModal:true});
    console.log(event);
    this.setState({FormId:event});
  };
   deleteContact=(e:any)=>{
    debugger;
    //  axios.delete(config.REACT_APP_CONTACTS_API_BASE+"/contact/"+contact.Id,{
    //   headers:{
    //     'Authorization':'Bearer '+sessionStorage.getItem('login')
    //   }
    // }).then(res=>{
    //   handleCloseParent();
    // })
    if(e === true){
      console.log("delete:"+ this.state.FormId)
      getForm({Id:this.state.FormId},
        (res:any)=>{        
    debugger;
           const activeflg=false;
           this.setState({formList:res.reverse()});
           //   setHistData(res)
              console.log('forms data is here',res);
          // console.log(res[0].EffectiveStart_DtTm);
            let s_Date=res[0].EffectiveStart_DtTm.split('T');
            let e_Date=res[0].EffectiveEnd_DtTm.split('T');
            console.log(s_Date[0]);
              res.map((x:any)=>{
                let formupdatepayload=
                {
                  Form_Nm:x.Form_Nm, 
                  Active_Flg:activeflg==false?"N":"Y" ,
                  Form_Typ:x.Form_Typ, 
                  FormSignReq_Flg:x.FormSignReq_Flg,
                  FormSignProvider_Typ:x.FormSignProvider_Typ, 
                  FormLocation_Url:x.FormLocation_Url,  
                  FormStorage_Loc:x.FormStorage_Loc,  
                  FormPrepopulateReq_Flg:x.FormPrepopulateReq_Flg,   
                  FormCallback_Url:x.FormCallback_Url,
                  FormToMedia_Flg:x.FormToMedia_Flg,
                  EffectiveStart_DtTm:s_Date[0],   
                  EffectiveEnd_DtTm:e_Date[0]  ,
                  Template_Val:x.Template_Val    
                }
                console.log(formupdatepayload);
              UpdateForm(formupdatepayload,{FormId:this.state.FormId},(res:any)=>{
                debugger;
                if(res){
                  debugger;
                  console.log(res.formId);
                  this.getData();
                  //setFormID(res.formId)
                }
                else{
                  debugger;
                  console.log(res);
                }
              })
              })
         })
    }
   else
   {
    this.getData();
   }
       
    // debugger
    // if(e === true){
    //   setShowDelete(true)
    //   deleteContactApi(contact.Id,(res)=>{
    //     handleCloseParent();
    //     debugger
    //   })
    // }
    
  }
   
   getData=()=>{
    debugger;
    // console.log("ISMOBILE"+isMobile)
    // if (isMobile) {
    //   this.setState({mobileStatus:isMobile});
    //   }
    getFormRequestsList((res:any)=>{
      this.setState({formRequestList:res})
    })
    getAllFormsHistory(
      (res:any)=>{        
  debugger;
  
         this.setState({formList:res.reverse()});
         //   setHistData(res)
            console.log('forms data is here',res);
       })
  }
  handleTabChange=(e)=>{
    debugger;
      this.setState({formtab:e});
  }
  

  handleChange = (event, newValue) => {
    this.setState({value:newValue});
  };
  getSearch=()=>{
  //   getSpecificForms(
  //     (res:any)=>{        
  // debugger;
  //        this.setState({formList:res});
  //        //   setHistData(res)
  //           console.log('forms data is here',res);
  //      })
 }

  render() {
    const {formList,page,pageSize,search,hasMore,infiniteScrollHeight}=this.state;
    
    return (
   
        <div className="contacts-container">   
          
              <FormsModal  show={this.state.modalShow}
              handleCloseParent={(e)=>{
                if(e)
                {
                  this.getData()
                } 
                this.setState({modalShow:false,Id:undefined}) 
               }}
              title={this.state.titlename} Id={this.state.Id} viewform={this.state.viewform}/>
      
      {/* {this.state.mobileStatus==true?
        <div className='MobilePage'> This content is available only on Desktop</div>: */}
          
          <div className="Forms-header row--three">
                  {/* <ul className='FormUL'>
                  <li className="FormList" onClick={()=>this.handleTabChange("Tab1")}>Form History</li>
                  <li className="FormList" onClick={()=>this.handleTabChange("Tab2")}>Form Status</li>
                </ul> */}
                <Box sx={{ width: '100%', typography: 'body1' }}>
                  <TabContext value={this.state.value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={this.handleChange} aria-label="lab API tabs example">
                        <Tab label={this.props.t("Form History")} value="1" />
                        <Tab label={this.props.t("Form Status")} value="2" />
                        <div className='formcontent__header__searchbox'>
              

              <SearchInput id={'allContactSearch'} classes={'form-control font-md search-form'} placeholder={this.props.t('Search Forms')} onChangeValue={(e)=>{this.setState({search:e,contactList:[],page:0,hasMore:true},()=>{this.getSearch()})}}/>
            
              </div>
              
              <Tooltip title={this.props.t("Add Form")} arrow>
              <div
            id="createFormId"
            className="floating-button create_contact notMobile"
            onClick={()=>{this.setState({modalShow:true,titlename:"Create"})}}
          >
    
            <i className="fas fa-plus"></i>
          </div>
                  </Tooltip>
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                        <div className="form-body" id='contacts-body'>
                        {this.state.gridView === false ?
                          <div className="formlist-title notMobile">
                            <div className='FormNameHeader'>{this.props.t('Name')}</div>
                            <div className='FormTypeHeader'>{this.props.t('Type')}</div>
                            <div className='FormURLHeader'>{this.props.t('URL')}</div>
                            <div className='FormStatusHeader'>{this.props.t('Status')}</div>
                            <div className='FormDateHeader'>{this.props.t('Date')}</div>
                            <div className='FormDateHeader'></div>
                          </div>: '' }

                          {/* LIST VIEW */}
                          {/* <ul> */}
                            
                            {/* <InfiniteScroll
                              dataLength={this.state.contactList.length}
                              next={() => {
                                console.log('ff')
                                this.setState(
                                  {
                                    page: this.state.page + 1,
                                  },
                                  () => {
                                    this.getContactsData();
                                  }
                                );
                              }}
                              
                              // scrollableTarget="contacts-body"
                              hasMore={hasMore}
                              loader={<Spinner />}
                              height={infiniteScrollHeight}
                            //   endMessage={

                            //     <p style={{ textAlign: "center",fontSize:'1rem',fontWeight:'lighter' }}>
                            //       {this.state.contactList.length==0?<span>No Forms found</span>:<span></span>}
                            //     </p>
                            //   }
                            
                            > */}
                            
                              <ul className='contact-scroll-list'  style={{height:infiniteScrollHeight}}>
                              {/* {contactList.map((item: any,i:number) => { */}           
                              {formList.map((item: any) => {
                                return (
                                  <li id={item.Id}>
                                    <div className="FormtListItem-div notMobile">
                                      <div className="FormName">
                                        {/* <Avatar {...stringAvatar(item.AcctFull_Nm)} /> */}
                                        {/* <Avatar
                                    imageType="prescriber"
                                  avatarHeight="3rem" avatarWidth="3rem" /> */}

                                        <div
                                          style={{
                                            fontSize: "1.098rem",
                                            fontWeight: "bold",
                                            paddingLeft: "2rem"
                                          
                                          }}
                                        >
                                        {item.Form_Nm}
                                        </div>
                                      </div>

                                      <div
                                      className='FormType'
                                        style={{
                                          fontVariant: "small-caps",
                                          fontSize: "1.098rem",
                                          // paddingLeft: "14%",
                                          width:"9%"                    
                                        }}
                                      >
                                        {item.Form_Typ}
                                      </div>
                                      <div
                                      className='FormURL'
                                        style={{
                                        //   fontVariant: "small-caps",
                                          fontSize: "1.098rem",
                                          // paddingLeft: "14%",
                                          width: "33%"
                                        }}
                                      >   
                                      <a className='link' target="_blank" href={item.FormLocation_Url==''?"https://p360zingforms.z13.web.core.windows.net"
                :item.FormLocation_Url}>                     
                                        {item.FormLocation_Url==''?"https://p360zingforms.z13.web.core.windows.net"
                :item.FormLocation_Url}</a>
                                      </div>
                                      <div className='Status'>
                                        {item.Active_Flg=="Y"?"Active":"Inactive"}                         
                                      </div>
                                      <div
                                      className='CreatedDate'
                                        style={{
                                          fontVariant: "small-caps",
                                          fontSize: "1.098rem",
                                          // paddingLeft: "7rem",
                                        }}
                                      >
                                        
                                      {moment(item.CreatedBy_DtTm).format("MM/DD/YYYY hh:mm a")}
                                        {/* {item.AcctPh_Nbr} */}
                                      </div>
                                    
                                      <div  style={{
                                          paddingLeft: "4rem",
                                        }}>
                                      <a className="edit" title={this.props.t("Edit")} data-toggle="tooltip" onClick={()=>{this.setState({modalShow:true,titlename:"Edit",Id:item.Id})}}><i className="material-icons">&#xE254;</i></a>
                                      <a className="view" title={this.props.t("View")} data-toggle="tooltip" onClick={()=>{this.setState({modalShow:true,titlename:"View",Id:item.Id})}}><i className="material-icons">&#xe8f4;</i></a>
                                      {item.Active_Flg=="Y"? <a className="delete" title={this.props.t("Delete")} data-toggle="tooltip" onClick={()=>{this.handleClickOpen(item.Id)}}><i className="material-icons">&#xE872;</i></a>:null}
                                      </div>
                                    </div>
                                    
                                  </li> 
                                  );
                              })}     
                              </ul> 
                              
                              <LogoutModal
                      show={this.state.deleteModal}
                      yorn={this.deleteContact}
                      modalText="Are you sure you want to delete ?"
                      // data={this.state.callDetails}
                      handleCloseParent={(e)=>{
                        console.log(e);
                        this.setState({deleteModal:false})
                      }} />
                            
                            {/* </InfiniteScroll> */}


                        
                        <div className="floating onlyMobile">
                        <Tooltip title={this.props.t("Add Contact")} arrow>
                          <div
                        id="createContactId"
                        className="floating-button create_contact"
                        onClick={()=>{this.setState({show:true})}}
                      >

                        <i className="fas fa-plus"></i>
                      </div>
                              </Tooltip>
                        </div>
                        </div>
                    </TabPanel>
                    <TabPanel value="2">
                      <div className='form-request-list'>
                    <div className="form-request-list-title notMobile row">
                            <div className='col-sm'>{this.props.t('Form Name')}</div>
                            <div className='col-sm'>{this.props.t('Prescriber')}</div>
                            <div className='col-sm'>{this.props.t('Status')}</div>
                            <div className='col-sm'>{this.props.t('Status Updated At')}</div>
                          </div>
                          <div className=" notMobile" style={{height:infiniteScrollHeight}}>
                            {
                              this.state.formRequestList.map((item:any)=>(
                                <div className='form-request-list-item row'>
                                <div className='col-sm'>{item.Form_Nm}</div>
                                <div className='col-sm'>{item.PrescriberAcctFull_Nm}</div>
                                <div className='col-sm'>{item.FormStatus.toUpperCase()}</div>
                                <div className='col-sm'>{moment(item.FormStatusUpdatedAt).format('MM/DD/YYYY hh:mm a')}</div>
                                </div>
                              ))
                            }
                           
                            
                          
                          </div>
                          </div>
                    </TabPanel>
              
                  </TabContext>
                </Box>
        
                {/* <div className="contactListView notMobile" >
                <Tooltip title="List Format" arrow>
                <i id="listFormatId" className="fas fa-list i-2p5" onClick={() =>{this.setState({ gridView:false})}}></i>
                </Tooltip>
                <Tooltip title="Grid Format" arrow>
                <i id="cardFormatId" className="fas fa-th-large i-2p5" onClick={() =>{this.setState({ gridView:true})}}></i>
                </Tooltip>
                

                </div> */}
          </div>
      {/* } */}
          {/* {this.state.formtab=="Tab1"?
          :null
          } */}


          {/* Add contact modal if contact not added  user click on message icon in contact details popup will appear */}
          {/* <ContactAddPopup 
          showAddContactModal={this.state.showAddContactModal}
          data={this.state.callDetails}
          redirectPage={this.state.msgOrCallIcon}
          handleCloseParent={(e)=>{
            console.log(e);
            this.setState({showAddContactModal:false, showDetails:false})}}
          /> */}
          
        </div>
     
    );
  }
}


const mapStateToProps = (state: any) => ({
  token: state.login.token,
  showCallModal: state.callModal.showCallModal,
  pageTitle:state.sideBar.title

});


export default withTranslation() (connect(mapStateToProps)(Forms));