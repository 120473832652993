import React ,{useState,useEffect}from 'react';
import MinimizeIcon from '@mui/icons-material/Minimize';
import MicSharpIcon from '@mui/icons-material/MicSharp';
import {ReactComponent as WhiteBoardIcon} from '../../../assets/icons/white-board.svg';
import {ReactComponent as MultiShare} from '../../../assets/images/associated_prescriber_icon.svg'
import { useSelector, useDispatch } from 'react-redux'
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseIcon from '@mui/icons-material/Close';
import Message from '../../chat/Message';
import CallEndIcon from '@mui/icons-material/CallEnd';
import {setVideoCallDetails,setVideoCallScreenTrack, setVideoCallRoom, setVideoCallShowFloater,setVideoFloaterChaton,setVideoCallCallStarted,setVideoCallEnded,setVideoCallChats, setVideoCallRecordingStarted, setVideoCallRecordingStartedTime} from '../../../app/slice/videoCallSlice';
import Crop54Icon from '@mui/icons-material/Crop54';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Participant from '../videoCall/Participant';
import {ReactComponent as RecordButton} from '../../../assets/icons/Record.svg';
import {ReactComponent as VideoEnable} from '../../../assets/icons/VideoEnable.svg';
import {ReactComponent as Setting} from '../../../assets/icons/settings.svg';
import {ReactComponent as Chat} from '../../../assets/icons/Chat.svg';
import {ReactComponent as Reactions} from '../../../assets/icons/Reaction.svg';
import {ReactComponent as Mute} from '../../../assets/icons/MuteIcon.svg';
import {ReactComponent as Unmute} from '../../../assets/icons/Unmute.svg';
import {ReactComponent as CallEnd} from '../../../assets/icons/end-call.svg';
import {ReactComponent as VideoDisable} from '../../../assets/icons/VideoDisable.svg';
import {ReactComponent as ScreenShare} from '../../../assets/icons/Screenshare.svg';
import config from '../../../env.json'
import axios from 'axios';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {ReactComponent as ParticipantsIcon} from '../../../assets/icons/ParticipantsIcon.svg';
import Snackbar from '@mui/material/Snackbar';
import './VideoFloater.css'
import Video,{createLocalTracks, createLocalVideoTrack,LocalDataTrack, LocalVideoTrack,connect} from 'twilio-video';

import ParticipantsList from '../videoCall/ParticipantsList';

import { useTranslation } from "react-i18next";
import { videoCallRecording } from '../../../apis/video/video';

function VideoFloater() {
  const { t } = useTranslation();
  const dispatch=useDispatch();
  const show =useSelector((state: any) => state.floaterVideoCall.videoCallShowFloater)
  const room=useSelector((state: any) => state.floaterVideoCall.videoCallRoom)
  // const token:any=useSelector((state: any) => state.floaterVideoCall)
  const videoFloaterChaton=useSelector((state:any)=>state.floaterVideoCall.videoFloaterChaton)
  const screenTrack=useSelector((state: any) => state.floaterVideoCall.videoCallScreenTrack)
  const videoCallScreenShareStarted=useSelector((state: any) => state.floaterVideoCall.videoCallScreenShareStarted)
  const videoCallShareUserId=useSelector((state: any) => state.floaterVideoCall.videoCallShareUserId)
  const chatsNew=useSelector((state:any)=>state.floaterVideoCall.videoCallChats)
  const videoCallEnded=useSelector((state:any)=>state.floaterVideoCall.videoCallEnded)
  const videoCallName=useSelector((state:any)=>state.floaterVideoCall.videoCallName);
    const videoCallUrl=useSelector((state:any)=>state.floaterVideoCall.videoCallUrl);
    const disconnectedParticipants:any=useSelector((state: any) => state.floaterVideoCall.disconnectedParticipants)
    const isFullScreenOn=useSelector((state:any)=>state.floaterVideoCall.isFullScreenOn)
    const videoCallRecordingStarted=useSelector((state:any)=>state.floaterVideoCall.videoCallRecordingStarted);
    // const RandomToken=useSelector((state:any)=>state.floaterVideoCall.RandomToken);
    // const OTP=useSelector((state:any)=>state.floaterVideoCall.OTP);
     const videoCallId=useSelector((state:any)=>state.floaterVideoCall.videoCallId);

  // const [room, setRoom] = useState(null);
  // const [participants, setParticipants] = useState<any>([]);
    const [bgcolor,setBgColor]= useState("#422d73")
    const [color,setColor]=useState('white')
    const [min,setMin]=useState(true);
    const [hideOptions,setHideOptions]=useState(false);
    const [isMobile,setIsMobile]=useState(false)

    const token:any=useSelector((state: any) => state.floaterVideoCall)
    // const [room, setRoom] = useState<any>(null);
    const [participants, setParticipants] = useState<any>([]);
    const [localParticipant,setLocalParticipant]=useState<any>(null);
    const [height,setHeight]=useState(10);
  const [width,setWidth]=useState(10);
  const [toggleChat,setToggleChat]=useState(true)
  const [toggleVideo,setToggleVideo]=useState(true);
  const [toggleVoice,setToggleVoice]=useState(true);
  const [dominantSid,setDominantSid]=useState<any>(null)
    
  const [more,setMore]=useState(false);
  const [mobileChat,setMobileChat]=useState(false)
  const [mobileparticipant,setMobileParticipants]=useState(false)
  const [chats,setChats]=useState<any>([])
  const [chatInput,setChatInput]=useState('')
  const [chatMsg,setChatMsg]=useState<any>(null)
  
  useEffect(() => {
    if (chats.length > 0) {
      //scrollToEle();
    }  
  })

  
  useEffect(()=>{
    
    console.log(disconnectedParticipants,room)
    
    if(disconnectedParticipants && participants.length>0){
      let p:any=participants.filter((x:any)=>x.state!='disconnected');
      debugger
      if(disconnectedParticipants.length>0){
        let t:any=[];
        p.map((x:any)=>{
          if(disconnectedParticipants.indexOf(x.sid)<0)
          t.push(x);
          return x;
        })
        setParticipants(t);
      // let t= p.filter((x)=>disconnectedParticipants.indexOf(x.sid)<0)
      console.log(t.length)
      
      }
      }
  },[disconnectedParticipants])
  const timepassfunction=(participant:any)=>{
    // let dis=[];
    // if(disconnectedParticipants)
    // if(disconnectedParticipants.length>0)
    // dis=disconnectedParticipants
    if(participant){
    if(participants.length>0){
      let allp:any=Array.from(room.participants.values())
        let p=allp.filter((x:any,i:any)=>x.state!='disconnected' && disconnectedParticipants.indexOf(x.sid)<0);     
        let i=p.findIndex(x=>x.sid==participant.sid);
        let t=p[0];
        p[0]=participant;
        p[i]=t;
     }
      setDominantSid(participant.sid)
    }else{
      setDominantSid(0)
    }
  }
   useEffect(()=>{
    console.log(chatsNew)
    debugger
    if(chatsNew){
      const filterChats = chatsNew.filter(item => item.MessageType === 'chat');
      console.log(filterChats)
      setChats(filterChats)
     // scrollToEle();
    }
    scrollToEle();
  },[chatsNew])
  
  const sendChat=()=>{
   
    let payload=  {
     "UserName": room.localParticipant.identity,
     "RoomId": room.name,
     "Message": chatInput.trim(),
     "CreatedOn":new Date().toISOString(),
     "MessageType":"chat"
     }
     setChatMsg(payload)
   
     // let temp:any=localChats//chatsNew; 
     //       temp = [...temp, payload]
     //      setLocalChats(temp)
           //setChats(temp)
          // dispatch(setVideoCallChats(temp))
           // setLocalChats(localChats.push);
     room.localParticipant.dataTracks.forEach(element => {
     
       element.track.send(JSON.stringify({"type":"message","value":payload}))
       });
 
     setChatInput('')
 
    axios.post(config.REACT_APP_VIDEO_API_BASE+'/meeting/InsertChat',payload).
    then((res)=>{
      scrollToEle();
      setChatInput('')
    }).catch((err)=>console.log(err))

  }
    // useEffect(() => {
     
    //   if(show){
        
    //   // dispatch(setVideoCallShowFloater(false))
    //       const participantConnected = participant => {
    //           setParticipants(prevParticipants => [...prevParticipants, participant]);
    //         };
        
    //         const participantDisconnected = participant => {
    //           setParticipants(prevParticipants =>
    //             prevParticipants.filter(p => p !== participant)
    //           );
    //         };
        
    //         Video.connect(token.videoCallToken, {
    //             name: token.videoCallId
    //           }).then((room:any) => {
    //             setRoom(room);
    //             room.on('participantConnected', participantConnected);
    //             room.on('participantDisconnected', participantDisconnected);
    //             room.participants.forEach(participantConnected);
    //           });
        
    //         return () => {
    //           // dispatch(setVideoCallShowFloater(true))
    //           setRoom(currentRoom => {
    //             if (currentRoom && currentRoom.localParticipant.state === 'connected') {
    //               currentRoom.localParticipant.tracks.forEach(function(trackPublication) {
    //                 trackPublication.track.stop();
    //               });
    //               currentRoom.disconnect();
    //               return null;
    //             } else {
    //               return currentRoom;
    //             }
    //           });
    //         };
    //       }
     
    //       console.log(show)
    // }, [ token,show]);
    const participantConnected = participant => {
      console.log(room)
      let part:any=Array.from(room.participants.values());
      let lRoom=room;
      let allPart:any=Array.from(lRoom.participants.values())//participants.concat(inactiveParticipant).push(participant)//Array.from(room.participants.values());
      allPart=allPart.filter((p:any)=> p.state!="disconnected" && disconnectedParticipants.indexOf(p.sid)<0 );
      setParticipants(allPart);
      sendFromMobile()
      };
  
      const reconnect=()=>{
    
        let item=videoCallUrl;
          let temp=item.split('?')[1].split('&');
          let randomToken=temp[0].split("=")[1];
          let name=temp[1].split("=")[1];
          let otp=temp[2].split("=")[1];
      
          debugger
          // dispatch(setVideoCallDetails({
          //   videoCallName:item.RoomName,
          // videoCallToken:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTSzEzNWFmMzEwMTQ1ZjFkYmZiMTI5NjEwOWUwZTUwYmVlLTE2NjEyNzYwOTAiLCJncmFudHMiOnsiaWRlbnRpdHkiOiJwYXJhZyIsInZpZGVvIjp7fSwiZGF0YV9zeW5jIjp7InNlcnZpY2Vfc2lkIjoiSVNiOTllOTc3MGFlNGUxOTJhOTI2M2RmZmVjNjZmZDBmNSJ9fSwiaWF0IjoxNjYxMjc2MDkwLCJleHAiOjE2NjEyNzk2OTAsImlzcyI6IlNLMTM1YWYzMTAxNDVmMWRiZmIxMjk2MTA5ZTBlNTBiZWUiLCJzdWIiOiJBQzE5YTljNzRjZGQ5YTNmMzRkNDQ4YjI4Y2IyZTFjYjE0In0.KLzFwRJUjp71jziX9cQId3yHdi__RvHbXv4LjcRl4QY",
          // videoCallId:item.RoomId,
          // videoCallUrl:item.WebHookUrl,
          // }))
          // // window.open(url, '_blank', 'noopener,noreferrer');
          // dispatch(setVideoCallShowFloater(true))
         
          console.log('before')
          axios.get(config.REACT_APP_VIDEO_API_BASE+'/meeting/GetAuthTokenFromZing',{params:{
            RandomToken:randomToken,
            OTP: otp,
            RoomId:videoCallId,
            UserName:name
          }}).then((res)=>
          {
            console.log(res)
            
            dispatch(setVideoCallDetails({
            videoCallName:videoCallName,
            videoCallToken:res.data.data,
            videoCallId:videoCallId,
            videoCallUrl:videoCallUrl,
            }))
            connect(res.data.data, {
              name:videoCallId,
              audio:true,
              video:true,
              networkQuality: {
                local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
                remote: 2 // RemoteParticipants' Network Quality verbosity [0 - 3]
              },
              dominantSpeaker: true,
            }).then((room:any) => {
              room.localParticipant.setNetworkQualityConfiguration({
                local: 2,
                remote: 1
              });
              room.localParticipant.publishTrack(new LocalDataTrack());
              dispatch(setVideoCallShowFloater(true))
              dispatch(setVideoCallRoom(room));
              dispatch(setVideoCallEnded(false))
              dispatch(setVideoCallCallStarted(true))
    
            })
           
          })

      }
      const participantDisconnected = participant => {
        console.log(room)
        // console.log(participant.identity,'viddeo disconnected')
        // let part=participants;
        // let index=participants.findIndex(x=>x.sid==participant.sid)
        // part=part.filter((p,i)=> i!== index);
        // console.log(part.length,'viddeo disconnected')
      let allPart:any=Array.from(room.participants.values());
      allPart=allPart.filter((p:any)=> p.state!="disconnected" && p.sid!=participant.sid && disconnectedParticipants.indexOf(p.sid)<0 );
  
      let p=[];
      p=p.concat(allPart);
      setParticipants(p);
      
      let lroom=room
      // lroom.participants=new Map(allPart);
      // dispatch(setVideoCallRoom(lroom))
      };
  //   useEffect(()=>{
  //     console.log(room)
  //     if(show && room){
  //      if(room){
  //       if(room._options){
  //         room.on('disconnected', function(room, error) {
  //           if (error) {
  //             console.log('Unexpectedly disconnected:', error);
  //           }
            
  //         });
  //       setLocalParticipant(room.localParticipant)
  //       room.on('participantConnected',participantConnected);
  //       room.on('participantDisconnected', participantDisconnected);
  //       room.participants.forEach(participantConnected);
  //       room.on('dominantSpeakerChanged', participant => {
        
  //         setTimeout(() => {
  //           timepassfunction(participant)
  //         }, 1000);
          
  //       });
  //       room.localParticipant.videoTracks.forEach((publication:any) => {
  //         if(!publication.track.isEnabled){
  //         publication.track.disable();
  //         setToggleVideo(false) 
  //        }
  //        else{
  //         publication.track.enable();
  //        setToggleVideo(true) 
  //        }
  //     });
  //     room.localParticipant.audioTracks.forEach((publication:any) => {
  //      if(!publication.track.isEnabled){
  //      publication.track.disable();
  //     setToggleVoice(false) 
      
  //     }
  //     else{
  //       publication.track.enable();
  //       setToggleVoice(true) 
  //      }
  //  });
  // }
  // else{
  //   reconnect()
  //   //code to reconnect local
  // }
  //      }
  //      else{
  //       setParticipants([])
  //       dispatch(setVideoCallShowFloater(false))
  //     }
  //     }
  //     return()=>{
  //       setMobileChat(false)
  //     }
  //   },[show,room])
  useEffect(() => {
    
    console.log(room)
   if(room){
    
   
    
      
    if(room._options){
      let allPart:any=Array.from(room.participants.values());
      let PCount = allPart.length;
      if(PCount==0){
          //insertVideoMeetingInfo();
        }
      
      setLocalParticipant(room.localParticipant)
      room.on('participantConnected',participantConnected);
      room.on('participantDisconnected',participantDisconnected);
      room.participants.forEach(participantConnected);
      room.on('participantReconnecting',(e)=>{console.log('reconnecting')})
      room.on('recordingStarted',(e)=>{dispatch(setVideoCallRecordingStarted(true))})
      room.on('recordingStopped',(e)=>{dispatch(setVideoCallRecordingStarted(false))})
      room.on('dominantSpeakerChanged',async (participant:any) => {
        setTimeout(() => {
          timepassfunction(participant)
        }, 1000);
        
        
      });
      room.localParticipant.videoTracks.forEach((publication:any) => {
        if(!publication.track.isEnabled){
        publication.track.disable();
        setToggleVideo(false) 
        }
        else{
        publication.track.enable();
        setToggleVideo(true) 
       }
    });
    room.localParticipant.audioTracks.forEach((publication:any) => {
     if(!publication.track.isEnabled){
     publication.track.disable();
    setToggleVoice(false) 
    
    }
    else{
      publication.track.enable();
      setToggleVoice(true) 
     }
 });

    }
    else{
    //to reconect 
    if(config.REACT_APP_IS_CROSS_PLATFORM==false)
    reconnect()
    else{
      dispatch(setVideoCallRecordingStarted(false))
      dispatch(setVideoCallShowFloater(false))
      dispatch(setVideoCallRoom(null))
      dispatch(setVideoCallEnded(true))
      dispatch(setVideoCallCallStarted(false)) 
    }
    
    }
    
   }
  else{
    setParticipants([])
    dispatch(setVideoCallShowFloater(false))
  }
    return () => {
      setMobileChat(false)
    }
    
  }, [show,room])
  const sendFromMobile=()=>{
    const isMobileDevice:any = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    if(isMobileDevice){
      console.log("yes im in")
      room.localParticipant.dataTracks.forEach(element => {

        element.track.send(JSON.stringify({ "type": "mobframeui", "value": {
          "participantSid": room.localParticipant.sid,
          "FromMobile": "yes"
        } }))
        })
    }
  }
    
    
    useEffect(() => {
      if(!min){
      let doc:any=document;
      let h=doc.getElementsByClassName('videoFloater_videoDiv')[0].offsetHeight;
      let w=doc.getElementsByClassName('videoFloater_videoDiv')[0].offsetWidth;
      console.log(w,h)
      setHeight(h);
      setWidth(w);
    }
    }, [min])
    
    useEffect(()=>{
      if(chatMsg){
        console.log(chatsNew)
        let t=[...chatsNew, chatMsg]
        dispatch(setVideoCallChats(t))
      }
    },[chatMsg])
    const remoteParticipants = participants.map((participant:any,i:any) => (
      <Participant 
      annotationset={(data:any)=>{
        
       
        setChatMsg(data)
         
                
      }}
      disconnected={(e)=>{
        debugger
       participantDisconnected(e)
      }}
       dominant={participant.sid==dominantSid} video={true} mute={false} key={participant.sid} participant={participant} parentWidth={width}
      parentHeight={height}
      children={i+2} />
    ));
      
    useEffect(() => {
       if (window.innerWidth <= 600) {
         setIsMobile(true);
       } else setIsMobile(false);
       window.addEventListener(
         "resize",
         function (event) {
           if (window.innerWidth <= 600) {
             setIsMobile(true);
           } else setIsMobile(false);
         },
         true
       );
     }, []);
    
  //   const connectTOTwilio=async()=>{
  //     console.log(token.videoCallToken,token.videoCallId)
  // // await  connect(token.videoCallToken, { name:token.videoCallId,audio: true,video: { width: 640 } }).then(room => {
  // //       console.log(`Successfully joined a Room: ${room}`);
  // //       room.on('participantConnected', participant => {
  // //         console.log(`A remote Participant connected: ${participant}`);
  // //       });
  // //     }, error => {
  // //       console.error(`Unable to connect to Room: ${error.message}`);
  // //     });

  // let localTracks:any
  // // createLocalTracks({
  // //   audio: true,
  // //   video: { width: 640 }
  // // }).then((localTracks:any) => {
  // //   localTracks=localTracks;
  // //   return connect(token.videoCallToken, {
  // //     name:token.videoCallId,
  // //     tracks: localTracks
  // //   });
  // // }).then(room => {
  // //   console.log(localTracks)
  // //   console.log(`Connected to Room: ${room.name}`);
  // // });

  // // connect('$TOKEN', {
  // //   audio: true,
  // //   name: 'my-room-name',
  // //   video: { width: 640 }
  // // }).then(room => {
  // //   console.log(`Connected to Room: ${room.name}`);
  // // });
      
  // const participantConnected = (participant:any) => {
  //   setParticipants(prevParticipants => [...prevParticipants, participant]);
  // };
  // const participantDisconnected = (participant:any) => {
  //   setParticipants(prevParticipants =>
  //     prevParticipants.filter(p => p !== participant)
  //   );
  // };
  // Video.connect(token.videoCallToken, {
  //   name: token.videoCallId
  // }).then((room:any) => {
  //   setRoom(room);
  //   room.on('participantConnected', participantConnected);
  //   room.on('participantDisconnected', participantDisconnected);
  //   room.participants.forEach(participantConnected);
  // });
  //   }
    
  const screenshare=()=>{
    console.log('hi',screenTrack)
    
    if(screenTrack){
      screenTrack.stop();
      room.localParticipant.unpublishTrack(screenTrack)
      room.localParticipant.videoTracks.forEach((publication:any) => {
        if(!publication.track.isEnabled){
        publication.track.disable();
        
        }
        else{
        publication.track.enable();
        
       }
    });
      room.localParticipant.dataTracks.forEach(element => {    

        element.track.send(JSON.stringify({"type":"screenshare","value":false}))
  
      });
      dispatch(setVideoCallScreenTrack(null))
    }else{
      
    navigator.mediaDevices.getDisplayMedia({
      video: true
    }).
    then(stream => {
     let screenTrack:any = (stream.getTracks()[0]);
     console.log(screenTrack)
     screenTrack = new LocalVideoTrack(screenTrack,{name:'user-screen',logLevel:'info'})
     screenTrack.on('disable',()=>{console.log('disable')})
     screenTrack.on('stopped',()=>{
      console.log('ended')
          screenTrack.stop();
          room.localParticipant.unpublishTrack(screenTrack)
          room.localParticipant.videoTracks.forEach((publication:any) => {
            if(!publication.track.isEnabled){
            publication.track.disable();
            
            }
            else{
            publication.track.enable();
            
           }
        });
          room.localParticipant.dataTracks.forEach(element => {    
            element.track.send(JSON.stringify({"type":"screenshare","value":false}))
          });
          console.log(room)
          
          dispatch(setVideoCallScreenTrack(null))
     })
    //  screenTrack.on('disable',()=>{console.log('disable')})

        dispatch(setVideoCallScreenTrack(screenTrack))
        room.localParticipant.publishTrack(screenTrack);
        room.localParticipant.dataTracks.forEach(element => {    

          element.track.send(JSON.stringify({"type":"screenshare","value":true}))
    
        });
        console.log(room)
      //   screenTrack.addEventListener("ended", () => {
      //     console.log('ended')
      //     room.localParticipant.unpublishTrack(screenTrack)
      //     console.log(room)
      //     screenTrack.stop();
      // dispatch(setVideoCallScreenTrack(null))

      //   });;
    }).catch((error) => {
      console.log(error)
      alert('Could not share the screen.')
  });
}


  }

  const scrollToEle = () => {
    setTimeout(() => {
      const lastMsg = document.getElementById('msg-' + chats.length);
      lastMsg?.scrollIntoView({
        behavior: 'smooth'
      });
    }, 200);
  }


  const blurBack=async()=>{
  //   console.log('hi blur')
  //   const bg = new GaussianBlurBackgroundProcessor({
  //     assetsPath: '/',
  //     debounce: 10,
  //   });
  //  bg.loadModel().then(res=>{console.log(res)}).catch(res=>{console.log(res)})
    
    //room.localParticipant.videoTracks[0].track.addProcessor(bg);

 
  
  }
  const muteUnmute=(mute)=>{
    if(mute){
      room.localParticipant.dataTracks.forEach(element => {    
        element.track.send(JSON.stringify({"type":"mute","value":true})) 
      });
      room.localParticipant.audioTracks.forEach(publication => {
      publication.track.disable();
    });
    room.localParticipant.audioTracks.forEach(publication => {
      publication.track.emit('disable')
    });
    setToggleVoice(!toggleVoice);
    }
    else{
      room.localParticipant.dataTracks.forEach(element => {    
        element.track.send(JSON.stringify({"type":"mute","value":false})) 
      });
      room.localParticipant.audioTracks.forEach(publication => {
        publication.track.enable();
      });
      setToggleVoice(!toggleVoice)
    }
    
  }
  const videoOnOff=(video)=>{
    // if(video){
    //   room.localParticipant.videoTracks.forEach((publication:any) => {
    //     publication.track.disable();
    //     publication.track.stop();
    // }); 
    // setToggleVideo(!toggleVideo)
    // }
    // else{
    //   // createLocalVideoTrack().then(track=>{
                      
    //                 //   // room.localParticipant.publishTrack(track).then((x)=>{

    //                 //   //   x.track.disable();
    //                 //   //   x.track.enable()
    //                 //   //   // room.localParticipant.videoTracks.forEach((publication:any) => {
    //                 //   //   //   console.log(publication)
                          
    //                 //   //   //  publication.track.disable();
    //                 //   //   //  publication.track.enable();
    //                 //   //   // })
    //                 //   // });
    //                 //   room.localParticipant.publishTrack(track);
    //                 //   room.localParticipant.videoTracks.forEach((publication:any) => {
                          
                          
    //                 //      publication.track.enable();
    //                 //      publication.track.start();
    //                 //     })

    //                 // })
    //                 room.localParticipant.videoTracks.forEach((publication:any) => {
    //                   console.log(publication)
    //                   // publication.unpublish();
    //                   publication.track.enable();
    //                   // publication.track.stop();
    //               }); 
    //                 console.log(room)
                    
    //                 ;
    //                 //dispatch(setVideoCallRoom(room));
    //                 setToggleVideo(!toggleVideo)
    // }
    if (room) { 
      if (video) {
         // turn off   
           room.localParticipant.videoTracks.forEach(function (publication) {
           publication.track.disable() 
          });
           setToggleVideo(!toggleVideo)
        } else {
           // turn on 
            room.localParticipant.videoTracks.forEach(function (publication) {
               publication.track.enable();});
               setToggleVideo(!toggleVideo)
            }
          }
        
  }
  const setVideoCallRecording=()=>{
    if(room)
    videoCallRecording({
      roomSId:room.sid,
      start:!videoCallRecordingStarted
    },(res)=>{
      console.log(res)
      
    })
    dispatch(setVideoCallRecordingStarted(!videoCallRecordingStarted))
  }
  const endCall=()=>{
    // room.localParticipant.videoTracks.forEach(publication => {
    //   const attachedElements = publication.track.detach();
    //   publication.track.stop();
    //       attachedElements.forEach(element => element.remove());
    //   });
    //   room.localParticipant.audioTracks.forEach(publication => {
    //     const attachedElements = publication.track.detach();
    //     publication.track.stop();
    //         attachedElements.forEach(element => element.remove());
    //     });
    room.localParticipant.dataTracks.forEach(element => {    
      element.track.send(JSON.stringify({"type":"pdftron","value":null})) 

    });

    room.localParticipant.tracks.forEach(publication => {
      
      
      if(publication.kind=="video"||publication.kind=="audio")
      {
      const attachedElements = publication.track.detach();
      attachedElements.forEach(element => element.remove());
      publication.track.stop();
      }
      });


//         const mediaTracks = [room.localParticipant.videoTracks,room.localParticipant.audioTracks];
// const tracks = [...mediaTracks, room.localParticipant.dataTracks];

// mediaTracks.forEach(track => console.log(track));
// room.localParticipant.unpublishTracks(tracks);
// mediaTracks.forEach(track => track.detach());
    room.disconnect();
    dispatch(setVideoCallRecordingStarted(false))
    dispatch(setVideoCallRecordingStartedTime(null))
    dispatch(setVideoFloaterChaton(false))
    dispatch(setVideoCallRoom(null))
    dispatch(setVideoCallEnded(true))
  dispatch(setVideoCallCallStarted(false))
  dispatch(setVideoCallShowFloater(false))
    //setRoom(null)
  }
 const getMuteOrNot=(x)=>{
//  let t= Array.from(x.audioTracks.values())
//   .map((publication:any) => publication.track)
//   .filter((track) => {track !== null});
//   if(t){
//   let t1=t[0];
//   if(t1){
//   if(!videoTrack.isEnabled || !videoTrack.isStarted){
//     setVideoOff(true)
//   }
//   videoTrack.attach(videoRef.current);
//   videoTrack.on('disabled',()=>{console.log('disable');setVideoOff(true)})
//   videoTrack.on('enabled', ()=>{console.log('enabled');setVideoOff(false)})
//   videoTrack.on('stopped',()=>{console.log('stopped');setVideoOff(true)})
//  videoTrack.on('started', ()=>{console.log('started');setVideoOff(false)})
//   }
//   }
 

  return false;
   
  }

  function truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }


  //if(show && !videoCallEnded)
  if(show && room && !isFullScreenOn)
  return (
    <div>
      {
        !isMobile?<div>  
        <div className='videoFloaterDiv' style={{background:bgcolor,color:color}}>
              <div style={{margin:"0 0 5px 0"}}>
              {
                hideOptions?<KeyboardArrowDownIcon onClick={()=>{setHideOptions(false)}}/>:<KeyboardArrowUpIcon onClick={()=>{setHideOptions(true)}}/>
              }
              </div>
            <div className='videoFloater_header' style={{display:hideOptions?"none":"flex"}}>
               {
                min?null:<span>{truncate(videoCallName,15)}</span>
               }
               <div style={{margin:"0 0 1rem 0"}}>
                {
                  min?<Crop54Icon onClick={()=>{setMin(false)}}/>:<MinimizeIcon onClick={()=>{setMin(true)}}/>
                }
               </div>
            </div>
            <div className='videoFloaterAfterName' style={{display:hideOptions?"none":"block"}}>
            <div className='videoFloater_videoDiv' style={{display:min?"none":"block", height: '9rem',
        width: '12rem'}}>
            {room && localParticipant? (
            <Participant
            annotationset={()=>{}}
            video={false}
            dominant={localParticipant.sid==dominantSid}
            mute={false}
              key={localParticipant.sid.sid}
              participant={room.localParticipant}
              parentWidth={height}
              parentHeight={width}
              children={ 1}
            />
          ) : (
            ''
          )}
          

          
          {remoteParticipants}
            </div>
            <div className='videoFloater_secKeys' >
              <div style={{display:min?"block":"flex",justifyContent:"space-between",alignItems:"center",paddingTop:"0rem"}}>
              {
                config.REACT_APP_SHOW_RECORD_VIDEO=="Y"?
                <div>
                  
                  <RecordButton onClick={()=>{setVideoCallRecording()}} style={{width: '1rem',fill:videoCallRecordingStarted?"red":"var(--mobile-video-footer-icons)"}} />
                  
                  </div>:null
              }
                
                     <div>{
                      toggleVoice?
                      <Mute onClick={()=>{muteUnmute(true)}}  style={{width: '0.7rem', fill: color}} />:
                      <Unmute onClick={()=>{muteUnmute(false)}} style={{width: '0.7rem', fill: color}} />
                      }
                    </div>
                    <div>{
                      toggleVideo?
                      <VideoEnable onClick={()=>{videoOnOff(true)}} style={{width: '1rem', fill: color}}/>
                      :
                      <VideoDisable onClick={()=>{videoOnOff(false)}} style={{width: '1rem', fill: color}}/>
                      }</div>
                      {
                        config.REACT_APP_SHOW_SCREENSHARE_VIDEO=="Y"?
                        <div> <ScreenShare onClick={()=>{screenshare()}} style={{width: '1rem', fill: color}}/></div>:null
                      }
                      
                      <div> <Chat style={{width: '1rem', fill: color}} onClick={()=>{dispatch(setVideoFloaterChaton(!videoFloaterChaton))}} /></div>
                      <div> <CallEndIcon color='error' onClick={()=>{endCall()}}/></div>
            {/* <div> <WhiteBoardIcon  style={{width: '1rem', fill: color}}/></div> */}
            </div>
            {/* <div style={{display:min?"block":"flex",justifyContent:"space-between"}}> */}
            {/* <div> <MultiShare  style={{width: '1.3rem', fill: color}}/></div> */}
            {/* <div> <Setting style={{width: '1rem', fill: color}}/></div> */}
            {/* <div> <Chat style={{width: '1rem', fill: color}} onClick={()=>{dispatch(setVideoFloaterChaton(!videoFloaterChaton))}} /></div> */}
            {/* <div> <ScreenShare onClick={()=>{screenshare()}} style={{width: '1rem', fill: color}}/></div> */}
            {/* <div> <CallEndIcon color='error' onClick={()=>{endCall()}}/></div> */}
            {/* </div> */}
            </div>
            </div>
            
            
        </div>
        {
          videoFloaterChaton?
          <div className='videoFloater_chatDiv'>
        <div className='videoFloater_chatFooter'>
               <input placeholder={t('Write your message')+'..'} value={chatInput} onChange={(e)=>setChatInput(e.target.value)}/>
               <button
                            className="btn btn-light"
                            onClick={()=>{sendChat()}}
                            disabled={!chatInput || chatInput.trim().length==0}  
                            >
                            <i
                                data-v-4d521fc4=""
                                data-name="send"
                                data-tags="message,mail,email,paper airplane,paper aeroplane"
                                data-type="send"
                                className="feather feather--send i-2"
                                
                            >
                            <svg
                                    style={{ color: 'var(--primary-color)' }}
                                data-v-4d521fc4=""
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-send feather__content"
                            >
                                <line x1="22" y1="2" x2="11" y2="13"></line>
                                <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                            </svg>
                        </i>
                    </button>
               </div>
          <div className='videoFloater_chatMain videochatContainer'>
            {
              localParticipant?
              <ul>
          
              {
                chats.map((x:any,i)=>(
                 <div className='chatListDivVideo' style={{float:x.UserName==localParticipant.identity?"right":'left',justifyContent:x.UserName==localParticipant.identity?"flex-end":'flex-start'}} >
                    {
                      (x.UserName!=localParticipant.identity)?
                      <div className='userIconvideoChat'>
                      <img src='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'/>
                    </div>:null
                    }
                   
                  <li key={x.CreatedOn+i} id={x.CreatedOn+i} className={'videomessage '+ (x.UserName==localParticipant.identity?"out":"in")}>
                    
                    <div  id={'msg-' + (i + 1)}>
                      <div>
                        {x.UserName}
                      </div>
                      {x.Message}
                    </div>
                  </li>
                  {
                      (x.UserName==localParticipant.identity)?
                      <div className='userIconvideoChat'>
                      <img src='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'/>
                    </div>:null
                    }
                  </div>
                  )
                )
              }
              </ul>:null
            }
        
          
          </div>
              
            </div>:null
        }
        </div>:

        <div className='mobileCallFloater'>
          {
            mobileChat?
            <div className='mobileCallChat'>
          
      <div className='sectionChatMain'>
          <div className='videochatContainer'>
          {
              localParticipant?
          <ul>
           
           {
             chats.map((x:any,i)=>(
              <div className='chatListDivVideo' style={{float:x.UserName==localParticipant.identity?"right":'left',justifyContent:x.UserName==localParticipant.identity?"flex-end":'flex-start'}} >
                 {
                   (x.UserName!=localParticipant.identity)?
                   <div className='userIconvideoChat'>
                   <img src='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'/>
                 </div>:null
                 }
                
               <li key={x.CreatedOn+i} id={x.CreatedOn+i} className={'videomessage '+ (x.UserName==localParticipant.identity?"out":"in")}>
                 
                 <div  id={'msg-' + (i + 1)}>
                   <div>
                     {x.UserName}
                   </div> 
                   {x.Message}
                 </div>
               </li>
               {
                   (x.UserName==localParticipant.identity)?
                   <div className='userIconvideoChat'>
                   <img src='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'/>
                 </div>:null
                 }
               </div>
               )
             )
           }
           </ul>
           :null}
          </div>
          <div className='videochatfooter'>
          <input placeholder={t('Write your message')+'..'} value={chatInput} onChange={(e)=>setChatInput(e.target.value)}/>
                 <button
                              className="btn btn-light"
                              onClick={()=>{sendChat()}}
                              >
                              <i
                                  data-v-4d521fc4=""
                                  data-name="send"
                                  data-tags="message,mail,email,paper airplane,paper aeroplane"
                                  data-type="send"
                                  className="feather feather--send i-2"
                                  
                              >
                              <svg
                                      style={{ color: 'var(--primary-color)' }}
                                  data-v-4d521fc4=""
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-send feather__content"
                              >
                                  <line x1="22" y1="2" x2="11" y2="13"></line>
                                  <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                              </svg>
                          </i>
                      </button>
          </div>
      
               
          </div>
            </div>:null
          }
          {
            mobileparticipant?<div className='mobileCallChat'>
              <div style={{height:'100%',overflow:'auto'}}>
              <ParticipantsList/>
              </div>
            </div>:null
          }
          {
            more?
            <div className='mobileCallFloaterActions'>
              
          <div><Chat style={{width: '1rem', fill: color}} onClick={()=>{setMobileChat(!mobileChat);setMobileParticipants(false)}} /></div>
          <div><ParticipantsIcon style={{width: '1rem', fill: color}} onClick={()=>{setMobileChat(false);setMobileParticipants(!mobileparticipant)}}/></div>
          {/* <div> <ScreenShare onClick={()=>{screenshare()}} style={{width: '1rem', fill: color}}/></div>
          <div> <Setting style={{width: '1rem', fill: color}}/></div> */}
          </div>:null
          }
          
          <div className='mobileCallFloaterActions' >
          <div style={{display:'none'}}>
              {room && localParticipant? (
            <Participant
            annotationset={()=>{}}
            video={false}
            dominant={localParticipant.sid==dominantSid}
            mute={false}
              key={localParticipant.sid.sid}
              participant={room.localParticipant}
              parentWidth={height}
              parentHeight={width}
              children={ 1}
            />
          ) : (
            ''
          )}
          

          
          {remoteParticipants} 

              </div>
              {
                config.REACT_APP_SHOW_RECORD_VIDEO=="Y"?
                <div>
                  
                  <RecordButton onClick={()=>{setVideoCallRecording()}} style={{width: '1.5rem',fill:videoCallRecordingStarted?"red":"var(--mobile-video-footer-icons)"}} />
                  
                  </div>:null
              }
            <div>{
                      toggleVoice?
                      <Mute onClick={()=>{muteUnmute(true)}}  style={{width: '1.2rem', fill: color}} />:
                      <Unmute onClick={()=>{muteUnmute(false)}} style={{width: '1.2rem', fill: color}} />
                      }
                    </div>
                    <div>{
                      toggleVideo?
                      <VideoEnable onClick={()=>{videoOnOff(true)}} style={{width: '1.7rem', fill: color}}/>
                      :
                      <VideoDisable onClick={()=>{videoOnOff(false)}} style={{width: '1.7rem', fill: color}}/>
                      }</div>
            {/* <div> <WhiteBoardIcon  style={{width: '1rem', fill: color}}/></div> */}
            <div> <CallEndIcon style={{width: '1.8rem', height: 'auto'}} color='error' onClick={()=>{endCall()}}/></div>
            <div> <MoreVertIcon style={{width: '1.5rem', fill: color}} onClick={()=>{if(more){setMobileChat(false);setMobileParticipants(false)}; setMore(!more);}}></MoreVertIcon></div>
            {/* <div style={{display:min?"block":"flex",justifyContent:"space-between"}}>
            
           
            <div> <ScreenShare onClick={()=>{screenshare()}} style={{width: '1rem', fill: color}}/></div>
            <div> <CallEndIcon color='error' onClick={()=>{endCall()}}/></div>
            </div> */}
            </div>
        </div>
      }
    
    </div>
  )
  else
  return null
}

export default VideoFloater