import axios from "axios";
import config  from '../../env.json';
function getCallLogsAPi(callback){
  
    try {
        axios.get(config.REACT_APP_CALL_API_BASE+"/calllogs")
        .then(res=>{ 
          callback(res.data);
        }).catch(()=>{
          callback([])
        })
    } catch (error) {
        callback([])
    }
   
}
 




export {getCallLogsAPi}