import React,{useEffect,useState}from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeView from '@mui/lab/TreeView';
import TreeItem,{ TreeItemProps}  from '@mui/lab/TreeItem';
import './TreeView.css'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import {ReactComponent as CampaignIcon} from '../../assets/images/campaign_icon.svg';
import {ReactComponent as WaveIcon} from '../../assets/icons/Wave-icon.svg';
import {ReactComponent as ProductIcon} from '../../assets/icons/Product.svg';
import {ReactComponent as TemplateIcon} from '../../assets/icons/Template.svg';
import {ReactComponent as AssetIcon} from '../../assets/icons/Assets.svg';

// import { useSpring, animated }  from 'react-spring/web.cjs';

import { alpha, styled } from '@mui/material/styles';


function MinusSquare(props: SvgIconProps) {
    return (
      // <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      //   {/* tslint:disable-next-line: max-line-length */}
      //   <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
      // </SvgIcon>
      <i className='minusIcon'>
        
      </i>
    );
  }
  
  function PlusSquare(props: SvgIconProps) {
    return (
      <i className='plusIcon'>
        
      </i>
      // <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      //   {/* tslint:disable-next-line: max-line-length */}
      //   <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
      // </SvgIcon>
    );
  }
  
  function CloseSquare(props: SvgIconProps) {
    return (
      <SvgIcon
        className="close"
        fontSize="inherit"
        style={{ width: 14, height: 14 }}
        {...props}
      >
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
      </SvgIcon>
    );
  }
const data = [
    {
    id: '1',
    name:'campaign1',
    type:'Campaign',
    labelName:"name",
    idName:['type','name','id'],
    childName:'children',
    children: [
      {
        id: '1',
        name:'wave1',
        type:'wave',
        labelName:"name",
        idName:['type','name','id'],
        mainItem:true,
      },
      {
        mainItem:true,
        id: '2',
        name:'wave2',
        type:'wave',
        labelName:"name",
        idName:['type','name','id'],
      },
      {
        mainItem:true,
        id: '3',
        name:'wave3',
        type:'wave',
        labelName:"name",
        idName:['type','name','id'],
      },

      
    ],
  },
  {
    id: '2',
    name:'campaign1',
    type:'Campaign',
    idName:['type','name','id'],
    labelName:"name",
    childName:'children',
    children: [
      {
        id: '4',
        mainItem:true,
        name:'wave1',
        labelName:"name",
        type:'wave',
        idName:['type','name','id'], 
      },
      {
        id: '5',
        name:'wave2',
        mainItem:true,
        type:'wave',
        labelName:"name",
        idName:['type','name','id'], 
      },
      {
        id: '6',
        name:'wave3',
        type:'wave',
        mainItem:true,
        labelName:"name",
        idName:['type','name','id'], 

      },      
    ],
  }
];
// function TransitionComponent(props: TransitionProps) {
//   const style = useSpring({
//     from: {
//       opacity: 0,
//       transform: 'translate3d(20px,0,0)',
//     },
//     to: {
//       opacity: props.in ? 1 : 0,
//       transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
//     },
//   });

//   return (
//     <animated.div style={style}>
//       <Collapse {...props} />
//     </animated.div>
//   );
// }

// const StyledTreeItem = styled((props: TreeItemProps) => (<TreeItem {...props} />))
// (({ theme }) => ({
//   [`& .${treeItemClasses.iconContainer}`]: {
//     '& .close': {
//       opacity: 0.3,
//     },
//   },
//   [`& .${treeItemClasses.group}`]: {
//     marginLeft: 15,
//     paddingLeft: 18,
//     borderLeft: `1px solid ${alpha(theme.palette.text.primary, 0.4)}`,
//   },
// }));
const StyledTreeItem = (props: TreeItemProps)=>{
  return <div className='treeViewItem'>
    
    <TreeItem {...props} />
    <div className="mask"></div>
    </div>
}


export default function TreeViewNew(props) {
  const [expanded, setExpanded] = React.useState<string[]>(['Campaigncampaign11']);
  const [mainParent,setMainParent]=useState([]);
//   const [selected, setSelected] = React.useState<string[]>([]);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    
    debugger
    setExpanded(nodeIds);
    
  };

//   const handleSelect = (event: React.SyntheticEvent, nodeIds: string[]) => {
//     setSelected(nodeIds);
//   };

  const handleExpandClick = () => {
    setExpanded((oldExpanded) =>
      oldExpanded.length === 0 ? ['1', '5', '6', '7'] : [],
    );
  };

  useEffect(() => {
    
    if(props.data){
        setMainParent(props.data)
    }
    
  }, [data])
  
//   const handleSelectClick = () => {
//     setSelected((oldSelected) =>
//       oldSelected.length === 0 ? ['1', '2', '3', '4', '5', '6', '7', '8', '9'] : [],
//     );
//   };

const getNodeId=(item:any)=>{
    if(!item) return ''
    let id=''
    item.idName.map((x:any)=>{id+=item[x];return x;});
    
        return id;
}
const getTreeItem=(item)=>{
  
    console.log(item)
    if(!item) return null;
    return <StyledTreeItem nodeId={getNodeId(item)} label={"("+item['type'].toUpperCase()+")"+item[item['labelName']]}>
        {
            item.mainItem?<div className='itemInside'>
            {props.mainItemView(item)}                
            </div>:null
        }
        {item[item.childName]?item[item.childName].map((x:any)=>(getTreeItem(x))):null}
</StyledTreeItem>
}

const clickLi=(e,spanOrIcon)=>{
    e.preventDefault();
    console.log(e.target)
    let elem=e.target;
    let hide=true;
    debugger
    if(spanOrIcon=="icon"){
        hide=elem.classList.value=='minusIcon' ? false:true;
        elem.classList.value=hide?'minusIcon':'plusIcon';    
    }else{
        hide=elem.previousSibling.classList.value=='minusIcon'?false:true;
        elem.previousSibling.classList.value=hide?'minusIcon':'plusIcon';    
    }
    

        elem=elem.parentElement.parentElement
        while (elem = elem.nextSibling) {
        if(hide)
        elem.style.display='none';
        else{
        elem.style.display='block';
        }
        }
   
    
    
   
}
const getTreeItemNew=(item:any,first:boolean)=>{
  

    console.log(item)
    if(!item) return null;
    return <ul className='wtreeUl'  >
        <li className='wtreeLl'  >
            <div className='iconSpanDiv' >
            <div  className='d-flex insideIconSpan' >
                <div className='maskBorder'></div>
                <i className='plusIcon' onClick={(e)=>{clickLi(e,'icon')}}></i>  
                <span className='wtreeSpan' onClick={(e)=>{clickLi(e,'span')}}>
                  <div className='campaign-icon'>
                    {item['type'] === "Campaign"?<CampaignIcon />: ''}
                    {item['type']==="Wave"? <WaveIcon/>: ''}
                    {item['type']==="Product"? <ProductIcon/>: ''}
                    {item['type']==="Template"? <TemplateIcon/>: ''}
                    {item['type']==="Asset"? <AssetIcon/>: ''}
                  </div>
                   {"("+item['type']+")"+item[item['labelName']]}</span>

                </div>
                
                {
                item.mainItem?<div className='itemInside'>
                  {/* {item} */}
                {props.mainItemView(item)}                
                </div>:null
                 }
           </div>   
          
        {item[item.childName]?item[item.childName].map((x:any)=>(getTreeItemNew(x,false))):null}
        </li>
</ul>
}
  return (
    <>
    <div className='wtree'>
     {
            props.data.map((item:any,index:number)=>(
                getTreeItemNew(item,index==0?true:false)
            ))
        }
        </div>
        



      <div className='TreeView' style={{display:'none'}}>
      <TreeView
        // defaultCollapseIcon={<ExpandMoreIcon />}
        // defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      defaultEndIcon={<CloseSquare />}
        onNodeToggle={handleToggle}
        // onNodeSelect={handleSelect}
      >
        {
            props.data.map((item)=>(
                getTreeItem(item)
            ))
        }
        {/* <TreeItem nodeId='1' label="Applications">
          <TreeItem nodeId="2" label="Calendar" />
          <TreeItem nodeId="3" label="Chrome" />
          <TreeItem nodeId="4" label="Webstorm" />
        </TreeItem>
        <TreeItem nodeId="5" label="Documents">
          <TreeItem nodeId="6" label="MUI">
            <TreeItem nodeId="7" label="src">
              <TreeItem nodeId="8" label="index.js" />
              <TreeItem nodeId="9" label="" >
              </TreeItem>
            </TreeItem>
          </TreeItem>
        </TreeItem>*/}
      </TreeView> 
      </div>
      </>
  );
}