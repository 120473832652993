import axios from "axios";
import config  from '../../env.json';
function sendMessageApi(payload,callback){
  

    try {
        axios.post(config.REACT_APP_MESSAGE_API_BASE +"/message",payload)
        .then(res=>{ 
          console.log(res)
          if(res.data.message === "Inappropriate message body"){
            callback(true, res.data);
          } else{
            callback(true);
          }
          
        }).catch(()=>{
          callback(false)
        })
    } catch (error) {
        callback(false)
    }
   
}
function unshortenUrl(payload,callback){

  try {

    axios.post(config.REACT_APP_MESSAGE_API_BASE +"/unshorturl",payload)

    .then(res=>{

        callback(res.data);

    }).catch(()=>{

      callback(false)

    })

} catch (error) {

    callback(false)

}

}




export {sendMessageApi, unshortenUrl}