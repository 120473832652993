import React,{useEffect,useState} from 'react'
import Avatar from '@mui/material/Avatar';
import config from '../../env.json'
import axios from 'axios';
import MultiselectDropdown from '../../layouts/mutliselectDropdown/MultiselectDropdown';
import DropdownInupt from "../../components/shared/Dropdown";
import "./Templates.css"
import {getCampaignsData} from '../../apis/campaigns/campaigns';
import Spinner from '../../layouts/spinner/Spinner';
import { useTranslation } from "react-i18next";
import { is } from 'immer/dist/internal';
import {getDeviceType} from '../../utils/deviceTypeUtil';
import { useSelector } from 'react-redux';
import Linkify from 'linkify-react';
import PreviewComponent from '../../components/chat/PreviewComponent';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
export default function Templates() {
  const { t } = useTranslation();
  const [pageSize,setPageSize]=useState(10);
  const [page,setPage]=useState(0);
  const [campaign,setCampaign]=useState([]);

  const [template,setTemplate]=useState([])
  const [activeWave,setActiveWave]=useState([]);
  const [waves,setWaves]=useState([])
  const [isMobile, setIsMobile] = useState(false);
  const [TemplateHeight,setTemplateHeight]=useState(0);  

  const [allCampaigns,setAllCampaigns]=useState([]);
  const [activeCampaign, setActiveCampagign] = useState([]);
  const [allWaves,setAlllWaves]=useState([]);
  const [activeWaves,setActiveWaves]=useState([]);
  const [allProducts,setAlllProducts]=useState([]);
  const [activeProducts,setActiveProducts]=useState([]); 
  const [spin,setSpin]=useState(false); 
  const pageTitle=useSelector((state:any)=>state.sideBar.title)
useEffect(() => {
  
  let device = getDeviceType()
      if(device=="mobile" || device=="tablet" ||  config.REACT_APP_IS_CROSS_PLATFORM){
    setIsMobile(true);
  } else {setIsMobile(false);}
  window.addEventListener(
    "resize",
    function (event) {
      
      let device = getDeviceType()
      if(device=="mobile" || device=="tablet" ||  config.REACT_APP_IS_CROSS_PLATFORM){
        setIsMobile(true);
      } else setIsMobile(false);
    },
    true
  );
  //getCampaign();
  getHeight();
  getTemplatesFromCampign();
  document.documentElement.style.setProperty('--visibility', 'hidden');
  console.log(pageTitle)
  document.getElementById("page-name")?.setAttribute('data-content',t(pageTitle));
}, [])

useEffect(()=>{
  document.documentElement.style.setProperty('--visibility', 'initial');
  document.getElementById("page-name")?.setAttribute('data-content', t(pageTitle));
},[pageTitle])
const getTemplatesFromCampign = () => {
  debugger
  setSpin(true);
 getCampaignsData({page:0,pageSize:10},(res)=>{
  debugger
  setSpin(false);
  if (res.length>0) {
          let tempAssets: any = [];
          
          console.log(res);
          let dataForTree = res;
          // dataForTree = dataForTree.map((x: any) => {
          //   x.type = "Campaign";
          //   x.idName = ["type", "campaignNm", "id"];
          //   x.labelName = "campaignNm";
          //   x.childName = "Waves";
          //   x.Waves.map((y: any) => {
          //     y.type = "Wave";
          //     y.idName = ["type", "campaignWaveNm", "id"];
          //     y.labelName = "campaignWaveNm";
          //     y.childName = "relatedAsset";
          //     y["relatedAsset"] = [];
          //     y["relatedAsset"].push({
          //       ...y.campaignWaveAsset,
          //       mainItem: true,
          //       type: "Asset",
          //       idName: ["type", "assetNm", "id"],
          //       labelName: "assetNm",
          //     });
          //     return y;
          //   });
          //   return x;
          // });

          //setDataForTree(dataForTree);
          // setWaves(res[0].Waves);
          // setActiveWave(res[0].Waves);
          // setCampaign(res);
          let campaigns:any=[];
          let waves:any=[];
          let products:any=[];

          // res.map((x:any) => {
          //   campaigns.push(x);  
          //   x.CampaignWaves.map((y: any) => {
          //     waves.push(y);
          //     // y.type = "Wave";
          //     // y.idName = ["type", "CampaignWave_Nm", "Id"];
          //     // y.labelName = "CampaignWave_Nm";
          //     // y.childName = "Products";
          //     y.CampaignWave_Nm=y.WaveDetails?y.WaveDetails.Wave_Nm:"";
          //     y.Products.map((z:any)=>{
          //       z.waveId=y.Id;
          //       products.push(z)
          //       tempAssets=tempAssets.concat(z.Templates)
          //       // z.Templates.map((p:any)=>{
          //       //   tempAssets=tempAssets.concat(p.Assets)
          //       //   return p;
          //       //   //tempAssets.push(y.campaignWaveAsset);
          //       // })
          //       return z;
          //     })
          //     return y;
          //   });
          //   return x;
          // });
          res.map((x:any) => {
            campaigns.push(x);  
            x.type = "Campaign";
            x.idName = ["type", "CampaignName", "CamapignId"];
            x.labelName = "CampaignName";
            x.childName = "WaveDetails";
            x.WaveDetails.map((y: any) => {
              y.UiKey = (y.WaveId)+'-'+(x.CamapignId); // create a new unique key for ui loading as the WaveId is not unique
              waves.push(y);
              y.type = "Wave";
              y.idName = ["type", "WaveName", "WaveId"];
              y.labelName = "WaveName";
              y.childName = "ProductDetails";
              y.parentName = y.WaveName+'('+x[x.labelName]+')';
              //y.CampaignWave_Nm=y.WaveDetails?y.WaveDetails.Wave_Nm:"";
              y.ProductDetails.map((z:any)=>{
                z.waveId=y.WaveId;
                z.UiKey = (y.WaveId)+'-'+(x.CamapignId)+'-'+(z.ProductId); //Unique key for product 
                products.push(z)
                z.type = "Product";
              z.idName = ["type", "ProductName", "ProductId"];
              z.labelName = "ProductName";
              z.childName = "TemplateDetails";
              z.parentName = z['ProductName']+'('+y[y.labelName]+')';
              //tempAssets=tempAssets.concat(z.TemplateDetails)
              z.TemplateDetails= z.TemplateDetails.sort(function(a:any,b:any){return a.SortOrder_Nbr - b.SortOrder_Nbr})
                z.TemplateDetails.map((p:any)=>{
                  p.type = "Template";
                  if(p.FormId){
                    p.TemplateName=p.FormName
                  }
              p.idName = ["type", "TemplateName", "TemplateId"];
              p.labelName = "TemplateName";
              p.childName = "AssetDetails";
              p.parentName = p.TemplateName+'('+z[z.labelName]+')';
                  //tempAssets=tempAssets.concat(p.AssetDetails)
                  p.AssetDetails.map((o:any)=>{
                    o.mainItem=true
                o.type= "Asset"
                o.idName= ["type", "AssetFile_Nm", "AssetId"]
                o.labelName="AssetFile_Nm"
                    return o;
                  })
                  tempAssets.push(p)
                  return p;

                  //tempAssets.push(y.campaignWaveAsset);
                })
                return z;
              })
              return y;
            });
            return x;
          });
          debugger
          setAllCampaigns(campaigns);
          setActiveCampagign(campaigns);

          setAlllProducts(products);
          setActiveProducts(products)

          setAlllWaves(waves);
          setActiveWaves(waves);
          tempAssets = tempAssets.sort(function(a,b){return a.SortOrder_Nbr - b.SortOrder_Nbr})       
          // console.log(tempAssets)
          setTemplate(tempAssets);
 }
}
 )

  // debugger;
  // // axios.get('https://p360zingcampaign.azurewebsites.net/api/product?Page=0&PageSize=10',{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then((res)=>{console.log(res)})
  // axios
  //   .get(
  //     config.REACT_APP_CAMPAIGN_API_BASE +
  //       "/campaign/" +
  //       userId +
  //       "?Page=" +
  //       page +
  //       "&PageSize=" +
  //       pageSize,
  //     {
  //       headers: {
  //         Authorization: "Bearer " + sessionStorage.getItem("login"),
  //       },
  //     }
  //   )
  //   .then((res) => {
  //     //setAssets(res.data.data);
  //     if (res.data.data) {
  //       let tempAssets: any = [];
  //       debugger;
  //       console.log(res.data.data);
  //       let dataForTree = res.data.data;
  //       dataForTree = dataForTree.map((x: any) => {
  //         x.type = "Campaign";
  //         x.idName = ["type", "campaignNm", "id"];
  //         x.labelName = "campaignNm";
  //         x.childName = "refCampaignWaves";
  //         x.refCampaignWaves.map((y: any) => {
  //           y.type = "Wave";
  //           y.idName = ["type", "campaignWaveNm", "id"];
  //           y.labelName = "campaignWaveNm";
  //           y.childName = "relatedAsset";
  //           y["relatedAsset"] = [];
  //           y["relatedAsset"].push({
  //             ...y.campaignWaveAsset,
  //             mainItem: true,
  //             type: "Asset",
  //             idName: ["type", "assetNm", "id"],
  //             labelName: "assetNm",
  //           });
  //           return y;
  //         });
  //         return x;
  //       });

  //       setDataForTree(dataForTree);
  //       setWaves(res.data.data[0].refCampaignWaves);
  //       setActiveWave(res.data.data[0].refCampaignWaves);
  //       setCampaign(res.data.data);
  //       debugger;
  //       res.data.data.map((x) => {
  //         x.refCampaignWaves.map((y: any) => {
  //           tempAssets.push(y.campaignWaveAsset);
  //           return y;
  //         });
  //         return x;
  //       });
  //       // tempAssets.push(res.data.data[0].refCampaignWaves[0].campaignWaveAsset);
  //       setAssets(tempAssets);
  //     }
  //   });
};

 
const selectActiveProducts=(e)=>{
  debugger
  setActiveProducts(e);
  let a=[];
  e.map((x:any)=>{
    a=a.concat(x.TemplateDetails)
    
    return x;
  })
  setTemplate(a);
}

const selectActiveWave=(e)=>{
  setActiveWaves(e);
  let p=[];
  e.map(x=>{
    p=p.concat(x.ProductDetails);
    return x;
  })
  console.log(p)
  debugger
  selectActiveProducts(p);
  setAlllProducts(p)
}
const selectActiveCampaign=(e)=>{

  setActiveCampagign(e);
  let w=[];
  e.map(x=>{
    w=w.concat(x.WaveDetails
      );
    return x;
  })
  //w=w.concat(e.map(x=>x.Waves));
  selectActiveWave(w);
  setAlllWaves(w);
}
// const getTemplatesFromCampign=(activeCampaign:any)=>{
//   axios.get(config.REACT_APP_CAMPAIGN_API_BASE+"/campaign/"+activeCampaign+"?Page="+page+"&PageSize="+pageSize,{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then((res)=>{
//     //setAssets(res.data.data);
//     let tempAssets:any=[];
//     console.log(res.data.data)
//     setCampaign(res.data.data)
//     setWaves(res.data.data[0].refCampaignWaves);
//     setActiveWave(res.data.data[0].refCampaignWaves);
      
//     res.data.data.map(x=>{
//       x.refCampaignWaves.map((y:any)=>{
//        tempAssets.push(y.campaignWaveTemplate);
//        return y;
//       });
//       return x;
//     })
//     setTemplate(tempAssets);
//   })
// }
// const selectCampagin=(e:any)=>{
//   setCampaign(e);
//   setWaves(e.refCampaignWaves);
//   setActiveWave(e.refCampaignWaves);
//   let tempAssets:any=[]
//   e.map(x=>{
//     x.refCampaignWaves.map((y:any)=>{
//       tempAssets.push(y.campaignWaveTemplate);
//      return y;
//     });
//    return x;
//   })
//   // tempAssets.push(res.data.data[0].refCampaignWaves[0].campaignWaveAsset);
//   setTemplate(tempAssets);
// }
// const selectWave=(e:any)=>{
//   // setWaves(e);
  
//   setActiveWave(e);
//   debugger
//   let tempAssets:any=[];
//   e.map((x:any)=>{
//     tempAssets.push(x.campaignWaveTemplate);
//   })
//   console.log(tempAssets)
//   setTemplate(tempAssets);
 
// }
useEffect(() => {
  getHeight();
},[allProducts])

useEffect(() => {
  window.addEventListener('orientationchange', getHeight);
  return () => {
    window.removeEventListener('orientationchange', getHeight)
  }
}, []) 


const linkProps = {

  onClick: (event) => {
      event.preventDefault()
    }   
};

 const getTabtoPreviewData = (value, type)=> {


  if(config.REACT_APP_VIDEO_PREVIEW_FLAG === "Y"){
    
    return (<div>
           <PreviewComponent value={value.trimStart()}/>
       
      <div >

      <span onClick={()=>{
        window.open((value.indexOf('https://')>=0 || value.indexOf('http://')>=0)?value:"https://"+value,"_blank")
        }} > {value}</span>
      
      </div>

      </div>

      )

  } else{
    return value;
  }

  }


 const getHeight=()=>{
  let viewportHeight = window.innerHeight;
  let div:any=document.getElementById('main');
  let divpaddingTop:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
  let divpaddingbottom:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
 
  let doc:any=document;
  console.log(doc.getElementById('MobHeader').offsetHeight, doc.getElementById('headerVal').offsetHeight,doc.getElementsByClassName('templates-header')[0].offsetHeight,doc.getElementById('footer').offsetHeight)
  let templateHeaderHeight = 0;
  
    let device = getDeviceType()
      if(device=="mobile" || device=="tablet" ||  config.REACT_APP_IS_CROSS_PLATFORM){
    templateHeaderHeight = allProducts.length <= 1 ?  0 : doc.getElementsByClassName('templates-header')[0].offsetHeight; 
  } else {
    templateHeaderHeight = doc.getElementsByClassName('templates-header')[0].offsetHeight;
  }
  
  if(doc.getElementsByClassName('templates-body')[0])
  setTemplateHeight(viewportHeight -(doc.getElementById('MobHeader').offsetHeight+doc.getElementById('headerVal').offsetHeight+templateHeaderHeight+doc.getElementById('footer').offsetHeight)-divpaddingTop-divpaddingbottom) ;
//   let viewportHeight = window.innerHeight;
//   let doc:any=document;
//   if(doc.getElementsByClassName('templates-body')[0])
//   return (viewportHeight - doc.getElementsByClassName('templates-body')[0].offsetTop - 50) ;
//   else return "100%"
 }
  return (
    <div className='templates-container'>
    <div className={`templates-header ${isMobile && allProducts.length <= 1 ? 'p-0':''}`}>
    <div className='d-flex fullwidth'>
      {
        !isMobile?  <div className="filterDropdownAssets">
        <MultiselectDropdown
            label={t("Campaign")}
            selectedData={activeCampaign}
            data={allCampaigns}
            name="CampaignName"
            id="CamapignId"
            func={(e) => {
              selectActiveCampaign(e);
            }}
          />
          </div>:null
      }
  

            {allWaves.length > 1 && !isMobile ? (
               <div className="filterDropdownAssets">
              <MultiselectDropdown
                label={t("Waves")}
                selectedData={activeWaves}
                data={allWaves}
                name="parentName"
                id="UiKey"
                func={(e) => {
                  selectActiveWave(e);
                }}
                
              />
              </div>
            ) : null}
            {allProducts.length > 1 ? (
              <div className="filterDropdownAssets">
              <MultiselectDropdown
                label={t("Brand")}
                selectedData={activeProducts}
                data={allProducts}
                name="parentName"
                id="UiKey"
                func={(e) => {
                  selectActiveProducts(e);
                }}
              />
              </div>
            ) : null}

           
        </div>
    </div>
    <div className='templates-body' style={{height:TemplateHeight}}>
      <div className='list-title templateHeader notMobile'>
          <div className='templateHeaderItem'>{t('Template Name')}</div>
          <div className='templateHeaderItem'>{t('Template Body')}</div>
          {/* <div className='templateHeaderItem'>{t('Hyperlink')}</div> */}
          <div className='templateHeaderItem'>{t('Attachment')}</div>
          <div className='templateHeaderItem'>{t('')}</div>
      </div>
      {spin?<Spinner/>:null}
      {template.length > 0 ? (
        <ul>
                {template.sort(function(a:any,b:any){return a.SortOrder_Nbr - b.SortOrder_Nbr}).map((item:any) =>{
                  // console.log(item)
                  return (
                    <li  id={item.id}>
                      {
                        isMobile?
                        <div className='templatesListItem-div'>
                                  <div className='temp d-flex' style={{fontSize:"1.098rem",fontWeight:"bolder"}}> <i  style={{fontSize:"larger"}}className="primary far fa-file-pdf i-2 mr-2 d-none d-xl-block"></i>{item.TemplateName}</div>
                                  <div className='temp' style={{fontSize:"1.098rem"}}>
                              {item.TemplateBody.replace(/(?:\r\n|\r|\n)/g, '\n')}
                                  </div>
                                  <div className='temp' style={{fontSize:"1.098rem"}}>
                                
                                  </div>
                                  <div className='temp' style={{fontSize:"1.098rem"}}>{item.Type}</div>
                                  <div className='temp' style={{fontSize:"1.098rem"}}>
                              {item.AssetDetails.map(x =>
                                  // return(
                                   <div>
                                     <a href={x.AssetFile_Url} target="__blank" className="templatesListItem-hyperlink"> {x.Asset_Nm}</a>
                                     {
                                      x.AssetFile_Typ != "random" ? <a  href={x.AssetFile_Url} target="__blank" className="templatesListItem-hyperlink template-download"  download={x.AssetFile_Nm}><i className="fas fa-download fa-x "></i></a>:null
                                     }
                                   </div>
                                  // )
                                  )}
                              </div>
                              {
                     item.WhatsappApproved_Flg === 'Y'?
                      <WhatsAppIcon className="whatsapp"></WhatsAppIcon>:null

                    } 
                              </div>:
                              <div className='templatesListItem-div'>
                              <div className='temp d-flex' style={{fontSize:"1.098rem",fontWeight:"bolder"}}> <i  style={{fontSize:"larger"}}className="primary far fa-file-pdf i-2 mr-2 d-none d-xl-block"></i>{item.TemplateName}</div>
                              <div className='temp' style={{fontSize:"1.098rem"}}>
                              {item.TemplateBody.replace(/(?:\r\n|\r|\n)/g, '\n')}
                              </div>
                              {/* <div className='temp' style={{fontSize:"1.098rem"}}>
                                {item.AssetDetails.map(x =>
                                  // return(
                                    <a href={x.AssetFile_Url} target="__blank" className="templatesListItem-hyperlink"> {x.Asset_Desc}</a>
                                  // )
                                  )}
                              
                              </div> */}
                              <div className='temp' style={{fontSize:"1.098rem"}}>
                              {item.AssetDetails.map(x =>
                                  // return(
                                   <div>
                                     <a href={x.AssetFile_Url} target="__blank" className="templatesListItem-hyperlink"> {x.Asset_Nm}</a>
                                     {
                                      x.AssetFile_Typ != "random" ? <a  href={x.AssetFile_Url} target="__blank" className="templatesListItem-hyperlink template-download"  download={x.AssetFile_Nm}><i className="fas fa-download fa-x "></i></a>:null
                                     }
                                   
                                   </div>
                                  // )
                                  )}
                              </div>
                              {
                     item.WhatsappApproved_Flg === 'Y'?
                      <WhatsAppIcon className="whatsapp"></WhatsAppIcon>:null

                    } 
                              
                          </div>
                      }
                          
                    </li>

                  )
                })}

          </ul>
      ):(
        <div style={{ width: "100%", textAlign: "center", fontSize: "large" }}>
              {!spin?<span>{t('No templates found')}</span>:null}
        </div>
      )}
    
     
    </div>
</div>
  )
}
