import { createSlice,PayloadAction } from '@reduxjs/toolkit';


interface loginState {
    repQuantity:any,
    presQauntity:any,
    aoc:any,
    formId:any
   
  }
const initialState: loginState = {
    repQuantity:[],
    presQauntity:[],
    aoc:[],
    formId:0
};
export const srf = createSlice({
  name: 'srf',
  initialState: initialState,
  reducers: {
    setRepQuantity: (state,action:any) => { 
       
        state.repQuantity=action.payload;

      },
    setPresQuantity: (state,action:any) => { 
        
        state.presQauntity=action.payload;

      },
      setAOCStatus: (state,action:any) => { 
        
        state.aoc=action.payload;

      },
      setFormId:(state,action:any) => { 
        
        state.formId=action.payload;

      },

    }
});

// this is for dispatch
export const { setAOCStatus,setPresQuantity,setRepQuantity,setFormId} = srf.actions;

// this is for configureStore
export default srf.reducer;