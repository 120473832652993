import axios from "axios";
import config  from '../../env.json';
let cancelToken:any;

function getAllFormsHistory(callback){
  // let {page,pageSize,search,cancelToken}=params;
    try {
        axios.get(config.REACT_APP_FORMS_API_BASE+'/forms')
        .then(res=>{ 
          debugger;
          callback(res.data);
        }).catch((error)=>{
          if (axios.isCancel(error)) {
            callback({error:true})
          }
          else{
            callback([])
          }
          
        })
    } catch (error) {
        callback([])
    }
   
}
function getForm(params,callback){
    let {Id}=params;
    try {
        axios.get(config.REACT_APP_FORMS_API_BASE+'/forms/'+Id)
        .then(res=>{ 
          debugger;
          callback(res.data);
        }).catch((error)=>{
          if (axios.isCancel(error)) {
            callback({error:true})
          }
          else{
            callback([])
          }
          
        })
    } catch (error) {
        callback([])
    }
   
}
// function getSpecificForms(params,callback){
//   let {search}=params;
//     try {
//         axios.get(config.REACT_APP_CONTACTS_API_BASE+'forms/1004',{
//           //cancelToken: cancelToken
//         })
//         .then(res=>{ 
          
//           callback(res.data.data);
//         }).catch((error)=>{
//           if (axios.isCancel(error)) {
//             callback({error:true})
//           }
//           else{
//             callback([])
//           }
          
//         })
//     } catch (error) {
//         callback([])
//     }
   
// }
function GetFormDetails(params,callback){
  let {FormId}=params;
  try {
      axios.get(config.REACT_APP_FORMS_API_BASE+"/formscomplete/"+FormId).then(res=>{
        callback(res.data);
      }).catch(()=>{
        callback(false)
      })
  } catch (error) {
      callback(false)
  }
}
function AddNewForm(payload,callback){
  
  try {
      axios.post(config.REACT_APP_FORMS_API_BASE+"/forms",payload).then(res=>{
        callback(res.data);
      }).catch(()=>{
        callback(false)
      })
  } catch (error) {
      callback(false)
  }
}
function UpdateForm(payload,params,callback){
  let {FormId}=params;
  try {
      axios.post(config.REACT_APP_FORMS_API_BASE+"/forms/"+FormId,payload).then(res=>{
        callback(res.data);
      }).catch(()=>{
        callback(false)
      })
  } catch (error) {
      callback(false)
  }
}
function AddNewFormFields(payload,callback){
  
  try {
      axios.post(config.REACT_APP_FORMS_API_BASE+"/formFieldbulk",payload).then(res=>{
        callback(res.data);
      }).catch(()=>{
        callback(false)
      })
  } catch (error) {
      callback(false)
  }
}
function AddNewPlatform(payload,callback){
  
  try {
      axios.post(config.REACT_APP_FORMS_API_BASE+"/formPlatform",payload).then(res=>{
        callback(res.data);
      }).catch(()=>{
        callback(false)
      })
  } catch (error) {
      callback(false)
  }
}
function AddNewDelivery(payload,callback){
  
  try {
      axios.post(config.REACT_APP_FORMS_API_BASE+"/formDeliverybulk",payload).then(res=>{
        callback(res.data);
      }).catch(()=>{
        callback(false)
      })
  } catch (error) {
      callback(false)
  }
}

function AddwaveAssociation(payload,callback){
  // let {Id}=params;
  try {
      axios.post(config.REACT_APP_FORMS_API_BASE+"/formwaveassociationbulk",payload).then(res=>{
        callback(res.data);
      }).catch(()=>{
        callback(false)
      })
  } catch (error) {
      callback(false)
  }
}

function UpdateFormField(payload,callback){
  
  try {
      axios.post(config.REACT_APP_FORMS_API_BASE+"/formFieldbulkupdate",payload).then(res=>{
        callback(res.data);
      }).catch(()=>{
        callback(false)
      })
  } catch (error) {
      callback(false)
  }
}
function UpdatePlatform(payload,params,callback){
  let {FormId}=params;
  try {
      axios.post(config.REACT_APP_FORMS_API_BASE+"/formPlatformbulkupdate/",payload).then(res=>{
        callback(res.data);
      }).catch(()=>{
        callback(false)
      })
  } catch (error) {
      callback(false)
  }
}
function UpdateWave(payload,callback){
  try {
      axios.post(config.REACT_APP_FORMS_API_BASE+"/formwaveassociationbulkupdate/",payload).then(res=>{
        callback(res.data);
      }).catch(()=>{
        callback(false)
      })
  } catch (error) {
      callback(false)
  }
}
function UpdateDelivery(payload,callback){
  // let {FormId}=params;
  try {
      axios.post(config.REACT_APP_FORMS_API_BASE+"/formDeliverybulkupdate",payload).then(res=>{
        callback(res.data);
      }).catch(()=>{
        callback(false)
      })
  } catch (error) {
      callback(false)
  }
}

function getFormsForMedia(callback){
  // let {FormId}=params;
  try {
    axios.get(config.REACT_APP_FORMS_API_BASE+'/formsformedia')
    .then(res=>{ 
      debugger;
      callback(res.data);
    }).catch((error)=>{
      if (axios.isCancel(error)) {
        callback([])
      }
      else{
        callback([])
      }
      
    })
} catch (error) {
    callback([])
}
}

function getFormRequestsList(callback){
  try {
    axios.get(config.REACT_APP_FORMS_API_BASE+'/formRequestsList')
    .then(res=>{ 
      debugger;
      callback(res.data);
    }).catch((error)=>{
      if (axios.isCancel(error)) {
        callback([])
      }
      else{
        callback([])
      }
      
    })
} catch (error) {
    callback([])
}
}

export {getAllFormsHistory,getForm,AddNewForm,AddwaveAssociation,AddNewFormFields,AddNewPlatform,AddNewDelivery,GetFormDetails,UpdateFormField,UpdateForm,UpdatePlatform,UpdateWave,UpdateDelivery,getFormsForMedia,getFormRequestsList}