import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import Avatar from "../../components/Avatar/Avatar";
import './VideoFiles.css'
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import CallIcon from '@mui/icons-material/Call';
import {ReactComponent as PdfIcon } from '../../assets/icons/video_logs_pdf.svg';
import {ReactComponent as VideoIcon } from '../../assets/icons/video_logs_vid.svg';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ShareIcon from '@mui/icons-material/Share';
import LinkIcon from '@mui/icons-material/Link';
import config from '../../env.json';
// import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import {  Popover } from 'antd';
import { getVideoLogFiles } from '../../apis/video/video';
import moment from 'moment';
import Spinner from '../../layouts/spinner/Spinner';
import SharePopup from '../SharePopup/SharePopup';
import { setSharedFilePermissionModal, setVideoCallFileSharedData } from '../../app/slice/sharefileModalSlice';
import { showModal } from '../../app/slice/modalSlice';

export default function VideoFiles(props:any) {
  let { files,fileName } = props
  const dispatch = useDispatch();
    const [videoPopup,setVideoPopup]=useState(false);
    const [value,setValue]=useState('');
    const [spin, setSpin] = useState(false);
    const [videoFileResult,setVideoFileResult]=useState([]) as any;
    const [showSharePopup, setShowSharePopup] = useState(false);
    const [sharePopupDetails, setSharePopupDetails] = useState({});
    const [copyText, setCopyText] = useState<any>('Get Link');

const getFileCopyUrl=(url)=> {
  if(url){
    console.log(url)
    navigator.clipboard.writeText(url);
    setCopyText('Copied!')
  }
}

const handleShare = (value) => {
  console.log(value)
  let id:any = value.Id;
  let fileType:any = "file";
  let name:any = value.VideoAnnotation_FileName
  ;
  let tempPath = '..';
  
  tempPath = tempPath + '/' + name;
  let temp = { "Id": id, "contentType": fileType, "filePath": '' };
  console.log("handle share...", temp);
  dispatch(showModal({
     path: "modalBody/share", props: {
      id: value.Id,
      contentType: "file",
    }
  }));

}

    useEffect(() => {
         setSpin(true)
        let doc:any=document;
        let accvideo2= doc.getElementsByClassName("vchat-main");
            console.log(accvideo2)
            for (var i = 0; i < accvideo2.length; i++) {
             console.log('fakeImage: ', accvideo2[i]);
             accvideo2[i].style.display="block";
        
           }
           console.log(props.files,props.recordName,'props.meeting-------------');
           let fileid=props.files;
           getVideoLogFiles({Id:fileid},(res)=>{
            if(res) {
              setSpin(false)
              console.log(res.data.data)
               setVideoFileResult(res.data.data);
            }
            // setVideoLogResult(res.data.data);
          })
      }, [])
      const openpopup=(el)=>{
        setValue(el);
        setVideoPopup(true);
      }
      const getVideoMoreOption =(item)=>{
        console.log(item.PermissionLevel_Typ)
        let accessPermission = item.PermissionLevel_Typ?.split(',');
        let shareFile:boolean = false;
        let read;
        accessPermission?.map((x)=>{
          if(x=="sh") shareFile= true
        }
        )
        
        return(
          <div id={'popup1'}>
          <div className={shareFile?'videopopup-row':'videopopup-row disabled_options'}onClick={()=>{if(shareFile)handleShare(item)}} >
                  <ShareIcon  className='videofileicons'                   
                  /> <div className="videopopup-details" >Share</div>
          </div>
      
          <div className='videopopup-row' onClick={()=>{getFileCopyUrl(item.VideoAnotation_Url)}}>
                  <LinkIcon    className='videofileicons'                   
                  /> <div className="videopopup-details">{copyText}</div>
          </div>
          </div>
        )
      }

  return (
    <div>
     <div className='main-chat-details notMobile'>
      <div className="vfiles-main" >
        {/* participants history */}
          <div className='video-files-headers'>
          <div className="video-type-list-item">{'Type'}</div>
          <div className="video-file-list-item">{'FileName'}</div>
          </div>
        
      </div>
        
        {spin && videoFileResult.length==0?<Spinner />: videoFileResult.length==0?<div className='validation'>No Files found</div>:null}

        {videoFileResult.map((videoResult:any,key:any) => (
            <>
            {videoResult?.VideoAnotation_Url&&<div className='file-content'>
            
             {videoResult?.VideoAnnotationFile_Nm&& <div><PdfIcon style={{fill:"var(--primary-color)",scale:"1.8"}} className='videofileicons'/></div>}
              {
              videoResult?.VideoAnnotationFile_Nm?
                <div className="videofile-details" > <a href={videoResult?.VideoAnotation_Url} target="__blank" className="templatesListItem-hyperlink">
                  {`${videoResult.VideoAnnotationFile_Nm}_${props.recordName}_${moment(props.meetingStartTime).format("YYYY-MM-DD")}_${moment(props.meetingStartTime).format("HH:mm")}`}
                  </a>
                  </div>:
                  <div className="videofile-details" >
                </div>
              }
              { config.REACT_APP_VIDEO_LOGS_OPTION=="Y"?
                 <Popover
                content={getVideoMoreOption(videoResult)}
                placement="bottomLeft"
                //   title="Title"
                trigger="click"
                //   open={open}
                //   onOpenChange={handleOpenChange}
                >
                <MoreHorizIcon style={{fill:"var(--primary-color)",transform:"rotate(90deg)"}} onClick={()=>openpopup("popup1")}></MoreHorizIcon>
                </Popover>:null}
          
            </div>}
            
            {videoResult?.VideoRec_Url&&<div className='file-content'>
                {videoResult?.VideoRec_Url&&<div><VideoIcon style={{fill:"var(--primary-color)",scale:"1.8"}} className='videofileicons'/></div> }
                {videoResult?.VideoRec_Url?<a className="videofile-details" href={videoResult?.VideoRec_Url} target="_blank" style={{color:'grey'}}>{props.recordName}</a>:null}
               
                
               { config.REACT_APP_VIDEO_LOGS_OPTION=="Y"?
                  <Popover
                  content={getVideoMoreOption(videoResult)}
                  placement="bottomLeft"
                  trigger="click"
                  >
                  <MoreHorizIcon onClick={()=>openpopup("popup1")}></MoreHorizIcon>
                  </Popover>
                    :null
                  }
            </div>}
            </>
            ))}
        
      </div>
      <div className='onlyMobile'>
      <div className='items-view-main only-mobile-view'>
      {spin && videoFileResult.length==0?<Spinner />: videoFileResult.length==0?<div className='mob-validation'>No Files found</div>:null}
        {
         videoFileResult.length>0 && videoFileResult.map((videoResult:any,key:any)=>(
            <div className="past-files-details">
            <div className='past-files-detail-items'>
            {videoResult?.VideoAnnotationFile_Nm &&
            <div className='file-logo'>
            <PdfIcon style={{fill:"var(--primary-color)",scale:"1.8"}} className='videofileicons'/>
            </div>}

            {videoResult?.VideoRec_Url&&
            <div className='file-logo'>
            <VideoIcon style={{fill:"var(--primary-color)",scale:"1.8"}} className='videofileicons'/>
            </div>}

            <div className="past-files-detail-item" style={{flexGrow:1}}>
                {
                videoResult?.VideoAnnotationFile_Nm && (
                <p className='past-files-name'>
                  <a href={videoResult?.VideoAnotation_Url}         
                     target="__blank" className="templatesListItem-hyperlink"  style={{color:'grey'}}>
                    {videoResult?.VideoAnnotationFile_Nm}
                  </a>
                </p> 
                )
                }

                  {videoResult?.VideoRec_Url?<p className='past-files-name'><a href={videoResult?.VideoRec_Url} target="__blank" className="templatesListItem-hyperlink" style={{color:'grey'}}>
                                  {props.meeting}</a>
                                  </p>:null
                  }

                <p className='past-files-date'>
                    {moment(videoResult?.CreatedBy_DtTm).format('MM/DD/YYYY')}. {"270.2 KB"}
                </p>
        
            </div>
            
            { config.REACT_APP_VIDEO_LOGS_OPTION=="Y"?
            <div className="past-files-detail-item">
                <Popover
                content={getVideoMoreOption(videoResult)}
                placement="bottomLeft"
                trigger="click"
                >
                <MoreHorizIcon style={{fill:"var(--primary-color)",transform:"rotate(90deg)"}} onClick={()=>openpopup("popup1")}></MoreHorizIcon>
                </Popover>
            </div>:null
             }
            </div>

        </div>
          ))
        }
     
      </div>
      </div>
    </div>

  )
}
