import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
// import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
// import Button from "@mui/material/Button";
import FieldDatePicker from "react-datepicker";



import "react-datepicker/dist/react-datepicker.css";
import Typography from "@mui/material/Typography";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import MultiselectDropdown from "../../layouts/mutliselectDropdown/MultiselectDropdown";
import { styled } from "@mui/material/styles";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ClearIcon from '@mui/icons-material/Clear';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import {createDynamicTemplate, getemplateDetails} from '../../apis/dynamicTemplateApi/dynamicTemplateApi'
// import DateTimeField from 'react-bootstrap-datetimepicker'
import { DateTime } from 'react-datetime-bootstrap';
// import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
// import AddBoxIcon from "@material-ui/icons/AddBox";
// import DoneIcon from "@material-ui/icons/Done";
// import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import DateTextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from '@mui/material/Stack';
import {getCampaignsData} from '../../apis/campaigns/campaigns';
// import Button from "../../layouts/button/Button";
import axios from "axios";
import '../forms/FormsModal.css';
import './templateModal.css'
// import "./AddContact.css";
import { data } from "jquery";
import { borderRadius } from "@mui/system";
import MultiSelect from  'react-multiple-select-dropdown-lite'
import  'react-multiple-select-dropdown-lite/dist/index.css'
import { AddNewForm } from '../../apis/forms/formsapi';
import { AddNewFormFields } from '../../apis/forms/formsapi';
import { AddNewPlatform } from '../../apis/forms/formsapi';
import { AddNewDelivery } from '../../apis/forms/formsapi';
import { UpdateFormField } from '../../apis/forms/formsapi';
import { UpdateForm } from '../../apis/forms/formsapi';
// import { GetFormDetails } from '../../apis/forms/formsapi';
import { UpdatePlatform } from '../../apis/forms/formsapi';
import { UpdateDelivery } from '../../apis/forms/formsapi';
import { UpdateWave } from '../../apis/forms/formsapi';
import moment from 'moment';
import { debug } from "console";
import { getWavesData } from "../../apis/waves/waves";
import { AddwaveAssociation } from "../../apis/forms/formsapi";
// import "../node_modules/bootstrap/dist/css/bootstrap.css";
import {
  Box, Button, Snackbar, Table,
  TableBody, TableCell, TableHead, TableRow
} from "@material-ui/core";
import { fabClasses } from "@mui/material";
import { useTranslation } from "react-i18next";
const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      theme.palette.mode === "dark"
        ? "0 0 0 1px rgb(16 22 26 / 40%)"
        : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
    backgroundImage:
      theme.palette.mode === "dark"
        ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
        : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background:
        theme.palette.mode === "dark"
          ? "rgba(57,75,89,.5)"
          : "rgba(206,217,224,.5)",
    },
  }));
  
  let primcolor=document.documentElement.style.getPropertyValue('--primary-color');
  const BpCheckedIcon = styled(BpIcon)({
  
    backgroundColor: primcolor?document.documentElement.style.getPropertyValue('--primary-color'):'var(--primary-color)' ,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: primcolor?document.documentElement.style.getPropertyValue('--primary-color'):'var(--primary-color)' ,
    },
  });
const style = {
  position: "absolute" as "absolute",
  top: "49%",
  left: "54%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  borderRadius: "1rem",
  padding: "0 3rem 1.5rem 3rem",
  bgcolor: "white",
  border: "none",
};
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderRadius: '1rem',
  }),
}
function BpRadio(props: RadioProps) {
    debugger;
    return (
      <Radio
        sx={{
          "&:hover": {
            bgcolor: "transparent",
          },
          "marginLeft":"1rem"
        }}
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }
  const useStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
        },
    },
    table: {
        minWidth: 650,
    }
    // snackbar: {
    //     bottom: "104px",
    // },
});

export default function FormsModal(props:any) {
 const { show, handleCloseParent ,title,Id,viewform,id}=props;
  const { t } = useTranslation();
  const [othersName, setOthersName] = useState("");
  const [PrescName, setPrescName] = useState("");
  const [othersPhone, setOthersPhone] = useState("");
  const [hiddenPresList, setHiddenPresList] = useState(true);
  const [assoPresc,setAssoPresc]=useState({Id:null})
  const [post, setPost] = useState([]);
  const [temp,setTemp]=useState('')
  const [age, setAge] = React.useState('');
  const [name, setName] = React.useState('');
  const [meetingType, setMeetingType] = useState(true);
  const [assoPrescName, setAssoPrescName] = useState("");
  const [disableBrandInput,setDisableBrandInput]=useState(true)
  const [phoneError,setPhoneError]=useState(false);
  const [meetingname,setMeetingName]=useState('');
  const [prescriberId,setPrescriberId]=useState('');
  const [disabled,setDisabled]=useState(true);
  const[signcapture,setSignCapture]=useState(false);
  const[prepform,setPrepform]=useState(false);
  const[vCardflag,setVCardflag]=useState(false);
  const[prepformfield,setPrepformfield]=useState(false);
  const[salesforce,setsalesforceform]=useState(false);
  const[showAssets,setShowAssets]=useState(false);
  const[templateStatus,setTemplateStatus]=useState(false);
  const[fieldformval,setNextTabfieldform]=useState('collapse');
  const[formval,setFormTab]=useState('show');
  const[waveval,setNextTabWave]=useState('collapse');
  const[platfrmtab,setNextTabplatform]=useState('collapse'); 
  const[deltab,setNextTabdelivery]=useState('collapse');
  const[FormName,setFormName]=useState('');
  const[FormType,setFormType]=useState('');
  const[FormBlobURL,setFormBlobURL]=useState('');
  const[Formloc,setFormstorageloc]=useState('');
  const[FormID,setFormID]=useState('');
  const [form, setForm]=useState([]);
  const classes = useStyles();
  const [platform,setPlatform]=useState('');
  const [deliveryval, setDelivery]=useState([]);
  const [value, setvalue] = useState([])
  const [deliverytype, setDeliveryType] = useState([])
  const [platformupdate, setUpdateplatform] = useState([])
  const[viewformval,setViewForm]=useState(false);
  const [formfieldupdate, setupdateformfield]=useState([]);
  const[activewaveflag,setActiveWave]=useState(false);
  const[activeplatformflag,setActivePlatform]=useState(false);
  const[activeDelflag,setActiveDelivery]=useState(false);
  // const [activeWaves, setActiveWaves] = useState([]);
  const [activeDel, setActiveDel] = useState([]);
  const [delupdate, setdelupdate]=useState([]);
  const[dynamicTemplate,setDynamicTemplate]=useState('')
  const[assetFile,setAssetFile]=useState<any>(false);
  const [allWaves,setAlllWaves]=useState([]);
  const [activeWaves,setActiveWaves]=useState([]);
  const [formdeltype,setFormDelType]=useState('');
  const [allCampaigns,setAllCampaigns]=useState([]);
  const [allProducts,setAlllProducts]=useState([]);
  const [activeProducts,setActiveProducts]=useState([]); 
  const [activeCampaign, setActiveCampagign] = useState([]);
  // const [assets, setAssets] = useState([]); 
  

  const [secPrescribers,setSecPrescribers]=useState<any>([]);
  // const[activewaveflag,setActiveWave]=useState(false);
  // const [startwavedate, setStartWaveDate] = useState<Date | null>(new Date());

  // const [enddatevalue, setEndDateValue] = useState<Date | null>(new Date());
  // const [startdatevalue, setStartDateValue] = useState<Date | null>(new Date());
  // const [endwavedate, setEndWaveDate] = useState<Date | null>(new Date());

  const [rows, setRows] = useState <any>([
    {id:1, Name: "", Type: "", Value: 'Yes', Query: "",Prepopulate:'Yes',Active:'Yes',EffectiveStartDate:new Date('MM/DD/YYYY'),EffectiveEndDate:new Date('MM/DD/YYYY')},
]);
  const [assetRow, setAssetRows] = useState([
    {id:1, Name: "",Body:"",Url:""}
]);
const [payloadval, setpayload] = useState([
  {     
    FieldForm_Id: "",
    Field_Nm: "",
    Field_Typ: "",
    FieldQueryExec_Val : "",
    FieldPrepopulate_Flg: "",
    FieldValByEmp_Flg: "",
    EffectiveStart_DtTm: "",
    EffectiveEnd_DtTm	 : "",
    Active_Flg: ""

},
]);
const [payloadwave, setwavepayload] = useState([
  {     
      WaveAssociationForm_Id:1004,
      WaveAssociationWave_Id:1001,
      EffectiveStart_DtTm:"09-08-2022",
      EffectiveEnd_DtTm:"01-01-1800",
      Active_Flg:"Y"
  },
]);
const FormDelData = [
  { value: 'open', label: 'Open' },
  { value: 'submit', label: 'Submit' }
]
const [startdatevalue, setStartDateValue] = useState<Date | null>(new Date());
const [waveStartdatevalue, setWaveStartDateValue] = useState<Date | null>(new Date());

const [enddatevalue, setEndDateValue] = useState<Date | null>(new Date());
const [startwavedate, setStartWaveDate] = useState<Date | null>(new Date());
const [endwavedate, setEndWaveDate] = useState<Date | null>(new Date());
const [startplatformdate, setStartPlatformDate] = useState<Date | null>(new Date());
const [endplatformdate, setEndPlatformDate] = useState<Date | null>(new Date());
const [startdeldate, setStartDeliveryDate] = useState<Date | null>(new Date());
const [enddeldate, setEndDeliveryDate] = useState<Date | null>(new Date());

const  [options,setOptions]:any  = useState([]);
const  [deliveryactions,setdeliveryactions]:any   = useState([
  { label:  'SMS', Id:  1001  },
  { label:  'Email', Id:  1002  },
  { label:  'Fax', Id:  1003  },
  { label:  'Salesforce', Id:  1004  },
  { label:  'Veeva', Id:  1005  }
])
const [rowsData, setRowsData] = useState([]);
 
  //   const addTableRows = ()=>{
  
  // //      const rowsInput={ Name: "", Type: "", Value: "", Query: "",Prepopulate:false }
  //       setRows([
  //         ...rows,
  //         {id: rows.length + 1, Name: "", Type: "", Value: "", Query: "",Prepopulate:false },
  //     ]);
      
      
  //   }
    const [open, setOpen] = React.useState(false);
    const [isEdit, setEdit] = React.useState(false);
    const [disable, setDisable] = React.useState(true);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [showAssetConfirm, setShowAssetConfirm] = React.useState(false);
     
  

    // Function For closing the alert snackbar
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };
  
    // Function For adding new row object
    const handleAdd = () => {
        setRows([
            ...rows,
            {id:rows.length+1, Name: "", Type: "", Value: 'Yes', Query: "",Prepopulate:"Yes" ,Active:"Yes",EffectiveStartDate:new Date('MM/DD/YYYY'),EffectiveEndDate:new Date('MM/DD/YYYY')},
        ]);
        setEdit(true);
    };
    // Function For adding new row object
    const handleAddAsset = () => {
      setAssetRows([
            ...assetRow,
            {id:assetRow.length+1,Name: "",Body: "",Url: ""},]);
    };
  
    // Function to handle edit
    const handleEdit = (i) => {
        // If edit mode is true setEdit will 
        // set it to false and vice versa
        setEdit(!isEdit);
    };
  
    // Function to handle save
    const handleSave = () => {
        setEdit(!isEdit);
        setRows(rows);
        console.log("saved : ", rows);
        setDisable(true);
        setOpen(true);
    };
  
    // The handleInputChange handler can be set up to handle
    // many different inputs in the form, listen for changes 
    // to input elements and record their values in state
    const handleInputChange = (e, index) => {
      debugger;
        console.log(e.target.value, "eeeeee", e.target.name)
        setDisable(false);
        const { name, value } = e.target;
        const list = [...rows];
        list[index][name] = value;
        console.log(list);
        setRows(list);
    };
    const handleAssetInputChange = (e, index) => {

        const { name, value } = e.target;
        const list = [...assetRow];
        list[index][name] = value;
        console.log(list);
        setAssetRows(list);
    };
    const handlePlatform=(event)=>{
      setPlatform(event.target.value);
    }
    // Showing delete confirmation to users
    const handleConfirm = () => {
        setShowConfirm(true);
    };
    const handleAssetConfirm = () => {
        setShowAssetConfirm(true);
    };
  
    // Handle the case of delete confirmation where 
    // user click yes delete a specific row of id:i
    const handleRemoveClick = (i) => {
        const list = [...rows];
        console.log("remove row ",i ,rows)
        list.splice(i, 1);
        setRows(list);
        setShowConfirm(false);
    };
    const handleRemoveAssetClick = (i) => {
        const list = [...assetRow];
        console.log("remove asset " ,i ,assetRow)
        list.splice(i, 1);
        setAssetRows(list);
        setShowAssetConfirm(false);
    };
  
    // Handle the case of delete confirmation 
    // where user click no 
    const handleNo = () => {
        setShowConfirm(false);
    };
    const handleAssetNo = () => {
      setShowAssetConfirm(false);
    };
    // console.log("test");
    // console.log(Id);
    // const [value, setvalue]:any = useState([]);


    const selectActiveWaves=(e)=>{

      //setActiveWaves(e);
     // let w=[];
      // e.map(x=>{
      //   w=w.concat(x.Waves);
      //   return x;
      // })
      //w=w.concat(e.map(x=>x.Waves));
      setActiveWaves(e);
      console.log(e);
      console.log(activeWaves);
    }
    
    const selectActiveDelivery=(e)=>{
debugger;
      //setActiveWaves(e);
     // let w=[];
      // e.map(x=>{
      //   w=w.concat(x.Waves);
      //   return x;
      // })
      //w=w.concat(e.map(x=>x.Waves));
      
      setActiveDel(e);
      console.log(e);
      console.log(activeDel);
    }


    const  handleOnchange  =  (val)  => {
      debugger;
      console.log(val);
      const final=val.split(",");
      console.log(final);
      
      // console.log(typeof(val));
      // console.log(JSON.parse(val));
      // console.log(JSON.stringify(val));
      setForm(final);
      console.log(form);
    }
    const  handleOnchangeDelivery  =  (val)  => {
      debugger;
      console.log(val);
      const final=val.split(",");
      console.log(final);
      
      // console.log(typeof(val));
      // console.log(JSON.parse(val));
      // console.log(JSON.stringify(val));
      setDelivery(final);
      console.log(form);
    }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  useEffect(() => {
    debugger;

    getCamppaignWaveData()
    setDynamicTemplateDetails()
 

  }, [show,id])


  const setDynamicTemplateDetails=()=>{
    if(id){
      console.log(id)
      setViewForm(true)
      getemplateDetails(id,(res)=>{
        console.log(res)
        if(res){
          let data=res.data
          setFormName(data.templateName);
          setDynamicTemplate(data.templateValue);
          setsalesforceform(data.stateDis=='Y');
          setVCardflag(data.vcard=='Y')
          setTemplateStatus(data.active=="Y");
          setStartDateValue(data.effectiveStart_DtTm);
          setEndDateValue(data.effectiveEnd_DtTm);
          setShowAssets(data.showAsset=='Y');

          //const [rows, setRows] = useState <any>([
  //   {id:1, Name: "", Type: "", Value: 'Yes', Query: "",Prepopulate:'Yes',Active:'Yes',EffectiveStartDate:new Date('MM/DD/YYYY'),EffectiveEndDate:new Date('MM/DD/YYYY')},
  // ]);
          let temprows:any=[];
          data.fields.map(x=>{

            let temprowsitem:any={
              id:x.fieldId,
              Name: x.fieldName,
              Type: x.fieldType,
              Value: '', 
              Query: "",
              Prepopulate:'Yes',
              Active:x.fieldActive=='Y'?'Yes':'No',
              EffectiveStartDate:moment(x.fieldEffectiveStart_DtTm).format('YYYY-MM-DD'),
              EffectiveEndDate:moment(x.fieldEffectiveEnd_DtTm).format('YYYY-MM-DD')
            }
            
            temprows.push(temprowsitem)
            return x;
          })      
          setRows(temprows);   

          // data.campaignAssociation.map(x=>{

          //   return x;
          // })

          if(data.campaignAssociation.length>0){
            let c:any=groupByKey(data.campaignAssociation,'CampaignId');
            let activec:any=[];
            for(let keys in c){
              if(c[keys].length>0)
              activec.push(c[keys][0])
            }
            setAllCampaigns(activec);
            setActiveCampagign(activec);
            let w:any=groupByKey(data.campaignAssociation,'WaveId');
            let activew:any=[];
            for(let keys in w){
              if(w[keys].length>0)
              activew.push(w[keys][0])
            }
            setAlllWaves(activew);
            setActiveWaves(activew)
            let p:any=groupByKey(data.campaignAssociation,'ProductId');
            let activep:any=[];
            for(let keys in p){
              if(p[keys].length>0)
              activep.push(p[keys][0])
            }
            setAlllProducts(activep);
            setActiveProducts(activep)
            setWaveStartDateValue(data.campaignAssociation[0].campaignEffectiveStart_DtTm);
            setEndWaveDate(data.campaignAssociation[0].campaignEffectiveEnd_DtTm);
            setActiveWave(data.campaignAssociation[0].capaignActive=='Y')
            
 
          }

        }
      })
    }else{
      setViewForm(false);
      setFormName('');
      setDynamicTemplate('');
      setsalesforceform(false);
      setVCardflag(false)
      setTemplateStatus(false);
      setStartDateValue(new Date());
      setEndDateValue(new Date());
      setShowAssets(false);
      setRows([
          {id:1, Name: "", Type: "", Value: 'Yes', Query: "",Prepopulate:'Yes',Active:'Yes',EffectiveStartDate:new Date('MM/DD/YYYY'),EffectiveEndDate:new Date('MM/DD/YYYY')},
        ]);   
        setAllCampaigns([]);
        setActiveCampagign([]);
      
        setAlllWaves([]);
        setActiveWaves([])
       
        setAlllProducts([]);
        setActiveProducts([])
        setWaveStartDateValue(new Date());
        setEndWaveDate(new Date());
      setViewForm(false)
    }
  }

  function groupByKey(array, key) {
    let local:any=[];
   return array
      .reduce((hash, obj) => {
        if(obj[key] === undefined) return hash; 
        //return local.push(obj)
        return Object.assign(hash, { [obj[key]]:( hash[obj[key]] || [] ).concat(obj)})
      }, {})
      //return local
 }
  const getCamppaignWaveData =()=>{
    getCampaignsData({page:0,pageSize:10},(res)=>{
      // setSpin(false);
      if (res.length>0) {
              let tempAssets: any = [];
              
              console.log(res);
              let dataForTree = res;
           
              let campaigns:any=[];
              let waves:any=[];
              let products:any=[];
  debugger
              dataForTree= res.map((x:any) => {
                debugger
                campaigns.push(x);  
                x.type = "Campaign";
                x.idName = ["type", "CampaignName", "CamapignId"];
                x.labelName = "CampaignName";
                x.childName = "WaveDetails";
                x.WaveDetails.map((y: any) => {
                  waves.push(y);
                  y.type = "Wave";
                  y.idName = ["type", "WaveName", "WaveId"];
                  y.labelName = "WaveName";
                  y.childName = "ProductDetails";
                  //y.CampaignWave_Nm=y.WaveDetails?y.WaveDetails.Wave_Nm:"";
                  y.ProductDetails.map((z:any)=>{
                    z.waveId=y.WaveId;
                    z.campaignId=x.CamapignId
                    products.push(z)
                    z.type = "Product";
                  z.idName = ["type", "ProductName", "ProductId"];
                  z.labelName = "ProductName";
                  z.childName = "TemplateDetails";
                  z.TemplateDetails= z.TemplateDetails.sort(function(a:any,b:any){return a.SortOrder_Nbr - b.SortOrder_Nbr})
                  console.log(z.TemplateDetails)
                    z.TemplateDetails.map((p:any)=>{
                      p.type = "Template";
                      if(p.FormId){
                        p.TemplateName=p.FormName
                      }
                  p.idName = ["type", "TemplateName", "TemplateId"];
                  p.labelName = "TemplateName";
                  p.childName = "AssetDetails";
                      tempAssets=tempAssets.concat(p.AssetDetails)
                      p.AssetDetails.map((o:any)=>{
                        o.mainItem=true
                    o.type= "Asset"
                    o.idName= ["type", "Asset_Nm", "AssetId"]
                    o.labelName="Asset_Nm"
                        return o;
                      })
                      return p;
  
                      //tempAssets.push(y.campaignWaveAsset);
                    })
                    return z;
                  })
                  return y;
                });
                return x;
              });
              console.log(dataForTree)
              // setDataForTree(dataForTree);
              setAllCampaigns(campaigns);
              // setActiveCampagign(campaigns);
  
              //setAlllProducts(products);
              // setActiveProducts(products)
  
              //setAlllWaves(waves);
              // setActiveWaves(waves);
              // tempAssets =tempAssets.sort(function(a:any,b:any){return a.SortOrder_Nbr - b.SortOrder_Nbr})
              // console.log(tempAssets)
              // setAssets(tempAssets);
     }
    }
     )
  }
  

  // useEffect(() => {
  //   debugger
  //   ;
  //   getData();
  //   console.log("DATA:"+options);
  // }, [])
  
  const handleFormClick=()=>{
    debugger;
    const s_date=moment(new Date()).toISOString();
  }
  // const getData=()=>{
  //   debugger;  
     
  //   getWavesData({page:0,pageSize:20},(res)=>{
  //     let data:any=res;
  //         //  setHistData(res)
  //         console.log(res);
  //         let d1:any=[];
  //         data=data.map((x:any) => {
            
  //           d1=[...d1,{label: x.Wave_Nm, value: x.Id}]
  //            return x;
  //         })
  //         console.log(d1);
  //         setOptions(d1);
  //         console.log(options);
          
  //      })
  // }
  const handleFormFieldClick=(event)=>{
    debugger;
    console.log(rows);
    const s_date=moment(new Date()).toISOString();
  //   console.log(FormName,FormType,signcapture,FormBlobURL,Formloc,prepform,salesforce,s_date);
  //   const signval=signcapture==true?"Y":"N"
  //   const prepval=prepform==true?"Y":"N"
  //   let d1:any=[];
  //   rows.map((x:any) =>{
  //     d1=[...d1,{   FieldForm_Id: FormID,
  //     Field_Nm: x.Name,
  //     Field_Typ: x.Type,
  //     FieldQueryExec_Val : "",
  //     FieldPrepopulate_Flg: x.Prepopulate,
  //     FieldValByEmp_Flg: "Y",
  //     EffectiveStart_DtTm: s_date,
  //     EffectiveEnd_DtTm	 :"1800-01-01",
  //     Active_Flg: "Y"
  // }]
  //     return x;
       
  //   })
  //   console.log(d1);
  //    let payload=d1;
  //   //   {     
  //   //       FieldForm_Id: FormID,
  //   //       Field_Nm: "Question2",
  //   //       Field_Typ: "string",
  //   //       FieldQueryExec_Val : "",
  //   //       FieldPrepopulate_Flg: "N",
  //   //       FieldValByEmp_Flg: "Y",
  //   //       EffectiveStart_DtTm: "2022-08-08",
  //   //       EffectiveEnd_DtTm	 : "1800-01-01",
  //   //       Active_Flg: "Y"
      
  //   // }
  //   console.log(
  //     payload
  //   )
  //   debugger;
  //   AddNewFormFields(payload,(res:any)=>{
  //     if(res){
  //       console.log(res);
  //     }
  //     else{
  //       console.log(res);
  //     }
  //   })
  }

  const handleWaveAssociation=()=>{
    debugger;
    const s_date=moment(new Date()).toISOString();
    // console.log(FormName,FormType,signcapture,FormBlobURL,Formloc,prepform,salesforce,s_date);
    // const signval=signcapture==true?"Y":"N"
    // const prepval=prepform==true?"Y":"N"
    // console.log(form,FormID);
    // let d1:any=[];
    //  for(var i=0;i<form.length;i++)
    // {        
    //     d1=[...d1,{    WaveAssociationForm_Id:FormID,
    //       WaveAssociationWave_Id:form[i],
    //       EffectiveStart_DtTm:s_date,
    //       EffectiveEnd_DtTm:"01-01-1800",
    //       Active_Flg:"Y"
    // }]
    // }

    // console.log(d1);
    // let payload=d1;
    // // console.log(
    // //   payload
    // // )
    // //   }
    // AddwaveAssociation(payload,(res:any)=>{
    //   if(res){
    //     console.log(res);
    //    // setFormID(res)
    //   }
    //   else{
    //     console.log(res);
    //   }
    // })
  }
  // const closePopup=(torf)=>{
  //   debugger;
  //   setOthersName('')
  //   // setFormName('')
  //   // setFormType('')
  //   // setSignCapture(false)
  //   // setOthersPhone('')
  //   // setFormBlobURL('')
  //   // setFormstorageloc('')
  //   // setPrepform(false);
  //   // setsalesforceform(false)
  //   // setRows([
  //   //   {id:1, Name: "", Type: "", Value: "", Query: "",Prepopulate:false }
  //   // ])
  //   // setForm([]);
  //   // setPlatform('');
  //   // setDelivery([]);
  //   // setAssoPrescName('');
  //   setShowAssets(false);
  //   setActiveCampagign([])
  //   setVCardflag(false)
  //   setDisabled(true);
  //   setFormName('')
  //   setDynamicTemplate('')
  //   setActiveWaves([])
  //   setActiveProducts([])
  //   // setAssoPresc({})
  //   setSecPrescribers([]);
  //   handleCloseParent(torf);
  //   setViewForm(false);
  // }
  const closePopup=(torf)=>{
    debugger
    setViewForm(false);
          setFormName('');
          setDynamicTemplate('');
          setsalesforceform(false);
          setVCardflag(false)
          setTemplateStatus(false);
          setStartDateValue(new Date());
          setEndDateValue(new Date());
          setShowAssets(false);
          setRows([
              {id:1, Name: "", Type: "", Value: 'Yes', Query: "",Prepopulate:'Yes',Active:'Yes',EffectiveStartDate:new Date('MM/DD/YYYY'),EffectiveEndDate:new Date('MM/DD/YYYY')},
            ]);   
            setAllCampaigns([]);
            setActiveCampagign([]);
          
            setAlllWaves([]);
            setActiveWaves([])
           
            setAlllProducts([]);
            setActiveProducts([])
            setWaveStartDateValue(new Date());
            setEndWaveDate(new Date());
            setActiveWave(false)
            handleCloseParent(torf)
            
 

  }
 
  const selectAssetFile=(e)=>{
    setAssetFile(e.target.files[0]);
  }
  const handleSavePlatform=()=>{
    const s_date=moment(new Date()).toISOString();
  //   console.log(platform);
  //   let payload=
  //   {
  //     PlatformForm_Id : FormID,
  //     Platform_Nm: platform,
  //     EffectiveStart_DtTm: s_date,
  //     EffectiveEnd_DtTm:s_date,
  //     Active_Flg: "Y"
  // }
  // AddNewPlatform(payload,(res:any)=>{
  //   debugger;
  //   if(res){
  //     debugger;
  //     console.log(res);
  //   }
  //   else{
  //     debugger;
  //     console.log(res);
  //   }
  // })
  }
 const handleSaveDelivery=()=>{
  debugger;
  
  if(title=="Edit")
  {
    const s_date=moment(new Date()).toISOString();
    let d1:any=[];
    const signval=signcapture==true?"Y":"N"
    const prepval=prepform==true?"Y":"N"
       


  
    rows.map((x:any) =>{

      d1=[...d1,{   Field_Id: x.id,
        FieldForm_Id: Id,
      Field_Nm: x.Name,
      Field_Typ: x.Type,
      FieldQueryExec_Val : x.Query,
      FieldPrepopulate_Flg: x.Prepopulate=="true"?"Y":"N",
      FieldValByEmp_Flg: x.Value,
      EffectiveStart_DtTm: moment(x.EffectiveStartDate).format("YYYY-MM-DD"),
      EffectiveEnd_DtTm	 :moment(x.EffectiveEndDate).format("YYYY-MM-DD"),
      Active_Flg: x.Active=="true"?"Y":"N"
  }]
      return x;
       
    })
    console.log(d1);
     let payload=d1;
    

        UpdateFormField(payload,(res:any)=>{
          debugger;
          if(res){
            debugger;
            console.log(res);
            handleCloseParent(true);
          }
          else{
            debugger;
            console.log(res);
          }
        })
        
        let platformpayload:any=[];
        platformupdate.map((x:any)=>{
         
          platformpayload =[...platformpayload,{
            FormPlatform_Id: x.Id,
            PlatformForm_Id : Id,
            Platform_Nm: platform,
            EffectiveStart_DtTm: moment(startplatformdate).format("YYYY-MM-DD"),
            EffectiveEnd_DtTm: moment(endplatformdate).format("YYYY-MM-DD"),
            Active_Flg: activeplatformflag==true?"Y":"N"
          }]
        })
      console.log(platformpayload);

        
        UpdatePlatform(platformpayload,{FormId:Id},(res:any)=>{
          debugger;
          if(res){
            debugger;
            console.log(res);
          }
          else{
            debugger;
            console.log(res);
          }
        })
        
      //wave association 
      let d3:any=[];
      activeWaves.map((x:any)=>{  
         d3=[...d3,{    Id:x.Id,
           WaveAssociationWave_Id:x.waveId,
           EffectiveStart_DtTm:moment(startwavedate).format("YYYY-MM-DD"),
           EffectiveEnd_DtTm:moment(endwavedate).format("YYYY-MM-DD"),
           Active_Flg:activewaveflag==true?"Y":"N"
          }]
        })
 
     console.log(d3);
     let wavepayload=d3;
     // console.log(
     //   payload
     // )
     //   }

    //  UpdateWave(payload,{FormId:Id},(res:any)=>{
    //    if(res){
    //      console.log(res);
    //     // setFormID(res)
    //    }
    //    else{
    //      console.log(res);
    //    }
    //  })
    let deldata:any=[];
    let deldata2:any=[];
    debugger;
    console.log(deliveryval);
    
    activeDel.map((x:any)=>{
      deldata=[...deldata,{FormDelivery_Id : x.Id,
        FormDelivery_Typ: x.label ,
        EffectiveStart_DtTm: moment(startdeldate).format("YYYY-MM-DD"),
        EffectiveEnd_DtTm:moment(enddeldate).format("YYYY-MM-DD"),
        Active_Flg: activeDelflag==true?"Y":"N"
    }]
    return x;
    }) 
     
  
  console.log(deldata);
  let delpayload=deldata;
  UpdateDelivery(delpayload,(res:any)=>{
    debugger;
    if(res){
      debugger;
      console.log(res);
      handleCloseParent(true);
    }
    else{
      debugger;
      console.log(res);
    }
  })
  setNextTabfieldform('collapse');
  setNextTabWave('collapse');
  setNextTabplatform('collapse');
  setNextTabdelivery('collapse');

        
  }
  else{
       debugger;
          // const s_date=moment(new Date()).toISOString();
          //form click
          // console.log(FormName,FormType,signcapture,FormBlobURL,Formloc,prepform,salesforce,s_date);
          // const signval=signcapture==true?"Y":"N"
          // const prepval=prepform==true?"Y":"N"

          // activeProducts
          console.log(activeProducts)
          let dynamicActiveProduct:any=[]
          activeProducts.map((x:any)=>{
            dynamicActiveProduct.push({
              'productId':x.ProductId,
              "waveId":x.waveId,
              "campaignId":x.campaignId,
              "effectiveStart_DtTm":waveStartdatevalue,
              "effectiveEnd_DtTm": endwavedate,
              "active":activewaveflag==true?"Y":"N"           
            })
          })
          console.log(activeProducts, "activeProducts")
          console.log(activeCampaign, "activeCampaign")
          console.log(activeWaves, "activeWaves")
          // Field
          let dynamicField:any = [];
          
          if(rows.length>0)
          if(rows[0].Name.length>0)
           rows.map((x:any)=>{
            dynamicField.push({
              
                "fieldName":x.Name,
                "fieldType":x.Type,
                "effectiveStart_DtTm":new Date(x.EffectiveStartDate).toISOString(),
                "effectiveEnd_DtTm":new Date(x.EffectiveEndDate).toISOString(),
                "active":x.Active == "Yes"? "Y":"N"
            
          })
        })
          console.log(dynamicField,"field rows")
          console.log(startdatevalue,enddatevalue)
          
          let formupdatepayload={
            "templateName": FormName,
            "templateValue": dynamicTemplate,
            "stateDis":salesforce == true? "Y":"N",
            "vcard":vCardflag==true?"Y":"N",
            "active":templateStatus==true?"Y":"N",
            "showAsset":showAssets==true?"Y":"N",
            "effectiveStart_DtTm":startdatevalue,
            "effectiveEnd_DtTm":enddatevalue,
            "fields":dynamicField,
            "campaignAssociation":dynamicActiveProduct 
      
      
      }
      createDynamicTemplate(formupdatepayload,(res:any)=>{
          debugger;
          closePopup(false);
          if(res){
            debugger;
            console.log(res);
            if(res.code === 200)
            
            setNextTabfieldform('collapse');
          setNextTabWave('collapse');
          setNextTabplatform('collapse');
          setNextTabdelivery('collapse');
            // setFormID(res.formId)
          }
          else{
            debugger;
            console.log(res);
          }
        })
          
          // //form field
          //   let d2:any=[];
          //   rows.map((x:any) =>{
          //     d2=[...d2,{   FieldForm_Id: FormID,
          //     Field_Nm: x.Name,
          //     Field_Typ: x.Type,
          //     FieldQueryExec_Val : "",
          //     FieldPrepopulate_Flg: x.Prepopulate=="Yes"?"Y":"N",
          //     FieldValByEmp_Flg: x.Value=="Yes"?"Y":"N",
          //     EffectiveStart_DtTm: x.EffectiveStartDate,
          //     EffectiveEnd_DtTm	 :x.EffectiveEndDate,
          //     Active_Flg: x.Active=="Yes"?"Y":"N"
          //   }]
          //       return x;
                
          //     })
          //   console.log(d2);
          //    let formfieldpayload=d2;
          //   console.log(
          //     formfieldpayload
          //   )
          //   debugger;
          //   AddNewFormFields(formfieldpayload,(res:any)=>{
          //     if(res){
          //       console.log(res);
          //     }
          //     else{
          //       console.log(res);
          //     }
          //   })
          
        
          
          // //wave association
          //   console.log(form,FormID);
          //   let d3:any=[];
          //   activeWaves.map((x:any)=>{
          //     d3=[...d3,{    WaveAssociationForm_Id:FormID,
          //       WaveAssociationWave_Id:x.value,
          //       EffectiveStart_DtTm:startwavedate,
          //       EffectiveEnd_DtTm:endwavedate,
          //       Active_Flg:activewaveflag==true?"Y":"N"
          //     }]
          //   })      
               
            
  
          //   console.log(d3);
          //   let wavepayload=d3;
          //   // console.log(
          //   //   payload
          //   // )
          //   //   }
  
            
          //   AddwaveAssociation(wavepayload,(res:any)=>{
          //     if(res){
          //       console.log(res);
          //     // setFormID(res)
          //     }
          //     else{
          //       console.log(res);
          //     }
          //   })
          
        
      
          // //form platform
          //   console.log(platform);
          //   let platformpayload=
          //   {
          //     PlatformForm_Id : FormID,
          //     Platform_Nm: platform,
          //     EffectiveStart_DtTm: startplatformdate,
          //     EffectiveEnd_DtTm:endplatformdate,
          //     Active_Flg: activeplatformflag==true?"Y":"N"
          //     }
          //     AddNewPlatform(platformpayload,(res:any)=>{
          //       debugger;
          //       if(res){
          //         debugger;
          //         console.log(res);
          //       }
          //       else{
          //         debugger;
          //         console.log(res);
          //       }
          //     })
          
          
          // //form delivery
          //   let d1:any=[];
          //   activeDel.map((x:any)=>{
          //     d1=[...d1,{FormDeliveryForm_Id : FormID,
          //       FormDelivery_Typ: x.label,
          //       EffectiveStart_DtTm: startdeldate,
          //       EffectiveEnd_DtTm:enddeldate,
          //       Active_Flg: activeDelflag==true?"Y":"N"
          //   }]
          // })    
              
         
          // console.log(d1);
          // let payload=d1;
          // AddNewDelivery(payload,(res:any)=>{
          //   debugger;
          //   if(res){
          //     debugger;
          //     console.log(res);
          //     handleCloseParent(true);
          //   }
          //   else{
          //     debugger;
          //     console.log(res);
          //   }
          // })
        
    
          
      } 
     

    }

    const selectActiveWave=(e)=>{
      setActiveWaves(e);
      let p=[];
      e.map(x=>{
        p=p.concat(x.ProductDetails);
        return x;
      })
      console.log(p)
      debugger
      selectActiveProducts(p);

      setAlllProducts(p)
    }
    const selectActiveCampaign=(e)=>{

      setActiveCampagign(e);
      let w=[];
      e.map(x=>{
        w=w.concat(x.WaveDetails
          );
        return x;
      })
      //w=w.concat(e.map(x=>x.Waves));
      selectActiveWave(w);
      setAlllWaves(w);
    }

    const selectActiveProducts=(e)=>{
      debugger
      setActiveProducts(e);
      // let a=[];
      // e.map((x:any)=>{
      //   x.TemplateDetails
      //   .map((p:any)=>{
      //     a=a.concat(p.AssetDetails)
      //     return p;
      //     //tempAssets.push(y.campaignWaveAsset);
      //   })
      //   return x;
      // })
      
      // console.log(a)
      // setAssets(a);
  
    }
    const handleNextTab=(event)=>{
    // debugger;
    if(event.target.dataset.bsTarget=='#panelsStayOpen-collapseOne'){
      setNextTabfieldform('show');
      handleFormClick();
    }

     if(event.target.dataset.bsTarget=="#panelsStayOpen-collapseTwo")
     {
     setNextTabWave('show');
     handleFormFieldClick(event);
     }
     if(event.target.dataset.bsTarget=="#panelsStayOpen-collapseThree")
     {
      debugger
      handleSaveDelivery();
     }
    //  if(event.target.dataset.bsTarget=="#panelsStayOpen-collapseFour")
    //  {
    //  setNextTabdelivery('show');
    //  handleSavePlatform();
    //  }
    //  if(event.target.dataset.bsTarget=="#panelsStayOpen-collapseFive")
    //  {
    //   debugger;
    //  handleSaveDelivery();
    //  }
    //  document.getElementsByClassName('accformfield')[0].append('show');
  }
  const checkDisabled=(assoPrescName,othersPhone,meetingname)=>
  {
    debugger;
    if(assoPrescName && othersPhone && meetingname){
      debugger;
      setDisabled(false);
    }
    else setDisabled(true);
  }
  const handleTabClick=(event)=>{
    debugger;
    if(event.target.dataset.bsTarget=='#panelsStayOpen-collapseOne'){
      setNextTabWave('collapse');
      setFormTab('show')
      setNextTabfieldform('collapse');
      setNextTabplatform('collapse');
      setNextTabdelivery('collapse');
    }

     if(event.target.dataset.bsTarget=="#panelsStayOpen-collapseTwo")
     {
     setNextTabWave('collapse');
     setFormTab('collapse')
     setNextTabfieldform('show');
     setNextTabplatform('collapse');
     setNextTabdelivery('collapse');
     }
     if(event.target.dataset.bsTarget=="#panelsStayOpen-collapseThree")
     {
        setNextTabWave('show');
        setFormTab('collapse')
        setNextTabfieldform('collapse');
        setNextTabplatform('collapse');
        setNextTabdelivery('collapse');
     }
     if(event.target.dataset.bsTarget=="#panelsStayOpen-collapseFour")
     {
      setNextTabWave('collapse');
      setFormTab('collapse')
      setNextTabfieldform('collapse');
      setNextTabplatform('show');
      setNextTabdelivery('collapse');
     }
     if(event.target.dataset.bsTarget=="#panelsStayOpen-collapseFive")
     {
      setNextTabWave('collapse');
      setFormTab('collapse')
      setNextTabfieldform('collapse');
      setNextTabplatform('collapse');
      setNextTabdelivery('show');
     }
    //  document.getElementsByClassName('accformfield')[0].append('show');
  }
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={show}
        onClose={handleCloseParent}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        // sx={{height:"50%"}}
      >
        <Fade in={show}>
          <Box className="template-box">
            <div className="modal-header d-flex ">
              <h1 className="font-createcontact">{t(title+` Template`)}</h1>
             
              <span onClick={() => {
                  closePopup(false);
                }}
                 className="close">
                ×
              </span>
            </div>
         <div className="accordion" id="accordionPanelsStayOpenExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne" onClick={handleTabClick}>
                  {t("Information")}
                </button>
              </h2>
              <div id="panelsStayOpen-collapseOne" className={formval} aria-labelledby="panelsStayOpen-headingOne">
                <div className="accordion-body">
                    {/* <form> */}
                    <TextField
                         className="roundborder"
                         label={t("Name")}
                         placeholder={t("Please enter dynamic template Name")}
                         value={FormName}
                         sx={{width:400}}
                         onChange={(e)=>setFormName(e.target.value)}
                         inputProps={
                          { readOnly: viewformval, }
                        }
                                                
                         focused
                        />
                        
                        <div className="textarea-wrapper">
                          <label className="textarea-dynamic">{t("Template Content")}</label>
                        <textarea
                         className="templatetype"
                        //  label={t("Template Content")}
                        // disabled
                         value={dynamicTemplate}
                         placeholder={t("Please enter template content")}
                        //  style={{width:400}}
                         disabled={viewformval}
                         onChange={(e)=>setDynamicTemplate(e.target.value)}
                            aria-label="minimum height"
                            rows={2}
                            // placeholder="Minimum 3 rows"
                            // style={{ width: 200 }}
                          ></textarea>
                        </div>

                         <div className="selecttype-radio-btns form-radio-select form-input">
                         <FormControl className="saleforce">
                              <FormLabel id="formdemo-customized-radios">{t('State Disclosures Required?')}</FormLabel>
                              <RadioGroup
                                row
                                defaultValue="no"
                                aria-labelledby="demo-customized-radios"
                                // value={salesforce}
                                value={salesforce==true?"yes":"no"}
                                name="customized-radios"
                              
                                onChange={(e: any) => {
                                    e.target.value === "no"
                                      ? setsalesforceform(false)
                                      : setsalesforceform(true);
                                  }}
                              >
                                <FormControlLabel                      
                                  value="yes"
                                  control={<BpRadio />}
                                  disabled={viewformval}
                                  label={t("Yes")}
                                  className="radio-formcontrol-salesforce"
                                />
                                <FormControlLabel
                                  value="no"
                                  control={<BpRadio />}
                                  disabled={viewformval}
                                  label={t("No")}
                                  className="radio-formcontrol-salesforce"
                                />
                              </RadioGroup>
                            </FormControl>
                            <FormControl>
                              <FormLabel id="formdemo-customized-radios">{t('Vcard Required?')}</FormLabel>
                              <RadioGroup
                                row
                                defaultValue="no"
                                aria-labelledby="demo-customized-radios"
                                name="customized-radios"
                                value={vCardflag==true?"yes":"no"}
                                onChange={(e: any) => {
                                    e.target.value === "no"
                                      ? setVCardflag(false)
                                      : setVCardflag(true);
                                  }}
                              >
                                <FormControlLabel                      
                                  value="yes"
                                  control={<BpRadio />}
                                  disabled={viewformval}
                                  label={t("Yes")}
                                  className="radio-formcontrol"
                                />
                                <FormControlLabel
                                  value="no"
                                  control={<BpRadio />}
                                  disabled={viewformval}
                                  label={t("No")}
                                  className="radio-formcontrol"
                                />
                              </RadioGroup>
                            </FormControl>
                            
                         </div>                        
                           
                              <div className="FormDate">
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack spacing={3}> 
                                 {viewformval == true?                               
                                  <DatePicker
                                    views={['day']}
                                    readOnly
                                    label={t("Effective start date")}
                                    value={startdatevalue}
                                    onChange={(newValue) => {
                                        setStartDateValue(newValue);
                                    }}
                                    renderInput={(params) => <DateTextField {...params} helperText={null}  disabled={true}/>}
                                  />:
                                  <DatePicker
                                  views={['day']}
                                  label={t("Effective start date")}
                                  value={startdatevalue}
                                  onChange={(newValue) => {
                                      setStartDateValue(newValue);
                                  }}
                                  renderInput={(params) => <DateTextField {...params} helperText={null} />}
                                />
                               } 
                                </Stack>
                              </LocalizationProvider>
                              </div>
                              <div className="FormDate">
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack spacing={3}>
                                   {viewformval == true?  
                                  <DatePicker
                                    views={['day']}
                                    label={t("Effective end date")}
                                    readOnly
                                    value={enddatevalue}
                                    onChange={(newValue) => {
                                       setEndDateValue(newValue);
                                    }}
                                    renderInput={(params) => <DateTextField {...params} helperText={null} />}
                                  />
                                   :                              
                                  <DatePicker
                                    views={['day']}
                                    label={t("Effective end date")}
                                    value={enddatevalue}
                                    onChange={(newValue) => {
                                       setEndDateValue(newValue);
                                    }}
                                    renderInput={(params) => <DateTextField {...params} helperText={null} />}
                                  />
                                 }   
                                </Stack>
                              </LocalizationProvider>
                              </div>
                              <div className="selecttype-radio-btns form-radio-select form-input">
                              <FormControl className="saleforce">
                              <FormLabel id="formdemo-customized-radios">{t('Status')}</FormLabel>
                              <RadioGroup
                                row
                                defaultValue="no"
                                aria-labelledby="demo-customized-radios"
                                // value={salesforce}
                                value={templateStatus==true?"yes":"no"}
                                name="customized-radios"
                                onChange={(e: any) => {
                                  e.target.value === "no"
                                  ? setTemplateStatus(false)
                                  : setTemplateStatus(true);
                                  }}
                              >
                                <FormControlLabel                      
                                  value="yes"
                                  control={<BpRadio />}
                                  disabled={viewformval}
                                  label={t("Active")}
                                  className="radio-formcontrol-salesforce"
                                />
                                <FormControlLabel
                                  value="no"
                                  control={<BpRadio />}
                                  disabled={viewformval}
                                  label={t("Inactive")}
                                  className="radio-formcontrol-salesforce"
                                />
                              </RadioGroup>
                            </FormControl>
                            <FormControl className="saleforce">
                              <FormLabel id="formdemo-customized-radios">{t('Assets Required?')}</FormLabel>
                              <RadioGroup
                                row
                                defaultValue="no"
                                aria-labelledby="demo-customized-radios"
                                // value={salesforce}
                                value={showAssets==true?"yes":"no"}
                                name="customized-radios"
                                onChange={(e: any) => {
                                    e.target.value === "no"
                                      ? setShowAssets(false)
                                      : setShowAssets(true);
                                  }}
                              >
                                <FormControlLabel                      
                                  value="yes"
                                  control={<BpRadio />}
                                  disabled={viewformval}
                                  label={t("Yes")}
                                  className="radio-formcontrol-salesforce"
                                />
                                <FormControlLabel
                                  value="no"
                                  control={<BpRadio />}
                                  disabled={viewformval}
                                  label={t("No")}
                                  className="radio-formcontrol-salesforce"
                                />
                              </RadioGroup>
                            </FormControl>
                            </div>
                            {
                              showAssets==true? 
                              <>

                              <div className="tabledata" style={{ gridColumn: "1 / -1"}}>
                                        <TableBody>
                                            <Box margin={1}>
                                              <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div>
                                                  Assets
                                                    <div className="table-btn">
                                                      <button className="additem" onClick={handleAddAsset}><i className="material-icons add">&#xe145;</i>{t('ADD')}</button> 
                                                    </div>
                                                </div>
                                              </div>
                                          
                                        
                                              <Table
                                                className={classes.table}
                                                size="medium"
                                                aria-label="a dense table"
                                              >
                                                <TableHead>
                                                  <TableRow>
                                                    <TableCell>{t('Asset Name')}</TableCell>
                                                    <TableCell>{t('Asset Body')}</TableCell>
                                                    <TableCell>{t('Asset URL')}</TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                {  
                                                <TableBody>
                                                  {assetRow.map((row, i) => {
                                                    return (
                                                  
                                                        <TableRow>
                                                              <TableCell padding="none">
                                                                <input
                                                                  value={row.Name}
                                                                  name="Name"
                                                                  onChange={(e) => handleAssetInputChange(e, i)}
                                                                  style={{border:"none"}}
                                                                />
                                                              </TableCell>
                                                              <TableCell padding="none">
                                                                <input
                                                                  value={row.Body}
                                                                  name="Name"
                                                                  onChange={(e) => handleAssetInputChange(e, i)}
                                                                  style={{border:"none"}}
                                                                />
                                                              </TableCell>
                                                              <TableCell padding="none">
                                                                <input
                                                                  value={row.Url}
                                                                  name="Name"
                                                                  onChange={(e) => handleAssetInputChange(e, i)}
                                                                  style={{border:"none"}}
                                                                />
                                                              </TableCell>
                                                            
                                                          
                                                          {/* {isEdit ? ( */}
                                                            <Button className="mr10" onClick={handleAssetConfirm}>
                                                              <ClearIcon />
                                                            </Button>
                                                          
                                                          {showAssetConfirm && (
                                                            <div>
                                                              <Dialog
                                                                open={showAssetConfirm}
                                                                onClose={handleNo}
                                                                aria-labelledby="alert-dialog-title"
                                                                aria-describedby="alert-dialog-description"
                                                              >
                                                                <DialogTitle id="alert-dialog-title">
                                                                  {"Confirm Delete"}
                                                                </DialogTitle>
                                                                <DialogContent>
                                                                  <DialogContentText id="alert-dialog-description">
                                                                    
                                                                  </DialogContentText>
                                                                </DialogContent>
                                                                <DialogActions>
                                                                  <Button
                                                                    onClick={() => handleRemoveAssetClick(i)}
                                                                    color="primary"
                                                                    autoFocus
                                                                  >
                                                                   { t('Yes')}
                                                                  </Button>
                                                                  <Button
                                                                    onClick={handleAssetNo}
                                                                    color="primary"
                                                                    autoFocus
                                                                  >
                                                                    {t('No')}
                                                                  </Button>
                                                                </DialogActions>
                                                              </Dialog>
                                                            </div>
                                                          )}
                                                        </TableRow>
                                                      
                                                    );
                                                  })}
                                                </TableBody>}
                                                
                                              </Table>
                                            </Box>
                                        </TableBody>
                                  </div>


                                {/* <TextField
                                 className="roundborder"
                                  label={t("Asset Name")}
                                  placeholder={t("Please enter dynamic asset name")}
                                  value={FormName}
                                  sx={{width:400}}
                                  onChange={(e)=>setFormName(e.target.value)}
                                  inputProps={
                                    { readOnly: viewformval, }
                                  }
                                  focused
                                  />
                                <TextField
                                 className="roundborder"
                                  label={t("Asset Body")}
                                  placeholder={t("Please enter dynamic asset body")}
                                  value={FormName}
                                  sx={{width:400}}
                                  onChange={(e)=>setFormName(e.target.value)}
                                  inputProps={
                                    { readOnly: viewformval, }
                                  }
                                  focused
                                  />
                                 <button className="saveitem" type="button" onClick={() => { let doc: any = document; doc.getElementById('assetAttachedFiles').click() }}>{t('Select Asset File')}</button>
                                  <input
                                      type="file"
                                      id="assetAttachedFiles"
                                      name="assetAttachedFiles"
                                      className="custom-file-input"
                                      multiple
                                      accept="application/pdf, application/vcard, text/vcard, text/x-vcard, text/csv, text/rtf, text/richtext, text/calendar, text/directory, image/bmp, image/tiff, image/jpeg, image/jpg, image/gif, image/png, video/H264, video/H263-2000, video/H263-1998, video/H263, video/H261, video/3gpp-tt, video/3gpp2, video/3gpp, video/webm, video/quicktime, video/mp4, video/mpeg, audio/basic, audio/L24, audio/mp4, audio/mpeg, audio/ogg, audio/vnd.rn-realaudio, audio/vnd.wave, audio/3gpp, audio/3gpp2, audio/ac3, audio/vnd.wave, audio/webm, audio/amr-nb, audio/amr"
                                      style={{ display: 'none' }}
                                      onChange={(e)=>selectAssetFile(e)}

                                  /> */}

                              {/* {
                                assetFile &&
                                <div className='attached-media grid-container'>
                                  <div className='media-tb'>
                                              <div className="media-left d-flex">
                                                  <i className="far fa-file-alt"></i>
                                                  <p>{(assetFile.size / 1e6).toFixed(2)}</p>
                                              </div>

                                              <div>{assetFile.name}</div>

                                              <div >
                                                  <i className="fas fa-times"  />

                                              </div>
                                          </div>
                                </div>
                               } */}
                              </>
                            :null}
                            
                    {/* </form> */}
                </div>
                <div className="save">
                            <button className="saveitem" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" onClick={handleNextTab}>{t('Next')}</button>
                 </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo" onClick={handleTabClick}>
                  {t('Fields')}
                </button>
              </h2>
              <div id="panelsStayOpen-collapseTwo" className={fieldformval} aria-labelledby="panelsStayOpen-headingTwo">
                <div className="accordion-body">
                {/* <button className="additem" onClick={addTableRows}>Add</button>
                <button className="saveitem" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" onClick={handleNextTab}>Next</button>
                 */}
               
              
                <div className="tabledata">
                      <TableBody>
                          <Box margin={1}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <div>
                                {/* {isEdit ? ( */}
                                {
                                  viewformval==false?
                                  <div className="table-btn">
                                     <button className="additem" onClick={handleAdd}><i className="material-icons add">&#xe145;</i>{t('ADD')}</button>                                  
                                     
                                        
                                    
                                    
                                  </div>:null
                                }
                                  
                                {/* ) : (
                                  <div> */}
                                    {/* <Button onClick={handleAdd}>
                                      <AddBoxIcon onClick={handleAdd} />
                                      ADD
                                    </Button> */}
                                    {/* <button className="additem" onClick={handleAdd}>Add</button>
                                    <button className="additem" onClick={handleEdit}>Edit</button> */}
                                    {/* <Button onClick={handleEdit}>
                                      <CreateIcon />
                                      EDIT
                                    </Button> */}
                                  {/* </div>
                                )} */}
                              </div>
                            </div>
                         
                      
                            <Table
                              className={classes.table}
                              size="medium"
                              aria-label="a dense table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>{t('Name')}</TableCell>
                                  <TableCell>{t('Type')}</TableCell>
                                  <TableCell>{t('Value')}</TableCell>
                                  <TableCell align="center">{t('Query')}</TableCell>
                                  <TableCell>{t('Prepopulate')}</TableCell>
                                  <TableCell>{t('Active')}</TableCell>
                                  <TableCell>{t('Start date')}</TableCell>
                                  <TableCell>{t('End date')}</TableCell>
                                  {/* <TableCell align="center">City</TableCell>
                                  <TableCell align="center"> </TableCell> */}
                                </TableRow>
                              </TableHead>
                              {viewformval==false?   
                              <TableBody>
                                {rows.map((row:any, i) => {
                                  return (
                                 
                                      <TableRow>
                                            <TableCell padding="none">
                                              <input
                                                value={row.Name}
                                                name="Name"
                                                onChange={(e) => handleInputChange(e, i)}
                                                style={{border:"none"}}
                                              />
                                            </TableCell>
                                            <TableCell padding="none">
                                              <select
                                                style={{ width: "100px" }}
                                                name="Type"
                                                value={row.Type}
                                                onChange={(e) => handleInputChange(e, i)}
                                              >
                                                <option value=""></option>
                                                <option value="Date">{t('Date')}</option>
                                                <option value="String">{t('String')}</option>
                                                <option value="Time">{t('Time')}</option>
                                                <option value="Check">{t('Check box')}</option>
                                                {/* <option value="Amaravati">Amaravati</option>
                                                <option value="Pulgaon">Pulgaon</option> */}
                                              </select>
                                            </TableCell>
                                            <TableCell padding="none">
                                            <select
                                                style={{ width: "100px" }}
                                                name="Value"
                                                defaultValue={'Yes'}
                                                value={row.Value=='Yes'?"Y":"N"}
                                      
                                                onChange={(e) => handleInputChange(e, i)}
                                              >
                                                {/* <option value=""></option> */}
                                                <option value="Yes">{t('Yes')}</option>
                                                <option value="No">{t('No')}</option>                                       
                                              </select>
                                            </TableCell>
                                            <TableCell padding="none">
                                              <input
                                                value={row.Query}
                                                name="Query"
                                                style={{border:"none"}}
                                                onChange={(e) => handleInputChange(e, i)}
                                              />
                                            </TableCell>
                                            <TableCell padding="none">
                                              <select
                                                style={{ width: "100px" }}
                                                name="Prepopulate"
                                                value={row.Prepopulate==t('Yes')?t("Yes"):t("No")}
                                                
                                                onChange={(e) => handleInputChange(e, i)}
                                              >
                                                {/* <option value=""></option> */}
                                                <option value="Yes">{t('Yes')}</option>
                                                <option value="No">{t('No')}</option>                                       
                                              </select>
                                            </TableCell>
                                            <TableCell padding="none">
                                              <select
                                                style={{ width: "100px" }}
                                                name="Active"
                                               value={row.Active=="Yes"?"Y":"N"}
                                                defaultValue={"Yes"}
                                                onChange={(e) => handleInputChange(e, i)}
                                              >
                                                {/* <option value=""></option> */}
                                                <option value="Y">{t('Yes')}</option>
                                                <option value="N">{t('No')}</option>                                       
                                              </select>
                                            </TableCell>
                                            <TableCell padding="none">
                                              {/* <FieldDatePicker selected={row.EffectiveStartDate} onChange={(e) => handleInputChange({ name: "EffectiveStartDate", target: e }, i)} /> */}
                                              <input name="EffectiveStartDate" className="date" value={row.EffectiveStartDate} type="date" onChange={(e) => handleInputChange(e, i)}/>
                                            </TableCell>
                                            <TableCell padding="none">
                                              {/* <FieldDatePicker selected={row.EffectiveStartDate} onChange={(e) => handleInputChange({ name: "EffectiveStartDate", target: e }, i)} /> */}
                                              <input  name="EffectiveEndDate" className="date" value={row.EffectiveEndDate}  type="date" onChange={(e) => handleInputChange(e, i)}/>
                                            </TableCell>
                                          
                                          
                                        
                                        {/* {isEdit ? ( */}
                                          <Button className="mr10" onClick={handleConfirm}>
                                            <ClearIcon />
                                          </Button>
                                        
                                        {showConfirm && (
                                          <div>
                                            <Dialog
                                              open={showConfirm}
                                              onClose={handleNo}
                                              aria-labelledby="alert-dialog-title"
                                              aria-describedby="alert-dialog-description"
                                            >
                                              <DialogTitle id="alert-dialog-title">
                                                {"Confirm Delete"}
                                              </DialogTitle>
                                              <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                {t('Are you sure to delete')}
                                                </DialogContentText>
                                              </DialogContent>
                                              <DialogActions>
                                                <Button
                                                  onClick={() => handleRemoveClick(i)}
                                                  color="primary"
                                                  autoFocus
                                                >
                                                  {t('Yes')}
                                                </Button>
                                                <Button
                                                  onClick={handleNo}
                                                  color="primary"
                                                  autoFocus
                                                >
                                                  {t('No')}
                                                </Button>
                                              </DialogActions>
                                            </Dialog>
                                          </div>
                                        )}
                                      </TableRow>
                                    
                                  );
                                })}
                              </TableBody>:
                                 <TableBody>
                                 {rows.map((row, i) => {
                                   return (
                                  
                                       <TableRow>
                                             <TableCell padding="none">
                                               <input
                                                 value={row.Name}
                                                 name="Name"
                                                 disabled
                                                //  onChange={(e) => handleInputChange(e, i)}
                                                 style={{border:"none"}}
                                               />
                                             </TableCell>
                                             <TableCell padding="none">
                                               <select
                                                 style={{ width: "100px" }}
                                                 name="Type"
                                                 disabled
                                                 value={row.Type}
                                                //  onChange={(e) => handleInputChange(e, i)}
                                               >
                                                 <option value=""></option>
                                                 <option value="Date">{t('Date')}</option>
                                                 <option value="String">{t('String')}</option>
                                                 <option value="Signature">{t('Signature')}</option>
                                                 {/* <option value="Amaravati">Amaravati</option>
                                                 <option value="Pulgaon">Pulgaon</option> */}
                                               </select>
                                             </TableCell>
                                             <TableCell padding="none">
                                             <select
                                                 style={{ width: "100px" }}
                                                 name="Value"
                                                 value={row.Value=='Yes'?"Yes":"No"}
                                                 disabled
                                                //  onChange={(e) => handleInputChange(e, i)}
                                               >
                                                 {/* <option value=""></option> */}
                                                 <option value="Yes">{t('Yes')}</option>
                                                 <option value="No">{t('No')}</option>                                       
                                               </select>
                                             </TableCell>
                                             <TableCell padding="none">
                                               <input
                                                 value={row.Query}
                                                 name="Query"
                                                 disabled
                                                 style={{border:"none"}}
                                                //  onChange={(e) => handleInputChange(e, i)}
                                               />
                                             </TableCell>
                                             <TableCell padding="none">
                                               <select
                                                 style={{ width: "100px" }}
                                                 name="Prepopulate"
                                                 value={row.Prepopulate=='Yes'?"Yes":"No"}
                                                 disabled
                                                //  onChange={(e) => handleInputChange(e, i)}
                                               >
                                                 {/* <option value=""></option> */}
                                                 <option value="Yes">{t('Yes')}</option>
                                                 <option value="No">{t('No')}</option>                                       
                                               </select>
                                             </TableCell>
                                             <TableCell padding="none">
                                               <select
                                               disabled
                                                 style={{ width: "100px" }}
                                                 name="Active"
                                                 value={row.Active=="Yes"?"Yes":"No"}
                                       
                                                //  onChange={(e) => handleInputChange(e, i)}
                                               >
                                                 {/* <option value=""></option> */}
                                                 <option value="Yes">{t('Yes')}</option>
                                                 <option value="No">{t('No')}</option>                                       
                                               </select>
                                             </TableCell>
                                             <TableCell padding="none">
                                              {/* <FieldDatePicker selected={row.EffectiveStartDate} onChange={(e) => handleInputChange({ name: "EffectiveStartDate", target: e }, i)} /> */}
                                              <input name="EffectiveStartDate" disabled type="date" value={row.EffectiveStartDate} />
                                            </TableCell>
                                            <TableCell padding="none">
                                              {/* <FieldDatePicker selected={row.EffectiveStartDate} onChange={(e) => handleInputChange({ name: "EffectiveStartDate", target: e }, i)} /> */}
                                              <input  name="EffectiveEndDate" disabled type="date" value={row.EffectiveEndDate}/>
                                            </TableCell>
                                        
                                           
                                           
                                         
                                        
                                        
                                       
                                         {showConfirm && (
                                           <div>
                                             <Dialog
                                               open={showConfirm}
                                               onClose={handleNo}
                                               aria-labelledby="alert-dialog-title"
                                               aria-describedby="alert-dialog-description"
                                             >
                                               <DialogTitle id="alert-dialog-title">
                                                 {"Confirm Delete"}
                                               </DialogTitle>
                                               <DialogContent>
                                                 <DialogContentText id="alert-dialog-description">
                                                 {t('Are you sure to delete')}
                                                 </DialogContentText>
                                               </DialogContent>
                                               <DialogActions>
                                                 <Button
                                                   onClick={() => handleRemoveClick(i)}
                                                   color="primary"
                                                   autoFocus
                                                 >
                                                   {t('Yes')}
                                                 </Button>
                                                 <Button
                                                   onClick={handleNo}
                                                   color="primary"
                                                   autoFocus
                                                 >
                                                   {t('No')}
                                                 </Button>
                                               </DialogActions>
                                             </Dialog>
                                           </div>
                                         )}
                                       </TableRow>
                                     
                                   );
                                 })}
                               </TableBody>}
                              
                            </Table>
                            
                            <div className="save">
                                    <button className="saveitem" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" onClick={handleNextTab}>{t('Next')}</button>
                               </div>
                          </Box>
                      </TableBody>
                </div>
                
                   
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree" onClick={handleTabClick}> 
                  {t('Wave Association')}
                </button>
              </h2>
              <div id="panelsStayOpen-collapseThree" className={waveval} aria-labelledby="panelsStayOpen-headingThree">
                <div className="wave-accordion-body">
                {/* <label className="wavecontent">Wave Name</label> */}
                {/* <MultiSelect
                   disabled={viewformval}
                  //  selectedItems={form.selectedItems}
                    onChange={(e)=>handleOnchange(e)}
                    options={options}
                    
                  /> */}
                 <div className="multiselect-wrapper">
                 <MultiselectDropdown
                 disable={viewformval}
                  label={t("Campaign")}
                  selectedData={activeCampaign}
                  data={allCampaigns}
                  name="CampaignName"
                  id="CamapignId"
                  func={(e) => {
                    selectActiveCampaign(e);
                  }}
                />
                 </div>
                 <div className="multiselect-wrapper">
              <MultiselectDropdown
               disable={viewformval}
                label={t("Wave Name")}
                selectedData={activeWaves}
                data={allWaves}
                name="WaveName"              
                id="WaveId"
                func={(e) => {
                    console.log('inside wave change')
                    selectActiveWave(e);
                }}
              />
              </div>
              <div className="multiselect-wrapper">
                <MultiselectDropdown
                 disable={viewformval}
                label={t("Brand")}
                selectedData={activeProducts}
                data={allProducts}
                name="ProductName"
                id="ProductId"
                func={(e) => {
                  selectActiveProducts(e);
                  
                }}
              />
                 </div>
                  <div className="selecttype-radio-btns form-radio-select form-input">
                  <FormControl>
                              <FormLabel id="formdemo-customized-radios">{t('Do you want this form visible?')}</FormLabel>
                              <RadioGroup
                                row
                                defaultValue="no"
                                aria-labelledby="demo-customized-radios"
                                name="customized-radios"
                                value={activewaveflag==true?"yes":"no"}
                                onChange={(e: any) => {
                                    e.target.value === "no"
                                      ? setActiveWave(false)
                                      : setActiveWave(true);
                                  }}
                              >
                                <FormControlLabel                      
                                  value="yes"
                                  control={<BpRadio />}
                                  disabled={viewformval}
                                  className="radio-formcontrol"
                                  label={t("Yes")}
                                />
                                <FormControlLabel
                                  value="no"
                                  control={<BpRadio />}
                                  disabled={viewformval}
                                  className="radio-formcontrol"
                                  label={t("No")}
                                />
                              </RadioGroup>
                            </FormControl>

                  </div>
                  <div className="FormDate">
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack spacing={3}> 
                                 {viewformval == true?                               
                                  <DatePicker
                                    views={['day']}
                                    readOnly
                                    label={t("Effective start date")}
                                    value={waveStartdatevalue}
                                    onChange={(newValue) => {
                                        setWaveStartDateValue(newValue);
                                    }}
                                    renderInput={(params) => <DateTextField {...params} helperText={null}  disabled={true}/>}
                                  />:
                                  <DatePicker
                                  views={['day']}
                                  label={t("Effective start date")}
                                  value={waveStartdatevalue}
                                  onChange={(newValue) => {
                                      setWaveStartDateValue(newValue);
                                  }}
                                  renderInput={(params) => <DateTextField {...params} helperText={null} />}
                                />
                               } 
                                </Stack>
                              </LocalizationProvider>
                              </div>
                              <div className="WaveFormEndDate">
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack spacing={3}>                                 
                                  <DatePicker
                                  readOnly={viewformval}
                                    views={['day']}
                                    label={t("Effective end date")}
                                    value={endwavedate}
                                    onChange={(newValue) => {
                                      setEndWaveDate(newValue);
                                    }}
                                    renderInput={(params) => <DateTextField {...params} helperText={null} />}
                                  />
                                </Stack>
                              </LocalizationProvider>
                              </div>
                              
                              
                </div>
                {
                  !viewformval?
                  <div className="save">
                  <button className="saveitem" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" onClick={handleNextTab}>{t('Save')}</button>
                  </div>:null
                }
               
              </div>
            </div>
          </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
