import React, { useState, useEffect, useRef } from "react";
import './Participant.css'
import SignalCellularAlt1BarIcon from '@mui/icons-material/SignalCellularAlt1Bar';
import SignalCellularAlt2BarIcon from '@mui/icons-material/SignalCellularAlt2Bar';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import MicOffIcon from '@mui/icons-material/MicOff';
import { setDisconnectedParticipants, setVideoCallScreenShareStarted, setVideoCallShareUserId } from "../../../app/slice/videoCallSlice";
import { useSelector, useDispatch } from 'react-redux'
import { setVideoCallAnnotaion, setVideoCallChats } from "../../../app/slice/videoCallSlice";
import { setFormId } from "../../../app/slice/srfSlice";
//import Avatar from "../../../components/Avatar/Avatar";
import Avatar from '@mui/material/Avatar';
import { sendMessageApi } from "../../../apis/chat/messageApi";
import { identity } from "rxjs";



const Participant = (props: any) => {
  let { participant, mute, video, parentWidth, parentHeight, children, dominant, annotationset, disconnected } = props
  const videoCallChats: any = useSelector((state: any) => state.floaterVideoCall.videoCallChats)
  const videoCallScreenShareStarted: any = useSelector((state: any) => state.floaterVideoCall.videoCallScreenShareStarted)
  const disconnectedParticipants:any=useSelector((state: any) => state.floaterVideoCall.disconnectedParticipants)
  const formId: any = useSelector((state: any) => state.srf.formId)
  const [videoTracks, setVideoTracks] = useState<any>([]);
  const [audioTracks, setAudioTracks] = useState<any>([]);
  const [dataTracks, setDataTracks] = useState<any>([]);

  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [width, setWidth] = useState(10);
  const [height, setHeight] = useState(10);
  const [muted, setMuted] = useState(false);
  const [videoOff, setVideoOff] = useState(false)
  const [network, setNetwork] = useState('')
  const [border, setBorder] = useState('none')
  const [localChats, setLocalChats] = useState<any>([])
  const [contact, setContact] = useState<any>(null)
  const [isMobileJoined,setIsMobileJoined]=useState(false)

  // useEffect(()=>{
  //   setLocalChats(videoCallChats)
  // },[videoCallChats])

  function stringAvatar(name) {
    if(name)
    name=name.toUpperCase();
    let tempname:any=name.split(' ');
    let shortName=tempname[0][0];
    if(tempname[1])
    shortName+=tempname[1][0];
    //console.log("n:::::::::::::::::::::::::::::::::::::::::::", n)
    return {

      sx: {

        cursor: "pointer",

        bgcolor: "#ffffff",
        fontSize:'4rem',
        color: "var(--primary-color)",
        width: '10rem',
        height: '10rem'
      },

      children: shortName

    };

  }
  const dispatch = useDispatch();
  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication: any) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    console.log("participant:::::::::::::::::::::::::::::", participant)
    console.log('sub')
    console.log('hi inside participant')
    console.log(videoCallChats)
    debugger
    if (participant.videoTracks instanceof Map)
      setVideoTracks(trackpubsToTracks(participant.videoTracks));
    if (participant.audioTracks instanceof Map)
      setAudioTracks(trackpubsToTracks(participant.audioTracks));
    if (participant.dataTracks instanceof Map)
      setDataTracks(trackpubsToTracks(participant.dataTracks));

    const trackSubscribed = (track) => {
      console.log('subscribed',track)
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }

      else if (track.kind === "data") {
        setDataTracks((dataTracks) => [...dataTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      console.log('unsubscribed',track)
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
      else if (track.kind === "data") {
        setDataTracks((dataTracks) => dataTracks.filter((a) => a !== track));
      }
    };
    function printNetworkQualityStats(networkQualityLevel, networkQualityStats) {
      // Print in console the networkQualityLevel using bars
      // console.log({
      //   1: '▃',
      //   2: '▃ ▄',
      //   3: '▃ ▄ ▅',
      //   4: '▃ ▄ ▅ ▆',
      //   5: '▃ ▄ ▅ ▆ ▇'
      // }[networkQualityLevel] || '');
      setNetwork({
        1: <SignalCellularAlt1BarIcon />,
        2: <SignalCellularAlt1BarIcon />,
        3: <SignalCellularAlt2BarIcon />,
        4: <SignalCellularAlt2BarIcon />,
        5: <SignalCellularAltIcon />
      }[networkQualityLevel] || '')

      if (networkQualityStats) {
        // Print in console the networkQualityStats, which is non-null only if Network Quality
        // verbosity is 2 (moderate) or greater
      //  console.log('Network Quality statistics:', networkQualityStats);
      }
    }

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);
    setNetwork({
      1: <SignalCellularAlt1BarIcon />,
      2: <SignalCellularAlt1BarIcon />,
      3: <SignalCellularAlt2BarIcon />,
      4: <SignalCellularAlt2BarIcon />,
      5: <SignalCellularAltIcon />
    }[participant.networkQualityLevel] || '')
    participant.on('networkQualityLevelChanged', printNetworkQualityStats);
    participant.on('subscribed', (track) => {
      console.log('subscribed')
    });
    participant.on('unsubscribed', (track) => {
      console.log('unsubscribed')
    });

    //resize2()
    return () => {

      setVideoTracks([]);
      setAudioTracks([]);
      setDataTracks([])
      //participant.removeAllListeners();

    };
  }, [participant]);


  const resize2 = () => {

    let doc: any = document;
    let w = parentWidth
    let h = parentHeight;
    const boardArea = (w * h)
    //console.log(w,h)
    // Calculate the tile area
    var tileArea = boardArea / children

    if (false) {
      return {
        height: h - 10, width: w - 10, margin: 1 + 'px'
      }
    }
    else {
      // Get the sqr 
      const squareRoot = Math.sqrt(tileArea)
      debugger
      // Get the Width 
      const width = (squareRoot - 100)

      //console.log(h/w)
      // Get the height
      let hm = 0.65//0.75<h/w?0.75:h/w;
      const height = (width * hm)
      // let childrens=doc.getElementsByClassName('participantsDiv')[0].children;
      //     for (var s = 0; s < childrens.length; s++) {

      //         // camera fron dish (div without class)
      //         let element =childrens[s];

      //         // custom margin
      //         element.style.margin = 6 + "px"

      //         // calculate dimensions
      //         debugger

      //         element.style.width =width+"px"//((100/children.length)-10)+"%"  //width + "px"
      //         element.style.height =height+'px' //(width * ratio()) + "px"

      //         // to show the aspect ratio in demo (optional)
      //         //element.setAttribute('data-aspect', this._ratios[this._aspect]);

      //     }
      //console.log(h,w,height,width)
      return {
        height, width, margin: 1 + 'px'
      }
    }

  }

  const areaNew = (increment, width, height) => {

    let i = 0;
    let w = 0;
    let h = increment * ratio() + 20;
    //let doc:any=document;
    //let children=chikdre//doc.getElementsByClassName('participantsDiv')[0].children;

    while (i < (children)) {
      if ((w + increment) > width) {
        w = 0;
        h = h + (increment * ratio()) + 20;
      }
      w = w + increment + 20;
      i++;
    }
    if (h > height || increment > width) return false;
    else return increment;

  }
  const resize = () => {

    // get dimensions of dish
    // let doc:any=document;
    // let width=doc.getElementsByClassName('participantsDiv')[0].offsetWidth
    // let height=doc.getElementsByClassName('participantsDiv')[0].offsetHeight
    let height = parentHeight - 20;
    let width = parentWidth - 20
    console.log(height, width)
    // loop (i recommend you optimize this)
    let max = 0
    let i = 1
    while (i < 5000) {
      let area = areaNew(i, width, height);
      if (area === false) {
        max = i - 1;
        break;
      }
      i++;
    }

    // console.log(doc.getElementsByClassName('participantsDiv')[0])

    // remove margins
    max = max - (20);
    return {
      height: (max * (9 / 16)), width: max, margin: 1 + 'px'
    }
    // set dimensions to all cameras
    //resizer(max);
  }
  const resizer = (width) => {

    let doc: any = document;
    let children = doc.getElementsByClassName('participantsDiv')[0].children;
    for (var s = 0; s < children.length; s++) {

      // camera fron dish (div without class)
      let element = children[s];

      // custom margin
      element.style.margin = 10 + "px"

      // calculate dimensions
      debugger

      element.style.width = width * 1.5 + "px"//((100/children.length)-10)+"%"  //width + "px"
      element.style.height = (width * ratio()) + "px"

      // to show the aspect ratio in demo (optional)
      //element.setAttribute('data-aspect', this._ratios[this._aspect]);

    }
  }
  const ratio = () => { return 480 / 640 }

  useEffect(() => {
    //console.log('new', videoTracks, videoRef.current)
    let isSharing = false;
    // videoTracks.map(x=>{
    //   console.log(x.name)
    //   if(x.name=="user-screen")
    //   {
    //     console.log(true)
    //     isSharing=true;
    //     dispatch(setVideoCallScreenShareStarted(true))
    //     dispatch(setVideoCallShareUserId(participant.sid))
    //   }
    //  })
    //  if(!isSharing){
    //   dispatch(setVideoCallScreenShareStarted(false))
    //   dispatch(setVideoCallShareUserId(null))
    //  }
    let videoTrack = videoTracks[0];
  
    videoTracks.map(x => {
      console.log(x.name)
      if (x.name == "user-screen" && videoCallScreenShareStarted) {
        videoTrack = x;
      }
      return x;
    })
    console.log(videoTrack)
    if (videoTrack) {
      if (!videoTrack.isEnabled || !videoTrack.isStarted) {
        setVideoOff(true)
      }
      videoTrack.attach(videoRef.current); 
      console.log('attached',videoTracks)
      videoTrack.on('disabled', () => { console.log('disable',participant.identity,videoTrack); setVideoOff(true);
      videoTrack.off('disabled', () => {
        console.log('disable Off',participant.identity,videoTrack);debugger; setVideoOff(false) })

    })
      videoTrack.on('enabled', () => { console.log('enabled',participant.identity,videoTrack); setVideoOff(false) })
      videoTrack.on('stopped', () => { console.log('stopped',participant.identity,videoTrack); setVideoOff(true) })
      videoTrack.on('started', () => { console.log('started',participant.identity,videoTrack); setVideoOff(false) })

      return () => {
        console.log('detached',videoTracks)
        //videoTrack.detach().forEach(function(detachedElement) {detachedElement.remove();});
        videoTrack.off('disabled', () => {console.log('disable Off',participant.identity,videoTrack);debugger; setVideoOff(false) })
        videoTrack.off('enabled', () => {console.log('enabled Off',participant.identity,videoTrack);  setVideoOff(false) })
        videoTrack.off('stopped', () => { console.log('stopped Off',participant.identity,videoTrack);  setVideoOff(true) })
        videoTrack.off('started', () => {  console.log('started Off',participant.identity,videoTrack);setVideoOff(false) })
        //videoTrack.detach(videoRef.current);
      };
    }


    // else
    // setVideoOff(true)

  }, [videoTracks]);


  useEffect(() => {
    const audioTrack = audioTracks[0];

    if (audioTrack) {
      if (!audioTrack.isEnabled) {
        setMuted(true)
      }
      audioTrack.on('disabled', () => { setMuted(true) })
      audioTrack.on('enabled', () => { setMuted(false) })
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
        audioTrack.off('disabled', () => { setMuted(false) })
        audioTrack.off('enabled', () => { setMuted(false) })
      };
    }
  }, [audioTracks]);

  useEffect(() => {
    const dataTrack = dataTracks[0];
    debugger
   async function name() {
    if (dataTrack) {

     await dataTrack.on('message',async message => {
        debugger

        console.log(JSON.parse(message))
        if (JSON.parse(message).type == "disconnect") {
          if (JSON.parse(message).value) {
            disconnected?disconnected(participant):console.log('')
            if(disconnectedParticipants)
            await dispatch(setDisconnectedParticipants([...disconnectedParticipants,participant.sid]))
            else
            await dispatch(setDisconnectedParticipants([participant.sid]))
            console.log('yooooo',disconnectedParticipants)
            //dispatch(setDisconnectedParticipants([...disconnectedParticipants,participant.sid]))
          }
        }
        if (JSON.parse(message).type == "annotation")
          dispatch(setVideoCallAnnotaion(JSON.parse(message).value))
        if (JSON.parse(message).type == "message") {

          //console.log(localChats);
          annotationset(JSON.parse(message).value)
        //   if(document.getElementsByClassName("videochatContainer")){
        //   var objDiv = document.getElementsByClassName("videochatContainer")[0];
        //   objDiv.scrollTop = objDiv.scrollHeight;
        // }
        }
        if (JSON.parse(message).type == "screenshare") {
          if (JSON.parse(message).value == false) {
            dispatch(setVideoCallScreenShareStarted(false))
            dispatch(setVideoCallShareUserId(null))
          }
          else {
            dispatch(setVideoCallScreenShareStarted(true))
            dispatch(setVideoCallShareUserId(participant.sid))
          }
        }
        if (JSON.parse(message).type == "signedFormURL") {

          //const {contact,url}=payload;
          //let phone=contact.PrescriberAcctPh_Nbr//getNumberFromItem(contact);
          console.log(JSON.parse(message).value)
          let url: any = ''
          let obj: any = JSON.parse(message).value;
          if (obj)
            url = obj.data;
          if (url.length > 0) {
            let contact: any = window.localStorage.getItem('formContact');
            contact = JSON.parse(contact);
            let formData = new FormData();
            formData.append("recipient_id", contact.Id);
            formData.append("recipient_name", contact.AcctFull_Nm);
            formData.append("recipient_phone", contact.IntlCallPfx_Nbr + contact.AcctPh_Nbr);
            // if(contact.Is_Prescriber=="Y"){
            formData.append("physcian_id", contact.Id);
            formData.append("role_id", '0');//for prescriber
            // }
            // else{
            //     formData.append("physcian_id",contact.Prescribers[0].Id);
            //     formData.append("role_id", '1');//for other
            //     formData.append('association_id',contact.Prescribers[0].Association_Id)
            // }
            let temptest = "Here is your signed form. ".toString();
            if (temptest.indexOf('shorturl') >= 0)
              formData.append("template", temptest.toString().replace('[shorturl_1]', url));
            else
              formData.append("template", temptest + " " + url);
            formData.append('urls', url)
            // formData.append("template","Your previous AOC is pending. please sign it by using this url "+url);
            formData.append("Campaign_ID", '1000');
            formData.append('wave_id', '1000')
            formData.append('product_id', "1006");
            formData.append("channel", "SMS");
            //   sendMessageForPresOrOthers(formData);
            sendMessageApi(formData, (res) => {

            })

          }



        }

        if (JSON.parse(message).type == "mobframeui") {
          
          setIsMobileJoined(true)
        }
      })
      
    } 
   }
   name()
    if(dataTrack){
      return () => {
        dataTrack.off('message', message => {
          console.log('recieved', message)
        })
      }
    }


  }, [dataTracks])
  useEffect(() => {
    console.log(localChats)
  }, [localChats])
  useEffect(() => {
    console.log(dominant)
    if (dominant) {
      setBorder('3px solid green')
    }
    else
      setBorder('none')
  }, [dominant])
  useEffect(() => {
    console.log(formId)
    if (formId) {
      setContact(formId)
    }
  }, [formId])

  return (
    <div className={"video-wrapper  video-" + children} style={{ border: border }}>
      <div>
        <h3>{participant.identity}
          <span>{network}</span>
          {
            muted ? <MicOffIcon /> : null
          }

        </h3>
      </div>



      <video ref={videoRef} className={isMobileJoined?'isMobileJoined':''} disablePictureInPicture autoPlay={true} />
      <div id={'div-' + participant.sid}> </div>
      {
        videoOff ?
          <div className="videoOffIcon">
            <Avatar {...stringAvatar((participant?.identity))} />

          </div> : null
      }



      <audio ref={audioRef} autoPlay={true} muted={mute} />
    </div>
  );
};

export default Participant;