import config from '../../env.json';
import React, { useState, useEffect } from 'react'
import './PhoneNumberDropdown.css'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { getPhoneNumberType } from '../../apis/checkPhoneNumberType/landlineLookupApi';

function PhoneNumberDropdown(props) {
    const { valueNumber,onChangeNumber,disableColored,errorFunc,initialYN,frompage, numberLandline } = props;
    const { t } = useTranslation();
    const [value, setValue] = useState(valueNumber)
    const [error,setError]=useState(false);
    const [initial,setInitial]=useState(true);
    const [isNumberLandline,setIsNumberLandline]=useState(false);
    const [countries, setCountries] = useState<any>(config.REACT_APP_COUNTRY_LIST.split(','));
    const [defaultCountry, setdefaultCountry] =useState(config.REACT_APP_DEFAULT_COUNTRY);
    const [prefix,setPrefix]= useState('+');
    const [minPhone,setMinPhone]=useState(10);

    const inputStyles = {
        "border" : "1px solid #d3d3d370",
        "border-radius": "1000px",
        "width":"100%",
        "padding":"1rem 1rem 1rem 4.5rem",
        "font-size":"1rem",
        "height":"3.5rem"
    }

    const buttonStyles = {
        "border-radius":"1000px 0px 0px 1000px",
        "background": "#f7f4ff",
        "padding": "0.2rem 0.8rem",
        "border":"none"
        
    }

    const containerStyles = {
        "margin":"0.2rem 0rem",
    }
    
    useEffect(() => {
        setValue(valueNumber);
        console.error("onchange 1::::::::::::::::::::::::::::::::::", valueNumber);

        if (valueNumber) {
            if (valueNumber.length > 0) {
                setInitial(false);
            }
            setError(!isValidPhoneNumber(valueNumber) || (valueNumber.length < minPhone))
            errorFunc(!isValidPhoneNumber(valueNumber)|| (valueNumber.length < minPhone))
            setIsNumberLandline(isNumberLandline)
        }
        else {
            setInitial(true);
            setError(true);
            errorFunc(true);
        }
    }, [valueNumber, isNumberLandline])

    useEffect(()=>{
        console.log(countries)
    }, [countries])

    useEffect(() => {
        if (initialYN)
            setInitial(true)
    }, [initialYN])
    
    useEffect(() => {
        if (valueNumber) {
            
        if(isValidPhoneNumber(valueNumber)) {
            getPhoneNumberType({phone:valueNumber}, (res)=>{
                if(res.type == "landline") {
                  setIsNumberLandline(true)
                  numberLandline?numberLandline(true):console.log('function not provided')
                } else {
                  setIsNumberLandline(false)
                  numberLandline?numberLandline(false):console.log('function not provided')
                }
              })
        } else {
            setIsNumberLandline(false)
            // numberLandline(false)
        } 
    }
      }, [isNumberLandline, valueNumber]);

    const handleNumberChange = (e) => {
        console.log(e);
        console.log(error)
        if(e){
            console.log(error,!isValidPhoneNumber(e))
            setError(!isValidPhoneNumber(e))
            console.log(error,!isValidPhoneNumber(e))

            errorFunc(!isValidPhoneNumber(e))
        }
        
        setValue(e);
        setInitial(false);
        onChangeNumber(e);
    }

    return (
        <div className={`${props.class} custom-wrapper`}>
        
        <PhoneInput
        value={defaultCountry===config.REACT_APP_DEFAULT_COUNTRY && value.length==0? "+1" :`+${value}`}
        country={defaultCountry.toLowerCase()}
        onlyCountries={countries.map((code:string)=> (code.toLowerCase()))}
        onChange={(e:any) =>{ console.log(value); handleNumberChange(`+${e}`)}}
        disableDropdown={countries.length==1}
        disableCountryCode={config.REACT_APP_REM_CNTRY_CD_INPUT =="N"?false:true}
        autoFormat={true}
        specialLabel={props.frompage === 'EditContact' ? '' :'Phone Number*'}
        inputClass={"shadow-none"}
        inputStyle={inputStyles}
        buttonClass={"dropdown-button"}
        buttonStyle={buttonStyles}
        containerStyle={containerStyles}
        countryCodeEditable={false}
        prefix={prefix}
        disabled={disableColored}
        inputProps={{
            name: 'phone',
            required: true,
          }}
        
        
        />
        {
            error && !initial &&
            <p className="erorrPhone form-error-message">
                {t('Enter a valid phone number')}
            </p>
        }
        {
            isNumberLandline && !initial &&
            <p className="erorrPhone form-error-message">
                {t('This is a landline number')}
            </p>
        }
        </div>
    )
}

export default PhoneNumberDropdown