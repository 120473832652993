import axios from "axios";
import config  from '../../env.json';
function callSyncApi(callback){
  // let {lang}=params;
    try {
        axios
        .post(
          config.REACT_APP_CALENDAR_API_BASE+"/outlook",
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("login"),
            },
          }
        )
        .then((res) => {
          console.log(res.data);    
          callback(res.data);
        })
        .catch((err)=>{
          console.log("Freetime Error:"+err);
          callback();
          console.log(JSON.stringify(err))
        })
    } catch (error) {
        callback()
        
    }
}


export {callSyncApi}