import React,{useState,useEffect} from 'react'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult, InteractionRequiredAuthError } from "@azure/msal-browser";
import {chat, setChatDetails, setChatType, setRefreshChatComponent, setRefreshChatList, setChatShowChat} from '../../app/slice/chatSlice';
import Spinner from '../../layouts/spinner/Spinner';
import LoginBgImage from '../../assets/images/LoginBgImage.png'
import Chat from "../chat/Chat"
import { msalInstance } from '../../index';
import "./Login.css"
import Home from '../home/Home';

// import { setLoginDetails } from '../../app/loginReducer';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { deleteUserDetails } from '../../apis/dbApi/dbApi';
import { useDispatch, useSelector } from 'react-redux';
import { setLoginDetails } from '../../app/slice/loginSlice';
import { getAuthApi } from '../../apis/authApi/authApi';
import { PingAuthApi, PingAuthRefresh } from '../../apis/pingauthApi/pingauthApi';
import { updatePushToken } from '../../apis/notificationApi/notificationApi';
import { createDatabase, addUserDetails, getUserDetails } from '../../apis/dbApi/dbApi';
import { setUserVirtualNumber,setUserDetails } from '../../app/slice/authApiSlice';
import axios from 'axios';
import {capacitormsal} from "capacitormsal";
import  config  from '../../env.json';
import { PushNotifications } from '@capacitor/push-notifications';
import { App } from '@capacitor/app';
import {getlangTransData} from '../../apis/langtranslation/langTranslation';
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";
import { sqlite, existingConn } from '../../App';
import { setCallModalView, setCallModalDetails } from '../../app/slice/callModalSlice';
import { useSearchParams } from "react-router-dom";
import { stringify } from 'querystring';
import { json } from 'stream/consumers';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { Plugins, PluginResultError } from '@capacitor/core';
import { isMobile, isTablet ,deviceType } from 'react-device-detect';
const { FaceId } = Plugins;



function Login() {
  let transLocData={};
  const [transData,setTransData]:any=useState([]);
  // const { t } = useTranslation();
  const [AuthType, setAuthType] = useState("");
  // const t=(test)=>{return test};
  const [IsLoggingSilently, setIsLoggingSilently] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [IsLoggedIn, setIsLoggedIn] = useState(true);
  const [loginSave, setLoginSave] = useState(false);

  useEffect(()=>{
    console.log("hhhhhhhhhhhhhh")
  },[])

  useEffect(() => {
    if(config.REACT_APP_IS_CROSS_PLATFORM) {
      App.addListener('appStateChange', ({ isActive }) => {
        console.log('App state changed. Is active?', isActive);
        if(isActive) {
          dispatch(setRefreshChatList(true))
          dispatch(setRefreshChatComponent(true))
        }
      });

      PushNotifications.addListener('registration', token => {
        sessionStorage.setItem("pushToken",token.value);
        updatePushToken({
          "DeviceToken_Val": token.value,
          "Device_Typ": "iOS",
          "is_active": 'Y'
        },(res)=>{     
          })
      });
  
      PushNotifications.addListener('pushNotificationReceived', async notification => {
        console.log('Push notification received: ', notification);
        // await Badge.increase();
        dispatch(setRefreshChatList(true))
        dispatch(setRefreshChatComponent(true))
      });
      
      PushNotifications.addListener('registrationError', err => {
        // alert("Token Error");
        console.error('Registration error: ', err.error);
      });
    
      // PushNotifications.addListener('pushNotificationReceived', notification => {
      //   console.log('Push notification received: ', notification);
      // });
    
      PushNotifications.addListener('pushNotificationActionPerformed', notification => {
        console.log('Push notification action performed', notification.actionId, notification.inputValue);
        dispatch(setRefreshChatList(true))
        dispatch(setRefreshChatComponent(true))
      });
      
      if(!sessionStorage.getItem("login") || sessionStorage.getItem("login") === "") {
        setIsLoggedIn(false);
        getUserDetails().then((users: Array<any>) => {
          setIsLoggingSilently(true);
          if(users.length>0) { 
              if(config.REACT_APP_IS_CROSS_PLATFORM) {
                if(config.REACT_APP_SHOULD_USE_FACE_LOCK == "Y") {
                  FaceId.isAvailable().then(checkResult => {
                    if(checkResult.value) {
                      FaceId.auth().then(() => {
                        console.log('authenticated');
                        silentLoginMobileApp(users);
                      }).catch((error: PluginResultError) => {
                        // handle rejection errors
                        console.error(error.message);
                        // alert(error.message);
                        if(error.message.includes("Authentication canceled") || error.message.includes("Authentication failure")) {
                          setIsLoggingSilently(false);
                          setIsLoggedIn(false);
                        } else {
                          silentLoginMobileApp(users);
                        }
                      });
                    } else {
                      silentLoginMobileApp(users);
                    }
                  });

              } else {
                silentLoginMobileApp(users);
              }
            }
        } else {
            //Go to login
            setIsLoggingSilently(false);
            setIsLoggedIn(false);
        }
        });
      } else {
        setIsLoggedIn(true);
      }
    }
    else
    {
      setIsLoggedIn(false);
    }
  }, [])
  
  //const dispatch = useAppDispatch();
  const dispatch = useDispatch();
  const loginDetails=useSelector((state:any)=>state.login.userName)
//   axios.interceptors.request.use(function (config) {
//     const token = store.getState().session.token;
//     config.headers.Authorization =  token;

//     return config;
// });
  let mobile = "Desktop"
  // var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  if (isMobile) {
    // mobile = deviceType
    if (isTablet){
      mobile = "Tablet"
    }
    else{
      mobile = "Mobile"
    }
  }



// if(!loginSave && e.accessToken){
  useEffect(()=>{
  if(config.REACT_APP_AUTH_TYPE=="AD")
  {
      msalInstance.handleRedirectPromise().then((e: any) => {
    console.log("mobile", mobile);
    console.log("Hiii", JSON.stringify(e));
    // var loginData : any 
    //  loginData = JSON.stringify(e)
    // console.log("loginData", e.account.username)
    console.log("REACT_APP_AUTH_API_BASE", config.REACT_APP_AUTH_API_BASE)
    try {
      axios.post(config.REACT_APP_AUTH_API_BASE + "/auth/login-details?email=" + e.account.username + "&device=" + mobile).then(res => {
    //    callback(res.data);

        console.log("res.data", res.data)
      }).catch(() => {
      //  callback(false)
      })
    } catch (error) {
    //  callback(false)
    }

    if(e){
      

      axios.defaults.headers.common["Authorization"] = "Bearer "+e.accessToken;
      //dispatch(setLoginDetails({'token':'Hi Hello','userName':e.account.username,'userAccount':e}));
      dispatch(setLoginDetails({'token':e.accessToken,'userName':e.account.username,'userAccount':e}));
      dispatch(setChatShowChat(false));
      dispatch(setCallModalView(false));
      getAuthApi({authToken:"Bearer "+e.accessToken,type:config.REACT_APP_AUTH_TYPE},(res)=>{
        
        if(res)
        dispatch(setUserVirtualNumber(
          {
            EmployeeV_Nbr: res[0].EmployeeV_Nbr,
            Id: res[0].Id
          }
        ))
        dispatch(setUserDetails(
          {
            // EmployeeEmail_Adr:res[0].EmployeeEmail_Adr,
            EmployeePh_Nbr:res[0].EmployeePh_Nbr,
            EmployeeQR_Cd:res[0].EmployeeQR_Cd,
            EmployeeFull_Nm:res[0].EmployeeFull_Nm
          }
        ))
        console.log(res)
      })

      
      // getAutoPilot({authToken:"Bearer "+e.accessToken},(res)=>{

      // })
      sessionStorage.setItem("login",e.accessToken);
      sessionStorage.setItem('loginName',e.account.username);
    }});
  }
//   setLoginSave(true)
// }
},[])
  
  if(config.REACT_APP_IS_CROSS_PLATFORM) {
    axios.interceptors.request.use((request:any) => {
      console.log(request.url);
      // Edit request config
      // alert("Token: " + sessionStorage.getItem('login'));
      if(sessionStorage.getItem('login') == null || sessionStorage.getItem('login') == "") {
        throw new axios.Cancel('');
      }
      request.headers.Authorization="Bearer " + sessionStorage.getItem('login');
      console.log(request)
      return request;
  }, error => {
      console.log(error);
      return Promise.reject(error);
  });
  }
  
  axios.interceptors.response.use(response => {
      return response;
  },async error => {
    if(sessionStorage.getItem("login") && sessionStorage.getItem("login") !== "" && IsLoggedIn && !IsLoggingSilently) {  
      console.log("---before login----");
      console.log(error);
      const originalRequest = error.config;
      if(error.response) {
      // if (
      //   error.response.status === 401 &&
      //   originalRequest.url === "/auth/generate-token"
      //   // true
      // ) {
      //   localStorage.clear();
      //  // router.push("/");
      //   // history.push('/');
      //   window.location.href = "/error"
      //   return Promise.reject(error);
      // }
  
      if (error.response.status === 401) {
        ;
        if(config.REACT_APP_AUTH_TYPE=="pingfederate")
        {
          // if(config.REACT_APP_IS_CROSS_PLATFORM) {
          //   //Do mobile refresh token task later
          // } else {
            //check refresh token
          //if refresh token is there then create another api for generate new acess token with current refresh token
          // alert("2" + originalRequest.url );

          // alert(sessionStorage.getItem("login"))
          // alert(IsLoggedIn);
          // alert(IsLoggingSilently);
          if(sessionStorage.getItem("refreshtoken") && sessionStorage.getItem("refreshtoken") !== "")
          {
            // alert("3");
          //  alert("Refresh Token call")
            let payload: any= {
                refresh_token:sessionStorage.getItem("refreshtoken")
            }

            axios.defaults.headers.common["Authorization"] = "Bearer "+sessionStorage.getItem("login");
            getAuthApi({authToken:"", type:config.REACT_APP_AUTH_TYPE},(res)=>{
              if(res) {
                //do nothing
              } else {
                PingAuthRefresh(payload,async (res)=>{
                  if(res.access_token!=null)
                  {
                    console.log(res);      
                    if(res.refresh_token!=null)
                    {
                      sessionStorage.setItem("refreshtoken",res.refresh_token);
                    }
                   // axios.defaults.headers.common["Authorization"] = "Bearer "+res.access_token;
                    sessionStorage.setItem("login",res.access_token);
    
                    if(config.REACT_APP_IS_CROSS_PLATFORM) {
                      await createDatabase();
                      await addUserDetails(res.access_token, res.refresh_token);
                    }
               
                    dispatch(setLoginDetails({'token':res.access_token,'userName':"",'userAccount':null}));
                    dispatch(setChatShowChat(false));
                    dispatch(setCallModalView(false));
                    axios.defaults.headers.common["Authorization"] = "Bearer "+res.access_token;
                    //if api call is success add in local storage and give access to the user 
                    getAuthApi({authToken:"", type:config.REACT_APP_AUTH_TYPE},(res)=>{
                      console.log(res);
                      if(res)
                      dispatch(setUserVirtualNumber(
                        {
                          EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                          Id: res[0].Id
                        }
                      ))
                      dispatch(setUserDetails(
                        {
                          // EmployeeEmail_Adr:res[0].EmployeeEmail_Adr,
                          EmployeePh_Nbr:res[0].EmployeePh_Nbr,
                          EmployeeQR_Cd:res[0].EmployeeQR_Cd,
                          EmployeeFull_Nm:res[0].EmployeeFull_Nm
                        }
                      ))
                      setIsLoggedIn(true);
                    })
                  } else {
                    if(sessionStorage.getItem("login")&& sessionStorage.getItem("login") !== "") {  
                      LogoutPing();
                    }
                  }
          
                })
              }
            }); 
          }
          else
          {
            if(sessionStorage.getItem("login")&& sessionStorage.getItem("login") !== "") {  
              // alert("4");
              axios.defaults.headers.common["Authorization"] = "Bearer "+sessionStorage.getItem("login");
            getAuthApi({authToken:"", type:config.REACT_APP_AUTH_TYPE},(res)=>{
              if(res) {
                //do nothing
              } else {
                LogoutPing();
              }
              })
              
            }
          }

           
        }
        else
        {
          originalRequest._retry = true;
        
          // const refreshToken = '0.AS0AQnsmL3Rw10OSf2zVwrpCdfpUxGM1yixKgwtXGlW_ewotAP4.AgABAAEAAAD--DLA3VO7QrddgJg7WevrAgDs_wQA9P9sQKbXIiQ1MC2W38gtPvD3gLLFb8EXXddnx3SvQhhfdqEc2_xywarLXU4DgGphVoaVXdxgn97JcEGBMBzDyZaeC-GLcT39o_D4YQSNUe2bMlnDKBqa_jZqxRiQU9C2XvcSCSwe3LAsX-PkROfSQOyXYAD8jIWc7oXGBSAM2NTggE5PVKjTaFsmrQISayd8C3RqosSes4SqOa0mJa1RWnDiRijXAdmOF2tS9r4OEc_oN6XUI6ZHQmTWPNkns2XKFhF-gL0pKHIHnW4kH3xT6zjbmTq_L5NPBdytDHo2oWpJMfvjIIoqX8a6D6Ikd7drqnlYod96Uc5uTIF5DYbw92NNqrWBEdtLTi5HEN6VuqeXD2CffQFkLV5Xa4zYkKrdDheMYUOh-h4NuCzMQbLW_7T4vhLl-PWfOQBQuNkDQX-Yz8AO3RK1THufbZVtr-bSmMzu1G6XBsEdR3uzV2jS228if4drQnm18p9YV3oKSpRxowMx1pn-TP66JZe91rgs_5OG4k18174dRNO_UCtto9q6ctrRlKSQcsLeb7PYa4PulVmEWEKCA42RdDfi5-jRbmIdkJyxSMD54hLf2aTTPn63ZEW2aevhgOGUvjWYG2Rc8WOC7IuB3EEqu-H8AqV1wkqt62hai6rI7dw3pXwraYO0wp0mP6xO8g7C0eyEPNMVZ3bb-16P_sxBAoyDaket44_PA3FzsAof03P6WdohqftXD1UIUb9ARWQ1CzeUXGFccngyUctnjBQEmZFbFtjyXjydzt9hL6s5f8RtqISMGL0XzGFcJEd3zFvU349M8BN3G5lCRcEotOr6Ng'//store.state.refresh_token;
           console.log('hi')
           var currentAccount:any = msalInstance.getAccountByUsername(loginDetails as string);
           
           // localStorage.setItem('errorauth',error.response.status);
           // window.location.href = "/error"
            var silentRequest:any = {
                scopes:loginRequest.scopes,
                account: currentAccount,
                forceRefresh: false
            };
            var request = {
              scopes: loginRequest.scopes,
              loginHint: currentAccount.username // For v1 endpoints, use upn from idToken claims
            };
            const tokenResponse:any = await msalInstance.acquireTokenSilent(silentRequest).catch(error => {
              if (error instanceof InteractionRequiredAuthError) {
                  // fallback to interaction when silent call fails
                  return msalInstance.acquireTokenRedirect(request as any)
              }
            });
            console.log("Silent Response");
            console.log(tokenResponse);
                  if (tokenResponse) {
                    console.log("tokenResponse", tokenResponse)
          
            sessionStorage.setItem("login",tokenResponse.accessToken)
            dispatch(setLoginDetails({'token':tokenResponse.accessToken,'userName':tokenResponse.account.username,'userAccount':tokenResponse}));
            
            
            originalRequest.headers.Authorization = "Bearer "+tokenResponse.accessToken;
            console.log(originalRequest)
            return axios(originalRequest);
        }
     
  }
        // return axios
        //   .post("/auth/generate-token", {
        //     refresh_token: refreshToken,
        //   })
        //   .then((res) => {
        //     if (res.status === 200) {
        //       //store.commit("updateAccessToken", res.data.data);
        //       // store.state.refresh_token = res.data.data.refresh_token;
        //       // store.state.access_token = res.data.data.access_token;
        //       
        //     }
        //   });
       
      }
      return Promise.reject(error);
    }
    }
  });

  function silentLoginMobileApp(users) {
    // alert("1");
    if(config.REACT_APP_AUTH_TYPE=="AD") {
      capacitormsal.signinSilent({
        authority: config.REACT_APP_AD_AUTHORITY as string,
        clientId: config.REACT_APP_AD_CLIENTID as string,
        redirectUri: config.REACT_APP_CROSS_REDIRECT_URI as string,
        scope: config.REACT_APP_CROSS_SCOPE as string,
        })
        .then(async (response) => {
            if(response["accesstoken"] && response["accesstoken"] !== "") {
                console.log("---Silent login success----");
                console.log(response["accesstoken"]);
                dispatch(setChatShowChat(false));
                dispatch(setCallModalView(false));
                axios.defaults.headers.common["Authorization"] = "Bearer "+response["accesstoken"];
                sessionStorage.setItem("login",response["accesstoken"]);
                sessionStorage.setItem('loginName',response["name"]);
                dispatch(setLoginDetails({'token':response["accesstoken"],'userName':response["name"],'userAccount':null}));
                registerNotifications();
                getAuthApi({authToken:"Bearer "+response["accesstoken"],type:config.REACT_APP_AUTH_TYPE},async (res)=>{
                    if(res) {
                        dispatch(setUserVirtualNumber({
                            EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                            Id: res[0].Id
                        }))

                        dispatch(setUserDetails({
                            EmployeePh_Nbr:res[0].EmployeePh_Nbr,
                            EmployeeQR_Cd:res[0].EmployeeQR_Cd,
                            EmployeeFull_Nm:res[0].EmployeeFull_Nm
                        }))
                        
                        await createDatabase();
                        await addUserDetails(response["accesstoken"], "");
                        console.log("---Silent Token Done----");
                        setIsLoggingSilently(false);
                        setIsLoggedIn(true);
                    }
                });

            // let langLocal = "es-ES"// window.navigator.language.toLowerCase();
            // getlangTransData(window.navigator.language.toLowerCase(),(res)=>{     
            //   if (res && res.data) {
            //     console.log("lang trans val",res.data['translatedValues'][0]);
            //     let langjson:any={};
            //     let transjson:any={};
            //     transjson['translation']=res.data['translatedValues'][0];
            //     // console.log("lang trans",transjson);
            //     langjson[langLocal]=transjson;
            //     console.log("lang",langjson);
            //     // console.log(langjson);
            //     setTransData(langjson);
            //     transLocData=langjson;
            //     // 
            //     console.log("lang before trans function",transLocData,"lang loc",langLocal);
            //     // console.log("lang before trans lang function",translang,"lang loc",langLocal);
            //     // ;
        
        
            //     // if(langLocal === "en-us") {
            //       i18n
            //       .use(initReactI18next) // passes i18n down to react-i18next
            //       .init({
            //         // the translations
            //         // (tip move them in a JSON file and import them,
            //         // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
            //         // resources: transData[0],
            //         resources: transLocData,
        
            //         lng: langLocal, // if you're using a language detector, do not define the lng option
                    
        
            //         interpolation: {
            //           escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
            //         }
            //       });
                    
            //     // }

            //     // window.location.href = "/Contacts";//window.location.origin;
            //     sessionStorage.setItem("isloggedin","1");
            //     }
                
                    
            //   })

            } else {
                console.log("---Silent login fail----");
                setIsLoggingSilently(false);
                setIsLoggedIn(false);
            }
        })
        .catch(error => {
            console.log("Error");
            console.log(error);
            console.log("---Silent login fail----");
            setIsLoggingSilently(false);
            setIsLoggedIn(false);
        })

    } else {
      let previousAccessToken = users[0].authToken;
      let previousRefreshToken = users[0].refreshToken;
      // alert(previousAccessToken);

      dispatch(setChatShowChat(false));
      dispatch(setCallModalView(false));
      axios.defaults.headers.common["Authorization"] = "Bearer " + previousAccessToken;
      sessionStorage.setItem("login", previousAccessToken);
      sessionStorage.setItem("refreshtoken",previousRefreshToken);
      sessionStorage.setItem('loginName', "");
      dispatch(setLoginDetails({'token': previousAccessToken,'userName':"",'userAccount':null}));
      registerNotifications();
      getAuthApi({authToken:"",type:config.REACT_APP_AUTH_TYPE},async (res)=>{
          // alert(JSON.stringify(res));
          if(res) {
              dispatch(setUserVirtualNumber({
                  EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                  Id: res[0].Id
              }))

              dispatch(setUserDetails({
                  EmployeePh_Nbr:res[0].EmployeePh_Nbr,
                  EmployeeQR_Cd:res[0].EmployeeQR_Cd,
                  EmployeeFull_Nm:res[0].EmployeeFull_Nm
              }))
              
              await createDatabase();
              await addUserDetails(previousAccessToken, previousRefreshToken);
              console.log("---Silent Token Done----");
              setIsLoggingSilently(false);
              setIsLoggedIn(true);
              // alert("11");

          } else {
            // alert("12");
            setIsLoggingSilently(false);
          }
        })
    }
  }
  function LogoutPing(){
    if(config.REACT_APP_IS_CROSS_PLATFORM) {
      updatePushToken({
        "DeviceToken_Val": sessionStorage.getItem("pushToken"),
        "Device_Typ": "iOS",
        "is_active": 'N'
      },(res)=>{       
      })
      if(config.REACT_APP_AUTH_TYPE=="AD"){
        capacitormsal.signOut({
          authority: config.REACT_APP_AD_AUTHORITY as string,
          clientId: config.REACT_APP_AD_CLIENTID as string,
          redirectUri: config.REACT_APP_CROSS_REDIRECT_URI as string,
          scope: config.REACT_APP_CROSS_SCOPE as string,
          })
          .then(async (response) => {
            sessionStorage.setItem("login", "");
            deleteUserDetails().then(() => {
              window.location.href = "/"  
            });               
          })
          .catch(error => {
            sessionStorage.setItem("login", "");
            deleteUserDetails().then(() => {
              window.location.href = "/"  
            });
          })
      } else {
        let browser = InAppBrowser.create(config.REACT_APP_PING_LOGOUT_URL, '_blank', 'location=no');
        browser.on('loadstop').subscribe(async event => {
          browser.close();
        });
        sessionStorage.setItem("login", "");
        deleteUserDetails().then(() => {
          window.location.href = "/"  
        });
      }
    }
    else
    {
      sessionStorage.clear();
      localStorage.clear();
      var logoutUrl = config.REACT_APP_PING_LOGOUT_URL;
      window.location.href = logoutUrl     
       window.location.href =config.REACT_APP_PING_REDIRECT_URL;
    }
               
  }
  useEffect(() => {
    setAuthType(config.REACT_APP_AUTH_TYPE)
    ;
    
    //check if you have code query param
    const state = new URLSearchParams(window.location.search).get("state");
    const code = new URLSearchParams(window.location.search).get("code");
    // console.log(state,code) 
    
    //if yes call api and show the loader and not the loggin button
    if(!config.REACT_APP_IS_CROSS_PLATFORM) {
      if(state!=undefined&&code!=undefined)
     {
      // let payload: any= {

      //   grant_type: 'authorization_code',

      //   client_id: config.REACT_APP_PING_CLIENTID,

      //   redirect_uri: config.REACT_APP_PING_REDIRECT_URL,

      //   code: code,

      //   client_secret: config.REACT_APP_PING_CLIENTSECRET

      // };
      let payload: any= {
        code: code
      }
      console.log(payload);
    //  alert(payload);
        sessionStorage.clear();
        localStorage.clear();
          PingAuthApi(payload,(res)=>{
            ;
            if(res.access_token!=null)
            {
              //alert("Auth API call")
              console.log(res);      
              if(res.refresh_token!=null)
              {
                sessionStorage.setItem("refreshtoken",res.refresh_token);
              }
              axios.defaults.headers.common["Authorization"] = "Bearer "+res.access_token;
              sessionStorage.setItem("login",res.access_token);
         
              dispatch(setLoginDetails({'token':res.access_token,'userName':"",'userAccount':null}));
              dispatch(setChatShowChat(false));
              dispatch(setCallModalView(false));
              //if api call is success add in local storage and give access to the user 
              getAuthApi({authToken:"Bearer "+res.access_token, type:config.REACT_APP_AUTH_TYPE},(res)=>{
                console.log(res);
                if(res){
                  try {
                    axios.post(config.REACT_APP_AUTH_API_BASE + "/auth/login-details?email=" + res[0].EmployeeEmail_Adr + "&device=" + mobile).then(res => {
                  //    callback(res.data);
                      console.log("res.data", res.data)
                    }).catch(() => {
                    //  callback(false)
                    })
                  } catch (error) {
                  //  callback(false)
                  }
                  dispatch(setUserVirtualNumber(
                    {
                      EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                      Id: res[0].Id
                    }
                  ))
                  dispatch(setUserDetails(
                    {
                      // EmployeeEmail_Adr:res[0].EmployeeEmail_Adr,
                      EmployeePh_Nbr:res[0].EmployeePh_Nbr,
                      EmployeeQR_Cd:res[0].EmployeeQR_Cd,
                      EmployeeFull_Nm:res[0].EmployeeFull_Nm
                    }
                  ))
                  setIsLoggedIn(true);
                }
              })
              
            }
            else
            {
              setIsLoggingSilently(true);
              axios.defaults.headers.common["Authorization"] = "Bearer "+sessionStorage.getItem("login");
              getAuthApi({authToken:"", type:config.REACT_APP_AUTH_TYPE},(res)=>{
                console.log(res);
                setIsLoggingSilently(false);
                if(res) {
                  dispatch(setUserVirtualNumber(
                    {
                      EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                      Id: res[0].Id
                    }
                  ))
                  dispatch(setUserDetails(
                    {
                      // EmployeeEmail_Adr:res[0].EmployeeEmail_Adr,
                      EmployeePh_Nbr:res[0].EmployeePh_Nbr,
                      EmployeeQR_Cd:res[0].EmployeeQR_Cd,
                      EmployeeFull_Nm:res[0].EmployeeFull_Nm
                    }
                  ))
                  setIsLoggedIn(true);
                }
              })
            }
          })
      
     } else {
        if(config.REACT_APP_AUTH_TYPE==="pingfederate") {
          if(sessionStorage.getItem("login") && sessionStorage.getItem("login") !== "") {
            setIsLoggingSilently(true);
            axios.defaults.headers.common["Authorization"] = "Bearer "+sessionStorage.getItem("login");
            getAuthApi({authToken:"", type:config.REACT_APP_AUTH_TYPE},(res)=>{
              console.log(res);
              setIsLoggingSilently(false);
              if(res) {
                dispatch(setUserVirtualNumber(
                  {
                    EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                    Id: res[0].Id
                  }
                ))
                dispatch(setUserDetails(
                  {
                    // EmployeeEmail_Adr:res[0].EmployeeEmail_Adr,
                    EmployeePh_Nbr:res[0].EmployeePh_Nbr,
                    EmployeeQR_Cd:res[0].EmployeeQR_Cd,
                    EmployeeFull_Nm:res[0].EmployeeFull_Nm
                  }
                ))
                setIsLoggedIn(true);
              }
            })

          }
        }
     } 
    }
  }, [])
  function generateRandomString() {
    var array = new Uint32Array(28);
    window.crypto.getRandomValues(array);
    return Array.from(array, (dec) => ("0" + dec.toString(16)).substr(-2)).join(
      ""
    );
  }
  var nonce = generateRandomString();
  var state = generateRandomString();
  function encodeQueryData(data) {
    ;
    let ret:any = [];
    for (let d in data){    console.log(data[d]);
       ret.push(encodeURIComponent(d)+ "=" + encodeURIComponent(data[d]));
    }
    return ret.join("&");
  }
  function sha256(plain) {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest("SHA-256", data);
  }
  // function base64urlencode(str) {
  //   
  //   console.log(str);
  //   // return btoa(String.fromCharCode.apply(new Uint8Array(str)));
  //   console.log(typeof(new Uint8Array(str)));
  //  // console.log(String.fromCharCode.apply( new Uint8Array(str)));
  //  console.log(JSON.stringify(new Uint8Array(str)));
  //   let temp=  btoa(JSON.stringify( new Uint8Array(str)).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, ''))
  //  // .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  //   console.log(temp)
  //   return temp;
  //   // return btoa(str)
  //   // .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  //   // // return str.toString('base64')
  //   // .replace(/\+/g, '-')
  //   // .replace(/\//g, '_')
  //   // .replace(/=/g, '');
  // }
  function base64urlencode(a) {
    var str = "";
    var bytes = new Uint8Array(a);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      str += String.fromCharCode(bytes[i]);
    }
    return btoa(str)
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");
  }
  async function pkceChallengeFromVerifier(v) {
    ;
    let hashed:any = await sha256(v);
    return base64urlencode(hashed);
  }
  
  async function handleLogin ()  {
    if(config.REACT_APP_IS_CROSS_PLATFORM) {
      if(config.REACT_APP_AUTH_TYPE=="AD"){
        capacitormsal.signin({
          authority: config.REACT_APP_AD_AUTHORITY as string,
          clientId: config.REACT_APP_AD_CLIENTID as string,
          redirectUri: config.REACT_APP_CROSS_REDIRECT_URI as string,
          scope: config.REACT_APP_CROSS_SCOPE as string,
        })
          .then(async (response) => {
              setIsLoggingSilently(true);
              registerNotifications();
              axios.defaults.headers.common["Authorization"] = "Bearer "+response["accesstoken"];
              sessionStorage.setItem("login",response["accesstoken"]);
              sessionStorage.setItem('loginName',response["name"]);
              dispatch(setLoginDetails({'token':response["accesstoken"],'userName':response["name"],'userAccount':null}));
              dispatch(setChatShowChat(false));
              dispatch(setCallModalView(false));
  
              
              getAuthApi({authToken:"Bearer "+response["accesstoken"],type:config.REACT_APP_AUTH_TYPE},async (res)=>{
                if(res) {
                  dispatch(setUserVirtualNumber(
                    {
                      EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                      Id: res[0].Id
                    }
                  ))
                  dispatch(setUserDetails(
                    {
                      // EmployeeEmail_Adr:res[0].EmployeeEmail_Adr,
                      EmployeePh_Nbr:res[0].EmployeePh_Nbr,
                      EmployeeQR_Cd:res[0].EmployeeQR_Cd,
                      EmployeeFull_Nm:res[0].EmployeeFull_Nm
                    }
                  ))
                  
                  await createDatabase();
                  await addUserDetails(response["accesstoken"], "");
                  let user = await getUserDetails();
                  setIsLoggingSilently(false);
                  setIsLoggedIn(true);

                } else {
                  setIsLoggingSilently(false);
                  setIsLoggedIn(false);
                  LogoutPing();
                }
              });
  
  
              
              // let langLocal = "es-ES"// window.navigator.language.toLowerCase();
              // getlangTransData(window.navigator.language.toLowerCase(),(res)=>{     
              //   if (res && res.data) {
              //     console.log("lang trans val",res.data['translatedValues'][0]);
              //     let langjson:any={};
              //     let transjson:any={};
              //     transjson['translation']=res.data['translatedValues'][0];
              //     // console.log("lang trans",transjson);
              //     langjson[langLocal]=transjson;
              //     console.log("lang",langjson);
              //     // console.log(langjson);
              //     setTransData(langjson);
              //     transLocData=langjson;
              //     // 
              //     console.log("lang before trans function",transLocData,"lang loc",langLocal);
              //     // console.log("lang before trans lang function",translang,"lang loc",langLocal);
              //     // ;
          
          
              //     // if(langLocal === "en-us") {
              //       i18n
              //       .use(initReactI18next) // passes i18n down to react-i18next
              //       .init({
              //         // the translations
              //         // (tip move them in a JSON file and import them,
              //         // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
              //         // resources: transData[0],
              //         resources: transLocData,
          
              //         lng: langLocal, // if you're using a language detector, do not define the lng option
                      
          
              //         interpolation: {
              //           escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
              //         }
              //       });
                    
              //     // }
  
              //     // window.location.href = "/Contacts";//window.location.origin;
              //     sessionStorage.setItem("isloggedin","1");
              //     }
                 
                      
              //   })
  
          })
          .catch(error => {
              console.log("Error");
              console.log(error);
              setIsLoggingSilently(false);
              setIsLoggedIn(false);
          })
        } else {
          var code_verifier = generateRandomString();
          var code_challenge = await pkceChallengeFromVerifier(code_verifier);
          const oAuthurl = config.REACT_APP_PING_OAUTH_URL;
          const params = {
            client_id: config.REACT_APP_PING_CLIENTID,
            redirect_uri: config.REACT_APP_PING_REDIRECT_URL,
            response_type: ["code"],
            scope: "openid profile email offline_access",
            state: state
          };
      
  
          sessionStorage.clear();
          localStorage.clear();
          // Build the authorization URL
          const url = oAuthurl + "?" + encodeQueryData(params);
          console.log(url);
          let browser = InAppBrowser.create(url, '_blank', 'location=no');
          browser.on('loadstart').subscribe(async event => {
              console.log("---event.url---");
              console.log(event.url);
              if(event.url.startsWith(config.REACT_APP_PING_REDIRECT_URL)) {
                  let code = getParameterByName('code', event.url);
                  let state = getParameterByName('state', event.url);
                  browser.close();
                  // alert("--code--" + code);
                  if(code && code !=="") {
                    let payload: any= {
                      code: code
                    }
                    setIsLoggingSilently(true);
                    PingAuthApi(payload,(res)=>{
                      //  alert(JSON.stringify(res));
                      // alert("Access token:" + res.access_token);
                      // alert(res.access_token);
                      if(res.access_token!=null) {
                        console.log(res); 
                              
                        axios.defaults.headers.common["Authorization"] = "Bearer "+res.access_token;
                        sessionStorage.setItem("login",res.access_token);
                        sessionStorage.setItem("refreshtoken",res.refresh_token);
                        sessionStorage.setItem('loginName','');
                        dispatch(setLoginDetails({'token':res.access_token,'userName':"",'userAccount':null}));
                        dispatch(setChatShowChat(false));
                        dispatch(setCallModalView(false));
                        registerNotifications();
                        //if api call is success add in local storage and give access to the user 
                        getAuthApi({authToken:"", type:config.REACT_APP_AUTH_TYPE},async (apires)=>{
                          //alert(JSON.stringify(apires));
                          console.log(apires);
                          if(apires) {
                            try {
                              axios.post(config.REACT_APP_AUTH_API_BASE + "/auth/login-details?email=" + apires[0].EmployeeEmail_Adr + "&device=" + mobile).then(res => {
                            //    callback(res.data);
                                console.log("res.data", res.data)
                              }).catch(() => {
                              //  callback(false)
                              })
                            } catch (error) {
                            //  callback(false)
                            }
                            dispatch(setUserVirtualNumber(
                              {
                                EmployeeV_Nbr: apires[0].EmployeeV_Nbr,
                                Id: apires[0].Id
                              }
                            ))
                            dispatch(setUserDetails(
                              {
                                // EmployeeEmail_Adr:res[0].EmployeeEmail_Adr,
                                EmployeePh_Nbr:apires[0].EmployeePh_Nbr,
                                EmployeeQR_Cd:apires[0].EmployeeQR_Cd,
                                EmployeeFull_Nm:apires[0].EmployeeFull_Nm
                              }
                            ))
                            await createDatabase();
                            await addUserDetails(res.access_token, res.refresh_token);
                            let user = await getUserDetails();
                            setIsLoggedIn(true);
                            setIsLoggingSilently(false);
                          } else {
                            setIsLoggingSilently(false);
                          }
                        })
                      } else {
                        setIsLoggingSilently(false);
                      }
              
                    })
                  }
              }
          });
        }
    } else {
      if(config.REACT_APP_AUTH_TYPE=="AD"){
      msalInstance.loginRedirect(loginRequest).then(e=>{
        console.log(e)
      }).catch((e:any) => {
        console.log(e);
         
      });
      }
      else
      {
        var code_verifier = generateRandomString();
        var code_challenge = await pkceChallengeFromVerifier(code_verifier);
        const oAuthurl = config.REACT_APP_PING_OAUTH_URL;
        const params = {
          client_id: config.REACT_APP_PING_CLIENTID,
          redirect_uri: config.REACT_APP_PING_REDIRECT_URL,
          response_type: ["code"],
          scope: "openid profile email offline_access",
          state: state
        };
    
        
        sessionStorage.clear();
        localStorage.clear();
        // Build the authorization URL
        const url = oAuthurl + "?" + encodeQueryData(params); 
        console.log(url);
        // const authClient = new PingOneAuthClient({
        //   environmentId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
        //   clientId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
        //   redirectUri: 'http://localhost:8080',
        //   postLogoutRedirectUri: 'http://localhost:8080',
        //   scopes: ['openid','profile', 'email', 'address'],
        //   responseType: ['token', 'id_token'],
        //   pkce: false
        // });
        // authClient.signIn();
        console.log(url);
        window.location.href=url;

      }
    }
  }

  function getParameterByName(name: string, url: string) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();
  
    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }
  
    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }
    await PushNotifications.register();

  }
  
  const getDeliveredNotifications = async () => {
    const notificationList = await PushNotifications.getDeliveredNotifications();
    console.log('delivered notifications', notificationList);
  }

  let IsCrossPlatForm = config.REACT_APP_IS_CROSS_PLATFORM
  let IsPingLogIn = config.REACT_APP_AUTH_TYPE=='pingfederate'
  let loginImage = config.REACT_APP_LOGIN_IMAGE

  return (
    <>
    { IsCrossPlatForm && IsLoggedIn && (
          <Home />
    )}
    { IsCrossPlatForm && !IsLoggedIn && (
              <div className="login-layout-bg login-bg">
              
              <div className="login-left-div">
                <img src={loginImage?loginImage:LoginBgImage} alt="LoginImage" />
              </div>
              {(IsLoggingSilently) ? <Spinner /> :
              <div className="login-right-div">
                <h1 className="login-text font--xl">{("let's sign you in")}</h1>
                <h4 className="login-text font--lg">
                  {('Your organization uses the following login modes to access the application')}
                </h4>
              <button
                  className="rounded-primary-40 btn font--btn"
                  type="button"
                  onClick={handleLogin}
                >
                  {config.REACT_APP_AUTH_TYPE==="pingfederate" ? ('Login with PING') : ('Login with AD')}
                </button>
              </div>
              }
            </div>
    )}

    { !IsCrossPlatForm && !IsPingLogIn &&(
      <><AuthenticatedTemplate>
          <Home /> {/*HomePage*/}
        </AuthenticatedTemplate><UnauthenticatedTemplate>
            <div className="login-layout-bg login-bg">

              <div className="login-left-div">
                <img src={loginImage?loginImage:LoginBgImage} alt="LoginImage" />
              </div>

              <div className="login-right-div">
                <h1 className="login-text font--xl">let's sign you in</h1>
                <h4 className="login-text font--lg">
                  Your organization uses the following login modes to access the
                  application
                </h4>
                <button
                  className="rounded-primary-40 btn font--btn"
                  type="button"
                  onClick={async () => {await handleLogin();} }
                >
                  {('Login with AD')}
                </button>


              </div>
            </div>
          </UnauthenticatedTemplate></>
    )

    
    }
    {!IsCrossPlatForm && IsLoggedIn && IsPingLogIn &&(
          <Home />
    )}
    
    { !IsCrossPlatForm && !IsLoggedIn && IsPingLogIn &&(
           

              <div className="login-layout-bg login-bg">
              
              <div className="login-left-div">
                <img src={loginImage?loginImage:LoginBgImage} alt="LoginImage" />
              </div>
              {(IsLoggingSilently) ? <Spinner /> :
              <div className="login-right-div">
                <h1 className="login-text font--xl">{("let's sign you in")}</h1>
                <h4 className="login-text font--lg">
                  {('Your organization uses the following login modes to access the application')}
                </h4>
              <button
                  className="rounded-primary-40 btn font--btn"
                  type="button"
                  onClick={handleLogin}
                >
                  {('Login with PING')}
                </button>
              </div>
              }
            </div>
    )}
  </>
  )
}

export default Login