import React, { useState, useEffect, useRef } from 'react';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { getAllContacts } from '../../apis/contacts/contactsApi'
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import './composeEmail.css';
import { createMail ,getEmailIds} from '../../apis/Email/Email'
import { setComposeEmailView, setEmailContentView, setEmailInboxView, setEmailSubTitle, setSentEmailView } from '../../app/slice/emailSubMenuSlice';
import Button from '../../layouts/button/Button';
import { AttachmentOutlined } from '@mui/icons-material';
import { getToken } from '../../apis/contentSharingApi/contentsharing';
import { useDispatch, useSelector } from 'react-redux';
// import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import config from "../../env.json";
import { useTranslation } from "react-i18next";

import axios from 'axios';
import uploadFileToBlob from '../azureUploads/azureBlob';
import { event } from 'jquery';
let cancelToken: any = undefined;

export default function ComposeMail() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );
  const [convertedContent, setConvertedContent] = useState(null);
  const [tomails, setToMails] = useState('');
  const [ccmails, setCCMails] = useState('');
  const [bccmails, setBccMails] = useState('');
  const [cc, setCC] = useState(false)
  const [bcc, setBCC] = useState(false);
  const [page, setPage] = useState<any>(0)
  const [pageSize, setPageSize] = useState<any>(25)
  const [searchValue, setSearchValue] = useState<any>();
  const [isHospitalContact, setIsHospitalContact] = useState<any>();
  const [contactDetail, setContactDetail] = useState<any>([]);
  const [typing, setTyping] = useState(false);
  const [hiddenPresList, setHiddenPresList] = useState(true);
  const [folderName, setFolderName] = useState('');
  const [selectedEmailId, setSelectedEmailId] = useState<any>([])
  const [toEmailOnChangevalue, setToEmailOnChangevalue] = useState<any>([])
  const [errorMessages, setErrorMessages] = useState<any>([]);
  // const [cancelToken, setCancelToken] =useState<any>(new AbortController())
  // cancelToken=new AbortController();
  const fileType = config.REACT_APP_FILESUPPORTED_TYPE;
  const fileSupportedExt = config.REACT_APP_FILESUPPORTED_EXT.split(',');
  const configuration = (
    {
      readonly: false,
    }
  );
  const [toArray, setToArray] = useState([]);
  const [ccArray, setCCArray] = useState([]);
  const [bccArray, setBCCArray] = useState([]);
  const [emailSubject, setEmailSubject] = useState<any>()
  const [blobList, setBlobList] = useState([]);
  const [progress, setProgress] = useState(0);

  const [currentFile, setCurrentFile] = useState<any>([]);
  const [files,setFiles]=useState<any>([]);
  const [from,setFrom]=useState<any>([
  
  ]);
  const [fromemail,setFromEmail]=useState<any>([])
  useEffect(() => {
    // handleInputChange()
    getEmailIds((resp: any) => {
      console.log("::::::::::::::::::::::::::::::::::::::::::::", resp.data);
      resp.data.map((item)=>{
        if(item.provider === 'google'){
          localStorage.setItem("gmailId",item.email)
        }
      })
      setFrom(resp.data)
    });
  },[])
  const handleInputChnageSubject = (e) => {
    //console.log(e.target.value)
    setEmailSubject(e.target.value)
  }

  const handleInputChange = (e) => {
    //console.log(e)
    setTyping(true)
    if (cancelToken) {
      //console.log('hi')
      cancelToken.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source()
    // if(state.To.length>2)
    getAllContacts({
      page: page, pageSize: pageSize, search: e.target.value,
      CancelToken: cancelToken.token, contactType: isHospitalContact ? "HA" : "All",
    },
      (res: any) => {
        //console.log(res)
        setContactDetail(res)
      })
  };

  useEffect(() => {

    let html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
  }, [editorState]);


  // function validateEmail(email) {
  //   var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  //   return re.test(email);
  // }


  // const [validEmails, setvalidEmails] = useState([]);

  // for (let i = 0; i < tomails.length; i++) {
  //   if (validateEmail(tomails[i])) {
  //     //push the item in to an arrat
  //   }
  // }

  // //console.log(validEmails);

  const saveasDraft = () => {

    //console.log(convertedContent);
    let data = {
      "ConversationId": 0,
      "ContactId": 1000,
      "ContactType": "PRES",
      "EmailStatus": "DRAFT",
      "EmailBody": convertedContent,
      "EmailSubject": emailSubject,
      "ContentType": "HTML",
      "ToEmail": tomails,
      "CCEmail":  ccmails,
      "Attachments":files,
      "EmailId":fromemail.email
      
    }
    //console.log(data)

    createMail(data, (resp) => {
      //console.log(resp);
      // if(resp)
      if (resp == 200) {
        debugger
        // dispac
        // resteValues()
        //   return dispatch => {
        dispatch(setEmailInboxView(true));
        dispatch(setComposeEmailView(false));
        dispatch(setEmailSubTitle('Inbox'));
        dispatch(setEmailContentView(false));
        // }

        // dispatch(setSentEmailView(true));
        // dispatch(setEmailContentView(true));
        // dispatch(setSentEmailView(false));
        // debugger
      }
    });
  }

  const handleDelete = (e) => {
    //console.log(e)
  }
  const handleOnSelect = (e) => {
    //console.log(e)
  }
  const sendMail = () => {
    let data = {
      "ConversationId": 0,
      "ContactId": 1000,
      "ContactType": "PRES",
      "EmailStatus": "SENT",
      "EmailBody": convertedContent,
      "EmailSubject": emailSubject,
      "ContentType": "HTML",
      "ToEmail": tomails,
      "CCEmail":  ccmails,
      "Attachments":files,
      "EmailId":fromemail.email
    }
    console.log(data)
    createMail(data, (resp) => {
      if (resp == 200) {
        debugger
        dispatch(setEmailInboxView(true));
        dispatch(setComposeEmailView(false));
        dispatch(setEmailSubTitle('Inbox'));
        // dispatch(setEmailContentView(false));
        // dispatch(setEmailContentView(true));
        // dispatch(setSentEmailView(false));
      }
    });
  }

  const handleUpload = (files) => {
    debugger
    //console.log(currentFile)
    getToken((res) => {
      if (res) {
        const temp = res.data.storageAccessToken;
        //console.log("token get api", temp);
        onUpload(temp, files);
      }
    });
    // const timer = setInterval(() => {
    // setProgress((oldProgress) => {
    //   // if (oldProgress === 100) {
    //   //   return 0;
    //   // }
    //   const diff = Math.random() * 10;
    //   return Math.min(oldProgress + diff);
    // });
    // }, 500);
    // return () => {
    //   clearInterval(timer);
    // };
  }

  const onUpload = async (res, files) => {
    //  const timer = setInterval(() => {
    setProgress((oldProgress) => {
      // if (oldProgress === 100) {
      //   return 0;
      // }
      const diff = Math.random() * 10;
      return Math.min(oldProgress + diff);
    });
    // }, 500);
    // return () => {
    //   clearInterval(timer);
    // };
    console.log(res,files);
    //console.log(currentFile)
    const blobsInContainer: any = await uploadFileToBlob(files, folderName, 'email', res);
    //console.log(blobsInContainer)

    if (blobsInContainer.length > 0) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          // if (oldProgress === 100) {
          //   return 0;
          // }
          const diff = Math.random() * 10;
          return Math.min(100);
        });
      }, 500);
    }setFiles(blobsInContainer);
  }

  const validateGetfiles = (files) => {
    //   let outputFiles = [];
    //   let fileSize = 0;
    // setDisableSendButton(false)
    //console.log(files)
    let errorMesg = "";
    files = Array.from(files);
    let file;
    for (var i = 0; i < files.length; i++) {
      file = files[i];
      let fileExt = file.name
        .split(".")
        .pop()
        .toLowerCase();
      // //console.log(this.fileType.includes(file.type), this.fileSupportedExt.includes(fileExt))
      //console.log("fileExt : ", fileExt);
      //console.log("fileExt : ", fileSupportedExt);
      //console.log("include file ext : ", fileSupportedExt.includes(fileExt));

      //console.log("filetype : ", file.type);
      //console.log("filetype : ", fileType);
      //console.log("file type list : ", fileType.includes(file.type))
      if (
        fileType.includes(file.type) &&
        fileSupportedExt.includes(fileExt)
      ) {
        // check non image file size if its greater that 675000b throw a error and remove the file;
        // files.push(file);
        // fileSize += file.size;
      }
      else if (file.type === "image/tiff" || file.type === "audio/amr" || fileExt === "amr" || file.type === "") {


        errorMesg = file.name + " is not supported";
        // setDisableSendButton(true)
      }
      else {
        // this.isFileTypeSupported= false;
        errorMesg = file.name + " is not supported";
        // errorMessages.push(file.name + " is not supported")
        // let t = errorMessages;
        // t.push("filetype is not supported");
        // setErrorMessages(t)
      }
    }
    return { errorMesg };
  }

  const removeFile = (i) => {
    //console.log("functioin called for ", i);
    let attfile = currentFile.filter((x, index) => i != index);
    setCurrentFile(attfile);
    validateGetfiles(attfile);
    handleUpload(attfile)
    // this.fileSize = 0;
    // for (let i = 0; i < this.files.length; i++) {
    //     this.fileSize += parseInt(this.files[i].size);
    // }
    // if (this.files.length <= 10) {
    //     this.footerErrorMsg = [];
    // }
  }
  const selectFile = (e) => {
    //console.log(e)
    const files: any = Array.from(e.target.files)
    //console.log(files)
    let newArray: any = [];
    newArray.push(files)
    setCurrentFile(newArray)
    let temp: any = [];
    currentFile.map(x => {
      temp.push(x)
      //console.log("old attach file content", temp)
    })
    files.map((e: any) => {
      e.testTime = new Date();
      temp.push(e)
      //console.log("file name", e.name)
      //console.log(temp)

    })
    // temp=temp.concat(files);
    //debugger
    // let { errorMesg } = validateGetfiles(e.target.files);

    //debugger
    if (temp.length > 10) {
      // footerErrorMsg.push('Max 10 files are allowed');
      let errmsg = "Max 10 files are allowed";
      // errorMessages.push("Max 10 files are allowed");
      // //console.log('Max 10 files are allowed');
      let t: any = [];
      errorMessages.map((x: any) => {
        t.push(x);
        return x;
      })

      t.push('Max 10 files are allowed');
      setErrorMessages(t)
      setTimeout(
        function () {
          setErrorMessages([])
        }
        ,
        2000
      );

    }
    else {
      setCurrentFile(temp)

      //console.log("attachedFiles : ", currentFile)
      //console.log("hello world : ", currentFile.length);
    }
    // if (false) {
    //   //debugger
    //   // this.footerErrorMsg.push(errorMesg);
    //   let t: any = [];
    //   errorMessages.map((x: any) => {
    //     t.push(x);
    //     return x;
    //   })
    //   t.push(false);
    //   setErrorMessages(t)
    //   setTimeout(
    //     function () {
    //       setErrorMessages([])
    //     }
    //     ,
    //     2000
    //   );
    // }



    //console.log(files);
    // const selectedFiles:any = Array.from(files);
    // const fileListAsArray = Array.from(selectedFiles)
    // //console.log(fileListAsArray)

    handleUpload(files)
    // setProgress(0);
  };

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    //console.log(string, results)
  }



  const selectedtoEmails = (value) => {
    let data: any = value;
    //console.log(data)
 
    //console.log("toarray====================", data)
    getemails('to', data);
  }
  const selectedCCEmails = (value) => {

    //console.log("ccarray====================", ccArray)
    getemails('cc', value);
  }
  const selectedBCCEmails = (value) => {

    //console.log("bccarray====================", bccArray)
    getemails('bcc', value);

  }
  function getemails(name, value) {
    //console.log(name, value)
    if (name === 'to') {
      let to = "";
      value.map((item: any) => {
        if (to !== undefined) {
          //console.log(item, "....")
          if (to.length > 0) {
            to = to + "," + item.AcctEmail_Adr
          }
          if (to.length <= 0) {
            //console.log(item, "+++")
            to = item.AcctEmail_Adr
          }
        }
      })
      //console.log("emailsto:::::::::::::::::", to)
      setToMails(to)
    }


    if (name === 'cc') {
      let cc = "";
      value.map((item: any) => {
        if (ccArray !== undefined) {
          //console.log(item, "....")
          if (cc.length > 0) {
            cc = cc + "," + item.AcctEmail_Adr
          }
          if (cc.length <= 0) {
            //console.log(item, "+++")
            cc = item.AcctEmail_Adr
          }
        }
      })
      //console.log("emailsto:::::::::::::::::", cc)
      setCCMails(cc)
    }
    if (name === 'bcc') {
      let bcc = "";
      value.map((item: any) => {
        if (bcc.length > 0) {
          bcc = bcc + "," + item.email
        }
        if (bcc.length <= 0) {
          bcc = item.email
        }
      })
      //console.log("emailsto:::::::::::::::::", bcc)
      setBccMails(bcc)
    }

  }

  const resteValues = () => {
    setContactDetail([])
    setEmailSubject('')
  }


  return (
    <div className="jodit" style={{
      height: '100vh',
      width: '100%',

    }}>
       <div className="form-field  searchInputs">
        <label>From:</label>
        <Autocomplete
          
          id="tags-standard"
          options={from}
          getOptionLabel={(option: any) => option.email}
          // defaultValue={contactDetail}

          onChange={(e, value: any) => {
            console.log("=================================", value)
            setFromEmail(value);
            //selectedtoEmails(value)
          }
          }
          onInputChange={handleInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
            // label="Multiple values"
            // placeholder="Favorites"
            />
          )}
        />

      </div>
      <div className="form-field  searchInputs">
        <label>To:</label>
        <Autocomplete
          multiple
          id="tags-standard"
          options={contactDetail}
          getOptionLabel={(option: any) => option.AcctFull_Nm}
          // defaultValue={contactDetail}

          onChange={(e, value: any) => {
            //console.log("=================================", value)
            setToArray(value);
            selectedtoEmails(value)
          }
          }
          onInputChange={handleInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
            // label="Multiple values"
            // placeholder="Favorites"
            />
          )}
        />

        <p style={{ margin: '0px' }} onClick={() => {
          setCC(!cc);
        }}>CC</p>
        <p onClick={() => {
          setBCC(!bcc);
        }}>BCC</p>
      </div>
      {cc ? <div className="form-field  searchInputs"  >
        <label>CC:</label>
        <Autocomplete
          multiple
          id="tags-standard"
          options={contactDetail}
          getOptionLabel={(option: any) => option.AcctFull_Nm}
          // defaultValue={contactDetail}

          onChange={(e, value: any) => {
            //console.log("=================================", value)
            setCCArray(value);
            selectedCCEmails(value)
          }
          }
          onInputChange={handleInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
            />
          )}
        />
      </div> : null}

      {bcc ? <div className="form-field searchInputs" >
        <label>BCC:</label>
        <Autocomplete
          multiple
          id="tags-standard"
          options={contactDetail}
          getOptionLabel={(option: any) => option.AcctFull_Nm}
          // defaultValue={contactDetail}

          onChange={(e, value: any) => {
            //console.log("=================================", value)
            setBCCArray(value);
            selectedBCCEmails(value)
          }
          }
          onInputChange={handleInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
            // label="Multiple values"
            // placeholder="Favorites"
            />
          )}
        />
      </div> : null}
      <div className="form-field">
        <label>Subject:</label>
        <input
          type="text"
          name="Subject"
          value={emailSubject}
          onChange={handleInputChnageSubject}
        />
      </div>

   {currentFile.length > 0 ?
        <div className='attached-media'>

          {currentFile?.map((file: any, index) => {
            return (
              <div key={file.name + "-" + file.type + "-" + index + '-' + file.testTime} id={file.name + "-" + file.type + "-" + index + '-' + file.testTime}>
                <div className='file-wrapper'>
                  <div className='media-tb'>
                    <div className="media-left d-flex">
                      <i className="far fa-file-alt"></i>
                      {/* <p>{(file.size / 1e6).toFixed(2)}</p> */}
                    </div>

                    <div>{file.name}</div>

                    <div >
                      <i className="fas fa-times" onClick={(() => removeFile(index))} />

                    </div>

                  </div>
                  <div>
                    <LinearProgress variant="determinate" value={progress} /></div>
                </div>

              </div>)
          })}
        </div>
        : null}

      <Editor
        editorState={editorState}
        onEditorStateChange={setEditorState}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        toolbar={{
          // options: ['inline', 'fontSize', 'fontFamily','list', 
          //         'textAlign', 'colorPicker', 'link', 'image'],
          // link:{
          //     defaultTargetOption:'_blank',
          //     popupClassName:"mail-editor-link"
          // },
          image: {
            urlEnabled: true,
            uploadEnabled: true,
            uploadCallback: "",
            alignmentEnabled: true,
            defaultSize: {
              height: 'auto',
              width: 'auto',
            },
            inputAccept: 'application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel'
          }
        }}
      />
      {/* {currentFile.length > 0 ?
        <div className='attached-media grid-container'>

          {currentFile?.map((file: any, index) => {
            return (
              <div key={file.name + "-" + file.type + "-" + index + '-' + file.testTime} id={file.name + "-" + file.type + "-" + index + '-' + file.testTime}>
                <div className='file-wrapper'>
                  <div className='media-tb'>
                    <div className="media-left d-flex">
                      <i className="far fa-file-alt"></i>
                    </div>

                    <div>{file.name}</div>

                    <div >
                      <i className="fas fa-times" onClick={(() => removeFile(index))} />

                    </div>

                  </div>
                  <div><LinearProgress variant="determinate" value={progress} /></div>
                </div>

              </div>)
          })}
        </div>
        : null} */}

      <div className='btn action-btns'>


        <Button type="button" onClick={() => { saveasDraft() }} label="Save as Draft" className="margin-right" />
        <Button type="button" onClick={() => { sendMail() }} label="Send" />
        {
          config.REACT_APP_ATTACHMENT_ENABLED == "Y" &&
          <Tooltip title={t('Attach Files')} arrow>
            <button
              className="btn-emailattachment"
              type='button'
              onClick={() => { let doc: any = document; doc.getElementById('attachedfiles').click() }}
            >

              <i className="fas fa-paperclip i-2" style={{ color: '#734bd1' }}>
              </i>

            </button>
          </Tooltip>
        }
        <input
          type="file"
          id="attachedfiles"
          name="attachedfiles"
          className="custom-file-input"
          multiple
          accept="application/pdf, application/vcard, text/vcard, text/x-vcard, text/csv, text/rtf, text/richtext, text/calendar, text/directory, image/bmp, image/tiff, image/jpeg, image/jpg, image/gif, image/png, video/H264, video/H263-2000, video/H263-1998, video/H263, video/H261, video/3gpp-tt, video/3gpp2, video/3gpp, video/webm, video/quicktime, video/mp4, video/mpeg, audio/basic, audio/L24, audio/mp4, audio/mpeg, audio/ogg, audio/vnd.rn-realaudio, audio/vnd.wave, audio/3gpp, audio/3gpp2, audio/ac3, audio/vnd.wave, audio/webm, audio/amr-nb, audio/amr"
          style={{ display: 'none' }}
          onChange={selectFile}

        />


      </div>


    </div>
  )
}



