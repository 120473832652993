import axios from "axios";
import config  from '../../env.json';
function getSRFQuantity(callback){
   // let {authToken}=params;
    try {
        axios.get(config.REACT_APP_CAMPAIGN_API_BASE+"/srf/getQuantity")
        .then(res=>{ 
          callback(res.data.data);
        }).catch(()=>{
          callback(null)
        })
    } catch (error) {
        callback(null)
    }
   
}
function getAOCStatus(callback){
  // let {authToken}=params;
   try {
       axios.get(config.REACT_APP_CAMPAIGN_API_BASE+"/srf/getAoc")
       .then(res=>{ 
         callback(res.data.data);
       }).catch(()=>{
         callback(null)
       })
   } catch (error) {
       callback(null)
   }
  
}




export {getAOCStatus,getSRFQuantity}