import React, { useEffect, useState } from 'react';
import {useSelector,useDispatch} from 'react-redux'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import {Modal} from 'react-bootstrap'
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { Fade, InputLabel } from '@mui/material';
import moment from 'moment';
import TimePicker from "rc-time-picker";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Stack from '@mui/material/Stack';
import { setAutoPilot } from '../../app/slice/loginSlice';
import { setOutOfOffice } from '../../apis/autopilotApi/autopilotApi';
import { validateDate } from '@mui/x-date-pickers/internals';


const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    borderRadius: "1rem",
    padding: ".5rem",
    bgcolor: "white",
    border: "none",
  };
const OutOfOfficeModal = ({ show, handleClose }) => {
    const { t } = useTranslation();
    const dispatch=useDispatch();
    const {chatId,chatName,chatIsPrescriber} = useSelector((state: any) => state.chat);
    const [mobileStyle, setMobileStyle] = useState(false);

    const [modalHeight, setModalHeight] = useState(0);
    const [modalWidth, setModalWidth] = useState(0);

    const [endDate, setEndDate] = React.useState();
    const [startDate, setstartDate] = React.useState();
    const [startTime, setstartTime] = useState(moment());
    const [endTime, setendTime] = useState(moment());
    const [timeerror,setTimeError]=useState(false);
    const [greatertimeError,setGreaterTimeError]=useState(false);
    const [lessStarttimeError,setLessStarttimeError]=useState(false);
    const [lessEndtimeError,setLessEndtimeError]=useState(false);

   const validateForm=()=>{
    let s_time=moment(startTime).format("HH:mm");
    let e_time=moment(endTime).format("HH:mm");
    const avail_start=moment(moment(startDate).format("YYYY-MM-DD")+" "+s_time);
    const avail_end=moment(moment(endDate).format("YYYY-MM-DD")+" "+e_time);
    if(avail_start.isBefore(moment())){
      setLessStarttimeError(true);
      return false;
    }
    else if(avail_end<avail_start){
      setLessEndtimeError(true);
      return false;
    }
    else{
      return true;
    }
   }

   const resetValidation=()=>{
    setLessStarttimeError(false);
    setLessEndtimeError(false);
   }

    async function handleSubmit(event){
      event.preventDefault();
      resetValidation();

      var today = new Date();
      let s_time=moment(startTime).format("HH:mm");
      let e_time=moment(endTime).format("HH:mm");
      console.log(moment(startDate).format("YYYY-MM-DD"),moment(endDate).format("YYYY-MM-DD"),startTime,endTime);
      const avail_start=moment(moment(startDate).format("YYYY-MM-DD")+" "+s_time);
      const newavailstrt=moment(avail_start).toISOString();
      const avail_end=moment(moment(endDate).format("YYYY-MM-DD")+" "+e_time);
      const newavailend=moment(avail_end).toISOString();
      console.log(newavailstrt, newavailend);

      let isFormValid:boolean = await validateForm(); 
      if(isFormValid){
        setOutOfOffice({newavailstrt, newavailend},(res:any)=>{
          if(res)
          handleClose();
        });
      }
    }



 

  return (
    <>
          <Modal
            className="free-time-modal"
            show={show}
            backdrop="static"
            closeAfterTransition
        >
           <Modal.Header>
           <h5 className="modal-title" id="exampleModalLabel">{t('Schedule Out Of Office')}</h5>
           <span onClick={()=>{handleClose()}} className="close">
                ×
              </span>
           </Modal.Header>
            <Modal.Body  >
            <div>
              <form onSubmit={handleSubmit}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={3}>
                      <div className="StartDateDiv">
                      <InputLabel className='genericCalDropdown_label' >{t('Select StartDate')}</InputLabel>
                      <DesktopDatePicker
                        // label="Select StartDate"
                        className="StartDate"
                        inputFormat="MM/dd/yyyy"
                        value={startDate}
                        disablePast={true}
                        onChange={(e:any)=>setstartDate(e)}
                        renderInput={(params) => <TextField {...params}/>}
                      />
                     
                        </div>
                      
                      <div className="form-group form-group--third floating-form">
                      <InputLabel className='genericCalDropdown_label' >{t('Select StartTime')}</InputLabel>
                      <TimePicker
                          className="form-control font-md calendar-starttime"
                          value={startTime}
                          showSecond={false}
                          use12Hours={true}
                          minuteStep={15}
                          defaultValue={moment(startTime)}
                          allowEmpty={false}
                          onChange={(e:any)=>setstartTime(e)}
                          inputReadOnly={true}
                        />

                       {lessStarttimeError ? <p className="form-error-message">{t('Start time can not be less than current time')}</p>:null}
                       
                       </div>
                       
                      <div  className="StartDateDiv">

                      <InputLabel className='genericCalDropdown_label' >{t('Select EndDate')}</InputLabel>
                        <DesktopDatePicker
                        //label="Select EndDate"
                        className="EndDate"
                        inputFormat="MM/dd/yyyy"
                        value={endDate}
                        disablePast={true}
                        onChange={(e:any)=>setEndDate(e)}
                        renderInput={(params) => <TextField {...params}/>}
                      />
                        

                      </div>
                       <div className="form-group form-group--third floating-form">
                       <InputLabel className='genericCalDropdown_label' >{t('Select EndTime')}</InputLabel>
                       <TimePicker
                            className="form-control font-md calendar-starttime"
                          value={endTime}
                          showSecond={false}
                          use12Hours={true}
                          minuteStep={15}
                          defaultValue={moment(endTime)}
                          allowEmpty={false}
                          onChange={(e:any)=>setendTime(e)}
                          inputReadOnly={true}
                        />
                    
                     {lessEndtimeError?<p className="form-error-message">{t('End time can not be less than start time')}</p>:null}
                     
                       </div>

                          </Stack>
                </LocalizationProvider>
                <div className="submitform"> 
                   <button type="submit" className="submit-btn btn rounded-primary-40 mx-auto set-freetime"><span> {t('Set Out Of Office')}</span></button>
                </div>
            
              </form>

            </div>
            
            </Modal.Body>
        </Modal>
    </>
  );
};

export default OutOfOfficeModal;

