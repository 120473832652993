// import React from 'react';
import React,{useState,useEffect, useRef} from 'react'
import '../chat/ChatComponent.css'
// import Avatar from '@mui/material/Avatar';
import Avatar from '../Avatar/Avatar';
import MsgComponent from "../../components/chat/MsgComponent";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function ChatComponent(props) {
    const { t } = useTranslation();
    const [activeComponent,setActiveComponent] = useState(true);
    const showChat = useSelector((state: any) => state.chat.showChat);
    const userName=useSelector((state: any) => state.authApi.EmployeeFull_Nm);
    useEffect(() => {
        setActiveComponent(props.showActiveChatPage);
        console.log(props.activeChatDetails)
      }, [props])
    return (
        <>
            <div className='chatcontainer'>
                { showChat === false ?
                 <div className="selectconvo__block d-flex shadowin">
                    <div className="avatar chat_av_font avt-9 mb-4" >
                        {/* <h1 className='initials'>ZU</h1> */}
                        <Avatar
                        imageType="EMP"
                        avatarHeight="9rem" avatarWidth="9rem"  presOrAssoc={userName} showAvatarType="initialsOnly" /> 
                    </div>
                    <h2 className="font-xl welcome mb-2 text-sm greeting-text"><b>{t('Welcome')}, {userName}</b></h2>
                    <p className="font-md welcome">{t('Please select a chat to start messaging')}</p>
                    <div className="">
                        <button type="button" onClick={()=>{props.openNewChat()}} className="submit-btn btn rounded-primary-40 mx-auto mt-3"> {t('Start Conversation')} </button>
                    </div>
                </div> :

                <MsgComponent 
                messageData={props.activeChatDetails}
                />
                }

            </div>
        </>
    )
}