import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Tooltip from '@mui/material/Tooltip';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import CountryDropdown from "../../layouts/countryDropdown/CountryDropdown";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@mui/material/Autocomplete";
import DialerApp from "./CallModalJs";
import { ApplicationCallOptions, CallsApiEvent, createInfobipRtc, InfobipRTC } from "infobip-rtc"
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {capacitortwilioaudio} from "capacitortwilioaudio";
import { createInfoBipCall, createInfoBipCallToken } from "../../apis/call/callApi";

import {getFormatedPhoneNumber} from '../../utils/phoneNumberUtil';
import {getDeviceType} from '../../utils/deviceTypeUtil';
import {
  useGetPrescribersQuery,
  useAddContactQuery,
} from "../../utils/ApiContacts";
import DropdownInupt from "../shared/Dropdown";
// import ReactAutocomplete from "react-autocomplete";
import SearchInput from "../../layouts/searchInput/SearchInput";
import config from "../../env.json";
// import ReactAutocomplete from "react-autocomplete";
import AssociatedPresList from "../../layouts/AssociatedPrescriber/AssociatedPresList";
import axios from "axios";
import ContactAvatar from "../../assets/icons/neutralAvatar.svg"
import { Device } from '@twilio/voice-sdk';
import {useSelector,useDispatch} from 'react-redux'
import { setCallModalView, setCallModalDetails } from '../../app/slice/callModalSlice';
import { setUserVirtualNumber} from '../../app/slice/authApiSlice';
import {setUserPersonalisationDeatils} from '../../app/slice/personalisationSlice'
import {updatePersonalizationData} from '../../apis/personalizationApi/personalization';
import {contactDetails} from '../../apis/contacts/contactsApi';
// import axios from 'axios';
import sidebarHeaderBg from '../../assets/images/mobilesidebar_headerbg_vertical.jpg'

import SpeakerUnmuteIcon from '../../assets/icons/Speaker2.svg'
import SpeakerMuteIcon from '../../assets/icons/Speaker1.svg'

import "./CallModal.css";
import { data } from "jquery";
import { useTranslation } from "react-i18next";
import Calllog from "../../pages/calllog/Calllog";
import Avatar from "../Avatar/Avatar";
import { InfobipRTCEvent } from "infobip-rtc/dist/event/InfobipRTCEvents";
// const Device = require('@twilio/voice-sdk').Device;




const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "28%",
  borderRadius: "1rem",
  padding: ".5rem",
  bgcolor: "white",
  border: "none",
};

export default function CallModal({ handleCloseParent, data }) {
  const { t } = useTranslation();
  const dispatch=useDispatch();
  const empPersonalisationCallType=useSelector((state: any) => state.personalisation);
  const empShowCallType=useSelector((state: any) => state.personalisation.showCallType);
  const callModal=useSelector((state: any) => state.callModal.showCallModal);
  const virtualNumber=useSelector((state: any) => state.authApi.EmployeeV_Nbr);
  const repId=useSelector((state: any) => state.authApi.Id);
  const callModalDetailName=useSelector((state: any) => state.callModal.contact_name);
  const callModalDetailNumber=useSelector((state: any) => state.callModal.contact_number);
  const token=useSelector((state:any)=>state.login.token)
  const [acctFullName,setAcctFullName]=useState('');
  const [acctPhoneNbr,setAcctPhoneNbr]=useState('')
  const [showMicIcon,setShowMicIcon]=useState(false);
  const [micIconMuted,setMicIconMuted]=useState(false);
  const [isExternalSpeaker,setIsExternalSpeaker]=useState(false);
  const [showErrorMsg,setShowErrorMsg]=useState('');
  const [connection,setConnection]=useState<any>({});
  const [onPhone,setOnPhone]=useState(Boolean);
  const [log,setLog]=useState('');
  const [voiceToken,setvoiceToken]= useState('');
  const [voiceTokenIdentity,setvoiceTokenIdentity]= useState('');
  const [tokenData,setTokenData]= useState<any>();
  const [callState,setCallState]= useState<any>();
  const [callModalHeight, setCallModalHeight] = useState(0);
  const [callModalWidth, setCallModalWidth] = useState(0);
  const [mobileStyle, setMobileStyle] = useState(false);
  const [webCallYOrN, setWebCallYOrN] = useState(false);
  const [alignment, setAlignment] = React.useState(empPersonalisationCallType.callType);
  const [optOut,setOptOut]=useState(true);
  const [showToggle, setShowToggle] = useState<any>(false);
  const [infoBipCall,setInfoBipCall]=useState<any>(null);
  const camp=useSelector((state: any) => state.personalisation.campaignName);

  // function updateCallStatus(status) {
  //   callStatus.text(status);
  // }
let tokenDevice : any;
const getVoiceToken = async ()=>{
  if(config.REACT_APP_AUDIO_CALL_TYPE === 'infobip') {
    createInfoBipCallToken((res) => {
      if(res && res.data && res.data.token) {
        setvoiceToken(res.data.token);
        setvoiceTokenIdentity(res.data.identity);
      } else {
        setShowErrorMsg('Could not fetch token')
      }
    });
  } else {
    axios.defaults.headers.common["Authorization"] = "Bearer "+token
    await axios.get(config.REACT_APP_CALL_API_BASE+'/voice-token',)
      .then(res=>{
        setvoiceToken(res.data.token);
        tokenDevice =new Device(res.data.token);
        tokenDevice.on("ready", (e) => {
          debugger
          console.log("Twilio.Device Ready!", e);
          debugger;
          
          // updateCallStatus("Ready");
        });
        setTokenData(tokenDevice)
      })
      .catch(function(err) { 
        console.log(err);
        // self.log = "Could not fetch token, see console.log";
        setShowErrorMsg('Could not fetch token, see console.log')
      });
   
  }   
}



    const getHeight = () => {
      let doc:any=document
      let div:any=document.querySelector('#main') 
      let divpaddingTop:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
      let divpaddingbottom:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
      let divpaddingleft:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-left'));
      let divpaddingright:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-right'));
      // console.log("gc modal toppad",divpaddingTop);
      // console.log("gc modal bottompad",divpaddingbottom);
      // console.log("gc modal leftpad",divpaddingleft);
      // console.log("gc modal rightpad",divpaddingright);
      // console.log("gc modal height",window.innerHeight-divpaddingTop-divpaddingbottom);
      // console.log("gc modal width",window.innerWidth-divpaddingleft-divpaddingright);
      
      let device = getDeviceType()
      if(device=="mobile" || device=="tablet" ||  config.REACT_APP_IS_CROSS_PLATFORM){
        setMobileStyle(true);
        if(document.getElementsByClassName('call_modal_content')[0]){
        setCallModalHeight(window.innerHeight-(divpaddingTop+divpaddingbottom));
        // document.querySelector('.MuiModal-root, .MuiBox-root').
        setCallModalWidth(window.innerWidth-(divpaddingleft+divpaddingright));
        // document.querySelector('.MuiModal-root, .MuiBox-root').style.width=(window.innerWidth-(divpaddingleft+divpaddingright))+'px'
        // document.querySelector('.MuiModal-root, .MuiBox-root').style.height=(window.innerHeight-(divpaddingTop+divpaddingbottom))+'px'

        // doc.getElementsById('modalOuter').offsetHeight=window.innerHeight-divpaddingTop-divpaddingbottom;
        // doc.getElementsById('modalOuter').offsetWidth=window.innerWidth-divpaddingleft-divpaddingright;
        }
      }
    }
  useEffect(() => {
    window.addEventListener('orientationchange', getHeight)
    return () => {
      window.removeEventListener('orientationchange',getHeight)
    }
  }, [])


    useEffect(()=>{
    // console.log("data",data)
      getHeight();
      (capacitortwilioaudio as any).removeListener('callDisconnected');
      (capacitortwilioaudio as any).addListener('callDisconnected', (info) => {
        setShowMicIcon(false);
        dispatch(setCallModalView(false));
        
      });
    })

    const connectBridgecall = ()=>{
      let prescrOrAssprs:any = data.Is_Prescriber === "Y"? "PRESCR":(config.REACT_APP_ENABLE_HOSPITAL_CONTACT && config.REACT_APP_CAMP_LIST_ENABLE_HOSPITAL_CONTACT==camp? "ASSHSP": "ASSPRS");
      let payload ={
        hcpNumber : callModalDetailNumber,
        recepientId : data.Id,
        recepientType: prescrOrAssprs
      }
      axios.defaults.headers.common["Authorization"] = "Bearer "+token
      axios.post(config.REACT_APP_CALL_API_BASE+'/bridgecall',payload)
      .then(res=>{
        console.log(res)
        setShowMicIcon(false);
        dispatch(setCallModalView(false))
        // tokenDevice =new Device(res.data.token);
        // tokenDevice.on("ready", (e) => {
        //   debugger
        //   console.log("Twilio.Device Ready!", e);
        //   debugger;
          
        //   // updateCallStatus("Ready");
        // });
        // setTokenData(tokenDevice)
      })
      .catch(function(err) { 
        console.log(err);
        // self.log = "Could not fetch token, see console.log";
        // setShowErrorMsg('Could not fetch token, see console.log')
      });
    
    }
const connectCall =async () =>{

let virtualNbWithoutPlus = virtualNumber.replace("+","")
let toNbrWithoutPlus=callModalDetailNumber.replace("+","")
console.log(virtualNbWithoutPlus, callModalDetailNumber)
  if(config.REACT_APP_AUDIO_CALL_TYPE === 'infobip') {
    if(voiceToken && voiceToken !=='') {
      setInfoBipCall(null)
      let prescrOrAssprs:any = data.Is_Prescriber === "Y"? "PRESCR":(config.REACT_APP_ENABLE_HOSPITAL_CONTACT && config.REACT_APP_CAMP_LIST_ENABLE_HOSPITAL_CONTACT==camp? "ASSHSP": "ASSPRS");
      
      let infobipRTC = createInfobipRtc(voiceToken, {debug: false});
      infobipRTC.on(InfobipRTCEvent.CONNECTED, function (event) {
        console.log('Connected with identity: ' + event.identity);
        const callParams = {
          "identity": event.identity,
          "to": toNbrWithoutPlus,
          "from": virtualNbWithoutPlus,
          "employeeId": repId,
          "recepientType": prescrOrAssprs,
          "recepientId": data.Id
        }
        createInfoBipCall(callParams, (res) => {
          if(res && res.data) {

          } else {
            // Show error
          }
          console.log("------Call Res----")
          console.log(res)
        });
      });
      infobipRTC.on(InfobipRTCEvent.DISCONNECTED, function (event) {
          console.log('Disconnected!');
      });
      infobipRTC.on(InfobipRTCEvent.INCOMING_APPLICATION_CALL, function(event) {
        const incomingCall = event.incomingCall;
        setInfoBipCall(incomingCall)
        
        console.log('Received incoming call from: ' + incomingCall.from());
    
        incomingCall.on(CallsApiEvent.ESTABLISHED, function(event) {
          console.log("----call establised---");
          (document.getElementById("remoteAudio") as HTMLAudioElement).srcObject = event.stream;
        })

        incomingCall.on(CallsApiEvent.HANGUP, function(event) {
          console.log("----call HANGUP---");
          dispatch(setCallModalView(false));
          handleCloseParent(showMicIcon);
        })
        
        incomingCall.on(CallsApiEvent.ERROR, function(event) {
          console.log("----call ERROR---");
          dispatch(setCallModalView(false));
          handleCloseParent(showMicIcon);
        })

        let applicationCallOptions = ApplicationCallOptions.builder().setAudio(true).build();
        incomingCall.accept(applicationCallOptions) // or incomingCall.decline();
        setShowMicIcon(true);
        setOnPhone(true)
        setMicIconMuted(false)


      })

      infobipRTC.connect();

    }
  } else {
    if(tokenData){
      setShowMicIcon(true);
      setOnPhone(true)
      setMicIconMuted(false)
      
      let prescrOrAssprs:any = data.Is_Prescriber === "Y"? "PRESCR":(config.REACT_APP_ENABLE_HOSPITAL_CONTACT && config.REACT_APP_CAMP_LIST_ENABLE_HOSPITAL_CONTACT==camp? "ASSHSP": "ASSPRS");
      if(config.REACT_APP_IS_CROSS_PLATFORM) {
        capacitortwilioaudio.connectCall({
          accessToken: voiceToken,
          toPhone: callModalDetailNumber,
          fromPhone: virtualNumber,
          employeeId:repId.toString(),
          recepientId:(data.Id).toString(),
          recepientType: prescrOrAssprs
        })
          .then((response) => {
            console.log("\n\n\n\nStatus \n\n\n\n " + response.status);
          })
          .catch(error => {
              console.log("Error");
              console.log(error);
          })
      } else {
        let call = await tokenData.connect({
          params: {
            agent : 'user',
            from: virtualNumber,
            To: callModalDetailNumber,
            employeeId:repId,
            recepientId:data.Id,
            recepientType: prescrOrAssprs
      
          } 
        })
        setCallState(call)
        call.on("disconnect", function(conn) {
          // handleCloseParent(false)
          dispatch(setCallModalView(false))
        })
      }
    }
    
  }
  
}
function truncate(source, size) {
  return source.length > size ? source.slice(0, size - 1) + "…" : source;
}

const handleChange = (e:any) => {
  
  // dispatch(setUserPersonalisationDeatils{

  // })
  setAlignment(e.target.value);
  dispatch(setUserPersonalisationDeatils({
    callType:e.target.value
  }))
  
  console.log(empPersonalisationCallType.notificationType)
  let persPayload={
    language:empPersonalisationCallType.language,
    locale:empPersonalisationCallType.locale,
    timeZone:empPersonalisationCallType.timeZone,
    personalisationId:empPersonalisationCallType.personalisationId,
    callType:e.target.value,
    notificationType:empPersonalisationCallType.notificationType
  }

  updatePersonalizationData(persPayload,(res:any)=>{
    if(res){
      console.log("lang update",res);
      // setFormID(res)
      // setupLanguage();
    }
    else{
      console.log("lang update",res);
    }
  })
};

 
useEffect(()=>{ 
  // console.log(empPersonalisationCallType)
{empPersonalisationCallType.callType==="webcall" || empPersonalisationCallType.callType==="" || empPersonalisationCallType.callType=== null|| empPersonalisationCallType.callType==="undefined" ? setWebCallYOrN(false) : setWebCallYOrN(true);} 
})

// const getUserInfo=()=>{
//   contactDetails({Id:data.Id,Is_Prescriber:data.is})
// }


const hangupCall =()=>{
  setShowMicIcon(false)
  if(config.REACT_APP_AUDIO_CALL_TYPE === 'infobip') {
    if(infoBipCall) {
      infoBipCall.hangup()
    }
    dispatch(setCallModalView(false));
    handleCloseParent(showMicIcon);
  } else {
    
    if(config.REACT_APP_IS_CROSS_PLATFORM) {
      dispatch(setCallModalView(false));
      capacitortwilioaudio.disconnectCall()
        .then((response) => {
        })
        .catch(error => {
            console.log("Error");
            console.log(error);
        })
        handleCloseParent(showMicIcon);
    } else {
      if(tokenData)
      tokenData.disconnectAll();
      dispatch(setCallModalView(false));
  
      handleCloseParent(showMicIcon);
  
    }
  }
  

 

}

const toggleMute=()=>{
  if(config.REACT_APP_AUDIO_CALL_TYPE === 'infobip') {
    if(infoBipCall) {
      infoBipCall.mute(true)
      setMicIconMuted(true)
    }
  } else {
    if(config.REACT_APP_IS_CROSS_PLATFORM) {
      setMicIconMuted(true);
      capacitortwilioaudio.muteCall()
        .then((response) => {
          console.log("\n\n\n\nStatus \n\n\n\n " + response.status);
        })
        .catch(error => {
            console.log("Error");
            console.log(error);
        })
    } else {
      console.log('mute',callState)
      setMicIconMuted(true)
      callState.mute(true)
    }
  }
}
const getNumberFromItem=(item)=>{
  if(item.AcctPh_Nbr){
    if(item.AcctPh_Nbr.indexOf("+")>=0){
      return item.AcctPh_Nbr;
    }else
    return '+'+item.IntlCallPfx_Nbr+item.AcctPh_Nbr;
  }
  else return ""
}

const setInternalSpeaker = ()=> {
  setIsExternalSpeaker(false);
  capacitortwilioaudio.setInternamSpeaker()
    .then((response) => {
      console.log("\n\n\n\nStatus \n\n\n\n " + response.status);
    })
    .catch(error => {
        console.log("Error");
        console.log(error);
    })
}

const setExternalSpeaker = () => {
  setIsExternalSpeaker(true);
  capacitortwilioaudio.setExternalSpeaker()
    .then((response) => {
      console.log("\n\n\n\nStatus \n\n\n\n " + response.status);
    })
    .catch(error => {
        console.log("Error");
        console.log(error);
    })
}
const toggleunMute= () =>{
  if(config.REACT_APP_AUDIO_CALL_TYPE === 'infobip') {
    if(infoBipCall) {
      infoBipCall.mute(false)
      setMicIconMuted(false)
    }
  } else {
    if(config.REACT_APP_IS_CROSS_PLATFORM) {
      setMicIconMuted(false);
      capacitortwilioaudio.unmuteCall()
        .then((response) => {
          console.log("\n\n\n\nStatus \n\n\n\n " + response.status);
        })
        .catch(error => {
            console.log("Error");
            console.log(error);
        })
    } else {
      setMicIconMuted(false)
      callState.mute(false)
    }
  }
}
  useEffect(() => {
   
    if(callModal){
    getVoiceToken()
    // console.log(callState.status());
    if(callState){
      console.log("closed 1")
      if(callState.status()==="closed"){
        console.log("closed 2")
        setShowMicIcon(false)
      }
    }
    if(data){
      if(data.All_Ph_Cont_Flags === "N, N, N")
      setOptOut(true)
      else
      setOptOut(false) 
      setAcctPhoneNbr( getNumberFromItem(data))
      setAcctFullName(data.AcctFull_Nm)
    }
    console.log(axios.CancelToken.source());

  }
  return()=>{

  }
    // getTemplatesFromCampign(1000)
  }, [callModal]);


 
// const Background = 'https://cordastinv3msgappuat.azureedge.net/img/callBg.0e58f8f3.png';


//const Background:any = sidebarHeaderBg;
const Background =config.REACT_APP_CALL_MODAL_BG_IMG



 

 

  return (
    <div>
    {showToggle?<Calllog/>:null}

      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      {/* <DialerApp /> */}
      <Modal
        open={callModal}
        // onClose={handleCloseParent}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="call_modal_content"
      >
              <Fade in={callModal}>
        <Box sx={style} className="call-modal-container" style={mobileStyle?{ backgroundImage: `url(${Background})`, height:callModalHeight+'px', width:callModalWidth+'px'}:{ backgroundImage: `url(${Background})`}} >
            <div className="call_modal_body">
                <div className="row call_avatar">
                    <div className="avatar_wrapper">
                    <Avatar
                            imageType={
                              data?.Is_Prescriber=== "Y"
                                ? "prescriber"
                                : "prescriberassociates"
                            }
                            avatarHeight="5.5rem"
                            avatarWidth="5.5rem"
                            presOrAssoc={data?.AcctFull_Nm}
                          />
                    </div>
                </div> 
                
                <div className="call_modal_details">
                    <h4>{truncate(callModalDetailName, 50)}</h4>
                    <h5>{getFormatedPhoneNumber(callModalDetailNumber)}</h5>
                    {optOut?<h5>{t('Opted out')}</h5>:null}
                </div> 
                {empShowCallType === true && config.REACT_APP_SHOW_BRIDGECALL_CALLMODAL==="Y" ?
                <div className="call-type-toggle">
                <ToggleButtonGroup
                      color="primary"
                      value={empPersonalisationCallType.callType}
                      exclusive
                      onChange={handleChange}
                      aria-label="Platform"
                    >
                      <ToggleButton value="webcall">{t('Webcall')}</ToggleButton>
                      <ToggleButton value="bridgecall">{t('Bridgecall')}</ToggleButton>
               </ToggleButtonGroup>
                </div>: null}
                <div className="call_modal_icons">
                  {showMicIcon === false  ?
                      <div>
                        {
                          optOut?
                          <div className="call_start">
                        <Tooltip title={t("Call")} arrow>
                        <i className="fas fa-phone-alt i-2p5 callIcon" style={{color:'grey'}}></i>
                        </Tooltip>
                      </div>:<div className="call_start" onClick={webCallYOrN ? connectBridgecall : connectCall}>
                        <Tooltip title={t("Call")} arrow>
                        <i className="fas fa-phone-alt i-2p5 callIcon"></i>
                        </Tooltip>
                      </div>
                        }
                      
                      </div>
                      :
                      <div>
                        <div className="call_micIcon">
                          { micIconMuted === false?
                          <i className='fas fa-microphone i-3p5 micIcon callCircle_mic_unmuted' onClick={toggleMute}></i> :
                          <i className='fas fa-microphone-slash i-3p5 callCircle_mic_muted' onClick={toggleunMute}></i> }
                          {/* <i className="fas fa-microphone i-3p5 micIcon callCircle_mic_muted" onClick={toggleMute}></i>                 */}
                        </div>

                        { 
                        
                        config.REACT_APP_IS_CROSS_PLATFORM &&
                          <div className="call_micIcon">
                          { isExternalSpeaker === false?
                          // <i className='fa fa-volume-up i-3p5 micIcon callCircle_mic_unmuted' onClick={setExternalSpeaker}></i> :
                          // <i className='fa fa-volume-down i-3p5 callCircle_mic_muted' onClick={setInternalSpeaker}></i> 

                          <img className="micIcon callCircle_mic_unmuted" style={{width:'2rem'}} onClick={setExternalSpeaker} src={SpeakerMuteIcon} alt="Speaker Unmute Logo" />:
                          <img className="micIcon callCircle_mic_unmuted" style={{width:'2rem'}} onClick={setInternalSpeaker} src={SpeakerUnmuteIcon} alt="Speaker Mute Logo" />

                        }        
                          </div>

                        }
                        
                      </div>
                      
                      
                    }                  
                    
                    <div className="call_end" onClick={hangupCall}>
                    <i className="fa fa-phone i-2p5 endIcon"></i>
                    </div>
                </div>              
            </div>
        </Box>
        </Fade>
      </Modal>
      <audio id="remoteAudio" autoPlay/>
    </div>
  );
}
