import axios from "axios";
import config  from '../../env.json';


function getDynamicTempList(callback){
  
    try {
        axios.get(config.REACT_APP_CAMPAIGN_API_BASE+"/dynamicTemplate")
        .then(res=>{ 
          callback(res.data);
        }).catch(()=>{
          callback([])
        })
    } catch (error) {
        callback([])
    }
   
}

function createDynamicTemplate(payload,callback){  
  debugger
    try {
        axios.post(config.REACT_APP_CAMPAIGN_API_BASE+"/dynamicTemplate/create",payload)
        .then(res=>{ 
          console.log(res)
          debugger
          if(res.data.message === "Inappropriate message body"){
            callback(true, res.data);
          } else{
            callback(true);
          }
          
        }).catch(()=>{
          callback(false)
        })
    } catch (error) {
        callback(false)
    }
   
}
 

function getemplateDetails(id:any,callback:any){
  try {
    axios.get(config.REACT_APP_CAMPAIGN_API_BASE+"/dynamicTemplate/"+id)
    .then(res=>{ 
      callback(res.data);
    }).catch(()=>{
      callback(false)
    })
} catch (error) {
    callback(false)
}
} 


export {getDynamicTempList,createDynamicTemplate,getemplateDetails}