import React, { useEffect, useState } from 'react'
import Tooltip from '@mui/material/Tooltip';
import { useTranslation, initReactI18next } from "react-i18next";
import TemplateModal from '../../components/dynamicTempModal/templateModal'
import { getDynamicTempList } from '../../apis/dynamicTemplateApi/dynamicTemplateApi'
import './DynamicTemplate.css';
import { Edit } from '@mui/icons-material';
import { useSelector } from 'react-redux';



export default function DynamicTemplate() {
  const [allDynamicTemplate, setAllDynamicTemplate] = useState<any>([]);
  const [selectedId,setSelectedId]=useState<any>(null);
  const [view,setView]=useState<boolean>(false);
  const pageTitle=useSelector((state:any)=>state.sideBar.title)
  const [dynamicTempHeight, setDynamicTempHeight]=useState<any>();
  useEffect(()=>{
    getlist();
    getHeight()
    document.documentElement.style.setProperty('--visibility', 'hidden');
  console.log(pageTitle)
  document.getElementById("page-name")?.setAttribute('data-content',t(pageTitle));
  },[])

  useEffect(()=>{
    document.documentElement.style.setProperty('--visibility', 'initial');
    document.getElementById("page-name")?.setAttribute('data-content', t(pageTitle));
  },[pageTitle])
  const getlist=()=>{
    getDynamicTempList((res)=>{
      setAllDynamicTemplate(res.data)
    })
  }


  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const getHeight = () => {
    let viewportHeight = window.innerHeight;
    let div:any=document.getElementById('main');
    let divpaddingTop:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
    let divpaddingbottom:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
    let doc:any=document;
    let assetsHeaderHeight = 0;
    if(window.innerWidth <= 1200) {
      assetsHeaderHeight = allDynamicTemplate.length <= 1 ?  0 : doc.getElementsByClassName('dynamic_templates-header')[0].offsetHeight; 
    } else {
      assetsHeaderHeight = doc.getElementsByClassName('dynamic_templates-header')[0].offsetHeight;
    }
    if(doc.getElementsByClassName('dynamic_template_body')[0])
    setDynamicTempHeight(viewportHeight -(doc.getElementById('MobHeader').offsetHeight+document.getElementById('headerVal')?.offsetHeight+assetsHeaderHeight+doc.getElementById('footer').offsetHeight)-divpaddingTop-divpaddingbottom) ;
  }

  return (
    <div className='contacts-container'>
      <TemplateModal show={showModal}
      id={selectedId}
        handleCloseParent={(e:any) => { setShowModal(false);getlist() }} title={selectedId?"Dynamic":"Create"} Id="dynamicTemp" viewform="true"
      />
      
        {/* <TemplateModal show={view}
        id={selectedId}
        handleCloseParent={(e:any) => { setShowModal(false);getlist() }} title="Create" Id="dynamicTemp" viewform="true"
      /> */}
      
      <div className='dynamic_wrapper'>
        <div className='dynamic_templates-header'>
          <Tooltip title="Add Template" arrow>
            <div
              id="createFormId"
              className="floating-button create_contact notMobile"
              onClick={() => { setSelectedId(null); setShowModal(true) }}
            >

              <i className="fas fa-plus"></i>
            </div>
          </Tooltip>
        </div>
        <div className='dynamic_template_body' style={{height:dynamicTempHeight}}>
          <div className="dynamic-temp-title">
            <div className='Dynamic_Temp_NameHeader'>{t('Name')}</div>
            <div className='Dynamic_Temp_body'>{t('Template Body')}</div>
            <div className='Dynamic_Temp_Assets'>{t('Assets')}</div>
            <div className='Dynamic_Temp_Status'>{t('Status')}</div>
            <div className='Dynamic_Temp_Vcard'>{t('VCard')}</div>
          </div>
          <ul className='contact-scroll-list dynamic-temp-list'>
          {/* {contactList.map((item: any,i:number) => { */}
          {allDynamicTemplate.map((x: any, index) => {
            return (
              <li id={index}>
                <div className="FormtListItem-div">
                  <div className="DynamicTempName">
                    <div
                    
                      style={{
                        fontSize: "1.098rem",
                        fontWeight: "bold",
                        paddingLeft: "2rem"

                      }}
                    >
                      {x.templateName}
                    </div>
                  </div>

                  <div
                    className='DynamicTempbody'
                    style={{
                      // fontVariant: "small-caps",
                      fontSize: "1.098rem",
                      // paddingLeft: "14%",
                      // width: "15%"
                    }}
                  >
                    {x.templateValue}
                  </div>
                  <div
                    className='DynamicTempAssets'
                    style={{
                      //   fontVariant: "small-caps",
                      fontSize: "1.098rem",
                      // paddingLeft: "14%",
                      // width: "30%"
                    }}
                  >
                    {x.showAsset == "Y" ? "Yes" : "No"}
                  </div>
                  <div className='DynamicTempStatus'>
                    {x.active == "Y" ? "Active" : "Inactive"}
                  </div>
                  <div
                    className='DynamicTempVcard'
                    style={{
                      // fontVariant: "small-caps",
                      fontSize: "1.098rem",
                      // paddingLeft: "7rem",
                    }}
                  >

                    {x.vcard == "Y" ? "Yes" : "No"}

                  </div>
                  
                    <a className="view" title={t("View")} data-toggle="tooltip" onClick={()=>{setSelectedId(x.id);setShowModal(true)}}><i className="material-icons">&#xe8f4;</i></a>
                   

                </div>

              </li>
            );
          })}
          <div className="create-dynamic-floating onlyMobile" 
          onClick={()=>{setShowModal(true)}}
        // style={{paddingBottom:this.state.paddingValBottom, paddingRight:this.state.paddingValRight}}
        >
         <Tooltip title={t("Add Contact")} arrow>
          <div
        id="createContactId"
        className="floating-button create_contact"
        // onClick={()=>{this.setState({show:true})}}
      >

        <i className="fas fa-plus"></i>
      </div>
              </Tooltip>
         </div>
        </ul>
        
        </div>
        


      </div>
    </div>

  )
}
