import axios from "axios";
import config from "../../env.json";

function optInOutStatusUpdate(payload: any, callback: any) {
    debugger
    try {
        axios.post(config.REACT_APP_CONTACTS_API_BASE + "/contact/optoutStatus/update", payload)
            .then(res => {
                console.log(res)
                debugger
                if (res.data.message === "Inappropriate message body") {
                    callback(true, res.data);
                } else {
                    callback(true);
                }

            }).catch(() => {
                callback(false)
            })
    } catch (error) {
        callback(false)
    }
}

function getOptInOutStatus(payload: any, callback: any) {
     try {
        axios.post(config.REACT_APP_CONTACTS_API_BASE + "/contact/optoutStatus", payload)
            .then(res => {
                console.log(res)
                debugger
                /*if (res.data.message === "Inappropriate message body") */
                    callback(res.data);
                /* } else {
                    callback(true);
                }*/
            }).catch(() => {
                callback(false)
            })
    } catch (error) {
        callback(false)
    }
}

export {optInOutStatusUpdate,getOptInOutStatus}