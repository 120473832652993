import React, { useEffect,useState} from 'react'
import Tooltip from '@mui/material/Tooltip';
import './campaign.css'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';

export default function Campaign() {
  const pageTitle=useSelector((state:any)=>state.sideBar.title)
  const { t } = useTranslation();
  const [campaignHeight,setCampaignHeight]=useState(0);  
  useEffect(() => {
    document.documentElement.style.setProperty("--visibility", "hidden");
    document
      .getElementById("page-name")
      ?.setAttribute("data-content", t(pageTitle));
      let viewportHeight = window.innerHeight;
    let div:any=document.getElementById('main');
    let divpaddingTop:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
    let divpaddingbottom:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
    let doc:any=document;
    if(doc.getElementsByClassName('campaign-body')[0])
    setCampaignHeight(viewportHeight -(doc.getElementById('MobHeader').offsetHeight+document.getElementById('headerVal')?.offsetHeight+doc.getElementsByClassName('campaign-header ')[0].offsetHeight+doc.getElementById('footer').offsetHeight)-divpaddingTop-divpaddingbottom) ;
  }, []);
  return (
    <div className="campaign-container">
        <div className="campaign-header row--three">
          {/* <div className='content__header__searchbox'>
          <div className="input-group-prepend">
            <span id="basic-addon3" className="input-group-text">
              <button className="btn" onClick={() => {}}>
                <i className="fas fa-search i-1p5"></i>
              </button>
            </span>
          </div>
          <SearchInput id={'allContactSearch'} classes={'form-control font-md search-contact'} placeholder={'Search Contacts'} onChangeValue={(e)=>{this.setState({search:e,contactList:[],page:0},()=>{this.getContactsData()})}}/>
        
          </div> */}
          
          {/* <Tooltip title="Add Contact" arrow>
          <div
        id="createContactId"
        className="floating-button create_contact notMobile"
        // onClick={()=>{this.setState({show:true})}}
      >

        <i className="fas fa-plus"></i>
      </div>
              </Tooltip> */}
              {/* <div className="contactListView notMobile" >
              <i id="listFormatId" className="fas fa-list i-2p5" onClick={() =>{this.setState({ gridView:false})}}></i>
              <i id="cardFormatId" className="fas fa-th-large i-2p5" onClick={() =>{this.setState({ gridView:true})}}></i>

              </div> */}
        </div>
        <div className="campaign-body" id='campaignbody' style={{height:campaignHeight}}>
        <div className="list-title notMobile">
            <div className='campaignNameHeader'>{t('Name')}</div>
            <div className='campaignTypeHeader'>{t('Type')}</div>
            <div className='campaignPhoneHeader'>{t('Phone Number')}</div>
          </div>
        </div>
        <ul>
        <li>
                    <div className="contacttListItem-div notMobile">
                        <div className='contactName'>
                            HCP
                        </div>
                      {/* <div className="avatar-div contactName">
                        <Avatar {...stringAvatar(item.AcctFull_Nm)} />

                        <div
                          style={{
                            fontSize: "1.098rem",
                            fontWeight: "bold",
                            paddingLeft: "1rem",
                          }}
                        >
                          {item.AcctFull_Nm}
                        </div>
                      </div> */}

                      <div
                      className='contactType'
                        style={{
                          fontVariant: "small-caps",
                          fontSize: "1.098rem",
                        }}
                      >
                        Other Staff
                        {/* {item.Is_Prescriber === "N" ? "Other Staff" : "HCP"} */}
                      </div>
                      <div
                      className='contactPhone'
                        style={{
                          fontVariant: "small-caps",
                          fontSize: "1.098rem",
                          paddingRight: "1rem",
                        }}
                      >
                        738836838383
                        {/* {this.getNumber(item.AcctPh_Nbr)} */}
                      </div>
                    </div>
                    {/* <div className="contacttListItem-div contacttListItem-divMobile">
                      <div className="avatar-div contactName">
                        <Avatar {...stringAvatar(item.AcctFull_Nm)} />
                        <div>
                        <div
                          style={{
                            fontSize: "1.098rem",
                            fontWeight: "bold",
                            paddingLeft: "1rem",
                          }}
                        >
                          {item.AcctFull_Nm}
                        </div>
                        <div
                      className='contactPhone'
                        style={{
                          fontVariant: "small-caps",
                          fontSize: "1.098rem",
                          paddingLeft: "1rem",
                        }}
                      >
                        
                        {this.getNumber(item.AcctPh_Nbr)}
                      </div>
                      </div>
                      
                      
                        </div>
                        <div className='onlyMobile eachContactsIcons'>
                      <i className="material-icons i-2p5">chat</i>
      <i className="material-icons i-2p5">phone</i>
                      </div>
                        
                    </div> */}
                  </li>
        </ul>
    </div>
  )
}
