import React, { useEffect, useState } from "react";
import Message from "./Message";
// import Avatar from '@mui/material/Avatar';
import axios from "axios";
import config from "../../env.json";
import ContactDetialsModal from "../contact/ContactDetialsModal";
import InfiniteScroll from "react-infinite-scroll-component";
import { setSkipHeader } from '../../app/slice/loginSlice';
import CircularProgress from "@mui/material/CircularProgress";
import PreApprovedTemplates from "../preApprovedTemplates/preApprovedTemplate";
import { contactDetails } from "../../apis/contacts/contactsApi";
import { getConversationDetailsApi } from "../../apis/chat/chatApi";
import { useSelector, useDispatch } from "react-redux";
import { sendMessageApi } from "../../apis/chat/messageApi";
import { getMsgReadAt } from "../../apis/chat/messageReadAt";
import { Keyboard } from '@capacitor/keyboard';
import { Link } from 'react-router-dom';
import {
  setChatDetails,
  setChatShowChat,
  setChatType,
  setRefreshChatComponent,
  setRefreshChatList,
  setNumberAssigned
} from "../../app/slice/chatSlice";
import {
  setCallModalDetails,
  setCallModalView,
} from "../../app/slice/callModalSlice";
import "./MobileChat.css";
import Avatar from "../Avatar/Avatar";
import { Tooltip } from "@mui/material";
import moment from "moment";
import CallModal from "../call/CallModal";
import { useTranslation } from "react-i18next";
import Pusher from "pusher-js";
import SaveIcon from "@mui/icons-material/Save";
import { scrollIntoView } from "seamless-scroll-polyfill";
import { polyfill } from "seamless-scroll-polyfill";
import SaveContactsForChat from "./SaveContactForChat";
import SaveContactsForChatGAM from "./SaveContactForChatGAM";
import { getFormatedPhoneNumber } from "../../utils/phoneNumberUtil";
import TestMessage from "./MobileMessage";
import {setShowSuccessSnackBar,setShowErrorSnackBar} from '../../app/slice/personalisationSlice';
import {getPhoneNumberType} from '../../apis/checkPhoneNumberType/landlineLookupApi';
import CallIcon from '@mui/icons-material/Call';
import sidebarHeaderBg from '../../assets/images/mobilesidebar_headerbg.jpg'
import ContactDetialsModalGAM from "../../components/contact/ContactDetialsModalGAM";
import ContactAddPopup from "../contact/ContactAddPopup";

//const Background:any = sidebarHeaderBg;
const Background = config.REACT_APP_PROFILE_BG_IMG;
let BestTimeFlag = config.REACT_APP_BESTTIME_FLAG;

// import '../chat/MsgComponent.css'

function NewMobileChatComponent(props) {
  const { t } = useTranslation();
  const { chatId, chatName, chatIsPrescriber, showChat, refreshChatComponent, pageSelected, numberAssigned } = useSelector((state: any) => state.chat);
  const messageChannelType = useSelector((state: any) => state.authApi.EmployeeMsgorWhatsAppChannelType)
  const type = useSelector((state: any) => state.chat.type);
  const token = useSelector((state: any) => state.login.token)
  const dispatch = useDispatch()
  const userTerrId=useSelector((state: any) => state.personalisation.EmployeeTerritoryId);

  const [changeTerritoryId,setChangeTerritoryId]=useState(false);
  const [messageData, setMessageData] = useState([]);
  const [contactData, setContactData] = useState<any>({})
  const [showDetails, setShowDetails] = useState(false);
  const [name, setName] = useState('')
  const [showPreApproveTemplates, setPreApproveTemplates] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [errorMessages, setErrorMessages] = useState<String[]>([])
  const [chatInput, setChatInput] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [hasMore, setHasMore] = useState(true);
  const [messageId, setMessageId] = useState<any>();
  const [pusherChannel, setPusherChannel] = useState<any>(null);
  const [pusherMsgProps, setPusherMsgProps] = useState<any>(null);

  const [showTextArea, setShowTextArea] = useState(true);
  const [chatWidth, setChatwidth] = useState(0);
  const [chatHeight, setChatHeight] = useState(0);
  const [paddingValTop, setPaddingValTop] = useState(0);
  const [paddingValBottom, setPaddingValBottom] = useState(0);
  const [paddingValRight, setPaddingValRight] = useState(0);
  const [paddingValLeft, setPaddingValLeft] = useState(0);

  const [infiniteScrollHeight, setInfiniteScrollHeight] = useState(0);
  // const [preApprovedTemplateData,setPreApprovedTemplateData]=useState({})
  const [showImgPopup, setShowImgPopup] = useState("");
  const [disableSendButton, setDisableSendButton] = useState(false);
  const [showCallModal, setShowCallModal] = useState(false);
  const [optedOut, setOptedOut] = useState(false);
  const [unknown, setUnknown] = useState(false);
  const [isNumberLandline, setIsNumberLandline] = useState(false);
  const [openSaveContacts, setOpenSaveContacts] = useState(false);
  const [mobileFooterBottom, setMobileHeaderTop] = useState<any>(0);
  const [mobileHeaderTop, setMobileFooterBottom] = useState<any>(0);
  const [mainChatTop, setMainChatTop] = useState<any>(0);
  const [mainChatBottom, setMainChatBottom] = useState<any>(0);
  const [finished, setFinished] = useState(false);
  const [deletedOthers,setDeletedOthers]=useState(false)
  const { optOutKeywords, optInKeywords } = useSelector((state: any) => state.personalisation);
  const [tempTemplateDataIfNoNumber,setTempTemplateDataIfNoNumber]=useState<any>(null);
  const [noNumber,setNoNumber]=useState(false);
  const [showAttachLoader, setShowAttachLoader] = useState<any>(false);
  const [isHospitalContact,setIsHospitalContact]=useState(false);
  const [addNumberPopup,setAddNumberPopup]=useState(false)
  const [chatPusher,setChatPusher]=useState<any>(null);
  const camp=useSelector((state: any) => state.personalisation.campaignName);
  

  let docElement: any = document;
  // const fileSupportedExt = [
  //   "png",
  //   "mpeg",
  //   "mpe",
  //   "mpa",
  //   "mp3",
  //   "mp2v",
  //   "mod",
  //   "mp4v",
  //   "3gpp",
  //   "3gp2",
  //   "3g2",
  //   "3gp",
  //   "mov",
  //   "mp4",
  //   "m1v",
  //   "au",
  //   "mp3",
  //   "ac3",
  //   "amr",
  //   "vcf",
  //   "csv",
  //   "rtx",
  //   "pdf",
  //   "bmp",
  //   "tif",
  //   "tiff",
  //   "jpe",
  //   "jpeg",
  //   "jpg",
  //   "gif",
  //   "m2v",
  //   "mpv2",
  //   "vbk",
  //   "web",
  //   "ogg",
  //   "ics",
  //   "webm",
  // ];
  // const fileSupported = {
  //   image: "image/bmp, image/tiff, image/jpeg, image/jpg, image/gif, image/png",
  //   video:
  //     "video/H264, video/H263-2000, video/H263-1998, video/H263, video/H261, video/3gpp-tt, video/3gpp2, video/3gpp, video/webm, video/quicktime, video/mp4, video/mpeg",
  //   audio:
  //     "audio/basic, audio/L24, audio/mp4, audio/mpeg, audio/ogg, audio/vnd.rn-realaudio, audio/vnd.wave, audio/3gpp, audio/3gpp2, audio/ac3, audio/vnd.wave, audio/webm, audio/amr-nb, audio/amr",
  //   application: "application/pdf, application/vcard",
  //   text: "text/vcard, text/x-vcard, text/csv, text/rtf, text/richtext, text/calendar, text/directory",
  // };
  const [isSMS, setIsSms] = useState(false);
  // const fileType =
  //   fileSupported.application +
  //   ", " +
  //   fileSupported.text +
  //   ", " +
  //   fileSupported.image +
  //   ", " +
  //   fileSupported.video +
  //   ", " +
  //   fileSupported.audio;


  const fileSupportedExt= config.REACT_APP_FILESUPPORTED_EXT.split(',');
  const fileType = config.REACT_APP_FILESUPPORTED_TYPE;
  const default_wave_id = config.REACT_APP_DEFAULT_WAVE_ID;
  const default_campaign_id = config.REACT_APP_DEFAULT_CAMPAIGN_ID;
  const default_product_id = config.REACT_APP_DEFAULT_PRODUCT_ID;

  const getUserInfo = () => {
    let id = chatId,
      presOrNot = chatIsPrescriber;
    // if(contact['Recipient Sender Typ']=="EMP"){
    //     id=contact['Recipient Id'];
    //     presOrNot=contact['Recipient Type']== "PRESCR";
    // }
    // else{
    //     presOrNot=contact['Recipient Sender Typ']=="PRESCR";
    //     id=contact['Recipient Sender Id'];
    // }
    // debugger
    if (id)
      contactDetails({ Id: id, Is_Prescriber: presOrNot }, (res) => {
        // debugger
        if (res) {
          if (res.message == "No data found") {
            setUnknown(true);
          } else {

            if(res.Terr_Id){
             if(config.REACT_APP_ABS_APPROVED == 'Y'){
              if(userTerrId ==res.Terr_Id){
                  setChangeTerritoryId(false)
              } else{
                  setChangeTerritoryId(true)

              }
             }
          }
          if(res.AcctPh_Nbr.length>0){
            dispatch(setNumberAssigned(true))
          }
            setUnknown(false);

            chatSubscriber(1);
            setContactData(res);
            checkNumber(res)
            if(!res.AcctPh_Nbr){
              setNoNumber(true)
              dispatch(setNumberAssigned(false))
            }
            else{
              setNoNumber(false)
            }
            dispatch(
              setCallModalDetails({
                AcctFull_Nm: res.AcctFull_Nm,
                AcctFull_Nbr: getNumberFromItem(res),
              })
            );

           
            if (res.All_Ph_Cont_Flags === "N, N, N"){
              setOptedOut(true);
            console.log("optout",res.All_Ph_Cont_Flags)

            } 
            else{
              setOptedOut(false);
            console.log("optout",res.All_Ph_Cont_Flags)

            }
          }
        }
        setFinished(true)
      });

    // axios.get(config.REACT_APP_CONTACTS_API_BASE+"/contact/"+id,{
    //     headers:{
    //       'Authorization':'Bearer '+sessionStorage.getItem('login')
    //     },
    //     params:{
    //       "IsPrescriber":presOrNot,
    //     }
    //   }).then((res:any)=>{

    //     setContactData(res.data.data)

    //   })
  };
  const handleFileSelected = (e) => {
    const files: any = Array.from(e.target.files);
    console.log("files:", files);
    setAttachedFiles(files);
    console.log("attachedFiles : ", attachedFiles);
  };

  const handleImgPopup = (imgdisurl) => {
    console.log("handling image enlarge parent", imgdisurl);
    setShowImgPopup(imgdisurl);
  };

  const forceFileDownload = (response: any, filename: any) => {
    const url = window.URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); //or any other extension
    document.body.appendChild(link);
    link.click();
  };
  
  const downloadWithAxios = (url) => {
    if(config.REACT_APP_IS_CROSS_PLATFORM) {
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
    } else {
      console.log("handle click downloadWithAxios called", url);
      let urlSplit = url.split("/");
      let filename = urlSplit[urlSplit.length - 1];
      delete axios.defaults.headers.common["Authorization"];
      // dispatch(setSkipHeader({ 'skipHeader': true }));
      fetch(url)
          .then(response => response.blob()) // Gets the response and returns it as a blob
          .then(blob => {
            forceFileDownload(blob, filename);
              axios.defaults.headers.common["Authorization"] = "Bearer " + token
              //   response.blob().then(blob => {
              //     let url = window.URL.createObjectURL(blob);
              //     let a = document.createElement('a');
              //     a.href = url;
              //     a.download = 'employees.json';
              //     a.click();
              //   });
              //   //window.location.href = response.url;
              // }
          });

    }
};

  const removeFile = (i) => {
    console.log("functioin called for ", i);
    let attfile = attachedFiles.filter((x, index) => i != index);
    setAttachedFiles(attfile);
   validateGetfiles(attfile);
    // this.fileSize = 0;
    // for (let i = 0; i < this.files.length; i++) {
    //     this.fileSize += parseInt(this.files[i].size);
    // }
    // if (this.files.length <= 10) {
    //     this.footerErrorMsg = [];
    // }
  };
  // const pusherSubscribe = (e)=>{
  //     console.log(e, "porA")

  //     debugger
  //     // const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
  //     //     cluster: config.REACT_APP_PUSHER_CLUSTER,
  //     //   //   encrypted: true,
  //     //   });

  //     const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
  //       cluster: config.REACT_APP_PUSHER_CLUSTER,
  //     //   encrypted: true,
  //     });
  //     debugger
  //     const channelName= getNumberFromItem(e)+"Dev";
  //     console.log(channelName)
  //     const channel = pusher.subscribe('chatdetailstatus');
  //   channel.bind(messageId, (data) => {
  //         console.log(data.Body)
  //         let pusherMsgData:any = messageData;
  //         pusherMsgData.push(data)
  //         setMessageData(data);
  //         optInOut(data.isOptIn)

  //         debugger
  //     })
  //     // return () => {
  //     //   pusher.unsubscribe('chatdetailstatus');
  //     // };
  //   }
  useEffect(() => {
    if(!optedOut && !deletedOthers && !unknown && !isNumberLandline){
      let doc:any=document;
      setMainChatBottom(doc.getElementsByClassName('mobile-footer')[0].clientHeight)
    } else {
      setMainChatBottom("0px")
    }
}, [optedOut, deletedOthers, unknown, isNumberLandline])

  useEffect(() => {
    // debugger
    let doc: any = document;
    console.log(getNumberFromItem(contactData));
    if (pusherChannel && pusherChannel.bind) {
      pusherChannel.unbind(getNumberFromItem(contactData));

      pusherChannel.bind(getNumberFromItem(contactData), (data) => {
        if (data) {
          console.log(messageData);
          // setShowTextArea(false);
          // document.querySelectorAll('textarea').forEach((element) => {

          //   element.blur();

          //   setTimeout(()=>{
          //     setShowTextArea(true);
          //   },1000)

          // });

          let jsonData = JSON.parse(data.Body);
          console.log(JSON.parse(data.Body));
          optInOut(jsonData.isOptIn);
        }
        //   if(data){
        //     console.log(messageData)
        //     // debugger
        //     let jsonData= JSON.parse(data.Body);
        //     console.log(JSON.parse(data.Body))
        //     let x = {
        //         'Recipient Channel Type':jsonData.channelType,
        //         'Recipient Msg End':jsonData.createdDateTime,
        //         'Recipient Msg Start':jsonData.createdDateTime,
        //         'Recipient Msg Body':jsonData.msgBody,
        //         'Recipient Msg Attachment':'',
        //         'Recipient Msg Status': 'sent'
        //     }
        //     // debugger
        //     let msgPusherData: any= [];
        //     messageData.map(e=>
        //       {msgPusherData.push(e);
        //           return e;
        //       }
        //       )
        //   //   messageData.map(e)
        //     console.log(jsonData.isOptIn)
        //     optInOut(jsonData.isOptIn)
        //     // debugger
        //     msgPusherData.unshift(x)
        //     console.log(msgPusherData)
        //     let t = msgPusherData;
        //     for (let chatIndex = 0;chatIndex < t.length;chatIndex++) {
        //       setMessageId(t[chatIndex]['Message Id'])
        //       // debugger
        //       if (chatIndex==t.length-1)
        //       {
        //         t[chatIndex].showDateBanner = true;
        //       }

        //       else if (chatIndex != 0) {
        //         if (
        //           moment(t[chatIndex]['Recipient Msg Start']).format(
        //             "MM/DD/YYYY"
        //           ) !=
        //           moment(t[chatIndex + 1]['Recipient Msg Start']).format(
        //             "MM/DD/YYYY"
        //           )
        //         ) {
        //           t[chatIndex].showDateBanner = true;
        //         } else {
        //           t[chatIndex].showDateBanner = false;
        //         }
        //       }
        //       else if (chatIndex == 0 &&  t.length>1) {

        //         if (
        //           moment(t[chatIndex]['Recipient Msg Start']).format("MM/DD/YYYY") == moment(t[chatIndex + 1]['Recipient Msg Start']).format( "MM/DD/YYYY")
        //         ) {
        //           t[chatIndex].showDateBanner = false;
        //         } else {
        //           t[chatIndex].showDateBanner = true;
        //         }
        //       }

        //     }
        //     setMessageData(t);
        //     // dispatch(setRefreshChatList(true))
        // }

        // setChats(data);
      });
    }
    let div: any = document.querySelector("#main");
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let divpaddingleft: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-left")
    );
    let divpaddingright: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-right")
    );
    setPaddingValTop(divpaddingTop);
    setPaddingValBottom(divpaddingbottom);
    setPaddingValRight(divpaddingleft);
    setPaddingValLeft(divpaddingright);

    // console.log("Padding"+ divpadding);
    console.log("Innerwidth" + window.innerHeight);

    if (
      document.getElementsByClassName("mobile-footer") &&
      document.getElementsByClassName("mobile-footer").length > 0
    )
      setChatHeight(
        window.innerHeight -
        (divpaddingTop +
          divpaddingbottom)
      );
    else setChatHeight(window.innerHeight - (divpaddingTop + divpaddingbottom));
    setChatwidth(window.innerWidth - (divpaddingright + divpaddingleft));
    scrollTOBottom();
    console.log("changes");
  }, [messageData, contactData]);
  useEffect(()=>{
    scrollTOBottom()
    chatSubscriber()
},[messageData])

  const chatSubscriber = (p=0) => {
    console.log(messageData);
    // debugger
    // const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
    const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
      // cluster: config.REACT_APP_PUSHER_CLUSTER,
      cluster: config.REACT_APP_PUSHER_CLUSTER,
      //   encrypted: true,
    });
    // debugger
    // console.log(contactNumber)
    const channel = pusher.subscribe("chatdetail");
    setPusherChannel(channel);
    setPusherMsgProps(pusher.subscribe("chatdetailstatus"));

    let payload:any={
      prescOrAssocId:chatId, 
      prescOrAssocType:chatIsPrescriber === true? "PRESCR": config.REACT_APP_ENABLE_HOSPITAL_CONTACT &&
      config.REACT_APP_CAMP_LIST_ENABLE_HOSPITAL_CONTACT == camp ? "ASSHSP" : "ASSPRS"
    }
    if(p=0){
      getMsgReadAt(payload,(res)=>{
        console.log(res.data.updated )
        if(res.data.updated === true){
            dispatch(setRefreshChatList(true))
        }
        
      })
    }
    let chatP:any= pusher.subscribe('chat')    
    setChatPusher(chatP);

        return () => {
            pusher.unsubscribe("chatdetail");
            pusher.unsubscribe("chat")
        };
  };
  const messageSubscriber = () => {
    // debugger
    // const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
    const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
      // cluster: config.REACT_APP_PUSHER_CLUSTER,
      cluster: config.REACT_APP_PUSHER_CLUSTER,
      //   encrypted: true,
    });
    // debugger
    // console.log(contactNumber)
    const channel = pusher.subscribe("chatdetailstatus");
    channel.bind(messageId, (data) => {
      //   if(data)
      // debugger
      let x = data;
      // console.log(JSON.parse(data.Body))
      console.log(data);
      // setChats(data);
    });

    return () => {
      //   pusher.unsubscribe("chatdetailstatus");
    };
  };

  //   const optInOut= (e)=>{
  //     if(e === 'Y'){
  //         setOptedOut(false)
  //     }else if( e=== 'N'){
  //         setOptedOut(true)
  //     }

  // }
  const getFiles = (e) => {
    console.log('inside')
    const files: any = Array.from(e.target.files)
    debugger
    let temp: any = [];
    let totalsize:any=0;
    attachedFiles.map((x:any) => {
        temp.push(x)
        totalsize+=x.size
        console.log(temp)
    })
    files.map((e: any) => {
        e.testTime = new Date();
        temp.push(e)
        totalsize+=e.size
        console.log(temp)
    })
    // temp=temp.concat(files);
    debugger
   
    console.log(temp)
    let { errorMesg } = validateGetfiles(temp);
    debugger
    if(totalsize>100000000){
      let t:any = [];
      errorMessages.map((x:any)=>{
          t.push(x);
          return x;
      })
      
      t.push('File size exceeds 100MB');
      setErrorMessages(t)
      setTimeout(
          function () {
              setErrorMessages([])
          }
          ,
          2000
      );
  }
   else if (temp.length > 10) {
        // footerErrorMsg.push('Max 10 files are allowed');
        let errmsg = "Max 10 files are allowed";
        // errorMessages.push("Max 10 files are allowed");
        // console.log('Max 10 files are allowed');
        let t:any = [];
        errorMessages.map((x:any)=>{
            t.push(x);
            return x;
        })
        
        t.push('Max 10 files are allowed');
        setErrorMessages(t)
        setTimeout(
            function () {
                setErrorMessages([])
            }
            ,
            2000
        );
        
    }
    else {
        setAttachedFiles(temp)

        console.log("attachedFiles : ", attachedFiles)
        console.log("hello world : ", attachedFiles.length);
    }
    if (errorMesg) {
      // temp.pop(e.target.files)
      setDisableSendButton(true)
        debugger
        // this.footerErrorMsg.push(errorMesg);
        let t:any = [];
        errorMessages.map((x:any)=>{
            t.push(x);
            return x;
        })
        t.push(errorMesg);
        setErrorMessages(t)
        setTimeout(
            function () {
                setErrorMessages([])
            }
            ,
            2000
        );
    }
    let doc:any=document
    doc.getElementById('attachedfiles').value = "";
    // setTimeout(() => {
    //     this.footerErrorMsg = [];
    // }, 5000);
    // e.target.value = null;
}
  const validateGetfiles = (files) => {
    //   let outputFiles = [];
    //   let fileSize = 0;

    setDisableSendButton(false)
    let errorMesg = "";
    files = Array.from(files);
    let file;
    for (var i = 0; i < files.length; i++) {
      file = files[i];
      let fileExt = file.name.split(".").pop().toLowerCase();
      // console.log(this.fileType.includes(file.type), this.fileSupportedExt.includes(fileExt))
      console.log("fileExt : ", fileExt);
      console.log("fileExt : ", fileSupportedExt);
      console.log("include file ext : ", fileSupportedExt.includes(fileExt));

      console.log("filetype : ", file.type);
      console.log("filetype : ", fileType);
      console.log("file type list : ", fileType.includes(file.type));
      if(files.length < 10){
      if (fileType.includes(file.type) && fileSupportedExt.includes(fileExt)) {
        // check non image file size if its greater that 675000b throw a error and remove the file;
        // files.push(file);
        // fileSize += file.size;
      }
      else if(file.type === "image/tiff" || file.type ==="audio/amr" || fileExt=== "amr" || file.type ==="" ){                
               
        errorMesg = file.name + " is not supported";  
        setDisableSendButton(true);
      }
       else {
        // this.isFileTypeSupported= false;
        errorMesg = file.name + " is not supported";
        // errorMessages.push(file.name + " is not supported")
        // let t = errorMessages;
        // t.push("filetype is not supported");
        // setErrorMessages(t)
        setDisableSendButton(true);
      }} else{
        if(files.length > 10 && (file.type === "image/tiff" || file.type ==="audio/amr" || fileExt=== "amr")){

          errorMesg = file.name + " is not supported and Max 10 files are allowed"; 
      }
      }
    }
    return { errorMesg };
  };
  // supportedFileType() {

  //   return this.fileType;
  // }
  const selectedTemplate = (tempData,contactData) => {
    // if(data['Recipient Sender Typ']=="EMP"){
    //     formData.append("recipient_id", data['Recipient Id']);
    //     formData.append("recipient_name", data['Recipient Name']);
    //     formData.append("recipient_phone", data['Phone #']);
    //     formData.append("template",templateData.templateVal);
    //     formData.append("Campaign_ID",templateData.campaginId.toString());
    //     formData.append("template_id",templateData.id.toString());
    //     formData.append('wave_id',templateData.waveId.toString())
    //    formData.append('product_id',templateData.productId.toString())

    //     formData.append("physcian_id",data['Recipient Id'] );
    //    formData.append("role_id", '0');//for prescriber
    //    if (!isSMS) {
    //     formData.append("channel", "SMS");
    //    } else {
    //     formData.append("channel", "whatsa");
    //    }
    //    sendMessageApi(formData,(res)=>{
    //     setPreApproveTemplates(false)
    //    })
    //    axios.post(config.REACT_APP_MESSAGE_API_BASE+"/message",formData,{
    //     params:{"Authorization":"Bearer "+sessionStorage.getItem('login')}
    // }).then((res)=>{
    //     setPreApproveTemplates(false)
    // }).catch(res=>{
    //     setPreApproveTemplates(false)
    //     console.log(res)
    // })
    // }
  
    let templateData: any = tempData.templateData
    console.log(contactData);
    let cData = contactData ? contactData : {};
    let phone = getNumberFromItem(contactData);
  //   if(!phone){
  //     setNoNumber(true)
  //     setTempTemplateDataIfNoNumber(tempData);
  //     setShowDetails(true)
  //     dispatch(setShowErrorSnackBar(true));
  //     return; 
  // }
  // setNoNumber(false)
  // setTempTemplateDataIfNoNumber(null);
    let formData = new FormData();
    // debugger
    formData.append("recipient_id", chatId);
    formData.append("recipient_name", chatName);
    formData.append("recipient_phone", phone);
    if (chatIsPrescriber) {
      formData.append("physcian_id", chatId);
      formData.append("role_id", "0"); //for prescriber
    } else {
      formData.append("physcian_id", cData.Prescribers[0].Id);
      formData.append("role_id", "1"); //for prescriber
      formData.append("association_id", cData.Prescribers[0].Association_Id);
    }

    // debugger
    formData.append("Campaign_ID", default_campaign_id); //templateData.campaginId.toString());
    formData.append("wave_id", templateData.mainWaveId.toString()); //templateData.waveId.toString())
    formData.append("product_id", templateData.productId.toString());

    if (!isSMS) {
      formData.append("channel", "SMS");
    } else {
      formData.append("channel", "whatsa");
    }
    if (attachedFiles.length > 0) {
      for (var i = 0; i < attachedFiles.length; i++) {
        let file = attachedFiles[i];
        formData.append("file", file);
      }
    }

    if (templateData.FormId) {
      let formPay = {
          "formId": templateData.FormId,
          "fieldDetails": tempData.formData,
          "recipientId": chatId,
          "recipientType": chatIsPrescriber ? "PRESCR" : 'ASSPRS'
      }
      axios.post(config.REACT_APP_FORMS_API_BASE + '/formRequest', formPay).then((res: any) => {
          console.log(res.data)
          let temptest = templateData.TemplateBody.toString();
          
          if (temptest.indexOf('shorturl') >= 0)
              formData.append("template", temptest.toString().replace('[shorturl_1]', res.data.FormUrl));
          else
              formData.append("template", temptest + " " + res.data.FormUrl);
          formData.append('urls', res.data.FormUrl)
          let qtPayload={
              quantityUsed:tempData.formData[0].valueOfFieldToAskFromEmp,
              prescriberId:chatId,
              productId:templateData.productId.toString()
            }
            axios.post(config.REACT_APP_CAMPAIGN_API_BASE+'/srf/updateQauntity',qtPayload).then((res:any)=>{
              
            })
          //   sendMessageForPresOrOthers(formData);
          sendMessageApi(formData, (res, msgData) => {
            console.log(msgData);
            setAttachedFiles([]);
            setChatInput("");
      
            // updateElSize({ target: { value: "" } });
            setPreApproveTemplates(false);
            if (res) {
              getMessageData(props.messageData, false, 1);
              dispatch(setRefreshChatList(true));
              dispatch(setShowSuccessSnackBar(true));
            }
            else{
              dispatch(setShowErrorSnackBar(true));
            }
          });
      })
          .catch(err => {
              dispatch(setShowErrorSnackBar(true));
          })
  }
  else {
      formData.append("template_id", templateData.TemplateId.toString());
      formData.append("template", templateData.TemplateBody);
      sendMessageApi(formData, (res, msgData) => {
        console.log(msgData);
        setAttachedFiles([]);
        setChatInput("");
  
        // updateElSize({ target: { value: "" } });
        setPreApproveTemplates(false);
        if (res) {
          getMessageData(props.messageData, false, 1);
          dispatch(setRefreshChatList(true));
          dispatch(setShowSuccessSnackBar(true));
        }
        else{
          dispatch(setShowErrorSnackBar(true));
        }
      });
  }
   
  };
  const saveUnknown = (data: any) => {
    // debugger
    if (data.Id) {
      dispatch(setChatType(data.Is_Prescriber == "Y" ? "PRESCR" : config.REACT_APP_ENABLE_HOSPITAL_CONTACT &&
        config.REACT_APP_CAMP_LIST_ENABLE_HOSPITAL_CONTACT == camp ? "ASSHSP" : "ASSPRS"));
      dispatch(
        setChatDetails({
          chatId: data.Id,
          chatName: data.AcctFull_Nm,
          chatIsPrescriber: data.Is_Prescriber == "Y",
          showChat: true,
        })
      );
      dispatch(setRefreshChatList(true));
      dispatch(setRefreshChatComponent(true));
    }
  };

  const getMessageData = (data, old, p) => {
    // debugger
    let id = chatId;
    // if(data['Recipient Sender Typ']=="EMP"){
    //     id=data['Recipient Id'];
    // }
    // else{
    //     id=data['Recipient Sender Id'];
    // }
    // if(id && id!=0)

    getConversationDetailsApi({ page: p, limit, id, type: type }, (res) => {
      debugger;
      window.localStorage.setItem('sentFromWindow',"false");
      if (res.length>0&&res[0]['Error'] || res.length==0) {
        setHasMore(false);
      }
      let t = res.reverse();
      // let t = res;
      if (old) {
        t = messageData.concat(t);
      }
      //  debugger
      for (let chatIndex = 0; chatIndex < t.length; chatIndex++) {
        if (chatIndex == 0) {
          t[chatIndex].showDateBanner = true;
        } else if (chatIndex != t.length - 1) {
          if (
            moment(t[chatIndex]["Recipient Msg Start"]).format("MM/DD/YYYY") !=
            moment(t[chatIndex - 1]["Recipient Msg Start"]).format("MM/DD/YYYY")
          ) {
            t[chatIndex].showDateBanner = true;
          } else {
            t[chatIndex].showDateBanner = false;
          }
        } else if (chatIndex == t.length - 1) {
          if (
            moment(t[chatIndex]["Recipient Msg Start"]).format("MM/DD/YYYY") ==
            moment(t[chatIndex - 1]["Recipient Msg Start"]).format("MM/DD/YYYY")
          ) {
            t[chatIndex].showDateBanner = false;
          } else {
            t[chatIndex].showDateBanner = true;
          }
        }
      }
      // t=t.sort((b:any,a:any)=>new Date(b['Recipient Msg Start']).getDate()-new Date(a['Recipient Msg Start']).getDate())
      //  for (let chatIndex = 0;chatIndex < t.length;chatIndex++) {
      //      debugger
      //      if (chatIndex==t.length-1)
      //      {
      //        t[chatIndex].showDateBanner = true;
      //      }

      //      else if (chatIndex != 0) {
      //        if (
      //          moment(t[chatIndex]['Recipient Msg Start']).format(
      //            "MM/DD/YYYY"
      //          ) !=
      //          moment(t[chatIndex + 1]['Recipient Msg Start']).format(
      //            "MM/DD/YYYY"
      //          )
      //        ) {
      //          t[chatIndex].showDateBanner = true;
      //        } else {
      //          t[chatIndex].showDateBanner = false;
      //        }
      //      }
      //      else if (chatIndex == 0 &&  t.length>1) {

      //        if (
      //          moment(t[chatIndex]['Recipient Msg Start']).format("MM/DD/YYYY") == moment(t[chatIndex + 1]['Recipient Msg Start']).format( "MM/DD/YYYY")
      //        ) {
      //          t[chatIndex].showDateBanner = false;
      //        } else {
      //          t[chatIndex].showDateBanner = true;
      //        }
      //      }

      //    }

      console.log(t);
      //  getHeight()
      if (res.length>0&&res[0]['Error']==undefined) {
      setMessageData(t);
      }
      let tt = t.filter(x => x[ 'Direction' ] == "incoming")
      let optOutIndex;
      let optOutIndexArray = new Array();
      let optInIndexArray = new Array();
      let optInIndex;
      let tr = tt.reverse();
      if (tt.length>0){             
          for(var i = 0; i < optOutKeywords.length; i++){
              
              optOutIndex = tr.findIndex(x => x[ 'Recipient Msg Body' ].toLowerCase() == optOutKeywords[i].toLowerCase());

              
              if (optOutIndex >= 0) {
                  optOutIndexArray.push(optOutIndex)
                  
              }
              else{
                  optOutIndex = 100000000;
                  optOutIndexArray.push(optOutIndex);
              }
          }
          for(var j = 0; j < optInKeywords.length; j++){
              optInIndex = tr.findIndex(x => x[ 'Recipient Msg Body' ].toLowerCase() == optInKeywords[j].toLowerCase());                  
              if (optInIndex >= 0) {
                  optInIndexArray.push(optInIndex)


              }
              else{
                  optInIndex = 100000000;
                  optInIndexArray.push(optInIndex);
              }
          }
          var smallestOptOutIndex = optOutIndexArray.sort((a, b) => a - b);
          var smallestOptInIndex = optInIndexArray.sort((a, b) => a - b);
          if(smallestOptOutIndex[0] < smallestOptInIndex[0]){
              setOptedOut(true);  
              console.log("lllllooooo", optedOut)
              // if(!optedOut)  {
              //   console.log("lllllooooo")
              //   setOptedOut(false); 
              // }         
          }
          else{
              setOptedOut(false);
          }

      } 
      //  scrollIntoView(docElement.querySelector('.msg-row'), { behavior: "smooth" });
      //  scrollTOBottom()
    });
    // axios.get(config.REACT_APP_MESSAGE_API_BASE+"/conversations/details?&page="+p+"&limit="+limit+"&recipientId="+id,{
    //     headers:{
    //       'Authorization':'Bearer '+sessionStorage.getItem('login')
    //     }
    //   }).then((res:any)=>{
    //     debugger
    //     if(res.data.length==0){
    //         setHasMore(false)
    //     }
    //     // if(data['Recipient Sender Typ']=="EMP"){
    //     //     setName(data['Recipient Name']);
    //     // }
    //     // else
    //     // {
    //     //     setName(data['Sender Name']);
    //     // }
    //     //debugger

    //     let t=res.data.reverse();
    //     if(old){
    //         t=t.concat(messageData)
    //     }
    //     for (let chatIndex = 0;chatIndex < t.length;chatIndex++) {
    //         if (chatIndex == 0) {
    //           t[chatIndex].showDateBanner = true;
    //         } else if (chatIndex != t.length - 1) {
    //           if (
    //             moment(t[chatIndex]['Recipient Msg Start']).format(
    //               "MM/DD/YYYY"
    //             ) !=
    //             moment(t[chatIndex - 1]['Recipient Msg Start']).format(
    //               "MM/DD/YYYY"
    //             )
    //           ) {
    //             t[chatIndex].showDateBanner = true;
    //           } else {
    //             t[chatIndex].showDateBanner = false;
    //           }
    //         } else if (chatIndex == t.length - 1) {
    //           if (
    //             moment(t[chatIndex]['Recipient Msg Start']).format(
    //               "MM/DD/YYYY"
    //             ) ==
    //             moment(t[chatIndex - 1]['Recipient Msg Start']).format(
    //               "MM/DD/YYYY"
    //             )
    //           ) {
    //             t[chatIndex].showDateBanner = false;
    //           } else {
    //             t[chatIndex].showDateBanner = true;
    //           }
    //         }
    //       }

    //     setMessageData(t);
    //   })
  };
  const scrollTOBottom = () => {
    if (
      docElement.getElementsByClassName("mainchat") &&
      docElement.getElementsByClassName("mainchat").length > 0
    ) {
      // let docElement:any=docElementument
      // console.log('hi');
      //document.getElementsByClassName('mainchat')[document.getElementsByClassName('mainchat').length-1].scrollTop=document.getElementsByClassName('mainchat')[document.getElementsByClassName('mainchat').length-1].scrollHeight
      //  setTimeout({docElement.getElementsByClassName('infinite-scroll-component')[docElement.getElementsByClassName('infinite-scroll-component').length-1].scrollTop=0}, 0)
      // docElementument.getElementsByClassName('infinite-scroll-component')[docElementument.getElementsByClassName('infinite-scroll-component').length-1].scrollTop=0
      setTimeout(function () {
        if(document.getElementsByClassName("mainchat")){
          if(document.getElementsByClassName("mainchat").length>0)
        document.getElementsByClassName("mainchat")[
          document.getElementsByClassName("mainchat").length - 1
        ].scrollTop =
          document.getElementsByClassName("mainchat")[
            document.getElementsByClassName("mainchat").length - 1
          ].scrollHeight;}

          if(document.getElementsByClassName("msg-attachment")){
            let scroll:any = document.querySelector(".infinite-scroll-component")
            scroll.scrollIntoView(false);
          }
        //  docElement.getElementsByClassName('infinite-scroll-component')[docElement.getElementsByClassName('infinite-scroll-component').length-1].scrollTop=0;
        //   docElement.querySelector('.msg-row').scrollIntoView({   behavior: 'smooth' });
      }
        ,
        500
      );
    }

  };
  const updateElSize = (e) => {
    // getHeight()
    console.log(e)
    let doc: any = document;
    // getHeight()
    doc.getElementById("footerinput").style.height = "auto"
    if (e.target.value === "" || !e) {
      doc.getElementById("footerinput").style.height = "0px"
    }
    else {
      doc.getElementById('footerinput').style.height = doc.getElementById('footerinput').scrollHeight + "px"
      document.getElementsByClassName('mainchat')[document.getElementsByClassName('mainchat').length - 1].scrollTop = document.getElementsByClassName('mainchat')[document.getElementsByClassName('mainchat').length - 1].scrollHeight
      // doc.getElementById('scrollableDiv')
    }
    scrollTOBottom()
    // doc.getElementById('footerinput').style.height = "0px"
  };

  const resizeInputBox = ()=>{

    return '\t';
    
  }

  useEffect(()=>{
    console.log("opppppp", optedOut)

   if(optedOut){
    setMainChatBottom("0px")
   }
   
  }, [optedOut]);
  const checkNumber = (contactData: any) => {
    let phoneNumber = getNumberFromItem(contactData);
    if(Object.keys(contactData).length > 0){
      getPhoneNumberType({phone:phoneNumber}, (res)=>{
        if(res.type == "landline") {
          setIsNumberLandline(true)
        } else {
          setIsNumberLandline(false)
        }
      })
    }
  }
  useEffect(()=>{
    debugger
    if(unknown && type=='ASSPRS'){
        setDeletedOthers(true)
    }else{
        setDeletedOthers(false)
    }
  },[unknown])
  useEffect(() => {
    //   // scrollTOBottom()
    //   getHeight()

    if(config.REACT_APP_IS_CROSS_PLATFORM) {
      Keyboard.addListener('keyboardWillShow', info => {
        // console.log('keyboard will show with height:', info.keyboardHeight);
         document.getElementById('main')?.setAttribute('style', 'padding-bottom: 0 !important');
        
      });
      
      Keyboard.addListener('keyboardDidShow', info => {
        calculateHeight();
      });
      
      Keyboard.addListener('keyboardWillHide', () => {
        // console.log('keyboard will hide');
        document.getElementById('main')?.removeAttribute('style');
      });
      
      Keyboard.addListener('keyboardDidHide', () => {
        // console.log('keyboard did hide');
        calculateHeight();
      });
    }
    let doc:any=document; 
    let div: any = document.querySelector("#main");
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    setMobileFooterBottom(divpaddingbottom);
    setMobileHeaderTop(divpaddingTop)
    if(doc.getElementsByClassName('header-wrapper')[0]){
      setMainChatTop(doc.getElementsByClassName('header-wrapper')[0].clientHeight)
   
    }    
    if(doc.getElementsByClassName('mobile-footer')[0]){
      console.log("Flag",optedOut)
     setMainChatBottom(doc.getElementsByClassName('mobile-footer')[0].clientHeight)
    } else{
      setMainChatBottom("0px")
    }
    window.addEventListener("resize", (e) => {
      debugger
      let div: any = document.querySelector("#main");
      let divpaddingTop: any = parseInt(
        window.getComputedStyle(div, "").getPropertyValue("padding-top")
      );
      let divpaddingbottom: any = parseInt(
        window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
      );
      let divpaddingleft: any = parseInt(
        window.getComputedStyle(div, "").getPropertyValue("padding-left")
      );
      let divpaddingright: any = parseInt(
        window.getComputedStyle(div, "").getPropertyValue("padding-right")
      );

      setChatHeight(
        window.innerHeight -
        (divpaddingTop +
          divpaddingbottom)
      );
      setChatwidth(window.innerWidth - (divpaddingright + divpaddingleft));
      getHeight();
      scrollTOBottom();
    });
    //   return ()=>{
    //     window.removeEventListener('resize',(e)=>>{
    //         getHeight()
    //     })
    //   }
    
  }, []);

  useEffect(() => {
    
    window.addEventListener('orientationchange', orientationChange)
    if (
      config.REACT_APP_ENABLE_HOSPITAL_CONTACT &&
      config.REACT_APP_CAMP_LIST_ENABLE_HOSPITAL_CONTACT == camp
    ) {
      setIsHospitalContact(true)
    } else {
      setIsHospitalContact(false)
    }
    return () => {
      window.removeEventListener('orientationchange', orientationChange)
    }
  }, [])

  function orientationChange() {
    let doc:any=document; 
    if(doc.getElementsByClassName('header-wrapper')[0]){
      setMainChatTop(doc.getElementsByClassName('header-wrapper')[0].clientHeight)
   
    }    
    if(doc.getElementsByClassName('mobile-footer')[0]){
      console.log("Flag 2",optedOut)

      setMainChatBottom(doc.getElementsByClassName('mobile-footer')[0].clientHeight)
    } else{
      setMainChatBottom("0px")
    }
    let div: any = document.querySelector("#main");
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let divpaddingleft: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-left")
    );
    let divpaddingright: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-right")
    );

    setChatHeight(
      window.innerHeight -
      (divpaddingTop +
        divpaddingbottom)
    );
    setChatwidth(window.innerWidth - (divpaddingright + divpaddingleft));
    getHeight();
    scrollTOBottom();
  }

  const calculateHeight = () => {
    let div: any = document.querySelector("#main");
        let divpaddingTop: any = parseInt(
          window.getComputedStyle(div, "").getPropertyValue("padding-top")
        );
        let divpaddingbottom: any = parseInt(
          window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
        );
        let divpaddingleft: any = parseInt(
          window.getComputedStyle(div, "").getPropertyValue("padding-left")
        );
        let divpaddingright: any = parseInt(
          window.getComputedStyle(div, "").getPropertyValue("padding-right")
        );
        setPaddingValTop(divpaddingTop);
        setPaddingValBottom(divpaddingbottom);
        setPaddingValRight(divpaddingleft);
        setPaddingValLeft(divpaddingright);

        // console.log("Padding"+ divpadding);
        console.log("Innerwidth" + window.innerHeight);

        if (
          document.getElementsByClassName("mobile-footer") &&
          document.getElementsByClassName("mobile-footer").length > 0
        )
          setChatHeight(
            window.innerHeight -
            (divpaddingTop +
              divpaddingbottom)
          );
        else setChatHeight(window.innerHeight - (divpaddingTop + divpaddingbottom));
        setChatwidth(window.innerWidth - (divpaddingright + divpaddingleft));
        scrollTOBottom();
  }
  const getHeight = () => {
    debugger
    let doc: any = document;
    let div: any = document.querySelector("#main");
    let divpaddingbottom: any = parseInt(	
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")	
    );	
    let divpaddingTop: any = parseInt(	
      window.getComputedStyle(div, "").getPropertyValue("padding-top")	
    );	
    setMobileFooterBottom(divpaddingbottom);	
    setMobileHeaderTop(divpaddingTop)

    if (doc.getElementsByClassName("mainchat")[0]) {
      let ht = doc.getElementsByClassName("mainchat")[0].clientHeight;
      let footertop;

      let headerbottom = document.getElementsByClassName('header-wrapper')[0].clientHeight;
      if (doc.getElementsByClassName("mobile-footer")[0]) {
        footertop=doc.getElementsByClassName('mobile-footer')[0].offsetTop;
        if(config.REACT_APP_IS_CROSS_PLATFORM) {
          doc.getElementsByClassName('mainchat')[0].style.height= "100%"
        } else {
          doc.getElementsByClassName('mainchat')[0].style.height=(footertop+headerbottom)+"px"
        }
      }
      // debugger;
      else{
        if(config.REACT_APP_IS_CROSS_PLATFORM) {
          doc.getElementsByClassName('mainchat')[0].style.height= "100%"
        } else {
          doc.getElementsByClassName('mainchat')[0].style.height=(headerbottom)+"px"
        }
        
      }
     

      //   setInfiniteScrollHeight((footertop-headerbottom));
    }
  };
  useEffect(() => {
    console.log(chatId);
    debugger;
    console.log("inchatId");
    if (showChat && chatId) {
      setHasMore(true);
      setAttachedFiles([])
      getMessageData(props.messageData, false, 1);
      getUserInfo();
    }
    let doc: any = document;
    // checkFirstMessages();

    // console.log(ht);

    //  if(doc.getElementsByClassName('mainchat')[0])
    //   {

    //     let headerbottom=doc.getElementsByClassName('header')[0].offsetTop+document.getElementsByClassName('header')[0].clientHeight;
    //     let footertop=doc.getElementsByClassName('footer')[0].offsetTop;
    //     // doc.getElementsByClassName('mainchat')[0].style.height=(footertop-headerbottom)+"px"

    //     doc.getElementsByClassName('mainchat')[0].style.height=(footertop-headerbottom)+"px"
    //     // console.log(calValue)
    //     // setMaichatscrollHeight( calValue)
    //     // let ht=doc.getElementsByClassName('mainchat')[0].clientHeight
    //   // console.log(ht);
    //   setInfiniteScrollHeight(footertop-headerbottom);
    //   }

    // let doc:any=document;
    // doc.getElementById('headerVal').style.display="none !important";
    // doc.getElementById('MobHeader').style.display="none !important";
    // doc.getElementsByClassName('chatListDiv')[0].style.display="none !important";
    return () => {
      setMessageData([]);
    };
  }, [chatId, showChat]);
  useEffect(()=>{
    console.log(chatId.toString(),chatPusher)
    if(chatPusher && chatPusher.bind){
        
        chatPusher.unbind(chatId.toString());
        //debugger

        chatPusher.bind(chatId.toString(), (data) => {
            
            let t=window.localStorage.getItem('sentFromWindow')
            if(t!="true")
            {
                dispatch(setRefreshChatList(true))
                dispatch(setRefreshChatComponent(true))
            }
        }) 
    }
},[chatId,chatPusher])
  // useEffect(()=>{
  //   console.log('i am chaned')
  //   let doc:any=document

  //   if( doc.getElementsByClassName('mainchat') &&doc.getElementsByClassName('mainchat').length>0)
  //   {
  //     console.log('hi')
  //     doc.getElementsByClassName('mainchat')[doc.getElementsByClassName('mainchat').length-1].scrollTop=0
  //   }
  // },[messageData])

  const ChatPageHeight = () => {
    // let div:any=document.querySelector('#main')
    // let divpaddingTop:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
    // let divpaddingbottom:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
    // let divpaddingleft:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-left'));
    // let divpaddingright:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-right'));
    // setPaddingValTop(divpaddingTop);
    // setPaddingValBottom(divpaddingbottom);
    // setPaddingValRight(divpaddingleft);
    // setPaddingValLeft(divpaddingright);

    // console.log("Padding"+ divpadding);
    console.log("Innerwidth" + window.innerHeight);
    //   if(doc.getElementsByClassName('besttime-banner')[0]!=undefined&&doc.getElementsByClassName('footer')[0]!=undefined)
    //   { debugger;
    //   console.log("Banner"+doc.getElementsByClassName('besttime-banner')[0].offsetHeight);
    //   console.log("Header"+doc.getElementsByClassName('header')[0].offsetHeight);
    //   console.log("search"+doc.getElementsByClassName('footer')[0].offsetHeight);

    //  setChatHeight(window.innerHeight-(divpadding+divpadding))
    //   }
    // setChatHeight(window.innerHeight-(divpaddingTop+divpaddingbottom))
    //  setChatwidth(window.innerWidth-(divpaddingright+divpaddingleft))
  };
  const getNumberFromItem = (item) => {
    if(item){
    if (item.AcctPh_Nbr) {
      if (item.AcctPh_Nbr.indexOf("+") >= 0) {
        return item.AcctPh_Nbr;
      } else return "+" + item.IntlCallPfx_Nbr + item.AcctPh_Nbr;
    } else return "";
  }
  else return "";
  };

  const optInOut = (e) => {
    if (e == "Y") {
      setOptedOut(false);
      console.log("optout",e)

    } else if (e == "N") {
      setOptedOut(true);
      console.log("optout",e)

    }
  };
  useEffect(() => {
    debugger;
    if (refreshChatComponent) {
      setAttachedFiles([])
      getMessageData(props.messageData, false, 1);
      dispatch(setRefreshChatComponent(false));
    }
  }, [refreshChatComponent]);
  const sendMessage = (contactData) => {
    window.localStorage.setItem('sentFromWindow',"true");
    // document.querySelectorAll('textarea').forEach((element) => {
    //     element.blur();
    // });
    setDisableSendButton(true);
    console.log(contactData);
    let cData = contactData ? contactData : {};
    let phone = getNumberFromItem(contactData);
    // setTempTemplateDataIfNoNumber(null);
    // if(!phone){
    //     setNoNumber(true)
    //     setShowDetails(true)
    //     dispatch(setShowErrorSnackBar(true));
    //     return; 
    // }
    // setNoNumber(false)
    let formData = new FormData();

    formData.append("recipient_id", chatId);
    formData.append("recipient_name", chatName);
    formData.append("recipient_phone", phone);
    if (chatIsPrescriber) {
      formData.append("physcian_id", chatId);
      formData.append("role_id", "0"); //for prescriber
    } else {
      formData.append("physcian_id", cData.Prescribers[0].Id);
      formData.append("role_id", "1"); //for prescriber
      formData.append("association_id", cData.Prescribers[0].Association_Id);
    }

    formData.append("template", chatInput);
    formData.append("Campaign_ID", default_campaign_id);
    formData.append("wave_id", default_wave_id);
    formData.append("product_id", default_product_id);

    if (!isSMS) {
      formData.append("channel", "SMS");
    } else {
      formData.append("channel", "whatsa");
    }
    if (attachedFiles.length > 0) {
      setShowAttachLoader(true);
      for (var i = 0; i < attachedFiles.length; i++) {
        let file = attachedFiles[i];
        formData.append("file", file);
      }
    }

    // sendMessageApi(formData,(res, msgData)=>{
    //     console.log(msgData)
    //     setAttachedFiles([]);
    //     setChatInput('')
    //    })

    sendMessageApi(formData, (res, msgData) => {
      console.log(res);
      debugger;
      if (msgData) {
        if (msgData.message === "Inappropriate message body") {
          setDisableSendButton(false);
          setShowAttachLoader(false);
          console.log(msgData.message);
          let t: any = [];
          t.push(msgData.message);
          errorMessages.map((x) => {
            t.push(x);
            return x;
          });
          setErrorMessages(t);
          setTimeout(function () {
            setErrorMessages([]);
          }, 2000);
          debugger;
        }
      } else if (res) {
        // console.log(msgData.message)
        setShowAttachLoader(false);
        // updateElSize('');
        setDisableSendButton(false);
        setAttachedFiles([]);
        setChatInput("");
        getMessageData(props.messageData, false, 1);
        dispatch(setRefreshChatList(true));
        dispatch(setShowSuccessSnackBar(true));
        let doc:any=document;
        doc.getElementById('footerinput').style.height="0px"
        scrollTOBottom();
      } else {
        setDisableSendButton(false);
        setShowAttachLoader(false);
        setAttachedFiles([]);
        setChatInput("");
        dispatch(setShowErrorSnackBar(true));
      }
    });

    //    axios.post(config.REACT_APP_MESSAGE_API_BASE+"/message",formData,{
    //     params:{"Authorization":"Bearer "+sessionStorage.getItem('login')}
    // }).then((res)=>{

    //     setAttachedFiles([]);
    //     setChatInput('')
    // }).catch(res=>{
    //     setAttachedFiles([]);
    //     setChatInput('')
    // })

    //     else{
    //         debugger
    //         formData.append("recipient_id", data['Recipient Sender Id']);
    //         formData.append("recipient_name", data['Sender Name']);
    //         formData.append("recipient_phone", contactData['AcctPh_Nbr']);
    //         formData.append("template",chatInput);
    //         formData.append("Campaign_ID",'1000');
    //         formData.append('wave_id','1000')
    //        formData.append('product_id','1000');
    //         formData.append("physcian_id",contactData['Prescriber_Id'] );
    //        formData.append("role_id", '0');//for prescriber
    //        if (!isSMS) {
    //         formData.append("channel", "SMS");
    //        } else {
    //         formData.append("channel", "whatsa");
    //        }

    //    if(attachedFiles.length>0){
    //     for (var i = 0; i < attachedFiles.length; i++) {
    //         let file = attachedFiles[i];
    //         formData.append("file", file);
    //       }
    //    }

    //    sendMessageApi(formData,(res)=>{
    //     setAttachedFiles([]);
    //     setChatInput('')
    //     doc.getElementById("footerinput").style.height = "auto"
    //    })
    //     //    axios.post(config.REACT_APP_MESSAGE_API_BASE+"/message",formData,{
    //     //     params:{"Authorization":"Bearer "+sessionStorage.getItem('login')}
    //     // }).then((res)=>{

    //     //     setAttachedFiles([]);
    //     //     setChatInput('')
    //     //     doc.getElementById("footerinput").style.height = "auto"
    //     // }).catch(res=>{
    //     //     setAttachedFiles([]);
    //     //     setChatInput('')
    //     // })
    //     }

    //  formData.append("physcian_id", data.physcian_id);
    //  formData.append("role_id", data.type);
    //  formData.append("template", data.template);
    //  formData.append("Campaign_ID", 1000);
    //  formData.append("template_id");
    //  formData.append('wave_id',data.)

    //  if (this.switchChecked) {
    //    formData.append("communicationType", "whats app");
    //  } else {
    //    formData.append("communicationType", "sms");
    //  }
    //  // formData.append("file", this.files);
    //  for (var i = 0; i < this.files.length; i++) {
    //    let file = this.files[i];
    //    formData.append("file", file);
    //  }
  };


  function truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }

  if (props.showActiveChatPage) {
    return (
      <div
        className="mobileChatPage msg-component-div"
        style={{
          width: chatWidth + "px",
          height: chatHeight + "px",
          // top: "0px",
        }}
      >
        {showDetails && !unknown ? 
        isHospitalContact?
        (
          <ContactDetialsModalGAM
            showChatIcon={false}
            showDetails={showDetails}
            showAddContactModal={(e, trueorFalse) => {setContactData(e);setAddNumberPopup(true)}}
            contact={contactData}
            handleCloseParent={(post: any, showDelete: any) => {
              setContactData(post);
              getUserInfo();
              setShowDetails(false);
              setDisableSendButton(false);
              setFinished(false);
              // if (noNumber && post.AcctPh_Nbr) {
              //   tempTemplateDataIfNoNumber
              //     ? selectedTemplate(tempTemplateDataIfNoNumber, post)
              //     : sendMessage(post);
              // }
            }}
            fetchDetails={true}
            showCallMakerModal={(e) => {
              console.log(e);
              setShowDetails(false);
              dispatch(setCallModalView(true));
            }}
          />
        ) 
        :
        (
          <ContactDetialsModal
          showChatIcon={false}
          showDetails={showDetails}
          showAddContactModal={(e, trueorFalse) => {setContactData(e);setAddNumberPopup(true)}}
          contact={contactData}
          handleCloseParent={(post: any, showDelete: any) => {
            setContactData(post);
            getUserInfo();
            setShowDetails(false);
            setDisableSendButton(false);
            setFinished(false);
            // if (noNumber && post.AcctPh_Nbr) {
            //   tempTemplateDataIfNoNumber
            //     ? selectedTemplate(tempTemplateDataIfNoNumber, post)
            //     : sendMessage(post);
            // }
          }}
          fetchDetails={true}
          showCallMakerModal={(e) => {
            console.log(e);
            setShowDetails(false);
            dispatch(setCallModalView(true));
          }}
            />
        )
        
        : null}

        <CallModal
          // show={showCallModal}
          data={contactData}
          handleCloseParent={(e) => {
            console.log(e);
            setShowDetails(false);
            // setShowCallModal(false)
            dispatch(setCallModalView(false));
          }}
        />
        {openSaveContacts ? (
          isHospitalContact ?
          <SaveContactsForChatGAM
            unknownid={chatId}
            number={chatName}
            deletedOthers={deletedOthers}
            show={openSaveContacts}
            handleCloseParent={(torf, data: any) => {
              debugger;
              console.log(data);
              saveUnknown(data);
              setOpenSaveContacts(false);
            }}
          />:
          <SaveContactsForChat
            unknownid={chatId}
            number={chatName}
            deletedOthers={deletedOthers}
            show={openSaveContacts}
            handleCloseParent={(torf, data: any) => {
              debugger;
              console.log(data);
              saveUnknown(data);
              setOpenSaveContacts(false);
            }}
          />
        ) : null}
        <PreApprovedTemplates
          show={showPreApproveTemplates}
          selectedTemplate={(e) => {
            selectedTemplate(e, contactData);
            updateElSize('');
          }}
          handleCloseParent={(e) => {
            debugger;
            setPreApproveTemplates(false);
          }}
          contact={contactData}
        />
        <div className="header-wrapper">
          {/* Add Key vault  */}
          <div
            className="besttime-banner"
            style={{ display: BestTimeFlag === "Y" ? "block" : "none" }}
          >
            <h5 className="bannertext m-0">
              <b> {t('Best time to connect: 10:00 AM - 03:30 PM')}</b>
            </h5>
          </div>

          <div className="header d-flex">
            <Link 
                
                
                onClick={()=>{
                  dispatch(setChatShowChat(false)); 
                  // dispatch(setRefreshChatList(true));
                }}
                to={window.location.href=="/Home"?"/Chat":pageSelected}
              >
              <i
              className="fas fa-chevron-left i-2">
              
              {" "}
            </i>
            </Link>
            <div className="avatar avt-2 ms-3 m-2">
              {/* <Avatar {...stringAvatar(chatName)} /> */}
              <Avatar
                // imageType={
                //   chatIsPrescriber ? "prescriber" : "prescriberassociates"
                // }
                imageType={type}
                avatarHeight="2.5rem"
                avatarWidth="2.5rem"
                presOrAssoc={chatName}
              />
            </div>
            <div className="contact-details m-2"  onClick={(config.REACT_APP_SHOW_INFO_BTN == "N" && (!deletedOthers && finished || !deletedOthers && unknown))?() => { setShowDetails(true) }:()=>{}}>
              <h5 className="contacttext">
                {chatName ? truncate(getFormatedPhoneNumber(chatName), 15) : "Unknown"}
              </h5>
            </div>
            <div className="headerright">
              
            {messageChannelType === true ? (
                <label className="autopilot-switch font-sm me-3">
                  <input
                    type="checkbox"
                    className="font-sm"
                    id="msg-wtsappChechedId"
                    checked={isSMS}
                    onChange={(e) => {
                      setIsSms(e.target.checked);
                    }}
                  />
                  <span
                    id="msg-wtsapptoggle"
                    className="b-slider b-round"
                  ></span>
                </label>
              ) : null}

            {noNumber &&  !changeTerritoryId && !deletedOthers && finished 
        && !numberAssigned ?null:
                        (!deletedOthers && finished && !unknown) ?(
                          <Tooltip title={t('Call')} arrow>
                          <button className="header-callbtn" onClick={()=>{
                                   !noNumber && dispatch(setCallModalView(true))}
                                   }><CallIcon style={{width:"20px", height:"20px"}}/></button>
                             </Tooltip>
                    ):null
                    }
            
             
              {unknown ? (
                <Tooltip title="" arrow>
                  <SaveIcon
                    onClick={() => {
                      setOpenSaveContacts(true);
                    }}
                    style={{ fill: "var(--primary)", fontSize: "2.5rem",marginLeft:'1rem' }}
                  />
                </Tooltip>
               ) :
               config.REACT_APP_SHOW_INFO_BTN == "Y" ? 
               (<div>
              {((changeTerritoryId == true && type=='ASSPRS') || changeTerritoryId == false) && finished ? <Tooltip title={t('')} arrow>
              <i
                  id="infoBtnId"
                  data-v-4d521fc4=""
                  data-name="info"
                  data-tags=""
                  data-type="info"
                  className="feather feather--info i-2p5 p-0"
                  onClick={() => { setShowDetails(true) }}

              ><svg
                  style={{ color: '#734bd1',verticalAlign:'sup',display:'flex'  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  fill="none"

                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-info feather__content"
              >
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="12" y1="16" x2="12" y2="12"></line>
                      <line x1="12" y1="8" x2="12.01" y2="8"></line>
                  </svg>
              </i>
          </Tooltip>
              :null}
              </div>):
            null
        }
            </div>
          </div>
        </div>
        {noNumber &&  !changeTerritoryId && !deletedOthers && finished 
        && !numberAssigned
        ? 
        (
          <div className="limit-msg error-footer optOut">
            <ul>
              <li>{t("Assign number to reply")}.</li>
            </ul>
          </div>
        ) : null}
        {
                changeTerritoryId && !deletedOthers && type!='UNKN' && config.REACT_APP_ABS_CONFIG_SHOWBANNER=='Y'? (
                    <div className="limit-msg error-footer optOut">
                        <ul>
                            {type!='ASSPRS'?<li>
                                {t('No longer ABS approved')}.
                            </li>:<li>
                                {t('Contact needs ABS-approved association')}.
                            </li>}
                        </ul>

                    </div> ): null
            }
        {(unknown || type=="UNKN") && !deletedOthers ? (
          <div
            className="limit-msg error-footer optOut"
            // style={{ top: mainChatTop }}
          >
            <ul>
              <li>{t("Save contact to reply.")}</li>
            </ul>
          </div>
        ) : null}
        {deletedOthers 
        //  && !noNumber 
         && finished
         ?

         (
          <div className="limit-msg error-footer optOut">
            <ul>
              <li>{t("Contact has been deleted")}.</li>
            </ul>
          </div>
        ) : null}
        {optedOut && finished ? (
          <div
            className="limit-msg error-footer optOut"
            // style={{ top: mainChatTop }}
          >
            <ul>
              <li>{t("Recipient opted out")}.</li>
            </ul>
          </div>
        ) : null}
        {isNumberLandline ? (
          <div
            className="limit-msg error-footer optOut"
            // style={{ top: mainChatTop }}
          >
            <ul>
              <li>{t("This is a landline number")}</li>
            </ul>
          </div>
        ) : null}
        {/* main - all the msg conv*/}
        {showAttachLoader && <span className="loader"></span>}

        <div
          className="shadowin  mainchat"
          id="scrollableDiv"
          style={{
            // marginTop: mainChatTop,
            marginBottom: `${(noNumber||deletedOthers||unknown || isNumberLandline || optedOut || (changeTerritoryId && config.REACT_APP_ABS_CONFIG_ALLOWCHAT=='N') )?'unset':mainChatBottom+'px'}`,
            overflowY: "auto",
          }}
        >
          <InfiniteScroll
            dataLength={messageData.length}
            next={() => {
              console.log("next");
              setHasMore(false);
              //    debugger
              //    getMessageData(props.messageData,true,page+1)
              //    setPage(page+1)
            }}
            refreshFunction={() => {
              console.log("reverse");
            }}
            inverse={true} //
            style={{
              display: "flex",
              overflow: "hidden",
              flexDirection: "column",
              // flexDirection: "column-reverse",
            }}
            // scrollableTarget="contacts-body"
            hasMore={hasMore}
            loader={
              <div className="spinnerCircle">
                <CircularProgress color="inherit" size={"2rem"} />
              </div>
            }
            // height={infiniteScrollHeight}

            scrollableTarget="scrollableDiv"
            //  endMessage={
            //   <p style={{ textAlign: "center" }}>
            //     {messageData.length == 0 ? <span>No data</span> : <span></span>}
            //   </p>
            // }
          >
            {/* <div className='innerChat'> */}
            {messageData.map((x: any) => (
              <TestMessage
                message={x}
                key={x["Recipient Msg Start"]}
                msgPusherSubscriber={pusherMsgProps}
                handleImgPopup={(imgdisurl) => handleImgPopup(imgdisurl)}
                avatarchatName ={chatName}
              />
            ))}
            {/* </div> */}
          </InfiniteScroll>
          {/* {
                               messageData.map((x:any)=>(<Message message={x}/>))
                              } */}
        </div>
        {errorMessages.length > 0 ? (
          <div className="limit-msg error-footer error-footer-mobile" style={{top:disableSendButton?'-7.68rem':'-4.5rem'}}>
            <ul>
              {errorMessages.map((error: String) =>
                error === "" ? null : (
                  <li>
                    {error} <br />
                  </li>
                )
              )}
            </ul>
          </div>
        ) : null}

        {!optedOut && !unknown && !noNumber && !isNumberLandline && !(changeTerritoryId && config.REACT_APP_ABS_CONFIG_ALLOWCHAT=='N')
        ?
          (
          <div
            className="footer mobile-footer"
            style={{
              backgroundImage: `url(${Background})`,
              // bottom: mobileFooterBottom,
            }}
          >
            <div className="insidefooter d-flex" style={{ color: "var(--primary-color)" }}>
              <div style={{ display: "none" }}> {errorMessages.length}</div>
              <div
                onClick={() => {
                  console.log("hi");
                  setPreApproveTemplates(true);
                }}
              >
                <i className="btn far fa-newspaper i-2 get-template-btn"></i>
              </div>
              {config.REACT_APP_ATTACHMENT_ENABLED == "Y" && (
                <button
                  className="btn"
                  type="button"
                  onClick={() => {
                    let doc: any = document;
                    doc.getElementById("attachedfiles").click();
                  }}
                >
                  <i className="fas fa-paperclip i-2"></i>
                </button>
              )}
              <label>
                <input
                  type="file"
                  id="attachedfiles"
                  name="attachedfiles"
                  className="custom-file-input"
                  multiple
                  accept="application/pdf, application/vcard, text/vcard, text/x-vcard, text/csv, text/rtf, text/richtext, text/calendar, text/directory, image/bmp, image/tiff, image/jpeg, image/jpg, image/gif, image/png, video/H264, video/H263-2000, video/H263-1998, video/H263, video/H261, video/3gpp-tt, video/3gpp2, video/3gpp, video/webm, video/quicktime, video/mp4, video/mpeg, audio/basic, audio/L24, audio/mp4, audio/mpeg, audio/ogg, audio/vnd.rn-realaudio, audio/vnd.wave, audio/3gpp, audio/3gpp2, audio/ac3, audio/vnd.wave, audio/webm, audio/amr-nb, audio/amr"
                  style={{ display: "none" }}
                  onChange={getFiles}
                  // onClick={getFiles}
                />
              </label>
             
              {/* <input id='footerinput' type="text" placeholder="Type your message..." value={chatInput} onChange={(e)=>setChatInput(e.target.value)} /> */}
              <textarea
                id="footerinput"
                placeholder={t(`Type your message`)+"..."}
                //  onBlur={getHeight}
                onPaste={resizeInputBox}
                value={chatInput}
                onChange={(e) => {
                  setChatInput(e.target.value);
                  // ChatPageHeight();
                  // updateElSize({ target: { value: "" } });
                }}
                rows={1}
                maxLength={1500}
                //   ref="textareaChat"
                //  onFocus={getHeight}
                // onKeyUp={updateElSize}
                // max-length="1500"
              ></textarea>
              <div className="mobileFooterSend">
                <button
                  className="btn btn-light sendbutton"
                  disabled={
                    ((chatInput.length <= 0
                      ? true
                      : !chatInput.replace(/\s/g, "").length
                      ? true
                      : false) &&
                      attachedFiles.length <= 0) ||
                    attachedFiles.length > 10 ||
                    disableSendButton 
                  }
                  onClick={() => {
                    sendMessage(contactData);
                  }}
                >
                  <i
                    data-v-4d521fc4=""
                    data-name="send"
                    data-tags="message,mail,email,paper airplane,paper aeroplane"
                    data-type="send"
                    className="feather feather--send i-2"
                  >
                    <svg
                      style={{ color: 'var(--primary-color)' }}
                      data-v-4d521fc4=""
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-send feather__content"
                    >
                      <line x1="22" y1="2" x2="11" y2="13"></line>
                      <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                    </svg>
                  </i>
                </button>
              </div>
            </div>

            {attachedFiles.length > 0 ? (
              <div className="attached-media grid-container">
                {attachedFiles.map((file: any, index) => (
                  <div key={file.name + "-" + file.type + "-" + index}>
                    <div className="media-tb">
                      <div className="media-left d-flex">
                        <i className="far fa-file-alt"></i>
                        <p>{(file.size / 1e6).toFixed(2)}</p>
                      </div>

                      <div>{file.name}</div>

                      <div>
                        <i
                          className="fas fa-times"
                          onClick={() => removeFile(index)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        ) : null}

        {/* footer */}

        {/* footer ends here */}

        {/* popup start here */}
        {showImgPopup ? (
          <div className="mobileimagePopup">
            <div className="mobileouterdiv">
              <div className="mobileimageBox">
                <a
                  id="mobilecloseBtn"
                  className="mobilepopupBtn"
                  onClick={(e) => setShowImgPopup("")}
                >
                  <i className="fa fa-times"></i>
                </a>
                <img src={showImgPopup} alt="" />
                <a
                  id="mobiledownloadBtn"
                  className="mobilepopupBtn"
                  onClick={(e) => downloadWithAxios(showImgPopup)}
                >
                  <i className="fa fa-arrow-down"></i>
                </a>
              </div>
            </div>
          </div>
        ) : null}
        {/* popup ends here */}
        <ContactAddPopup 
        showAddContactModal={addNumberPopup}
        data={contactData}
        redirectPage={false}
        handleCloseParent={(e)=>{
          // if(e){
          //   if(e.AcctPh_Nbr && e.IntlCallPfx_Nbr){
          //     let Temp=this.state.contactList;
          //     Temp[this.state.selectedContactIndex].AcctPh_Nbr=e.AcctPh_Nbr;
          //     Temp[this.state.selectedContactIndex].IntlCallPfx_Nbr=e.IntlCallPfx_Nbr;
          //     this.setState({showDetails:false,contactList:Temp})
          //   }  
          // }
          //setShowCallModalPopup(true);
          setShowDetails(false)
          setAddNumberPopup(false);
          getUserInfo()
          // this.setState({showAddContactModal:false, showDetails:false})}}
        }}
        />
      </div>
    );
  } else return null;
}

export default NewMobileChatComponent;
