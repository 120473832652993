import React, { useEffect, useState } from 'react';
import '../chat/MsgComponent.css'
import Message from "../chat/Message"
// import Avatar from '@mui/material/Avatar';
import axios from 'axios';
import config from '../../env.json';
import ContactDetialsModal from '../contact/ContactDetialsModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularProgress from '@mui/material/CircularProgress';
import PreApprovedTemplates from '../preApprovedTemplates/preApprovedTemplate';
import { Tooltip } from "@mui/material";
import { getMsgReadAt } from "../../apis/chat/messageReadAt";
import Pusher from "pusher-js";
import moment from "moment";
import { getConversationDetailsApi } from "../../apis/chat/chatApi";
import { contactDetails } from "../../apis/contacts/contactsApi";
import { useSelector, useDispatch } from "react-redux";
import { sendMessageApi } from "../../apis/chat/messageApi";
import {
    chat,
    setChatType,
    setNumberAssigned,
    setRefreshChatComponent,
    setRefreshChatList,
} from "../../app/slice/chatSlice";
import {
    setCallModalDetails,
    setCallModalView,
} from "../../app/slice/callModalSlice";
import { setChatDetails } from "../../app/slice/chatSlice";
import { setSkipHeader } from '../../app/slice/loginSlice';
import Avatar from "../Avatar/Avatar";
import SaveIcon from "@mui/icons-material/Save";
import SaveContactsForChat from "./SaveContactForChat";

import CallModal from '../call/CallModal';
import { useTranslation } from "react-i18next";
import { getFormatedPhoneNumber } from '../../utils/phoneNumberUtil';
import {setShowSuccessSnackBar,setShowErrorSnackBar} from '../../app/slice/personalisationSlice';

import CallIcon from '@mui/icons-material/Call';
import { getPhoneNumberType } from '../../apis/checkPhoneNumberType/landlineLookupApi';
import ContactAddPopup from '../contact/ContactAddPopup';


export default function MsgComponent(props) {
    const validMsg = new RegExp('^\s*$');
    const { t } = useTranslation();
    const { chatId, chatName, chatIsPrescriber, refreshChatComponent ,numberAssigned} = useSelector((state: any) => state.chat);
    const messageChannelType = useSelector((state: any) => state.authApi.EmployeeMsgorWhatsAppChannelType);
    const Chattype = useSelector((state: any) => state.chat.type);
    const userTerrId=useSelector((state: any) => state.personalisation.EmployeeTerritoryId);

    const [changeTerritoryId,setChangeTerritoryId]=useState(false);
    const token = useSelector((state: any) => state.login.token)
    const dispatch = useDispatch()
    const [messageData, setMessageData] = useState<any>([]);
    const [contactData, setContactData] = useState<any>({})
    const [contactNumber, setContactNumber] = useState<any>('')
    const [showDetails, setShowDetails] = useState(false);
    const [name, setName] = useState('')
    const [showPreApproveTemplates, setPreApproveTemplates] = useState(false);
    const [attachedFiles, setAttachedFiles] = useState([]);
    const [errorMessages, setErrorMessages] = useState<any>([])
    const [chatInput, setChatInput] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(100);
    const [hasMore, setHasMore] = useState(true);
    const [infiniteScrollHeight, setInfiniteScrollHeight] = useState(10);
    // const [preApprovedTemplateData,setPreApprovedTemplateData]=useState({})
    const [showImgPopup, setShowImgPopup] = useState('');
    const [disableSendButton, setDisableSendButton] = useState(false);
    const [reverseOrNot, setReverseOrNot] = useState(false);
    const [optedOut, setOptedOut] = useState(false);
    const [optedOutRes, setOptedOutRes] = useState(false);
    const [messageId, setMessageId] = useState<any>();
    const [pusherChannel, setPusherChannel] = useState<any>(null);
    const [pusherMsgProps, setPusherMsgProps] = useState<any>(null);
    const [chatPusher,setChatPusher]=useState<any>(null);
    const [showCallModal, setShowCallModal] = useState(false);
    const [pusher, setPusher] = useState<any>(null);
    const [unknown, setUnknown] = useState(false);
    const [openSaveContacts, setOpenSaveContacts] = useState(false);
    const [deletedOthers,setDeletedOthers]=useState(false);
    const { optOutKeywords, optInKeywords } = useSelector((state: any) => state.personalisation);
    const [tempTemplateDataIfNoNumber,setTempTemplateDataIfNoNumber]=useState<any>(null);
    const [noNumber,setNoNumber]=useState(false);
    const [finished, setFinished] = useState(false);
    const [showAttachLoader, setShowAttachLoader] = useState<any>(false);
    const [addNumberPopup,setAddNumberPopup]=useState(false)
    const camp=useSelector((state: any) => state.personalisation.campaignName);
  
    const fileSupportedExt= config.REACT_APP_FILESUPPORTED_EXT.split(',');
    const BestTimeFlag = config.REACT_APP_BESTTIME_FLAG;
    const default_wave_id = config.REACT_APP_DEFAULT_WAVE_ID;
  const default_campaign_id = config.REACT_APP_DEFAULT_CAMPAIGN_ID;
  const default_product_id = config.REACT_APP_DEFAULT_PRODUCT_ID;
   
    const [isSMS, setIsSms] = useState(false);
    const [isNumberLandline, setIsNumberLandline] = useState(false);

        const fileType = config.REACT_APP_FILESUPPORTED_TYPE;

        const checkNumber = (contactData: any) => {
            let phoneNumber = getNumberFromItem(contactData);
            if(Object.keys(contactData).length > 0){
              getPhoneNumberType({phone:phoneNumber}, (res)=>{
                if(res.type == "landline") {
                  setIsNumberLandline(true)
                } else {
                  setIsNumberLandline(false)
                }
              })
            }
          }
    useEffect(() => {


        if (refreshChatComponent) {
            getMessageData(props.messageData, false, 1);
            dispatch(setRefreshChatComponent(false))
            setAttachedFiles([])
        }
    }, [refreshChatComponent])

    useEffect(()=>{

    },[optedOut])

    useEffect(() => {
        getMessageData(props.messageData, false, 1);     
    }, [unknown])

    const getUserInfo = () => {

        let id = chatId,
            presOrNot = chatIsPrescriber;
        // if(contact['Recipient Sender Typ']=="EMP"){
        //     id=contact['Recipient Id'];
        //     presOrNot=contact['Recipient Type']== "PRESCR";
        // }
        // else{
        //     presOrNot=contact['Recipient Sender Typ']=="PRESCR";
        //     id=contact['Recipient Sender Id'];
        // }
        console.log(contactData)
        //debugger
        if (id)

            contactDetails({ Id: id, Is_Prescriber: presOrNot,ContactType:"SMS" }, (res) => {
                //debugger
                debugger
                if (res) {
                    if (res.message == "No data found" || Chattype=="UNKN") {
                        setUnknown(true)
                        if( Chattype=='ASSPRS'){
                            setDeletedOthers(true)
                        }else{
                            setDeletedOthers(false)
                        }
                    } else {
                        if(res.Terr_Id){
                          if(config.REACT_APP_ABS_APPROVED == 'Y'){
                            if(userTerrId ==res.Terr_Id){
                                setChangeTerritoryId(false)
                            } else{
                                setChangeTerritoryId(true)
    
                            }
                          }
                        }
                        setUnknown(false)
                        setContactNumber(res.AcctPh_Nbr)
                        checkNumber(res)
                        if(res.AcctPh_Nbr.length>0){
                            dispatch(setNumberAssigned(true))
                          }
                        if(!res.AcctPh_Nbr){
                            setNoNumber(true)
                            dispatch(setNumberAssigned(false))
                        }
                        else{
                            setNoNumber(false)
                        }
                        chatSubscriber()
                        dispatch(setCallModalDetails({
                            AcctFull_Nm: res.AcctFull_Nm,
                            AcctFull_Nbr: getNumberFromItem(res)
                        }))
                        console.log(res)
                        //debugger
                        setContactData(res)
                        if (res.All_Ph_Cont_Flags === "N, N, N"){
                            setOptedOut(true);
                            setOptedOutRes(true);
                        }
                        else {
                            setOptedOut(false);
                            setOptedOutRes(false);
                        }
                    }

                }
                setFinished(true)
            })
        // axios.get(config.REACT_APP_CONTACTS_API_BASE+"/contact/"+id,{
        //     headers:{
        //       'Authorization':'Bearer '+sessionStorage.getItem('login')
        //     },
        //     params:{
        //       "IsPrescriber":presOrNot,
        //     }
        //   }).then((res:any)=>{


        //     setContactData(res.data.data)

        //   })
    }
    const handleFileSelected = (e) => {
        const files: any = Array.from(e.target.files)
        console.log("files:", files)
        setAttachedFiles(files)
        console.log("attachedFiles : ", attachedFiles)
    }


    const optInOut = (e) => {
        if (e === 'Y' || e.toLowerCase() == 'help') {
            setOptedOut(false)
        } else if (e === 'N') {
            setOptedOut(true)
        }

    }

    // const messageSubscriber=() => {
    //     //debugger
    //     // const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
    //       const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
    //         // cluster: config.REACT_APP_PUSHER_CLUSTER,
    //       cluster: config.REACT_APP_PUSHER_CLUSTER,
    //     //   encrypted: true,
    //     });
    //     //debugger
    //     // console.log(contactNumber)
    //     const channel = pusher.subscribe("chatdetailstatus");
    //     channel.bind(messageId,(data) => {
    //     //   if(data)
    //         //debugger
    //         let x = data;
    //         // console.log(JSON.parse(data.Body))
    //         console.log(data)
    //         // setChats(data);
    //     });msgStatus === "undelivered" || msgStatus === "failed

    //     return () => {
    //     //   pusher.unsubscribe("chatdetailstatus");
    //     };
    //   }

    const scrollTOBottom = () => {
        let docElement= document;
        if (
          docElement.getElementsByClassName("mainchat") &&
          docElement.getElementsByClassName("mainchat").length > 0
        ) {
          // let docElement:any=docElementument
          // console.log('hi');
          //document.getElementsByClassName('mainchat')[document.getElementsByClassName('mainchat').length-1].scrollTop=document.getElementsByClassName('mainchat')[document.getElementsByClassName('mainchat').length-1].scrollHeight
          //  setTimeout({docElement.getElementsByClassName('infinite-scroll-component')[docElement.getElementsByClassName('infinite-scroll-component').length-1].scrollTop=0}, 0)
          // docElementument.getElementsByClassName('infinite-scroll-component')[docElementument.getElementsByClassName('infinite-scroll-component').length-1].scrollTop=0
          setTimeout(function () {
            if(document.getElementsByClassName("mainchat")){
                if(document.getElementsByClassName("mainchat").length>0)
            document.getElementsByClassName("mainchat")[document.getElementsByClassName("mainchat").length - 1].scrollTop =document.getElementsByClassName("mainchat")[document.getElementsByClassName("mainchat").length - 1].scrollHeight;
            }

            if(document.getElementsByClassName("msg-attachment")){
                let scroll:any = document.querySelector(".infinite-scroll-component")
                scroll.scrollIntoView(false);
              }
            //  docElement.getElementsByClassName('infinite-scroll-component')[docElement.getElementsByClassName('infinite-scroll-component').length-1].scrollTop=0;
            //   docElement.querySelector('.msg-row').scrollIntoView({   behavior: 'smooth' });
          }
            ,
            500
          );
        }
    
      };


    const removeFile = (i) => {
        console.log("functioin called for ", i);
        let attfile = attachedFiles.filter((x, index) => i != index);
        setAttachedFiles(attfile);
        validateGetfiles(attfile);
        // this.fileSize = 0;
        // for (let i = 0; i < this.files.length; i++) {
        //     this.fileSize += parseInt(this.files[i].size);
        // }
        // if (this.files.length <= 10) {
        //     this.footerErrorMsg = [];
        // }
    }
    const getFiles = (e) => {
        console.log('inside')
        const files: any = Array.from(e.target.files)
        console.log("files:", files)
        //debugger
        let temp: any = [];
        let totalsize:any=0;
        attachedFiles.map((x:any) => {
            temp.push(x)
            totalsize+=x.size
            console.log(temp)
        })
        files.map((e: any) => {
            e.testTime = new Date();
            temp.push(e)
            totalsize+=e.size
            console.log(temp)
        })
        // temp=temp.concat(files);
        //debugger
        
        console.log(temp)

        let { errorMesg } = validateGetfiles(temp);
        //debugger
        if(totalsize>100000000){
            let t:any = [];
            errorMessages.map((x:any)=>{
                t.push(x);
                return x;
            })
            
            t.push('File size exceeds 100MB');
            setErrorMessages(t)
            setTimeout(
                function () {
                    setErrorMessages([])
                }
                ,
                3000
            );
        }
        else if (temp.length > 10 && !isSMS) {
            // footerErrorMsg.push('Max 10 files are allowed');
            let errmsg = "Max 10 files are allowed";
            // errorMessages.push("Max 10 files are allowed");
            // console.log('Max 10 files are allowed');          
                let t:any = [];
            errorMessages.map((x:any)=>{
                t.push(x);
                return x;
            })

            t.push('Max 10 files are allowed');
            
            setErrorMessages(t)

            setTimeout(
                function () {
                    setErrorMessages([])
                }
                ,
                2000
            );  
        }
        else if (temp.length > 1 && isSMS) {
            // footerErrorMsg.push('Max 10 files are allowed');
            let errmsg = "Max 1 file are allowed";
            // errorMessages.push("Max 10 files are allowed");
            // console.log('Max 10 files are allowed');          
                let t:any = [];
            errorMessages.map((x:any)=>{
                t.push(x);
                return x;
            })

            t.push('Max 1 file are allowed');
            
            setErrorMessages(t)

            setTimeout(
                function () {
                    setErrorMessages([])
                }
                ,
                2000
            );   
        }
        else {
            setAttachedFiles(temp)

            console.log("attachedFiles : ", attachedFiles)
            console.log("hello world : ", attachedFiles.length);
        }
        if (errorMesg) {
            //debugger
            // this.footerErrorMsg.push(errorMesg);
            let t:any = [];
            errorMessages.map((x:any)=>{
                t.push(x);
                return x;
            })
            t.push(errorMesg);
            setErrorMessages(t)
            setTimeout(
                function () {
                    setErrorMessages([])
                }
                ,
                2000
            );
        }
        let doc:any=document
        doc.getElementById('attachedfiles').value = "";
        // setTimeout(() => {
        //     this.footerErrorMsg = [];
        // }, 5000);
        // e.target.value = null;
    }
    const validateGetfiles = (files) => {
        //   let outputFiles = [];
        //   let fileSize = 0;
        setDisableSendButton(false)
        let errorMesg = "";
        files = Array.from(files);
        let file;
        for (var i = 0; i < files.length; i++) {
            file = files[i];
            let fileExt = file.name
                .split(".")
                .pop()
                .toLowerCase();
            // console.log(this.fileType.includes(file.type), this.fileSupportedExt.includes(fileExt))
            console.log("fileExt : ", fileExt);
            console.log("fileExt : ", fileSupportedExt);
            console.log("include file ext : ", fileSupportedExt.includes(fileExt));

            console.log("filetype : ", file.type);
            console.log("filetype : ", fileType);
            console.log("file type list : ", fileType.includes(file.type))
            if(files.length < 10){
                if (
                    fileType.includes(file.type) &&
                    fileSupportedExt.includes(fileExt)
                ) {
                    // check non image file size if its greater that 675000b throw a error and remove the file;
                    // files.push(file);
                    // fileSize += file.size;
                } 
                else if(file.type === "image/tiff" || file.type ==="audio/amr" || fileExt=== "amr" || file.type ==="" ){
                    
                   
                    errorMesg = file.name + " is not supported";  
                    setDisableSendButton(true)
                }
                else if(files.length > 10 && (file.type === "image/tiff" || file.type ==="audio/amr" || fileExt=== "amr" || file.type ==="")){
                    console.log("error")
                }
                 else {
                    // this.isFileTypeSupported= false;
                    errorMesg = file.name + " is not supported";
                    // errorMessages.push(file.name + " is not supported")
                    // let t = errorMessages;
                    // t.push("filetype is not supported");
                    // setErrorMessages(t)
                }
            } else{
                if(files.length > 10 && (file.type === "image/tiff" || file.type ==="audio/amr" || fileExt=== "amr")){

                    errorMesg = file.name + " is not supported and Max 10 files are allowed"; 
                }
            }
        }
        return { errorMesg };
    }
    // supportedFileType() {

    //   return this.fileType;
    // }
    const selectedTemplate = (tempData,contactData:any) => {

        // if(data['Recipient Sender Typ']=="EMP"){
        //     formData.append("recipient_id", data['Recipient Id']);
        //     formData.append("recipient_name", data['Recipient Name']);
        //     formData.append("recipient_phone", data['Phone #']);
        //     formData.append("template",templateData.templateVal);
        //     formData.append("Campaign_ID",templateData.campaginId.toString());
        //     formData.append("template_id",templateData.id.toString());
        //     formData.append('wave_id',templateData.waveId.toString())
        //    formData.append('product_id',templateData.productId.toString())

        //     formData.append("physcian_id",data['Recipient Id'] );
        //    formData.append("role_id", '0');//for prescriber
        //    if (!isSMS) {
        //     formData.append("channel", "SMS");
        //    } else {
        //     formData.append("channel", "whatsa");
        //    }
        //    sendMessageApi(formData,(res)=>{
        //     setPreApproveTemplates(false)
        //    })
        //    axios.post(config.REACT_APP_MESSAGE_API_BASE+"/message",formData,{
        //     params:{"Authorization":"Bearer "+sessionStorage.getItem('login')}
        // }).then((res)=>{
        //     setPreApproveTemplates(false)
        // }).catch(res=>{
        //     setPreApproveTemplates(false)
        //     console.log(res)
        // })
        // }
        let templateData: any = tempData.templateData
        console.log(contactData);
        let cData = contactData ? contactData : {}
        let phone = getNumberFromItem(contactData);
        // if(!phone){
        //     setNoNumber(true)
        //     setTempTemplateDataIfNoNumber(tempData);
        //     setShowDetails(true)
        //     dispatch(setShowErrorSnackBar(true));
        //     return; 
        // }
        // setNoNumber(false)
        // setTempTemplateDataIfNoNumber(null);
        let formData = new FormData();
        //debugger
        formData.append("recipient_id", chatId);
        formData.append("recipient_name", chatName);
        formData.append("recipient_phone", phone);
        if (chatIsPrescriber) {
            formData.append("physcian_id", chatId);
            formData.append("role_id", '0');//for prescriber
        }
        else {
            formData.append("physcian_id", cData.Prescribers[0].Id);
            formData.append("role_id", '1');//for prescriber
            formData.append('association_id', cData.Prescribers[0].Association_Id)
        }



        formData.append("Campaign_ID", default_campaign_id)//templateData.campaginId.toString());
        // formData.append("template_id", templateData.TemplateId.toString());
        formData.append('wave_id', templateData.mainWaveId.toString())//templateData.waveId.toString())
        formData.append('product_id', templateData.productId.toString())//templateData.productId.toString())


        if (!isSMS) {
            formData.append("channel", "SMS");
        } else {
            formData.append("channel", "whatsa");
        }
        if (attachedFiles.length > 0) {
            for (var i = 0; i < attachedFiles.length; i++) {
                let file = attachedFiles[i];
                formData.append("file", file);
            }
        }
        if (templateData.FormId) {
            let formPay = {
                "formId": templateData.FormId,
                "fieldDetails": tempData.formData,
                "recipientId": chatId,
                "recipientType": chatIsPrescriber ? "PRESCR" : 'ASSPRS'

            }
            axios.post(config.REACT_APP_FORMS_API_BASE + '/formRequest', formPay).then((res: any) => {
                console.log(res.data)
                let temptest = templateData.TemplateBody.toString();
                if (temptest.indexOf('shorturl') >= 0)
                    formData.append("template", temptest.toString().replace('[shorturl_1]', res.data.FormUrl));
                else
                    formData.append("template", temptest + " " + res.data.FormUrl);
                formData.append('urls', res.data.FormUrl)
                let qtPayload={
                    quantityUsed:tempData.formData[0].valueOfFieldToAskFromEmp,
                    prescriberId:chatId,
                    productId:templateData.productId.toString()
                  }
                  axios.post(config.REACT_APP_CAMPAIGN_API_BASE+'/srf/updateQauntity',qtPayload).then((res:any)=>{
                    
                  })
                //   sendMessageForPresOrOthers(formData);
                sendMessageApi(formData, (res) => {
                    console.log(res)
                    setAttachedFiles([]);
                    setChatInput('')
                    setPreApproveTemplates(false)

                    if (res) {
                        getMessageData(props.messageData, false, 1);
                        dispatch(setRefreshChatList(true))
                        dispatch(setShowSuccessSnackBar(true));
                    }
                })
            })
                .catch(err => {
                    dispatch(setShowErrorSnackBar(true));
                })
        }
        else {
            formData.append("template_id", templateData.TemplateId.toString());
            formData.append("template", templateData.TemplateBody);
            sendMessageApi(formData, (res) => {
                console.log(res)
                setAttachedFiles([]);
                setChatInput('')
                setPreApproveTemplates(false)
                if (res) {
                    getMessageData(props.messageData, false, 1);
                    dispatch(setRefreshChatList(true))
                    dispatch(setShowSuccessSnackBar(true));
                }
                else{
                    dispatch(setShowErrorSnackBar(true));
                }
            })
        }

    }

    useEffect(() => {
        scrollTOBottom()
        console.log(getNumberFromItem(contactData))
        if (pusherChannel && pusherChannel.bind) {


            pusherChannel.unbind(getNumberFromItem(contactData));
            //debugger
            pusherChannel.bind(getNumberFromItem(contactData), (data) => {
                if (data) {
                    console.log(messageData)
                    //debugger
                    let jsonData = JSON.parse(data.Body);
                    console.log(JSON.parse(data.Body))
                    optInOut(jsonData.isOptIn)
                }
                //     if(data){
                //       console.log(messageData)
                //       //debugger
                //       let jsonData= JSON.parse(data.Body);
                //       console.log(JSON.parse(data.Body))
                //       let x = {
                //           'Recipient Channel Type':jsonData.channelType,
                //           'Recipient Msg End':jsonData.createdDateTime,
                //           'Recipient Msg Start':jsonData.createdDateTime,
                //           'Recipient Msg Body':jsonData.msgBody,
                //           'Recipient Msg Attachment':'',
                //           'Recipient Msg Status': 'sent'
                //       }
                //       //debugger
                //       let msgPusherData: any= [];  
                //       messageData.map(e=> 
                //         {msgPusherData.push(e);
                //             return e;
                //         }
                //         ) 
                //     //   messageData.map(e)
                //       console.log(jsonData.isOptIn)
                //       optInOut(jsonData.isOptIn) 
                //       //debugger       
                //       msgPusherData.unshift(x)
                //       console.log(msgPusherData)
                //       let t = msgPusherData;
                //       for (let chatIndex = 0;chatIndex < t.length;chatIndex++) {
                //         setMessageId(t[chatIndex]['Message Id'])
                //         //debugger
                //         if (chatIndex==t.length-1) 
                //         {
                //           t[chatIndex].showDateBanner = true;
                //         } 

                //         else if (chatIndex != 0) {
                //           if (
                //             moment(t[chatIndex]['Recipient Msg Start']).format(
                //               "MM/DD/YYYY"
                //             ) !=
                //             moment(t[chatIndex + 1]['Recipient Msg Start']).format(
                //               "MM/DD/YYYY"
                //             )
                //           ) {
                //             t[chatIndex].showDateBanner = true;
                //           } else {
                //             t[chatIndex].showDateBanner = false;
                //           }
                //         } 
                //         else if (chatIndex == 0 &&  t.length>1) {

                //           if (
                //             moment(t[chatIndex]['Recipient Msg Start']).format("MM/DD/YYYY") == moment(t[chatIndex + 1]['Recipient Msg Start']).format( "MM/DD/YYYY")
                //           ) {
                //             t[chatIndex].showDateBanner = false;
                //           } else {
                //             t[chatIndex].showDateBanner = true;
                //           }
                //         }

                //       }
                //       setMessageData(t);
                //     //   dispatch(setRefreshChatList(true))
                //   }

                // setChats(data);
            });
        }
        
        
    }, [messageData, contactData])

    useEffect(()=>{
        chatSubscriber()
    },[messageData])
    const chatSubscriber = () => {
       
        // const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
        const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
            // cluster: config.REACT_APP_PUSHER_CLUSTER,
            cluster: config.REACT_APP_PUSHER_CLUSTER,
            //   encrypted: true,
        });
        //debugger
        console.log(contactNumber)
        const channel = pusher.subscribe("chatdetail");
        setPusherChannel(channel)
        setPusherMsgProps(pusher.subscribe("chatdetailstatus"))
        
            console.log("coming")
            let payload:any={
                prescOrAssocId:chatId, 
                prescOrAssocType:chatIsPrescriber === true? "PRESCR": config.REACT_APP_ENABLE_HOSPITAL_CONTACT &&
                config.REACT_APP_CAMP_LIST_ENABLE_HOSPITAL_CONTACT == camp ? "ASSHSP" : "ASSPRS"
              }
              getMsgReadAt(payload,(res)=>{
                console.log(res.data.updated )
                if(res.data.updated === true){
                    dispatch(setRefreshChatList(true))
                }
                
              })
              

    let chatP:any= pusher.subscribe('chat')    
    setChatPusher(chatP);

        return () => {
            pusher.unsubscribe("chatdetail");
            pusher.unsubscribe("chat")
        };
    }
    const getMessageData = (data, old, p) => {

        //debugger
        let id = chatId;;

        getConversationDetailsApi({ page: p, limit, id, type: Chattype }, (res) => {
            window.localStorage.setItem('sentFromWindow',"false");
            console.log(res)
            if (res.length>0&&res[0]['Error'] || res.length==0 ) {
                setHasMore(false)
            }
            let t = res
            if (old) {
                t = messageData.concat(t)
            }
            //debugger
                            // setOptedOut(true);
            //     for (let chatIndex = 0;chatIndex < t.length;chatIndex++) {
            //     if (chatIndex == 0) {
            //       t[chatIndex].showDateBanner = true;
            //     } else if (chatIndex != t.length - 1) {
            //       if (
            //         moment(t[chatIndex]['Recipient Msg Start']).format(
            //           "MM/DD/YYYY"
            //         ) !=
            //         moment(t[chatIndex - 1]['Recipient Msg Start']).format(
            //           "MM/DD/YYYY"
            //         )
            //       ) {
            //         t[chatIndex].showDateBanner = true;
            //       } else {
            //         t[chatIndex].showDateBanner = false;
            //       }
            //     } else if (chatIndex == t.length - 1) {
            //       if (
            //         moment(t[chatIndex]['Recipient Msg Start']).format(
            //           "MM/DD/YYYY"
            //         ) ==
            //         moment(t[chatIndex - 1]['Recipient Msg Start']).format(
            //           "MM/DD/YYYY"
            //         )
            //       ) {
            //         t[chatIndex].showDateBanner = false;
            //       } else {
            //         t[chatIndex].showDateBanner = true;
            //       }
            //     }
            //   }
            // t=t.sort((b:any,a:any)=>new Date(b['Recipient Msg Start']).getDate()-new Date(a['Recipient Msg Start']).getDate())
       if (res.length>0&&res[0]['Error']==undefined) {
            for (let chatIndex = 0; chatIndex < t.length; chatIndex++) {
                setMessageId(t[chatIndex]['Message Id'])
                //debugger
                if (chatIndex == t.length - 1) {
                    t[chatIndex].showDateBanner = true;
                }

                else if (chatIndex != 0) {
                    if (
                        moment(t[chatIndex]['Recipient Msg Start']).format(
                            "MM/DD/YYYY"
                        ) !=
                        moment(t[chatIndex + 1]['Recipient Msg Start']).format(
                            "MM/DD/YYYY"
                        )
                    ) {
                        t[chatIndex].showDateBanner = true;
                    } else {
                        t[chatIndex].showDateBanner = false;
                    }
                }
                else if (chatIndex == 0 && t.length > 1) {

                    if (
                        moment(t[chatIndex]['Recipient Msg Start']).format("MM/DD/YYYY") == moment(t[chatIndex + 1]['Recipient Msg Start']).format("MM/DD/YYYY")
                    ) {
                        t[chatIndex].showDateBanner = false;
                    } else {
                        t[chatIndex].showDateBanner = true;
                    }
                }

            }
        
              console.log(t)
            setMessageData(t);
        }
            let tt = t.filter(x => x[ 'Direction' ] == "incoming")
            let optOutIndex;
            let optOutIndexArray = new Array();
            let optInIndexArray = new Array();
            let optInIndex;
            if(unknown){
                if (tt.length>0){             
                    for(var i = 0; i < optOutKeywords.length; i++){

                        optOutIndex = tt.findIndex(x => x[ 'Recipient Msg Body' ].toLowerCase() == optOutKeywords[i].toLowerCase());

                        
                        if (optOutIndex >= 0) {
                            optOutIndexArray.push(optOutIndex)
                            
                        }
                        else{
                            optOutIndex = 100000000;
                            optOutIndexArray.push(optOutIndex);
                        }
                    }
                    for(var j = 0; j < optInKeywords.length; j++){

                        optInIndex = tt.findIndex(x => x[ 'Recipient Msg Body' ].toLowerCase() == optInKeywords[j].toLowerCase());                  
                        if (optInIndex >= 0) {
                            optInIndexArray.push(optInIndex)


                        }
                        else{
                            optInIndex = 100000000;
                            optInIndexArray.push(optInIndex);
                        }
                    }
                    var smallestOptOutIndex = optOutIndexArray.sort((a, b) => a - b);
                    var smallestOptInIndex = optInIndexArray.sort((a, b) => a - b);
                    if(smallestOptOutIndex[0] < smallestOptInIndex[0]){
                        // if(optedOutRes){
                            setOptedOut(true);
                        // }
                    }
                    else{
                        setOptedOut(false);

                    }
            
                } 
            }  
            else{
                getUserInfo();
            }         
        })
        // axios.get(config.REACT_APP_MESSAGE_API_BASE+"/conversations/details?&page="+p+"&limit="+limit+"&recipientId="+id,{
        //     headers:{
        //       'Authorization':'Bearer '+sessionStorage.getItem('login')
        //     }
        //   }).then((res:any)=>{
        //     //debugger
        //     if(res.data.length==0){
        //         setHasMore(false)
        //     }
        //     // if(data['Recipient Sender Typ']=="EMP"){
        //     //     setName(data['Recipient Name']);
        //     // }
        //     // else
        //     // {
        //     //     setName(data['Sender Name']);
        //     // }
        //     ////debugger

        //     let t=res.data.reverse();
        //     if(old){
        //         t=t.concat(messageData)
        //     }
        //     for (let chatIndex = 0;chatIndex < t.length;chatIndex++) {
        //         if (chatIndex == 0) {
        //           t[chatIndex].showDateBanner = true;
        //         } else if (chatIndex != t.length - 1) {
        //           if (
        //             moment(t[chatIndex]['Recipient Msg Start']).format(
        //               "MM/DD/YYYY"
        //             ) !=
        //             moment(t[chatIndex - 1]['Recipient Msg Start']).format(
        //               "MM/DD/YYYY"
        //             )
        //           ) {
        //             t[chatIndex].showDateBanner = true;
        //           } else {
        //             t[chatIndex].showDateBanner = false;
        //           }
        //         } else if (chatIndex == t.length - 1) {
        //           if (
        //             moment(t[chatIndex]['Recipient Msg Start']).format(
        //               "MM/DD/YYYY"
        //             ) ==
        //             moment(t[chatIndex - 1]['Recipient Msg Start']).format(
        //               "MM/DD/YYYY"
        //             )
        //           ) {
        //             t[chatIndex].showDateBanner = false;
        //           } else {
        //             t[chatIndex].showDateBanner = true;
        //           }
        //         }
        //       }




        //     setMessageData(t);
        //   })
    }
    // const checkIfURL=(textToCheck)=> {
    //     return linkifyHtml(textToCheck, {
    //       defaultProtocol: "https",
    //       className: "linkified",
    //       download:textToCheck,
    //       nl2br: false,
    //       validate: true,
    //       formatHref: function(href, type) {
    //         console.log(href, type);
    //         return href;
    //       },
    //       format: function(value, type) {
    //         console.log(value, type);
    //         return value;
    //       },
    //     });
    //   }

    useEffect(()=>{
        console.log("oooooooooooooooo", optedOut)
    }, [optedOut])

    const updateElSize = (e) => {
        // console.log(e)
        let doc: any = document;
        doc.getElementById("footerinput").style.height = "auto"
        if (e.target.value === "") {
            doc.getElementById("footerinput").style.height = "auto"
        }
        else {
            doc.getElementById('footerinput').style.height = doc.getElementById('footerinput').scrollHeight + "px"
            doc.getElementById('scrollableDiv').scrollTop = doc.getElementById('scrollableDiv').scrollHeight
            // doc.getElementById('scrollableDiv')
        }
        // document.getElementById('footerinput').style.height = document.getElementById('footerinput').scrollHeight+"px"
    };

    const resizeInputBox = ()=>{

        return '\t';
        
      }

    const handleImgPopup = (imgdisurl) => {
        // console.log('handling image enlarge parent',imgdisurl);
        setShowImgPopup(imgdisurl);
    };
    const forceFileDownload = (response: any, filename: any) => {
        console.log("I am here 33")
        const url = window.URL.createObjectURL(response);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename); //or any other extension
        document.body.appendChild(link);
        link.click();
    }


    const downloadWithAxios = (url) => {
        console.log("handle click downloadWithAxios called", url);
        let urlSplit = url.split("/");
        let filename = urlSplit[urlSplit.length - 1];
        delete axios.defaults.headers.common["Authorization"];
        // dispatch(setSkipHeader({ 'skipHeader': true }));
        fetch(url)
            .then(response => response.blob()) // Gets the response and returns it as a blob
            .then(blob => {
                forceFileDownload(blob, filename);
                axios.defaults.headers.common["Authorization"] = "Bearer " + token
                //   response.blob().then(blob => {
                //     let url = window.URL.createObjectURL(blob);
                //     let a = document.createElement('a');
                //     a.href = url;
                //     a.download = 'employees.json';
                //     a.click();
                //   });
                //   //window.location.href = response.url;
                // }
            });
    };

    // useEffect(() => {


    //   if(props.messageData){
    //     // let empty=[];
    //     // setMessageData(empty)
    //     setPage(1)
    //     setHasMore(true)
    //     getMessageData(props.messageData,false,1)
    //     getUserInfo(props.messageData)
    //     //setMessageData(props.messageData.reverse())
    //   }
    //     if (attachedFiles){
    //         setAttachedFiles(attachedFiles);
    //     }

    // }, [props, attachedFiles],)
    useEffect(() => {
        // console.log(chatId);
        // console.log(chatName);
        // console.log(chatIsPrescriber)
        console.log(chatId, chatName)
        //debugger
        if (chatId && Chattype) {
            setHasMore(true)
            setAttachedFiles([])
            getMessageData(props.messageData, false, 1)
        }

        getUserInfo();
        // pusherSubscribe();
        let doc: any = document;
        // checkFirstMessages();
        let ht = (window.innerHeight 
            - doc.getElementsByClassName('besttime-banner')[0].clientHeight 
            - doc.getElementsByClassName('header')[0].clientHeight 
            - (10 * window.innerHeight /100)
        )
        if(doc.getElementsByClassName('footer')[0]){
            let ht = (window.innerHeight 
                - doc.getElementsByClassName('besttime-banner')[0].clientHeight 
                - doc.getElementsByClassName('header')[0].clientHeight 
                - doc.getElementsByClassName('footer')[0].clientHeight 
                - (10 * window.innerHeight /100)
            )
        }
         
        setInfiniteScrollHeight(ht);

        
        return () => {
            setMessageData([]);
        }

    }, [chatId, Chattype])
    useEffect(()=>{
        console.log(chatId.toString(),chatPusher)
        if(chatPusher && chatPusher.bind){
            
            chatPusher.unbind(chatId.toString());
            //debugger

            chatPusher.bind(chatId.toString(), (data) => {
                
                let t=window.localStorage.getItem('sentFromWindow')
                if(t!="true")
                {
                    dispatch(setRefreshChatList(true))
                    dispatch(setRefreshChatComponent(true))
                }
            }) 
        }
    },[chatId,chatPusher])
    useEffect(()=>{
        debugger
        if(unknown && Chattype=='ASSPRS'){
            setDeletedOthers(true)
        }else{
            setDeletedOthers(false)
        }
    },[unknown])
    useEffect(() => {
        return () => {
            if (pusherChannel)
                pusherChannel.unbind(getNumberFromItem(contactData));
        }
    })
    const getNumberFromItem = (item) => {
        if(item){
            if (item.AcctPh_Nbr) {
              if (item.AcctPh_Nbr.indexOf("+") >= 0) {
                return item.AcctPh_Nbr;
              } else return "+" + item.IntlCallPfx_Nbr + item.AcctPh_Nbr;
            } else return "";
          }
          else return "";
    }


    const sendMessage = (contactData:any) => {
        window.localStorage.setItem('sentFromWindow',"true");
        setDisableSendButton(true)
        // console.log(contactData);
        //setTempTemplateDataIfNoNumber(null);
        let cData = contactData ? contactData : {}
        let phone = getNumberFromItem(contactData);
        // if(!phone){
        //     setNoNumber(true)
        //     setShowDetails(true)
        //     dispatch(setShowErrorSnackBar(true));
        //     return; 
        // }
        // setNoNumber(false)
        let formData = new FormData();
        //debugger
        formData.append("recipient_id", chatId);
        formData.append("recipient_name", chatName);
        formData.append("recipient_phone", phone);
        if (chatIsPrescriber) {
            formData.append("physcian_id", chatId);
            formData.append("role_id", '0');//for prescriber
        }
        else {
            formData.append("physcian_id", cData.Prescribers[0].Id);
            formData.append("role_id", '1');//for prescriber
            formData.append('association_id', cData.Prescribers[0].Association_Id)
        }

        formData.append("template", chatInput);
        formData.append("Campaign_ID", default_campaign_id);
        formData.append('wave_id', default_wave_id)
        formData.append('product_id', default_product_id);


        if (!isSMS) {
            formData.append("channel", "SMS");
        } else {
            formData.append("channel", "whatsa");
        }
        if (attachedFiles.length > 0) {
             setShowAttachLoader(true);
            for (var i = 0; i < attachedFiles.length; i++) {
                let file = attachedFiles[i];
                formData.append("file", file);
            }
        }

        sendMessageApi(formData, (res, msgData) => {
            // console.log(res)
            //debugger
            // setDisableSendButton(true)
            if (msgData) {
                if (msgData.message === "Inappropriate message body") {
                    setDisableSendButton(false)
                    setShowAttachLoader(false);
                    let t: any = [];
                    t.push(msgData.message)
                    errorMessages.map(x => {
                        t.push(x)
                        return x;
                    })
                    setErrorMessages(t)
                    setTimeout(
                        function () {
                            setErrorMessages([])
                        }
                        ,
                        2000
                    );
                    //debugger
                }
            }
            else if (res) {
                // console.log(msgData.message)
                //debugger
                setDisableSendButton(false);
                setShowAttachLoader(false);
                setAttachedFiles([]);
                setChatInput('')
                getMessageData(props.messageData, false, 1);
                dispatch(setRefreshChatList(true))
                let doc:any=document;
                doc.getElementById('footerinput').style.height="0px"
                dispatch(setShowSuccessSnackBar(true));
            }
            else {
                setDisableSendButton(false);
                setShowAttachLoader(false);
                setAttachedFiles([]);
                setChatInput('')
                dispatch(setShowErrorSnackBar(true));
            }
        })

        //    axios.post(config.REACT_APP_MESSAGE_API_BASE+"/message",formData,{
        //     params:{"Authorization":"Bearer "+sessionStorage.getItem('login')}
        // }).then((res)=>{

        //     setAttachedFiles([]);
        //     setChatInput('')
        // }).catch(res=>{
        //     setAttachedFiles([]);
        //     setChatInput('')
        // })

        //     else{
        //         //debugger
        //         formData.append("recipient_id", data['Recipient Sender Id']);
        //         formData.append("recipient_name", data['Sender Name']);
        //         formData.append("recipient_phone", contactData['AcctPh_Nbr']);
        //         formData.append("template",chatInput);
        //         formData.append("Campaign_ID",'1000');
        //         formData.append('wave_id','1000')
        //        formData.append('product_id','1000');
        //         formData.append("physcian_id",contactData['Prescriber_Id'] );
        //        formData.append("role_id", '0');//for prescriber
        //        if (!isSMS) {
        //         formData.append("channel", "SMS");
        //        } else {
        //         formData.append("channel", "whatsa");
        //        }

        //    if(attachedFiles.length>0){
        //     for (var i = 0; i < attachedFiles.length; i++) {
        //         let file = attachedFiles[i];
        //         formData.append("file", file);
        //       }
        //    }


        //    sendMessageApi(formData,(res)=>{
        //     setAttachedFiles([]);
        //     setChatInput('')
        //     doc.getElementById("footerinput").style.height = "auto"
        //    })
        //     //    axios.post(config.REACT_APP_MESSAGE_API_BASE+"/message",formData,{
        //     //     params:{"Authorization":"Bearer "+sessionStorage.getItem('login')}
        //     // }).then((res)=>{

        //     //     setAttachedFiles([]);
        //     //     setChatInput('')
        //     //     doc.getElementById("footerinput").style.height = "auto"
        //     // }).catch(res=>{
        //     //     setAttachedFiles([]);
        //     //     setChatInput('')
        //     // })
        //     }



        //  formData.append("physcian_id", data.physcian_id);
        //  formData.append("role_id", data.type);
        //  formData.append("template", data.template);
        //  formData.append("Campaign_ID", 1000);
        //  formData.append("template_id");
        //  formData.append('wave_id',data.)


        //  if (this.switchChecked) {
        //    formData.append("communicationType", "whats app");
        //  } else {
        //    formData.append("communicationType", "sms");
        //  }
        //  // formData.append("file", this.files);
        //  for (var i = 0; i < this.files.length; i++) {
        //    let file = this.files[i];
        //    formData.append("file", file);
        //  }



    }
    //   useEffect(() => {
    //     getMessageData(props.messageData,true)
    //   }, [page])

    const saveUnknown = (data: any) => {
        //debugger
        if (data.Id) {
            //debugger
            //debugger
            dispatch(setChatType(data.Is_Prescriber == "Y" ? "PRESCR" : "ASSPRS"))
            dispatch(setChatDetails({
                chatId: data.Id,
                chatName: data.AcctFull_Nm,
                chatIsPrescriber: data.Is_Prescriber == "Y",
                showChat: true
            }))

            dispatch(setRefreshChatList(true))
            dispatch(setRefreshChatComponent(true))
        }
    }
    const scrollToTop = (e: any) => {
        //     if(e.currentTarget.scrollTop === 0)
        //    {
        //     getMessageData(props.messageData,true,page+1);
        //     setPage(page+1);
        //    }
        // console.log(hasMore)
    }
    const saveNewContact = () => {
        setOpenSaveContacts(true);
    }

    function truncate(source, size) {
        return source.length > size ? source.slice(0, size - 1) + "…" : source;
      }


      
    // const checkFirstMessages=()=>{
    //     let doc:any=document;
    //    let  inHeight:number=doc.getElementsByClassName('innerChat')[0].clientHeight;
    //    let  outHeight:number=doc.getElementsByClassName(' mainchat')[0].clientHeight;
    //    if(inHeight<=outHeight){
    //     setReverseOrNot(false);
    //    }
    //    else{
    //     setReverseOrNot(true);
    //    }
    // }
    return (
        <div className='msg-component-div'>
            <PreApprovedTemplates
                show={showPreApproveTemplates}
                selectedTemplate={(e)=>selectedTemplate(e,contactData)}
                handleCloseParent={(e) => {
                    //   console.log(e);
                    //debugger;
                    setPreApproveTemplates(false)
                }}
                contact={contactData}
            />
            {
                showDetails ?
                    <ContactDetialsModal showChatIcon={false} showDetails={showDetails}  contact={contactData} handleCloseParent={(post:any,showDelete) => { 
                        setContactData(post)
                        getUserInfo(); setShowDetails(false); 
                        setDisableSendButton(false);
                        setFinished(false);
                        debugger
                        
                        // if(noNumber && post.AcctPh_Nbr)
                        // {
                        //     tempTemplateDataIfNoNumber? selectedTemplate(tempTemplateDataIfNoNumber,post):sendMessage(post)
                        // } 

                    }} 
                    fetchDetails={true} 
                    showAddContactModal={(e, trueorFalse) => {setContactData(e);setAddNumberPopup(true)}}
                    showCallMakerModal={(e) => { console.log(e); setShowDetails(false); dispatch(setCallModalView(true)) }} />
                    : null
            }
            <CallModal
                // show={showCallModal}
                data={contactData}
                handleCloseParent={(e) => {
                    console.log(e);
                    setShowDetails(false)
                    // setShowCallModal(false)
                    dispatch(setCallModalView(false))
                }}
            />
            {
                openSaveContacts ? <SaveContactsForChat deletedOthers={deletedOthers} unknownid={chatId} number={chatName} show={openSaveContacts} handleCloseParent={(torf, data: any) => {
                    debugger

                    saveUnknown(data)
                    setOpenSaveContacts(false)
                }} /> : null
            }

            {/* header vis Key vault*/}
           <div className="besttime-banner" style={{display:BestTimeFlag==="Y"?"block":"none"}}>
                <h5 className='bannertext m-0'>
                    <b> {t('Best time to connect')}: {t("10:00 AM - 03:30 PM")}</b>
                </h5>
            </div>

            <div className='header'>
                <div className="avatar avt-2 ms-3 m-2"  >
                    {/* <Avatar {...stringAvatar(chatName)} /> */}
                    <Avatar
                        imageType={Chattype?Chattype:"Unknown"}
                        // imageType={chatIsPrescriber ? "prescriber" : 'prescriberassociates'}
                        avatarHeight="2.5rem" avatarWidth="2.5rem"  presOrAssoc={chatName} />                        
                </div>
                <div className="contact-details m-2" onClick={ (config.REACT_APP_SHOW_INFO_BTN == "N" && (!deletedOthers && finished || !deletedOthers && unknown) )?() => { setShowDetails(true) }:()=>{}}>
                    <h5 className="contacttext">
                        {chatName ? truncate(getFormatedPhoneNumber(chatName), 50) : "Unknown"} 
                    </h5>


                </div>
                <div className='headerright' >

                {
                        messageChannelType === true ?
                            <label className="autopilot-switch font-sm me-3">
                                <input
                                    type="checkbox"
                                    className="font-sm"
                                    id="msg-wtsappChechedId"
                                    checked={isSMS}
                                    onChange={(e) => { setIsSms(e.target.checked) }}
                                />
                                <Tooltip title={isSMS === false ? t('Message Mode') : t('WhatsApp Mode')} arrow>
                                    <span id="msg-wtsapptoggle" className="b-slider b-round"></span>
                                </Tooltip>
                            </label> : null
                }

                {noNumber &&  !changeTerritoryId && !deletedOthers && finished 
        && !numberAssigned ?null:
                        (!deletedOthers && finished && !unknown) ?(
                          <Tooltip title={t('Call')} arrow>
                          <button className="header-callbtn" onClick={()=>{
                                   !noNumber && dispatch(setCallModalView(true))}
                                   }><CallIcon style={{width:"20px", height:"20px"}}/></button>
                             </Tooltip>
                    ):null
                    }

                    

                    {
                        unknown ? <Tooltip title={t('Save Contact')} arrow>
                            <SaveIcon onClick={() => { saveNewContact() }} style={{ fill: "var(--primary)", fontSize: '2.5rem' }} />
                        </Tooltip>
                            : 
                        config.REACT_APP_SHOW_INFO_BTN == "Y" ? 
                           ( <div>
                                {((changeTerritoryId == true && Chattype =='ASSPRS') || changeTerritoryId == false)  && finished  ? <Tooltip title={t('Details')} arrow>
                                <i
                                    id="infoBtnId"
                                    data-v-4d521fc4=""
                                    data-name="info"
                                    data-tags=""
                                    data-type="info"
                                    className="feather feather--info i-2p5 p-0"
                                    onClick={() => { setShowDetails(true) }}

                                ><svg
                                    style={{ color: 'var(--primary-color)',verticalAlign:'unset',display:'flex' }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 24 24"
                                    fill="none"

                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-info feather__content"
                                >
                                        <circle cx="12" cy="12" r="10"></circle>
                                        <line x1="12" y1="16" x2="12" y2="12"></line>
                                        <line x1="12" y1="8" x2="12.01" y2="8"></line>
                                    </svg>
                                </i>
                            </Tooltip>:null}
                            </div>)
                            : null
                    }

                </div>




            </div>
            {
                noNumber &&  !changeTerritoryId && !deletedOthers && finished && !numberAssigned?<div className="limit-msg error-footer optOut">
                <ul>
                    <li>
                        {t('Assign number to reply')}.
                    </li></ul>

            </div> : null
            }
            
            {
               
                changeTerritoryId && !deletedOthers && Chattype!='UNKN' && (config.REACT_APP_ABS_CONFIG_SHOWBANNER=='Y'&&config.REACT_APP_ABS_APPROVED == 'Y') ? (
                    <div className="limit-msg error-footer optOut">
                        <ul>
                            {Chattype!='ASSPRS'?<li>
                                {t('No longer ABS approved')}.
                            </li>:<li>
                                {t('Contact needs ABS-approved association')}.
                            </li>}
                        </ul>

                    </div> ): null
            }
            {
                (unknown || Chattype=='UNKN')&& !deletedOthers?
                    <div className="limit-msg error-footer optOut">
                        <ul>
                            <li>
                                {t('Save contact to reply')}.
                            </li></ul>

                    </div> : null
            }

            {
                deletedOthers && finished? <div className="limit-msg error-footer optOut">
                <ul>
                    <li>
                        {t('Contact has been deleted')}.
                    </li></ul>

            </div> : null
            }
            {
                optedOut  && finished ?
                    <div className="limit-msg error-footer optOut">
                        <ul>
                            <li>
                                {t('Recipient opted out')}.
                            </li></ul>

                    </div> : null
            }
        {isNumberLandline ? (
          <div
            className="limit-msg error-footer optOut"
            // style={{ top: mainChatTop }}
          >
            <ul>
              <li>{t("This is a landline number")}</li>
            </ul>
          </div>
        ) : null}
            {/* main - all the msg conv*/}
            {showAttachLoader && <span className="loader"></span>}
            <div className='shadowin  mainchat' id="scrollableDiv" style={{
                    height: '100%'
                   // height: infiniteScrollHeight
                //   display: 'flex',
                //     flexDirection: 'column-reverse',
            }}>
                <InfiniteScroll

                    dataLength={messageData.length}
                    next={() => {
                        console.log("next");
                        setHasMore(false);
                       //    debugger
                       //    getMessageData(props.messageData,true,page+1)
                       //    setPage(page+1)
                    }}

                    //  pullDownToRefreshThreshold={50}
                    refreshFunction={() => {
                        console.log('reverse')
                    }}
                    //   pullDownToRefreshContent={
                    //     <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                    //   }
                    //   releaseToRefreshContent={
                    //     <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                    //   }
                    inverse={true} //
                    style={{ display: 'flex', flexDirection: 'column-reverse'}}
                    // scrollableTarget="contacts-body"
                    hasMore={hasMore}
                    loader={<div className="spinnerCircle"><CircularProgress color='inherit' size={'2rem'} /></div>}
                    // height={infiniteScrollHeight}
                //  scrollableTarget="scrollableDiv"
                //   endMessage={

                //      <p style={{ textAlign: "center",fontSize:'1rem',fontWeight:'lighter' }}>
                //      {messageData.length==0?<span>No data</span>:<span></span>}
                //    </p>
                //   }

                >
                    {/* <div className='innerChat'> */}
                    {



                        messageData.map((x: any) => (<Message key={x['Recipient Msg Start']} avatarChatName={chatName} message={x} msgPusherSubscriber={pusherMsgProps} handleImgPopup={(imgdisurl) => handleImgPopup(imgdisurl)} />))

                    }
                    {/* </div> */}

                </InfiniteScroll>
                {/* {
                messageData.map((x:any)=>(<Message message={x}/>))
               } */}

            </div>

            {/* error Message div */}
            {
                errorMessages.length > 0 ?
                    <div className="limit-msg error-footer">
                        <ul>
                            {errorMessages.map((error: String, index:number) => (
                                error === "" ? null : <li key={index+new Date().toISOString()}>
                                    {error}  <br />

                                </li>
                            ))}


                        </ul>
                    </div> : null
            }

            {/* footer */}
            {
                !optedOut && !unknown && !noNumber && !isNumberLandline && !(changeTerritoryId && config.REACT_APP_ABS_CONFIG_ALLOWCHAT=='N')  ? <div className='footer'>
                    <div className='insidefooter' style={{ color: 'var(--primary-color)' }}>
                    <div style={{display:'none'}}> {errorMessages.length}</div> 
                        <div onClick={() => { setPreApproveTemplates(true) }} >
                            <Tooltip title={t('Pre-Approved Templates')} arrow>
                                <i className="btn far fa-newspaper i-2 get-template-btn" style={{ color: 'var(--primary-color)' }}></i>
                            </Tooltip>
                        </div>

                        {/* <i className="fas fa-paperclip i-2" style={{ color: 'var(--primary-color)' }}>
                    <input type="file" className="custom-file-input"></input>
                    </i> */}
                    {
                        config.REACT_APP_ATTACHMENT_ENABLED=="Y" &&
                        <Tooltip title={t('Attach Files')} arrow>
                            <button
                                className="btn"
                                type='button'
                                onClick={() => { let doc: any = document; doc.getElementById('attachedfiles').click() }}
                            >

                                <i className="fas fa-paperclip i-2" style={{ color: 'var(--primary-color)' }}>
                                </i>

                            </button>
                        </Tooltip>
                    }
                    <label >
                        <input
                            type="file"
                            id="attachedfiles"
                            name="attachedfiles"
                            className="custom-file-input"
                            multiple
                            accept="application/pdf, application/vcard, text/vcard, text/x-vcard, text/csv, text/rtf, text/richtext, text/calendar, text/directory, image/bmp, image/tiff, image/jpeg, image/jpg, image/gif, image/png, video/H264, video/H263-2000, video/H263-1998, video/H263, video/H261, video/3gpp-tt, video/3gpp2, video/3gpp, video/webm, video/quicktime, video/mp4, video/mpeg, audio/basic, audio/L24, audio/mp4, audio/mpeg, audio/ogg, audio/vnd.rn-realaudio, audio/vnd.wave, audio/3gpp, audio/3gpp2, audio/ac3, audio/vnd.wave, audio/webm, audio/amr-nb, audio/amr"
                            style={{ display: 'none' }}
                            onChange={getFiles}
                        // onClick={getFiles}

                        />
                    </label>
                        

                        {/* <input id='footerinput' type="text" placeholder="Type your message..." value={chatInput} onChange={(e)=>setChatInput(e.target.value)} /> */}
                        <textarea id='footerinput' onPaste={resizeInputBox} placeholder={t("Type your message") + "..."} value={chatInput} onChange={(e) => setChatInput(e.target.value)} rows={1} maxLength={1500}
                            //   ref="textareaChat" 
                            onKeyUp={updateElSize}
                        // max-length="1500"
                        ></textarea>


                        <Tooltip title={t('Send')} arrow>
                            <button
                                className="btn btn-light sendbutton"
                                // disabled={chatInput.length <= 0 && attachedFiles.length <= 0 || disableSendButton}
                                disabled={(chatInput.length<=0?true :(!chatInput.replace(/\s/g, '').length?true:false)) && attachedFiles.length<=0 || attachedFiles.length > 10 || disableSendButton}
                                onClick={() => { sendMessage(contactData) }}
                            >

                                <i
                                    data-v-4d521fc4=""
                                    data-name="send"
                                    data-tags="message,mail,email,paper airplane,paper aeroplane"
                                    data-type="send"
                                    className="feather feather--send i-2"

                                >
                                    <svg
                                        style={{ color: 'var(--primary-color)' }}
                                        data-v-4d521fc4=""
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-send feather__content"
                                    >
                                        <line x1="22" y1="2" x2="11" y2="13"></line>
                                        <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                    </svg>
                                </i>
                            </button>
                        </Tooltip>


                    </div>

                    {
                        attachedFiles.length > 0 ?
                            <div className='attached-media grid-container'>

                                {
                                    attachedFiles.map((file: any, index) => (
                                        <div key={file.name + "-" + file.type + "-" + index + '-' + file.testTime} id={file.name + "-" + file.type + "-" + index + '-' + file.testTime}>
                                            <div className='media-tb'>
                                                <div className="media-left d-flex">
                                                    <i className="far fa-file-alt"></i>
                                                    <p>{(file.size / 1e6).toFixed(2)}</p>
                                                </div>

                                                <div>{file.name}</div>

                                                <div >
                                                    <i className="fas fa-times" onClick={(() => removeFile(index))} />

                                                </div>
                                            </div>
                                        </div>


                                    ))
                                }
                            </div>
                            : null}


                </div> : null
            }

            {/* footer ends here */}

            {/* popup start here */}
            {showImgPopup ?
                <div className='imagePopup'>
                    <div className='outerdiv'>


                        <div className='imageBox'>
                            <a
                                id='closeBtn'
                                // href="javascript:void(0)"
                                className="popupBtn"
                                // @click="downloadWithAxios(modalImageUrl)"
                                onClick={(e) => setShowImgPopup('')}
                            >
                                <i className="fa fa-times"></i>
                            </a>
                            <img src={showImgPopup}
                                alt="" 
                                className='img-size'
                                // style={{maxWidth:'800px',maxHeight:'400px',}}
                                />
                            <a
                                id='downloadBtn'
                                // href="javascript:void(0)"
                                className="popupBtn"
                                // @click="downloadWithAxios(modalImageUrl)"
                                onClick={(e) => downloadWithAxios(showImgPopup)}
                            >
                                <i className="fa fa-arrow-down"></i>
                            </a>
                        </div>
                    </div>
                </div> : null}
            {/* popup ends here */}
            {/* number add popup here starts */}
            <ContactAddPopup 
        showAddContactModal={addNumberPopup}
        data={contactData}
        redirectPage={false}
        handleCloseParent={(e)=>{
          // if(e){
          //   if(e.AcctPh_Nbr && e.IntlCallPfx_Nbr){
          //     let Temp=this.state.contactList;
          //     Temp[this.state.selectedContactIndex].AcctPh_Nbr=e.AcctPh_Nbr;
          //     Temp[this.state.selectedContactIndex].IntlCallPfx_Nbr=e.IntlCallPfx_Nbr;
          //     this.setState({showDetails:false,contactList:Temp})
          //   }  
          // }
          //setShowCallModalPopup(true);
          setShowDetails(false)
          setAddNumberPopup(false);
          getUserInfo()
          // this.setState({showAddContactModal:false, showDetails:false})}}
        }}
        />
        {/* number add popup here ends */}
        </div>
    )
}