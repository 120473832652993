import axios from "axios";
import config  from '../../env.json';
let cancelToken:any;

function getAllVideoHistory(param,callback){
  let {Page}=param as any
    try {
        axios.get(config.REACT_APP_VIDEO_API_BASE+"/meeting/video-logs?page="+Page+"&page_size=20&type=upcoming",{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}})
        .then(res=>{ 
          debugger;
          callback(res.data.data);
        }).catch((error)=>{
          if (axios.isCancel(error)) {
            callback({error:true})
          }
          else{
            callback([])
          }
          
        })
    } catch (error) {
        callback([])
    }
   
}

function CreateVideoMeeting(payload,callback){
  try {
    axios.post(config.REACT_APP_VIDEO_API_BASE+"/meeting/GetMultipleVideoURLs",payload).then(res=>{
      callback(res.data);
    }).catch(()=>{
      callback(false)
    })
} catch (error) {
    callback(false)
}
}


function getVideoLogs(param,callback){
  let {Page}=param as any
  try {
    axios.get(config.REACT_APP_VIDEO_API_BASE+"/meeting/video-logs?page="+Page+"&page_size=20&type=past",{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then(res=>{
      callback(res);
    }).catch(()=>{
      callback(false)
    })
  } catch (error) {
      callback(false)
  }
}

function getVideoLogFiles(param,callback){
  let {Id}=param as any
  try {
    console.log("api triggered")
    axios.get(config.REACT_APP_VIDEO_API_BASE+"/meeting/docs?TrnCall_Id="+Id,{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then(res=>{
      callback(res);
    }).catch(()=>{
      callback(false)
    })
  } catch (error) {
      callback(false)
  }
}
function getVideoLogParticipants(param,callback){
  let {Id}=param as any
  try {
    axios.get(config.REACT_APP_VIDEO_API_BASE+"/meeting/participants?TrnCall_Id="+Id+"&page=" + config.REACT_APP_VIDEO_PARTICIPANTS_PAGE + "&page_size=" + config.REACT_APP_VIDEO_PARTICIPANTS_PAGE_SIZE,{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then(res=>{
      callback(res);
    }).catch(()=>{
      callback(false)
    })
  } catch (error) {
      callback(false)
  }
}

function getVideoLogChats(param,callback){
  let {RoomId}=param as any
  try {
    axios.get(config.REACT_APP_VIDEO_API_BASE+"/meeting/GetChat?RoomId="+RoomId,{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then(res=>{
      callback(res);
    }).catch(()=>{
      callback(false)
    })
  } catch (error) {
      callback(false)
  }
}

function videoCallRecording(param,callback){
  let RoomSId:any=param.roomSId;
  let inOrEx:boolean=param.start
  try {
    axios.get(config.REACT_APP_VIDEO_API_BASE+"/meeting-room/start-recording?roomSid="+RoomSId+"&state="+(inOrEx?'include':'exclude'))
    .then(res=>{
      callback(res);
    }).catch(()=>{
      callback(false)
    })
  } catch (error) {
      callback(false)
  }
}


export {getAllVideoHistory,CreateVideoMeeting,getVideoLogs,getVideoLogFiles,getVideoLogParticipants,getVideoLogChats,videoCallRecording}