import React, { useState, useEffect, useRef } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Paper from '@mui/material/Paper';
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Radio, { RadioProps } from "@mui/material/Radio";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import './VideoModal.css';
import Autocomplete from "@mui/material/Autocomplete";
import { useGetPrescribersQuery, useAddContactQuery } from "../../utils/ApiContacts";
// import ReactAutocomplete from "react-autocomplete";
import SearchInput from "../../layouts/searchInput/SearchInput";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';
import config from '../../env.json';
import AssociatedPresList from "../../layouts/AssociatedPrescriber/AssociatedPresList";
import CountryDropdown from "../../layouts/countryDropdown/CountryDropdown";
// import ReactAutocomplete from "react-autocomplete";
import OutlinedInput from '@mui/material/OutlinedInput';
import { CreateVideoMeeting } from "../../apis/video/video";
import Button from "../../layouts/button/Button";
import axios from "axios";
import { sendMessageApi } from "../../apis/chat/messageApi";

// import "./AddContact.css";
import { data } from "jquery";
import { borderRadius } from "@mui/system";
import { useDebounce } from 'usehooks-ts'; import { useTranslation } from "react-i18next";
import moment from "moment";

import { useSelector, useDispatch } from 'react-redux';

import { Chip, Tooltip } from "@mui/material";
import { getPhoneNumberType } from "../../apis/checkPhoneNumberType/landlineLookupApi";
import { getFormatedPhoneNumber } from "../../utils/phoneNumberUtil";
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import { getDeviceType } from "../../utils/deviceTypeUtil";
import { isValid } from "../../utils/CheckValidString";


const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

let primcolor = document.documentElement.style.getPropertyValue('--primary-color');
const BpCheckedIcon = styled(BpIcon)({

  backgroundColor: primcolor ? document.documentElement.style.getPropertyValue('--primary-color') : '#734bd1',
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: primcolor ? document.documentElement.style.getPropertyValue('--primary-color') : '#734bd1',
  },
});
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "28%",
  borderRadius: "1rem",
  padding: ".5rem",
  bgcolor: "white",
  border: "none",
};
function BpRadio(props: RadioProps) {
  debugger;
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}


export default function VideoModal({ show, handleCloseParent }) {
  const { t } = useTranslation();
  const [othersName, setOthersName] = useState("");
  const [PrescName, setPrescName] = useState("");
  const [othersPhone, setOthersPhone] = useState("");
  const [modalHeight, setModalHeight] = useState(0);
  const [modalWidth, setModalWidth] = useState(0);
  const [hiddenPresList, setHiddenPresList] = useState(true);
  const [assoPresc, setAssoPresc] = useState({ Id: null })
  const [post, setPost] = useState([]);
  const [temp, setTemp] = useState('')
  const [age, setAge] = React.useState('');
  const [name, setName] = React.useState('');
  const [formType, setFormType] = useState(true);
  const [meetingType, setMeetingType] = useState(true);
  const [assoPrescName, setAssoPrescName] = useState("");
  const [disableBrandInput, setDisableBrandInput] = useState(true)
  const [phoneError, setPhoneError] = useState(false);
  const [meetingname, setMeetingName] = useState('');
  const [prescriberId, setPrescriberId] = useState('');
  const [userType, setUserType] = useState("P");
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [contactLoading, setContactLoading] = useState(false);

  const [pageSize, setPageSize] = useState(10);
  const debouncedValue = useDebounce<string>(othersName, 350);
  const [othersData, setOthersData] = useState([])
  const [othersDatahidden, setOthersDataHidden] = useState(true)
  const [selectedOthers, setSelecteedOthers] = useState<any>({})
  const [templateId, setTemplateId] = useState(0);
  const [secPrescribers, setSecPrescribers] = useState<any>([]);
  const [showPhnErrMsg, setShowPhnErrMsg] = useState<any>(false);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [startTime, setStartTime] = useState(moment().format('HH:mm'));
  const [mobileStyle, setMobileStyle] = useState(false);
  const [showOnce, setShowOnce] = useState(false);
  const [timeSelect, setTimeSelect] = useState(false);
  const [clearDataOnDelete, setClearDataOnDelete] = useState(false)
  const meetingName = useRef<any>(null);
  const [mutliAssociatePrescriberCheck, setMutliAssociatePrescriberCheck] = useState<any>(true);

  const messageChannelType = useSelector((state: any) => state.authApi.EmployeeMsgorWhatsAppChannelType)

  const [isSMS, setIsSms] = useState(false);
  const [showOptOutErrMsg, setShowOptOutErrMsg] = useState<any>(false);
  const [landlineArray, setLandlineArray] = useState<any>([])
  const [isValidString,setIsValidString]=useState(false);


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const getContactsData = (value) => {

    // useGetContactsQuery({
    //         page:this.state.page,
    //         pageSize:this.state.pageSize,
    //         search:this.state.search
    //       })
    // const DATATEMP = useGetContactsQuery({
    //       page:this.state.page,
    //       pageSize:this.state.pageSize,
    //       search:this.state.search
    //     })
    //     console.log(DATATEMP);
    debugger;
    if (value.length >= 2) {
      setContactLoading(true);

      console.log("Typeeeeeeeeeeeeee -----------", userType)
      let type = userType == 'O' ? 'A' : 'P';

      axios
        .get(
          config.REACT_APP_CONTACTS_API_BASE +
          "/contact?ContactType=" + type + "&Page=" + 0 + "&PageSize=" + pageSize + "&Search=" + value,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("login"),
            },
          }
        )
        .then((res) => {
          setContactLoading(false);
          setOthersData(res.data.data.filter((x: any) => x.Is_Prescriber == "N"))
          setPost(res.data.data.filter((x: any) => x.Is_Prescriber == "Y"));

        });
    } else {
      setOthersDataHidden(true)
      setOthersData([])
      setPost([]);
      setHiddenPresList(true);
    }
  };

  const checkStopTyping = (e) => {
    var timeout = setTimeout(function () { }, 0);
    clearTimeout(timeout); /// clear timeout if user is typing

    timeout = setTimeout(
      function () {
        getContactsData(assoPrescName);
      },
      500 /// Time in milliseconds
    );
  };
  useEffect(() => {
    // Do fetch here...
    debugger
    // Triggers when " " changes
    setOthersDataHidden(false)
    getContactsData(debouncedValue);
    //getModalDimensions();

  }, [debouncedValue])

  // const getModalDimensions = () => {
  //   let div: any = document.querySelector('#main')
  //   let divpaddingTop: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
  //   let divpaddingbottom: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
  //   let divpaddingleft: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-left'));
  //   let divpaddingright: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-right'));
  //   if (window.innerWidth <= 1200) {
  //     setMobileStyle(true);
  //     setModalHeight(window.innerHeight - divpaddingTop - divpaddingbottom);
  //     setModalWidth(window.innerWidth - divpaddingleft - divpaddingright);
  //   }
  // }
//   const isValid = (inputString:string)=>{

//     let pattern = /^(?=.*[a-zA-Z].*[a-zA-Z].*[a-zA-Z])[a-zA-Z0-9\s]{1,97}[a-zA-Z0-9]?\s*$/;
//     let result = pattern.test(inputString);    

//     return result;
// }

  useEffect(() => {
    calcHeightWidth();
    window.addEventListener('resize', (e) => {
      calcHeightWidth()
    })
  })
  const calcHeightWidth = () => {
    let doc: any = document
    let div: any = document.querySelector('#main')
    let divpaddingTop: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
    let divpaddingbottom: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
    let divpaddingleft: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-left'));
    let divpaddingright: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-right'));

    // console.log("gc modal toppad", divpaddingTop);
    // console.log("gc modal bottompad", divpaddingbottom);
    // console.log("gc modal leftpad", divpaddingleft);
    // console.log("gc modal rightpad", divpaddingright);
    // console.log("gc modal height", window.innerHeight - divpaddingTop - divpaddingbottom);
    // console.log("gc modal width", window.innerWidth - divpaddingleft - divpaddingright);

    let device = getDeviceType()
    if (device == "mobile" || device == "tablet" || config.REACT_APP_IS_CROSS_PLATFORM) {
      setMobileStyle(true);
      setModalHeight(window.innerHeight - divpaddingTop - divpaddingbottom);
      setModalWidth(window.innerWidth - divpaddingleft - divpaddingright);
    }

  }
  useEffect(() => {
debugger;
    if (config.REACT_APP_VIDEO_MULTI_PRES_ASSOCIATION) {
      let value = config.REACT_APP_VIDEO_MULTI_PRES_ASSOCIATION

      setMutliAssociatePrescriberCheck(config.REACT_APP_VIDEO_MULTI_PRES_ASSOCIATION == "N" ? false : true)
    }
  console.log(startDate)
    return () => {

      setStartDate(moment().format('YYYY-MM-DD'));
      setStartTime(moment().format('HH:mm'));

    }
  }, [])
  const setPrescriber = (x: any) => {
    setUserType("P");
    setPrescriberId(x.Id);
    setAssoPrescName(x.AcctFull_Nm);
    setAssoPresc(x);

    //setOthersPhone(getNumberFromItem(x));
    setHiddenPresList(true);

    //  let nophnarr = secPrescribers.filter(a => a.AcctPh_Nbr == "");
    //  debugger
    //  console.log(nophnarr)
    if (x.AcctPh_Nbr == "") {
      setShowPhnErrMsg(true);
      // return;
    }
    //setDisabled(false);
    // setLoading(true);
    console.log(othersPhone);
  };


  const getNumberFromItem = (item) => {
    debugger

    if (item.AcctPh_Nbr) {
      if (item.AcctPh_Nbr.indexOf("+") >= 0) {
        return item.AcctPh_Nbr
      } else
        return '+' + item.IntlCallPfx_Nbr + item.AcctPh_Nbr
    }
    else return ""
  }

 const changeDate=(value)=>{
  debugger
  console.log(value)
  console.log(moment(value).format("YYYY-MM-DD"))
   setStartDate(value);
 }

  const brandInputdisable = (name, phone, asso_pres) => {

    // console.log(assoPrescName === "" && othersPhone === "", assoPrescName, othersPhone)
    if (name && phone && asso_pres) {
      debugger
      setDisableBrandInput(false)
    } else {
      debugger
      setDisableBrandInput(true)
    }

  }
  const setPhoneNumber = (e) => {

    // let phn:any=parsePhoneNumber(othersPhone);
    //     let payload={
    //       AcctPh_Nbr:phn.nationalNumber,
    //       Prescriber_Id:[prescriber.Id],
    //       AcctFull_Nm:othersName,
    //       IntlCallPfx_Nbr:phn.countryCallingCode
    //     }

    if (e) {
      setOthersPhone(e);
      brandInputdisable((assoPrescName.length > 0), (e.length > 0), (assoPrescName.length > 0));
    } else {
      setOthersPhone("");
      brandInputdisable((assoPrescName.length > 0), false, (assoPrescName.length > 0));
    }
  }



  const setOthersFromList = (x) => {
    debugger
    let t: any = [x];
    setUserType("O");
    setShowOnce(false);
    setSelecteedOthers(x);
    setOthersName(x.AcctFull_Nm);
    //setOthersPhone(x.AcctPh_Nbr);
    setOthersDataHidden(true);
    if (x.Prescriber) {
      setPrescriber(x.Prescriber);
    }

    if (x) {
      setPrescriberId(x.Id);
    }
    getPhoneNumberType({ phone: getNumberFromItem(x) }, (res) => {
      if (res.type == "landline") {
        setLandlineArray([...landlineArray, getNumberFromItem(x)])
      } else {

      }
    })
    setOthersPhone(getNumberFromItem(x));

    brandInputdisable(true, (x.AcctPh_Nbr.length > 0), meetingname.length > 0)

    if (mutliAssociatePrescriberCheck) {
      t = t.concat(secPrescribers);
    }
    const result: any = [];
    const map = new Map();
    for (const item of t) {
      if (!map.has(item.Id)) {
        map.set(item.Id, true);    // set any value to Map
        result.push(item);
      }
    }
    setSecPrescribers(result);
    // showOptOutErrMsg === true ? setDisabled(true) : setDisabled(false)

    if (showOptOutErrMsg === true) {
      setDisabled(true)
    }
    if (x.AcctPh_Nbr == "") {
      setShowPhnErrMsg(true);
      // return;
    }
    // if(showOptOutErrMsg === false){
    //   setDisabled(false)
    // }


  }

  const reSetFormInputs = (e) => {
    setSelecteedOthers({});
    // console.log(e)
    setFormType(e)
    // if(e === true){
    //   setOthersPhone("");
    //   setTemplateText("")
    //   setAssoPrescName("");
    // }else{
    setTemplateId(0)
    setAssoPrescName("");
    setHiddenPresList(true);
    setSecPrescribers([])
    // setDisabled(false);
    setOthersPhone("");
    setDisableBrandInput(true)
    // setTemplate([]);
    setOthersName("");
    setAssoPrescName("");
    setMeetingType(true)
    setMeetingName('');
    setDisabled(true);
  }

  const closePopup = (torf) => {
    setOthersName('')
    setOthersPhone('')
    setMeetingName('');
    // setAssoPrescName('');
    // setDisabled(true);
    setAssoPrescName("");
    // setAssoPresc({})
    setFormType(true)
    setSecPrescribers([]);
    handleCloseParent(torf);
    setLandlineArray([])
    setStartDate(new Date());
    setStartTime(moment().format('HH:mm'));
    setShowPhnErrMsg(false)
    setShowOptOutErrMsg(false)
    setTimeout(()=>{  setDisabled(true);},200)

  }

  const setMultiPresAssocation = (e) => {
    // console.log({config.REACT_APP_MULTI_PRES_ASSOCIATION})
    // if(e === 0){
    //   // setDisabled(true)
    // } else if (secPrescribers.length > config.REACT_APP_MULTI_PRES_ASSOCIATION){
    //   checkDisabled(true,othersPhone.length>0,false);
    // }

    console.log(e)
    console.log(secPrescribers)
  }

  const clearData = (e) => {
    console.log("gc values changes e set", secPrescribers.length);
    setClearDataOnDelete(false);
    if (!mutliAssociatePrescriberCheck && e.length == 0) {
      setSecPrescribers([]);
      console.log("gc values changes e cleared!");
      checkDisabled(othersName.length > 0, !phoneError, e.length > 0, false,false);
    }
  }

  async function CreateVideo() {
    debugger;

    let isValidStringEntered = isValid(meetingname);

    if(isValidStringEntered){

 

    console.log(secPrescribers);
    let ParticipantListArr: any[] = [
    ];
    let nophnarr = secPrescribers.filter(a => a.AcctPh_Nbr == "");

    if (!meetingType) {
      var currentDate = moment(moment(new Date(startDate).toISOString().split("T")[0] + ' ' + moment(startTime, 'HH:mm').format('HH:mm')).format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');
      var getValueDate = moment(moment(new Date().toISOString().split("T")[0] + ' ' + moment().format('HH:mm')).format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');
      if (currentDate.isAfter(getValueDate)) {
        setTimeSelect(false)
      } else {
        setTimeSelect(true)
        return
      }
    }

    if (nophnarr.length > 0) {
      // setShowPhnErrMsg(true);
      // return;
    }
    else {

      secPrescribers?.map((a: any) => {
        ParticipantListArr.push(
          {
            "Id": a?.Id,
            "Type": a?.Is_Prescriber === 'Y' ? 'P' : 'O'
          }
        )
      })

    }

    console.log(meetingname, prescriberId);
    setLoading(true);
    console.log(startDate, startTime)
    console.log()
    let ChannelType: string = "SMS";
    if (!isSMS) {
      //formData.append("channel", "SMS");
      console.log("Message Type -", "SMS")
      ChannelType = "SMS";
    } else {
      console.log("Message Type -", "Whatsa")
      ChannelType = "Whatsa";
    }

  
    let payload = {
      Source: "ZingSaaS",
      channel: ChannelType,
      RoomName: meetingname,
      ParticipantList: ParticipantListArr,
      StartTime: new Date(moment(startDate).format("YYYY-MM-DD") + "T" + startTime).toISOString()
      // Requester: {
      //     Id: "1013"
      // }
    }
    console.log(
      payload
    )
    CreateVideoMeeting(payload, (res: any) => {
     
      debugger;

      if (res) {
        sendSMSWaMessage(res, ParticipantListArr);
        setMeetingType(true);
        closePopup(true);
        setLoading(false);
      }
      else {
        closePopup(false);
        setLoading(false);
      }
    })

    }
    else{
      setIsValidString(!isValidStringEntered);
    }

  }


  const sendSMSWaMessage = (videoUrlsList, typeOfUserList) => {
    debugger
    let recipentsList: any;
    let userTypeValue: string;

    if (videoUrlsList) {
      recipentsList = videoUrlsList.data.UrlList.slice(1);

      console.log("RECIPIENT LIST FOR SMS ---------> ", recipentsList);
    }

    recipentsList.forEach((recipient, index) => {
      debugger
      let phoneNumber: any = secPrescribers.find(a => a.AcctFull_Nm === recipient.userName).AcctPh_Nbr;
      let CallPfx_Nbr: any = secPrescribers.find(a => a.AcctFull_Nm === recipient.userName).IntlCallPfx_Nbr;


      if (phoneNumber) {
        if (phoneNumber.indexOf("+") >= 0) {

        } else {

          phoneNumber = '+' + CallPfx_Nbr + phoneNumber;
        }
      }

      let formData: any = new FormData();
      //debugger
      formData.append("recipient_id", recipient.participantId);
      formData.append("recipient_name", recipient.userName);
      formData.append("recipient_phone", phoneNumber);

      console.log(" NEW USER TYPE VALUE ------------------", typeOfUserList[index].Id);
      console.log(" Sec Prescriber VALUE ------------------", secPrescribers);

      if (recipient.participantId === typeOfUserList[index].Id) {
        userTypeValue = typeOfUserList[index].Type;
      }


      if (userTypeValue === "P") {
        formData.append("physcian_id", recipient.participantId);
        formData.append("role_id", '0');//for prescriber
      }
      else {
        let OthersPhysicianInfo: any = secPrescribers.find(a => a.AcctFull_Nm === recipient.userName);

        
        formData.append("physcian_id", OthersPhysicianInfo.Id);
        formData.append("role_id", '1');//for prescriber
        formData.append('association_id', OthersPhysicianInfo.Association_Id)
      }

      const smsContent = "Please Join Meeting using this URL " + recipient.videoUrl;
      formData.append("template", smsContent);
      formData.append("Campaign_ID", '1000');
      formData.append('wave_id', '1000')
      formData.append('product_id', '1000');


      if (!isSMS) {
        formData.append("channel", "SMS");
      } else {
        formData.append("channel", "whatsa");
      }
      // if (attachedFiles.length > 0) {
      //     for (var i = 0; i < attachedFiles.length; i++) {
      //         let file = attachedFiles[i];
      //         formData.append("file", file);
      //     }
      // }
      debugger
      sendMessageApi(formData, (res) => {
        console.log(res)
      })
    });



  }

  const checkDisabled = (assoPrescName, othersPhone, meetingName, status,meeting) => {
    console.log(":::::::::::::::::::::::::::::::::", secPrescribers.length, meetingName, showOptOutErrMsg, meetingname, meetingname.length, othersPhone, status)
    if ((secPrescribers.length > 0 || status) && meetingName === true && (meetingname.length > 0 || meeting)) {
      if (showOptOutErrMsg === false) {

        setDisabled(false);

      }

    }
    else setDisabled(true);
  }


  const setRadioPrescriber = () => {
    if (secPrescribers.length > 0 && meetingname.length) {
      checkDisabled(secPrescribers.length, true, true, true,true)
    }
    setUserType("P")
    setFormType(true)
    // setMeetingName('')
   // reSetFormInputs(true)
  }


  const setRadioOthers = () => {
    if (secPrescribers.length > 0 && meetingname.length) {
      checkDisabled(secPrescribers.length, true, true, true,true)
    }
    setUserType("O")
    setFormType(false)
    setOthersName('')
    //setMeetingName('')
    //reSetFormInputs(false)
  }

  const checkoptin = (temp) => {
    let data: Array<any> = [];
    let ph: Array<any> = []
    console.log("temp:::::::::::::::::", temp)

    temp.map((item) => {
      if (item.All_Ph_Cont_Flags === "N, N, N") {
        data.push(item)
      }
      if (item.AcctPh_Nbr == '') {
        ph.push(item)
      }

    })
    console.log("data::::::::::::::::::::", data, ph)
    if (data.length > 0) {
      setShowOptOutErrMsg(true)
    } else {
      setShowOptOutErrMsg(false)
    }
    if (ph.length > 0) {
      setShowPhnErrMsg(true)
    } else {
      setShowPhnErrMsg(false)
    }

  }

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={show}
        onClose={(event: object, reason: string) => {
          if (reason != 'backdropClick') {
            closePopup(false);
            //handleCloseParent(event)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      // sx={{height:"50%"}}
      >
        <Fade in={show}>
          <Box sx={style}>
            <div className="modal-box" style={mobileStyle ? { height: modalHeight, width: modalWidth } : { width: '100%', borderRadius: '1rem', overflow: 'auto' }}>
              <div className="modal-header d-flex ">
                <h1 className="font-createcontact">{t('Create Video Meeting')}</h1>
                <span onClick={() => {
                  closePopup(false);
                  // handleCloseParent(true);
                  setMeetingType(true);
                  reSetFormInputs(true);
                }}
                  className="close">
                  ×
                </span>
              </div>
              <form onSubmit={(e) => { e.preventDefault(); CreateVideo(); }}>
                <div className="modal-body video-body" 
                style={mobileStyle?{height:(modalHeight - 99.7)+'px'}:{}}
                >

                  <div className="selecttype-radio-btns">
                    <FormControl>

                      <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%', gap: '13rem'
                      }}>


                        <FormLabel id="demo-customized-radios">{t('Select Type')}</FormLabel>
                        {
                          messageChannelType == true ?
                            <div className='headerright' >
                              <label className="autopilot-switch font-sm me-3" style={{ visibility: 'visible' }}>

                                <input
                                  checked={isSMS}
                                  onChange={(e) => { setIsSms(e.target.checked) }}
                                  type="checkbox"
                                  className="font-sm"
                                  id="msg-wtsappChechedId"
                                />

                                <Tooltip title={isSMS === false ? t('Message Mode') : t('WhatsApp Mode')} arrow>
                                  <span id="msg-wtsapptoggle" className="b-slider b-round"></span>
                                </Tooltip>
                              </label>
                            </div>
                            : null
                        }
                      </div>

                      <RadioGroup
                        row
                        defaultValue="prescriber"
                        aria-labelledby="demo-customized-radios"
                        name="customized-radios"
                        onClick={(e: any) => {
                          e.target.value === "prescriber"
                            ? (
                              setRadioPrescriber()
                            )
                            : (
                              setRadioOthers()
                            )  //setFormType (Previously)
                        }}
                      >
                        <FormControlLabel
                          value="prescriber"
                          control={<BpRadio />}
                          label="Prescriber"
                        />
                        <FormControlLabel
                          value="others"
                          control={<BpRadio />}
                          label="Others"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>

                  {formType === false ? (
                    <>

                      {mutliAssociatePrescriberCheck ?
                        <div className="secPresDiv" style={secPrescribers.length > 0 ? { display: 'block' } : { display: 'none' }}>
                          {
                            secPrescribers.map((x: any) => (<Chip key={x.Id} id={x.Id} label={x.AcctFull_Nm}

                              onClick={() => {
                                checkoptin(secPrescribers)
                                if (x.All_Ph_Cont_Flags === "N, N, N") {                                  
                                  setShowOptOutErrMsg(true);
                                  checkDisabled(assoPrescName.length > 0 || othersName.length > 0, othersPhone.length > 0, true, false,false)
                                }
                                else {
                                  //setShowOptOutErrMsg(false);
                                  checkDisabled(assoPrescName.length > 0 || othersName.length > 0, othersPhone.length > 0, true, true,false)
                                }

                              }}

                              onDelete={() => {
                                let temp = secPrescribers.filter((p: any) => { if (p.Id !== x.Id) return p; })
                                let landar = landlineArray.filter((p: any) => p !== getNumberFromItem(x))
                                console.log(temp);
                                setLandlineArray(landar)
                                if (showPhnErrMsg) setShowPhnErrMsg(false);

                                setSecPrescribers(temp);
                                setOthersName('');
                                //setShowOptOutErrMsg(false);
                                checkDisabled(othersName.length > 0, !phoneError, temp.length > 0, false,false)
                                if (x.All_Ph_Cont_Flags !== "N, N, N") { setShowOptOutErrMsg(false); }
                                if (temp.length > 0) { checkoptin(temp) }
                                else {
                                  setShowOptOutErrMsg(false)
                                  setShowPhnErrMsg(false)
                                }
                              }} />))
                          }
                        </div> : null}


                      <div className="asso-prescriber-div createContactAssociate"



                      >

                        <TextField
                          // onChange={(e) => {
                          //   setTyping(true);
                          //   setAssoPrescName(e.target.value)
                          // }}
                          value={othersName}
                          label={t("Recipient Name") + '*'}
                          // Added Validation to Prescriber
                          focused
                          InputProps={
                            contactLoading ? {
                              endAdornment: <InputAdornment position="end"><CircularProgress color={'inherit'} size={'1rem'} /></InputAdornment>,
                            } : undefined
                          }
                          
                          // value={assoPrescName}
                          placeholder={t("Search for a Recipient")}
                          color="secondary"
                          id="messageBox"
                          onChange={(e) => {
                            setShowOnce(true);
                            const result = e.target.value.replace(/[^A-Za-z0-9 _.]/gi, '');
                            if (result.length > 0 && result) {
                              checkDisabled(true, othersPhone.length > 0, secPrescribers.length > 0, true,false)
                              setOthersName(result);
                            }
                            else {
                              checkDisabled(false, othersPhone.length > 0, secPrescribers.length > 0, false,false)
                              setShowOptOutErrMsg(false)
                              setOthersName(result);
                            }
                            console.log(e.target.value)

                          }
                          }


                          autoComplete="off"
                          aria-expanded="true"
                          aria-controls="presList"
                          className={'associateInput inputEnable'}
                        />


                      </div>
                      {/* <div className="othesNameDiv" hidden={othersDatahidden}>
                    <ul>
                      {
                        othersData.map((x:any)=>(
                          <li key={x.Id} onClick={()=>{
                            setOthersFromList(x);
                            
                          }}>{x.AcctFull_Nm}</li>
                        ))
                      }
                      
                    </ul>
                  </div> */}
                      {showOnce && othersData.length > 0 ?
                        <Paper hidden={othersDatahidden}>
                          <div id="presList" >

                            {othersData.length > 0 && (
                              othersData.map((x: any) => <div key={x.Id} onClick={() => {
                                setOthersFromList(x);
                                checkDisabled(false, othersPhone.length > 0, othersData.length > 0, true,false)
                                console.log(x)
                                setOthersName('');
                                if (x.All_Ph_Cont_Flags === "N, N, N") { setShowOptOutErrMsg(true); }
                              }}>{x.AcctFull_Nm}</div>)
                            )
                            }
                          </div>
                        </Paper> :
                        contactLoading == false && <Paper hidden={othersDatahidden}>
                          <div id="presList" ><div>{t('No suggestion list is displayed')}</div> </div>
                        </Paper>
                      }


                      {showOptOutErrMsg ? <p style={{ fontSize: '.5em', color: 'red' }}>{t('One or more contacts are Opted Out')}</p> : null}

                    </>

                  ) : (
                    <>
                      {/* <h1 className="font-md">Prescriber</h1><div className='presc__searchbox'>
                //                       <div className="input-group-prepend">
                //                           <span id="basic-addon3">
                //                               <button className="btn" onClick={() => { } }>
                //                                   <i className="fas fa-search i-1p5"></i>
                //                               </button>
                //                           </span>
                //                       </div>
                //                       <input type="text" className={'form-control font-md search-prescriber'} placeholder={"Search a Prescriber"} />
                //                   </div> */}
                      {mutliAssociatePrescriberCheck ?
                        <div className="secPresDiv" style={secPrescribers.length > 0 ? { display: 'block' } : { display: 'none' }}>
                          {
                            secPrescribers.map((x: any) => (<Chip key={x.Id} id={x.Id} label={x.AcctFull_Nm}

                              onClick={() => {
                                checkoptin(secPrescribers)
                                if (x.All_Ph_Cont_Flags === "N, N, N") { setShowOptOutErrMsg(true); }

                                //else { setShowOptOutErrMsg(false); }
                              }}

                              onDelete={() => {
                                let temp = secPrescribers.filter((p: any) => { if (p.Id !== x.Id) return p; });

                                if (showPhnErrMsg) setShowPhnErrMsg(false);
                                let landar = landlineArray.filter((p: any) => p !== getNumberFromItem(x))
                                console.log(temp);
                                setLandlineArray(landar)
                                setSecPrescribers(temp);
                                checkDisabled(othersName.length > 0, !phoneError, temp.length > 0, true,false)
                                setClearDataOnDelete(true);
                                if (x.All_Ph_Cont_Flags !== "N, N, N") { setShowOptOutErrMsg(false); }
                                if (temp.length > 0) { checkoptin(temp) }
                                else {
                                  setShowOptOutErrMsg(false)
                                  setShowPhnErrMsg(false)
                                }
                              }} />))
                          }
                        </div> : null}

                      <AssociatedPresList

                        placeholder={t("Search for a Prescriber")}
                        label={t("Prescriber Name") + '*'}
                        styleClass="inputEnable"
                        clearDataOnSelect={mutliAssociatePrescriberCheck}
                        multisel={mutliAssociatePrescriberCheck}
                        //onChangeInputValue={(e:any)=>{console.log(e)}} 
                        //setAssoPresc={(e:any)=>{setPrescriber(e);}}
                        //required

                        clearDataOnDelete={clearDataOnDelete}

                        onChangeInputValue={(e: any) => {
                          setMultiPresAssocation(e);
                          clearData(e)


                        }}
                        setAssoPresc={(e: any) => {
                          let t: any = [e];

                          console.log("gc values changes e t", e);
                          getPhoneNumberType({ phone: getNumberFromItem(e) }, (res) => {
                            if (res.type == "landline") {
                              setLandlineArray([...landlineArray, getNumberFromItem(e)])
                            } else {

                            }
                          })

                          //if (showPhnErrMsg) setShowPhnErrMsg(false);

                          if (e.All_Ph_Cont_Flags === "N, N, N") setShowOptOutErrMsg(true);

                          if (mutliAssociatePrescriberCheck) {
                            t = t.concat(secPrescribers);
                          }
                          const result: any = [];
                          const map = new Map();
                          for (const item of t) {
                            if (!map.has(item.Id)) {
                              map.set(item.Id, true);    // set any value to Map
                              result.push(item);
                            }
                          }
                          checkDisabled(othersName.length > 0, !phoneError, result.length > 0, true,false)
                          setClearDataOnDelete(true);

                          setSecPrescribers(result);
                          setPrescriber(e);

                        }}

                      />

              {showOptOutErrMsg ? <p style={{ fontSize: '.5em', color: 'red' }}>{t('One or more contacts are Opted Out')}</p> : null}
                    </>

                  )}



                  {/* <div className={"phno-txtfield"}>
               <CountryDropdown errorFunc={(e)=>{setDisabled(e);setPhoneError(e);if(e)brandInputdisable((assoPrescName.length > 0),false,(assoPrescName.length > 0));}} disableColored={disableBrandInput} valueNumber={othersPhone} onChangeNumber={(e:any)=>{setPhoneNumber(e)}}/>
                </div> */}

                  <div className="meeting-textfield">
                    <TextField
                      ref={meetingName}
                      className="roundborder"
                      label={t("Meeting Name") + '*'}
                      placeholder={t("Please enter meeting name")}
                      value={meetingname}
                      //required // Added Validation to meeting name
                      sx={{ width: 400 }}
                      inputProps = {{maxLength:config.REACT_APP_VIDEO_MEETING_NAME_MAXLENGTH}}
                      onChange={(e) => {
                        console.log(e.target.value)
                        setIsValidString(false)
                        const result = e.target.value.replace(/[^A-Za-z0-9 _]/gi, '');

                        if (result && result.length <= 32) {
                          setMeetingName(result)
                          showOptOutErrMsg == false && checkDisabled(assoPrescName.length > 0 || othersName.length > 0, othersPhone.length > 0, true, false,true)
                        }
                        else if(result.length > 32){
                          checkDisabled(assoPrescName.length > 0 || othersName.length > 0, othersPhone.length > 0, false, false,false)
                        }
                        else {
                          setMeetingName('')
                          checkDisabled(assoPrescName.length > 0 || othersName.length > 0, othersPhone.length > 0, false, false,false)
                        }
                      }}

                      focused
                    />
                   
                  </div>
                  {
                     isValidString && <p style={{color:'red',fontSize:'12px',margin:'2px',wordWrap: 'break-word'}}>*Name should contain atleast 3 alphabets,no all numbers,no empty string</p>
                  }
                  <div className="selecttype-radio-btns-video">
                    <FormControl>
                      <FormLabel id="demo-customized-radios">{t('Select Meeting Type')}</FormLabel>
                      <RadioGroup
                        row
                        defaultValue="Instant"
                        aria-labelledby="demo-customized-radios"
                        name="customized-radios"
                        onClick={(e: any) => {
                          if (e.target.value === "Instant") {
                            setStartDate(new Date());
                            setStartTime(moment().format('HH:mm'));
                          }
                          e.target.value === "Instant"
                            ? setMeetingType(true)
                            : setMeetingType(false);
                        }}
                      >
                        <FormControlLabel
                          value="Instant"
                          control={<BpRadio />}
                          label="Instant"
                        />
                        <FormControlLabel
                          value="Schedule"
                          control={<BpRadio />}
                          label="Schedule"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  {meetingType == true ? (<></>
) : (<>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={3}>
                  <DesktopDatePicker
                        label={t("Select Meeting Date") + '*'}
                        className="Datepicker"
                        inputFormat="MM/dd/yyyy"
                        disablePast={true}
                        value={startDate}
                       // defaultValue={new Date()} 
                        onChange={changeDate}              
                        renderInput={(params) => <TextField {...params}/>}
                      />
                       </Stack>
                       </LocalizationProvider>
                    <TextField
                      className="Timepicker"
                      id="time"
                      label={t("Select Meeting Time") + '*'}
                      type="time"
                      placeholder="--:--"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        style: {
                          height: '84',
                          padding: '1rem',
                          color: 'grey',
                        },
                      }}
                      value={startTime}
                      error={timeSelect ? true : false}
                      helperText={timeSelect ? "Selected time can not be less than the current time" : null}
                      onChange={(e) => { setStartTime(e.target.value) }}
                      sx={{ width: 150 }} focused /></>
                  )}


                  {showPhnErrMsg ? <p style={{ fontSize: '.5em', color: 'red' }}>{t('One or more contacts does not have Phone number')}</p> : null}
                  {landlineArray.length > 0 ? <p style={{ fontSize: '.5em', color: 'red' }}>{t('One or more contacts has landline number')}</p> : null}
                  <br />
                  {/* <button type="submit" className="submit-btn btn rounded-primary-40 font-md mx-auto send-videolink" style={{backgroundColor: "rgba(115, 75, 209, 0.3)"}} >
                    <span> Send Video Link</span>
                    </button> */}
                  <div className="SendVideo">
                    <Button label={t("Send video link")} type="submit" disable={disabled || landlineArray.length > 0 || showPhnErrMsg || showOptOutErrMsg} loading={loading} />
                  </div>

                  {/* <button type="button" className="create-btn" disabled={disabled} style={{opacity:disabled?0.3:1}} >
                  Create
                </button> */}

                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
