import React, { useEffect, useState } from "react";
import config from "../../env.json";
import AvatarLogo from '@mui/material/Avatar';
import './Avatar.css';



function stringAvatar(name: string, type, avatarwd, avatarht ) {
  let trimName:any;
  let primaryColor:any=document.documentElement.style.getPropertyValue('--primary-color');

  if(type){
    if(type.toLowerCase() === "unknown" || type==="UNKN" || type === "" || type === "undefined" || type == "UNKNOW" ){
      trimName="UN"
    }else{   
      
    name=name.trim().toUpperCase();
    let tempname:any=name.split(' ');
    trimName=tempname[0][0];
    if(tempname[1])
    trimName+=tempname[1][0];
    }
  }
    return {
    sx: {
      bgcolor: primaryColor+'05',
      border: "1px solid "+primaryColor+"4D",
      color: primaryColor,  
      fontSize: "1rem",
      fontWeight: "bold",
      width: avatarwd,
      height: avatarht,      
    },    
    children: trimName
  };
}

export default function Avatar(props) {
  const { imageType, avatarHeight, avatarWidth,  presOrAssoc, showAvatarType } =props

  const getIcons =() =>{
    return(
      <div>
      {imageType == "prescriberassociates" || imageType == "ASSPRS" ? 
        (
          <div className="avatar-container other-staff" style={{ height:avatarHeight, width:avatarWidth}}>
            <img src={config.REACT_APP_ASSPRS_ICON} alt="other-staff-icon"   />
          </div>
        )
      :null}
      {imageType == "prescriber" || imageType=="PRESCR"?(<div className="avatar-container" style={{ height:avatarHeight, width:avatarWidth}}>
          <img src={config.REACT_APP_PRESCR_ICON} alt="doctor-icon" />
        </div>
      ):null }
      {imageType.toLowerCase() === "unknown" || imageType==="UNKN"|| imageType == "UNKNOW" || imageType === "" || imageType === "undefined"?
      (<div className="avatar-container avatar-border" style={{ height:avatarHeight, width:avatarWidth}}>
      <img className="unknown-icon-width" src={config.REACT_APP_UNKOWN_ICON} alt="unknown-icon" />
    </div>):null
      }
      {imageType == "Employee" || imageType == "EMP" ? 
        (
          <div className="avatar-container avatar-border emp-user" style={{ height:avatarHeight, width:avatarWidth}}>
            <img src={config.REACT_APP_EMPLOYEE_ICON} alt="other-staff-icon"   />
          </div>
        )
      :null}
      
    </div>
    )
  }

  if(showAvatarType == "initialsOnly" ){
    return(
      <AvatarLogo  {...stringAvatar(presOrAssoc, "initialsOnly", avatarWidth, avatarHeight)} />
    )
  }

  if(showAvatarType == "initials" ) {
    return(
      <AvatarLogo  {...stringAvatar(presOrAssoc, imageType, avatarWidth, avatarHeight)} />
    )
  } 
   else if(showAvatarType == "icons"){
    return getIcons()

  } else{
    if(config.REACT_APP_AVATAR_WITH_ICON==="Y"){
    return  getIcons()
    } else{
      return( <AvatarLogo {...stringAvatar(presOrAssoc, imageType, avatarWidth, avatarHeight)} />)
    }
  }
}

