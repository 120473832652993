import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from "@mui/material/Fade";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { styled } from "@mui/material/styles";

import './ReasonPopup.css'


const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      theme.palette.mode === "dark"
        ? "0 0 0 1px rgb(16 22 26 / 40%)"
        : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
    backgroundImage:
      theme.palette.mode === "dark"
        ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
        : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background:
        theme.palette.mode === "dark"
          ? "rgba(57,75,89,.5)"
          : "rgba(206,217,224,.5)",
    },
  }));
  
  let primcolor=document.documentElement.style.getPropertyValue('--primary-color');
  const BpCheckedIcon = styled(BpIcon)({
  
    backgroundColor: primcolor?document.documentElement.style.getPropertyValue('--primary-color'):'#734bd1' ,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: primcolor?document.documentElement.style.getPropertyValue('--primary-color'):'#734bd1' ,
    },
  });

const optionsList = [
    {
        "label":"Sample Ordering"
    },
    {
        "label":"I approve this document"
    },
    {
        "label":"I have reviewed this document"
    }
]
 /* istanbul ignore next */
export default function ReasonPopup({show, handleClose}:any) {
    const { t } = useTranslation();
    const [value,setValue]=useState('Sample Ordering')
    const [acValue, setACValue] = useState<any>(optionsList[0]);
    const [disabled, setDisabled] = useState(true);
  return (
    <Modal
    open={show}
    //onClose={()=>{closePopup(false)}}
    closeAfterTransition
    // BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={show}>
      <Box>
      <div className="modal-box contact-box addcontact-height">
            <div className="modal-header d-flex ">
              <h1 className="font-createcontact">{t('Reason for Signature')}</h1>
              <span
                onClick={() => {
                    handleClose()
                }}
                className="close"
              >
                ×
              </span>
            </div>

            <div className="modal-body addContacts" 
            // style={mobileStyle?{height:(modalHeight - 99.7)+'px'}:{}}
            >
              <div className='autocomplete-wrapper'>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={optionsList}
                        disableClearable
                        sx={{ width: 300 }}
                        defaultValue={optionsList[0]}
                        onChange={(e,value)=>{debugger; setValue(value.label)}}
                        renderInput={(params) => <TextField {...params} label={t("Reason for Signature")} />}
                 />
                </div>
                <button
                  type="submit"
                  className="create-btn reason-popup-btn"
                //   disabled={disabled}
                //   style={{ opacity: disabled ? 0.3 : 1 }}
                  onClick={(e) => {handleClose(value) }}
                >
                  {t('Submit')}
                </button>
                {/* <div className='btn-reason-wrapper'>
                    <button className='otp-btn-primary' id="reasonSave"onClick={(e) => {handleClose(value) }}>Submit</button>
                  </div> */}
            </div>
            </div>
      </Box>
      </Fade>
      </Modal>
  )
}

