import axios from "axios";
import config  from '../../env.json';
function getAuthApi(params,callback){
    let {authToken,type}=params;
    console.log("AuthToken"+authToken)
    console.log("type"+type)
    try {
      if(config.REACT_APP_IS_CROSS_PLATFORM) {
        const uninterceptedAxiosInstance = axios.create();
        uninterceptedAxiosInstance.get(config.REACT_APP_AUTH_API_BASE+"/auth/?EndpointType=SMS")
        .then(res=>{ 
          callback(res.data);
        }).catch((error)=>{
          alert(JSON.stringify(error));
          callback(null)
        })
      } else {
        axios.get(config.REACT_APP_AUTH_API_BASE+"/auth/?EndpointType=SMS")
        .then(res=>{ 
          callback(res.data);
        }).catch(()=>{
          callback(null)
        })
      }
    } catch (error) {
        callback(null)
    }
   
}
 


export {getAuthApi}

