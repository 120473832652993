import React, { Component, useEffect } from "react";
// import Avatar from '@mui/material/Avatar';
import InfiniteScroll from "react-infinite-scroll-component";
import "./Contacts.css";
import { useGetContactsQuery } from "../../utils/ApiContacts";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { Search } from "@mui/icons-material";
import AddContact from "../../components/contact/AddContact";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation, withTranslation } from "react-i18next";
import ContactDetialsModal from "../../components/contact/ContactDetialsModal";
import SearchInput from "../../layouts/searchInput/SearchInput";
// import {wformatPhoneNumber,getTenDigitPhoneNumber} from '../../utils/phoneNumberUtil';
import { useAppSelector, useAppDispatch } from "../../app/hooks";
//import { getLoginToken } from '../../app/loginReducer';
import { RootState } from "../../app/store";
import config from "../../env.json";
import { connect, ConnectedProps } from "react-redux";
import CallModal from "../../components/call/CallModal";
import { getAllContacts } from "../../apis/contacts/contactsApi";
import { getFormatedPhoneNumber } from "../../utils/phoneNumberUtil";
import { Keyboard } from '@capacitor/keyboard';
import ContactAddPopup from "../../components/contact/ContactAddPopup";
import {
  setCallModalView,
  setCallModalDetails,
} from "../../app/slice/callModalSlice";
import {
  setChatDetails,
  setChatShowChat,
  setChatType,
  setRefreshChatList,
} from "../../app/slice/chatSlice";

import Spinner from "../../layouts/spinner/Spinner";

import Avatar from "../../components/Avatar/Avatar";
import { contactDetails } from "../../apis/contacts/contactsApi";
import { setUserCallType } from "../../app/slice/personalisationSlice";
import AddContactGAM from "../../components/contact/AddContactGAM";
import ContactDetialsModalGAM from "../../components/contact/ContactDetialsModalGAM";
let cancelToken: any = undefined;


// const connector = connect(getLoginToken)
// type PropsFromRedux = ConnectedProps<typeof connector>
// interface MyEditorProps extends PropsFromRedux {
// }
// const getLoginToken = (state: RootState) => state.login.token;

interface MyComponentProps {
  token: string;
}

class Contacts extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.iPadOrientation = this.iPadOrientation.bind(this);

    this.state = {
      contactList: [],
      page: 0,
      pageSize: 20,
      search: "",
      show: false,
      showDetails: false,
      contact: [],
      hasMore: true,
      tempValue: "",
      gridView: false,
      token: "",
      infiniteScrollHeight: 10,
      //  showCallModal: false,
      callDetails: {},
      CancelToken: new AbortController(),
      loading: false,
      selectedContactIndex: 0,
      msgOrCallIcon: false,
      paddingValRight: 0,
      paddingValBottom: 0,
      showContactCard: false,
      isHospitalContact:
        config.REACT_APP_ENABLE_HOSPITAL_CONTACT &&
        config.REACT_APP_CAMP_LIST_ENABLE_HOSPITAL_CONTACT ==
          this.props.campaign,
    };
  }
  //var refCancel:any= React.createRef();
  // const [showCallModal, setshowCallModal] = useState(false);
  componentDidMount() {


    document.documentElement.style.setProperty("--visibility", "hidden");
    console.log(this.props.token);
    document
      .getElementById("page-name")
      ?.setAttribute("data-content", this.props.t(this.props.pageTitle));
    this.getHeight();
    if (
      config.REACT_APP_ENABLE_HOSPITAL_CONTACT &&
      config.REACT_APP_CAMP_LIST_ENABLE_HOSPITAL_CONTACT == this.props.campaign
    ) {
      this.setState({ isHospitalContact: true });
    } else {
      this.setState({ isHospitalContact: false });
    }
    if(!sessionStorage.getItem("contactViewType")){
      this.setState({gridView:false});
    }else if(sessionStorage.getItem("contactViewType") === "LIST"){
      this.setState({gridView:false})
    }else{
      this.setState({gridView:true})
    }
    this.getContactsData();
    window.addEventListener("orientationchange", this.iPadOrientation);

    if(config.REACT_APP_IS_CROSS_PLATFORM) {
      Keyboard.addListener('keyboardWillShow', info => {
        // console.log('keyboard will show with height:', info.keyboardHeight);

      });

      Keyboard.addListener('keyboardDidShow', info => {
        this.getHeight();
      });

      Keyboard.addListener('keyboardWillHide', () => {
        // console.log('keyboard will hide');
      });

      Keyboard.addListener('keyboardDidHide', () => {
        // console.log('keyboard did hide');
        this.getHeight();
        console.log("---Keyboard height---");
      });
    }
    this.getHeight()
    window.addEventListener("resize", (e) => {this.getHeight()})

  }

  componentWillUnmount(): void {
    window.removeEventListener("orientationchange", this.iPadOrientation);
    sessionStorage.removeItem("contactViewType");
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>): void {
    if (
      prevState.paddingValBottom !== this.state.paddingValBottom ||
      prevState.paddingValRight !== this.state.paddingValRight ||
      prevState.infiniteScrollHeight !== this.state.infiniteScrollHeight
    )
      this.getHeight();
      document
      .getElementById("page-name")
      ?.setAttribute("data-content", this.props.t(this.props.pageTitle));
  }

  iPadOrientation(e) {
    this.getHeight();
  }

  getHeight = () => {
    let doc: any = document;
    let div: any = document.querySelector("#main");
    // console.log("gc MobHeader" + doc.getElementById("MobHeader").offsetHeight);
    // console.log("gc Header" + doc.getElementById("headerVal").offsetHeight);
    // console.log("gc search" + doc.getElementById("contactSearch").offsetHeight);
    // console.log("gc footer" + doc.getElementById("footer").offsetHeight);
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let divpaddingleft: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-left")
    );
    let divpaddingright: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-right")
    );
    this.setState({ paddingValBottom: divpaddingbottom });
    this.setState({ paddingValRight: divpaddingright });
    this.setState({
      // infiniteScrollHeight:window.innerHeight - doc.getElementById('contacts-body').offsetTop-50
      infiniteScrollHeight:
        window.innerHeight -
        (doc.getElementById("MobHeader").offsetHeight +
          document.getElementById("headerVal")?.offsetHeight +
          doc.getElementById("contactSearch").offsetHeight +
          doc.getElementById("footer").offsetHeight) -
        divpaddingTop -
        divpaddingbottom,
    });
  };

  showContactDetials = (item: any, i: number) => {
    this.setState(
      { contact: item, showDetails: true, selectedContactIndex: i },
      () => {
        // this.setState({showDetails:true})
      }
    );
    this.props.dispatch(
      setChatDetails({
        chatId: item.Id,
        chatIsPrescriber: item.Is_Prescriber == "Y" ? true : false,
        showChat: false,
        chatName: item.AcctFull_Nm,
      })
    );
  };

  callMakerModal = (item: any) => {
    this.props.dispatch(setCallModalView(true));
    this.setState({ contact: item }, () => {
      // this.setState({showDetails:true})
    });
  };

  getContactsDataForContacts = () => {
    if (this.state.search.length != 1) {
      this.setState({ contactList: [], page: 0, hasMore: true });
      this.getContactsData();
    }
  };

  getContactsData = async () => {
    // console.log("kkkkki",this.state.search.length);

    if (cancelToken) {
      console.log("hi");
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();

    getAllContacts(
      {
        page: this.state.page,
        pageSize: this.state.pageSize,
        search: this.state.search,
        CancelToken: cancelToken.token,
        contactType: this.state.isHospitalContact ? "HA" : "All",
      },
      (res: any) => {
        let temp = this.state.contactList;
        if (this.state.page === 0) temp = res;
        else temp = temp.concat(res);
        let hasMore = this.state.hasMore;
        if (res.length === 0 || res.length < this.state.pageSize)
          hasMore = false;
        else hasMore = true;

        this.setState({
          contactList: temp,
          hasMore: hasMore,
          loading: false,
        });

        // for(let item=0;item<res.length;item++){
        //   if(res[item].AcctPh_Nbr === ""){
        //     this.setState({showContactCard:true})
        //   }
        // }
      }
    );
  };

  getNumberFromItem = (item) => {
    if (item?.AcctPh_Nbr) {
      if (item?.AcctPh_Nbr.indexOf("+") >= 0) {
        return getFormatedPhoneNumber(item?.AcctPh_Nbr);
      } else
        return getFormatedPhoneNumber(
          (item?.IntlCallPfx_Nbr.indexOf("+") >= 0 ? "" : "+") +
            item?.IntlCallPfx_Nbr +
            item?.AcctPh_Nbr
        );
    } else {
      return "";
    }
  };
  truncate(source, size) {
    return source?.length > size ? source?.slice(0, size - 1) + "…" : source;
  }

  refreshContactListFunc=()=>{

    if (cancelToken) {
      console.log("hi");
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();


    getAllContacts(
      {

        page: 0,
        pageSize:this.state.contactList.length,
        search: this.state.search,
        CancelToken: cancelToken.token,
        contactType: this.state.isHospitalContact ? "HA" : "All",
      },
      (res: any) => {
        let hasMore = this.state.hasMore;
        if (res.length === 0 || res.length < this.state.pageSize)
          hasMore = false;
        else hasMore = true;

        this.setState({
          contactList: res,
          hasMore: hasMore,
          loading: false,
        });

       // this.props.dispatch(setRefreshContactsList(false));
        // for(let item=0;item<res.length;item++){
        //   if(res[item].AcctPh_Nbr === ""){
        //     this.setState({showContactCard:true})
        //   }
        // }
      }
    );
  }

  containsUppercase=(str:string)=> {
    return str ===  (str || '').toUpperCase();
  }

  getItemName=(item)=>{
    let checkUppercaseStr = this.containsUppercase(item['AcctFull_Nm'])

     if(checkUppercaseStr){
      return this.truncate(item['AcctFull_Nm'],10)
      }
      else{
      return this.truncate(item['AcctFull_Nm'],18)

      }
  }


  redirectInMobile = (item: any, isChat: any, i: any) => {
    console.log("gc 4622 - item", item, "ischat", isChat, "index", i);

    this.setState({ selectedContactIndex: i });
    console.log(this.getNumberFromItem(item));
    this.setState({ callDetails: item });
    let tempData;
    contactDetails(
      { Id: item.Id, Is_Prescriber: item.Is_Prescriber == "Y" },
      (res) => {
        if (res) {
          if (!res.message) {
            tempData = res;
            this.setState({ callDetails: tempData });
            if (tempData.AcctPh_Nbr && tempData.AcctPh_Nbr.length > 0) {
              if (isChat) {
                console.log("chat");
                this.setChat(tempData);
              } else {
                console.log("call");
                this.setState({ callDetails: tempData }, () => {
                  this.props.dispatch(
                    setCallModalDetails({
                      AcctFull_Nm: tempData.AcctFull_Nm,
                      AcctFull_Nbr: this.getNumberFromItem(tempData),
                    })
                  );
                  this.props.dispatch(setCallModalView(true));
                });
              }
            } else {
              this.setState({
                showAddContactModal: true,
                data: tempData,
                msgOrCallIcon: isChat,
              });
            }
          }
          console.log("4622 api call ", this.state.callDetails);
        }
        // setBlockUI(false);
      }
    );
  };
  setChat = (post: any) => {
    this.props.dispatch(
      setChatDetails({
        chatId: post.Id,
        chatIsPrescriber: post.Is_Prescriber == "Y",
        showChat: true,
        chatName: post.AcctFull_Nm,
      })
    );
    this.props.dispatch(
      setChatType(post.Is_Prescriber == "Y" ? "PRESCR" : "ASSPRS")
    );
    if (config.REACT_APP_IS_CROSS_PLATFORM) {
      //Do nothing check code
      window.location.href = "/chat";
    } else {
      window.location.href = "/chat";
    }
  };
  render() {
    const {
      contactList,
      page,
      pageSize,
      search,
      hasMore,
      infiniteScrollHeight,
    } = this.state;
    return (
      <div className="contacts-container">
        {this.state.showDetails ? (
          this.state.isHospitalContact ? (
            <ContactDetialsModalGAM
              showChatIcon={true}
              showDetails={this.state.showDetails}
              showAddContactModal={(e, trueorFalse) => {
                this.setState({
                  callDetails: e,
                  showAddContactModal: true,
                  showDetails: false,
                  msgOrCallIcon: trueorFalse,
                });
              }}
              contact={this.state.contact}
              fetchDetails={true} //true call api or false for get details from parent
              showCallMakerModal={(e) => {
                this.setState({ callDetails: e, showDetails: false });
                this.props.dispatch(setCallModalView(true));
              }}
              funcForEdited={(e)=>{
                this.getContactsData();
              }}
              handleCloseParent={(e, deleteValue) => {
                let Temp = this.state.contactList;

                if (deleteValue === false) {
                  Temp[this.state.selectedContactIndex] = e;
                } else {
                  Temp.splice(this.state.selectedContactIndex, 1);
                  this.getContactsData();
                }

                this.setState({ showDetails: false, contactList: Temp });
                
              }}
            />
          ) : (
            <ContactDetialsModal
              showChatIcon={true}
              showDetails={this.state.showDetails}
              showAddContactModal={(e, trueorFalse) => {
                this.setState({
                  callDetails: e,
                  showAddContactModal: true,
                  showDetails: false,
                  msgOrCallIcon: trueorFalse,
                });
              }}
              contact={this.state.contact}
              fetchDetails={true} //true call api or false for get details from parent
              showCallMakerModal={(e) => {
                this.setState({ callDetails: e, showDetails: false });
                let Temp = this.state.contactList;
                Temp[this.state.selectedContactIndex] = e;
                this.setState({ contactList: Temp });
                this.props.dispatch(setCallModalView(true));
                this.refreshContactListFunc()
              }}
              funcForEdited={(e)=>{
                this.refreshContactListFunc();
              }}
              handleCloseParent={(e, deleteValue) => {
                let Temp = this.state.contactList;

                console.log(e,deleteValue,Temp)
              if (deleteValue === false) {
                Temp[this.state.selectedContactIndex] = e;
              } else {
                Temp.splice(this.state.selectedContactIndex, 1);
                this.refreshContactListFunc();
              }
              console.log(e,deleteValue,Temp)
                this.setState({ showDetails: false, contactList: Temp });
                
              }}
            />
            )
 
        ) : (
          <AddContact
            show={this.state.show}
            handleCloseParent={(e) => {
              if (e) {
                this.getContactsData();
              }
              this.setState({ show: false });
            }}
          />
        )}
        {/* {
          this.props.showCallModal? */}
        <CallModal
          // show={this.props.showCallModal}
          data={this.state.callDetails}
          handleCloseParent={(e) => {
            console.log(e);

            this.setState({ showDetails: false });
            this.props.dispatch(setCallModalView(false));
          }}
        />
        {/* :null
        } */}

        <div id="contactSearch" className="contacts-header row--three">
          <div className="content__header__searchbox">
            <div className="contactsSearchDiv">
              <SearchInput
                id={"allContactSearch"}
                classes={"form-control font-md search-contact"}
                placeholder={this.props.t("Search Contacts")}
                onChangeValue={(e) => {
                  this.setState(
                    { search: e },
                    // this.setState({search:e,contactList:[],page:0,hasMore:true},
                    () => {
                      this.getContactsDataForContacts();
                    }
                  );
                }}
              />
            </div>
          </div>
          <div className="contactListView notMobile">
            <Tooltip title={this.props.t("Add Contact")} arrow>
              <div
                id="createContactId"
                className="floating-button create_contact notMobile"
                onClick={() => {
                  this.setState({ show: true });
                }}
              >
                <i className="fas fa-plus"></i>
              </div>
            </Tooltip>
          </div>
          <div className="contactListView notMobile contactListViewButtonsContainer">
            <Tooltip title={this.props.t("List View")} arrow>
              <i
                id="listFormatId"
                className= { "fas fa-list i-2p5 " + ( this.state.gridView ? 'btn-inactive' : 'btn-active')}
                onClick={() => {
                  sessionStorage.setItem("contactViewType", "LIST");
                  this.setState({ gridView: false });
                }}
              ></i>
            </Tooltip>
            <Tooltip title={this.props.t("Grid View")} arrow>
              <i
                id="cardFormatId"
                className={"fas fa-th-large i-2p5 "+(this.state.gridView ? 'btn-active' : 'btn-inactive')}
                onClick={() => {
                  sessionStorage.setItem("contactViewType", "GRID");
                  this.setState({ gridView: true });
                }}
              ></i>
            </Tooltip>
          </div>
        </div>
        <div className="contacts-body" id="contacts-body">
          {this.state.gridView === false ? (
            <div className="list-title notMobile">
              <div className="contactNameHeader">{this.props.t("Name")}</div>
              <div className="contactTypeHeader">{this.props.t("Type")}</div>
              <div className="contactPhoneHeader">
                {this.props.t("Phone Number")}
              </div>
            </div>
          ) : (
            ""
          )}

          {/* LIST VIEW */}
          {/* <ul> */}

          <InfiniteScroll
            dataLength={this.state.contactList.length}
            next={() => {
              console.log("ff");
              this.setState(
                {
                  page: this.state.page + 1,
                },
                () => {
                  this.getContactsData();
                }
              );
            }}
            // scrollableTarget="contacts-body"
            hasMore={hasMore}
            loader={<Spinner />}
            height={infiniteScrollHeight}
            endMessage={
              <p
                style={{
                  textAlign: "center",
                  fontSize: "1rem",
                  fontWeight: "lighter",
                }}
                className="content-spacing"
              >
                {this.state.contactList.length == 0 ? (
                  <span>{this.props.t("No contacts found")}</span>
                ) : (
                  <span></span>
                )}
              </p>
            }
          >
            {this.state.gridView === false ? (
              <ul className="contact-scroll-list">
                {contactList.map((item: any, i: number) => {
                  return (
                    <li key={item?.Id} id={item?.Id}>
                      <div
                        className="contacttListItem-div notMobile"
                        onClick={() => {
                          this.showContactDetials(item, i);
                        }}
                      >
                        <div className="avatar-div contactName">
                          {/* <Avatar {...stringAvatar(item.AcctFull_Nm)} /> */}
                          <Avatar
                            imageType={
                              item?.Is_Prescriber === "Y"
                                ? "prescriber"
                                : "prescriberassociates"
                            }
                            avatarHeight="3rem"
                            avatarWidth="3rem"
                            presOrAssoc={item["AcctFull_Nm"]}
                          />

                          <div
                            style={{
                              fontSize: "1.098rem",
                              fontWeight: "bold",
                              paddingLeft: "1rem",
                            }}
                          >
                            {this.truncate(item["AcctFull_Nm"], 25)}
                          </div>
                        </div>

                        <div
                          className="contactType"
                          style={{
                            fontVariant: "small-caps",
                            fontSize: "1.098rem",
                          }}
                        >
                          {item.Is_Prescriber === "N"
                            ? this.props.t("Others")
                            : this.props.t("Prescriber")}
                        </div>
                        <div
                          className="contactPhone"
                          style={{
                            fontVariant: "small-caps",
                            fontSize: "1.098rem",
                            paddingRight: "1rem",
                          }}
                        >
                          {this.getNumberFromItem(item)}
                          {/* {item.AcctPh_Nbr} */}
                        </div>
                      </div>

                      <div className="contacttListItem-div contacttListItem-divMobile paddingCtnBlock">
                        <div
                          className="avatar-div contactName"
                          onClick={() => {
                            this.showContactDetials(item, i);
                          }}
                        >
                          {/* <Avatar {...stringAvatar(item.AcctFull_Nm)} /> */}
                          <Avatar
                            imageType={
                              item.Is_Prescriber === "Y"
                                ? "prescriber"
                                : "prescriberassociates"
                            }
                            avatarHeight="3.5rem"
                            avatarWidth="3.5rem"
                            presOrAssoc={item["AcctFull_Nm"]}
                          />
                          <div>
                            <div
                              style={{
                                fontSize: "1.098rem",
                                fontWeight: "bold",
                                paddingLeft: "1rem",
                              }}
                            >
                              {this.truncate(item["AcctFull_Nm"], 18)}
                            </div>
                            <div
                              className="contactPhone"
                              style={{
                                fontVariant: "small-caps",
                                fontSize: "1.098rem",
                                paddingLeft: "1rem",
                              }}
                            >
                              {this.state.isHospitalContact && (
                                <div
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    fontVariant: "normal",
                                  }}
                                >
                                  {this.truncate(item?.HospFull_Nm, 25)}
                                </div>
                              )}
                              {this.getNumberFromItem(item)}
                              {/* {item.AcctPh_Nbr} */}
                            </div>
                          </div>
                        </div>
                        <div className="onlyMobile eachContactsIcons">
                          <i
                            className="material-icons i-2p5"
                            onClick={() => {
                              this.redirectInMobile(item, true, i);
                            }}
                          >
                            {this.props.t("chat")}
                          </i>
                          <i
                            className="material-icons i-2p5"
                            onClick={() => {
                              this.redirectInMobile(item, false, i);
                            }}
                          >
                            {this.props.t("phone")}
                          </i>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div>
                {this.state.contactList.length>0?
                <div className="main-grid-container">
                  {/* This is the repeating div , so loop this div to display contents */}
                  {contactList.map((item: any, i: number) => {
                    return (
                      <div
                        className="grid-item-div"
                        key={item.Id}
                        id={item.Id}
                        onClick={() => {
                          this.showContactDetials(item, i);
                        }}
                      >
                        <div>
                          <div className="contact-item-div">
                            <div className="grid-avatar-div">
                              {/* <Avatar {...stringAvatar(item.AcctFull_Nm)} /> */}
                              <Avatar
                                imageType={
                                  item.Is_Prescriber === "Y"
                                    ? "prescriber"
                                    : "prescriberassociates"
                                }
                                avatarHeight="3rem"
                                avatarWidth="3rem"
                                presOrAssoc={item["AcctFull_Nm"]}
                              />
                            </div>
                            <div className="grid-usercontact-div">
                              <label
                                style={{
                                  fontSize: "1.098rem",
                                  fontWeight: "bold",
                                }}
                              >
                                 {
                                    this.getItemName(item)
                                 }              

                              </label>
                              <label style={{ fontSize: "1.098rem" }}>
                                {item.Is_Prescriber === "N"
                                  ? this.props.t("Others")
                                  : this.props.t("Prescriber")}
                              </label>
                              <br />
                              <label style={{ fontSize: "1.098rem" }}>
                                {this.getNumberFromItem(item)}
                                {/* {item.AcctPh_Nbr} */}
                                {/* {{ wformatPhoneNumber(getTenDigitPhoneNumber(item.AcctPh_Nbr))}} */}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>:null}
              </div>
            )}
          </InfiniteScroll>

          <div
            className="create-dynamic-floating onlyMobile"
            style={{
              paddingBottom: this.state.paddingValBottom,
              paddingRight: this.state.paddingValRight,
            }}
          >
            <Tooltip title={this.props.t("Add Contact")} arrow>
              <div
                id="createContactId"
                className="floating-button create_contact"
                onClick={() => {
                  this.setState({ show: true });
                }}
              >
                <i className="fas fa-plus"></i>
              </div>
            </Tooltip>
          </div>
        </div>

        {/* Add contact modal if contact not added  user click on message icon in contact details popup will appear */}
        <ContactAddPopup
          showAddContactModal={this.state.showAddContactModal}
          data={this.state.callDetails}
          redirectPage={this.state.msgOrCallIcon}
          handleCloseParent={(e) => {
            console.log(e);
            if (e) {
              if (e.AcctPh_Nbr && e.IntlCallPfx_Nbr) {
                let Temp = this.state.contactList;
                Temp[this.state.selectedContactIndex].AcctPh_Nbr = e.AcctPh_Nbr;
                Temp[this.state.selectedContactIndex].IntlCallPfx_Nbr =
                  e.IntlCallPfx_Nbr;
                this.setState({ showDetails: false, contactList: Temp });
              }
            }
            this.setState({ showAddContactModal: false, showDetails: false });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  token: state.login.token,
  showCallModal: state.callModal.showCallModal,
  pageTitle: state.sideBar.title,
  campaign: state.personalisation.campaignName,
});

export default withTranslation()(connect(mapStateToProps)(Contacts));
