import React,{useState,useEffect} from 'react'
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import "./Header.css";
import {msalInstance} from '../../index';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import qrcode from "../../assets/images/qr-code.svg"
import poweredByP360 from "../../assets/images/powered_by_p360.png"
import {useSelector,useDispatch} from 'react-redux'
import { setAutoPilot } from '../../app/slice/loginSlice';
import { setProfileSidebarView } from '../../app/slice/loginSlice';
import LogoutModal from '../../components/logoutModal/LogoutModal';
import { autopilotApi, getAutoPilot } from '../../apis/autopilotApi/autopilotApi';
import { getAuthApi } from '../../apis/authApi/authApi';
import {  setUserVirtualNumber,setUserDetails} from '../../app/slice/authApiSlice';
import i18n from "i18next";
import { Language } from '@mui/icons-material';
import {getPersonalizationData,updatePersonalizationData, getEmailDetails} from '../../apis/personalizationApi/personalization';
import { useTranslation, initReactI18next } from "react-i18next";
import * as translang from '../../assets/translations/translation.json';
import {getlangTransData} from '../../apis/langtranslation/langTranslation';
import {ReactComponent as Edit} from '../../assets/icons/Edit.svg';
import {ReactComponent as LangIcon} from '../../assets/icons/langIcon.svg';
import {ReactComponent as LangLocIcon} from '../../assets/icons/langLocIcon.svg';
import {ReactComponent as TimeZoneIcon} from '../../assets/icons/timeZoneIcon.svg';
import MessageIcon from '@mui/icons-material/Message';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
// import EmailIcon from '@mui/icons-material/Email';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';

import config from "../../env.json";
import datalist from '../../assets/personalization/datalist.json';
import { getFormatedPhoneNumber } from '../../utils/phoneNumberUtil';
import { deleteUserDetails } from '../../apis/dbApi/dbApi';
import { letterSpacing } from '@mui/system';
import {setUserPersonalisationDeatils,setOptOutKeywords,setOptInKeywords, setUserTerritoryId} from '../../app/slice/personalisationSlice';
import {capacitormsal} from "capacitormsal";
import Pusher from 'pusher-js';
import BlockUI from '../bockUi/BlockUI';
import {getDeviceType} from '../../utils/deviceTypeUtil';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { updatePushToken } from '../../apis/notificationApi/notificationApi';
import EmailDetailsModal from '../../components/EmailDetailsModal/EmailDetailsModal';
import QRModal from '../../components/QR/QR';
import OutOfOfficeModal from '../../components/OutOfOfficeModal/OutOfOfficeModal';

function stringAvatar(name: string) {
  // if(!name){
  //   name='D P';
  // }
  return {
    sx: { 
      cursor:"pointer",
      bgcolor: "#ffffff",
      color:document.documentElement.style.getPropertyValue('--primary-color'),
      fontSize:"1rem",
      width:"35px",
      height:"35px"
    },
    children: name ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : ' ',
  };
}





function stringProfileSidebarAvatar(name: string) {
  // console.log("avatar name",name.split(' '));
  // if(!name){
  //   name='D P';
  // }
  //console.log("avatar name",name.split(' '));
  return {
    sx: {
      bgcolor: "#FAF8FF",
      color:document.documentElement.style.getPropertyValue('--primary-color'),
      fontSize:"2rem",
      width:"7rem",
      height:"7rem"
    },
    // children: `${name.split(' ')[0]}${name.split(' ')[1]}`,
    children: name ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : ' ',
  };
}

i18n.use(initReactI18next) // passes i18n down to react-i18next
.init({
  // the translations
  // (tip move them in a JSON file and import them,
  // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
  // resources: transData[0],

  interpolation: {
    escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  }
});

function Header() {
  const { t } = useTranslation();
  const dispatch=useDispatch();
  const autopilot=useSelector((state: any) => state.login.autoPilot);
  const empPersonalisationCallType=useSelector((state: any) => state.personalisation);
  const empShowCallType=useSelector((state: any) => state.personalisation.showCallType);
  const profileSidebar=useSelector((state: any) => state.login.profileSidebarView);
  const {chatId,chatName,chatIsPrescriber} = useSelector((state: any) => state.chat);
  const [open, setOpen] = useState(false);
  const [profileSidebarOpen, setProfileSidebarOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isMobile, setIsMobile] = useState(false);
  const [showAutopilot,setShowAutopilot]=useState(true);
  const [showDeleteOrNoModal,setShowDeleteOrNoModal]=useState(false);
  const [editProfile,setEditProfile]=useState(false);
  const [settingData,setSettingData]=useState({});
  const [profileHt,setProfileHt]=useState<any>();

  const [langList,setLangList]=useState<any>();
  const [tmzoneList,setTmzoneList]=useState<any>();
  const [langLocalList,setLangLocalList]=useState<any>();
  const [callTypeList,setCallTypeList]=useState<any>();
  const [notificationTypeList,setNotificationTypeList]=useState<any>();
  const [msgnotificationSelect,setMsgNotificationSelect]=useState<any>(true);
  const [emailnotificationSelect,setEmailNotificationSelect]=useState<any>(true);
  const [pushnotificationSelect,setPushNotificationSelect]=useState<any>(true);

  const [lang,setLang] = useState<any>();
  const [tmzone,settmzone] = useState<any>();
  const [langLocal,setLangLocal] = useState('');
  const [langLoc,setLangLoc] = useState('');
  const [empId,setEmpId] = useState<any>();
  const [emailId,setEmailId] = useState<any>();
  const [empCallType,setEmpCallType] = useState<any>();
  
  const [transData,setTransData]:any=useState([]);
  const userPhno=useSelector((state: any) => state.authApi.EmployeeV_Nbr);
  const userEmail=useSelector((state: any) => state.authApi.EmployeeEmail_Adr);
  const userQR=useSelector((state: any) => state.authApi.EmployeeQR_Cd);
  const userName=useSelector((state: any) => state.authApi.EmployeeFull_Nm);
  const { optOutKeywords } = useSelector((state: any) => state.personalisation);
  const [changingAuto,setChangingAuto]=useState<any>(false);
  const virtualUserId=useSelector((state: any) => state.authApi.Id);
  const [visisbileAutoPilot,setVisibilityAutoPilot]=useState<any>('hidden');
  const [campName,setCampName]:any=useState('');
  const [showEmailModal,setShowEmailModal]=useState<any>(false);
  const [showOutOfOfficeModal,setShowOutOfOfficeModal]=useState<any>(false);
  const [outOfOffice,setOutOfOffice]=useState<any>(false);
  const [modalShow, setModalShow] = React.useState(false);
  let transLocData={};


  
  // useEffect(()=>{
  //   console.log("gc var phone",userPhno);
  //   console.log("gc var email",userEmail);
  //   console.log("gc var QR",userQR);
  //   console.log("gc var name",userName);

  //   setLangLoc(window.navigator.language.toLowerCase());
  //   // setLangLoc('hi');
  //   // let dt=new Date();
  //   // let dtstr=dt.toString()
  //   // console.log("gc date",dtstr.substring(25,33));
  //   // settmzone(dtstr.substring(25,33));
  //   setupLanguage();
  //   // setupLanguage();
  
  // },[langLocal]);

  

  const handleSave=() =>{
    // empPersonalisation
  
   let emptyString = "" ;
   if(pushnotificationSelect  && msgnotificationSelect ){
    emptyString = "push,sms";
   } else if(pushnotificationSelect) {
    emptyString = "push";
   } else if(msgnotificationSelect){
    emptyString = "sms"
   }
    setEditProfile(false);
    let persPayload={
      language:lang,
      locale:langLocal,
      timeZone:tmzone,
      personalisationId:empId,
      callType:empPersonalisationCallType.callType,
      notificationType:emptyString,
      EmployeePersonalizationBottomNav_Flg:config.REACT_APP_MOBILE_BOTTOM_NAV_FLAG,
    }
    dispatch(setUserPersonalisationDeatils({
      callType:empCallType,
      language: lang,
      locale:langLocal,
      personalisationId: empId,
      timeZone:tmzone,
      notificationType:emptyString,
      campaignName:campName,
      emailId: emailId
  }))
    updatePersonalizationData(persPayload,(res:any)=>{
      if(res){
        console.log("lang update",res);
        // setFormID(res)
        setupLanguage();
        
      }
      else{
        console.log("lang update",res);
      }
    })
  }
  const setupLanguage = () => {
    setLangLoc(window.navigator.language.toLowerCase());
    // setLangLoc('hi')
    if(!tmzone){
      let dt=new Date();
      let dtstr=dt.toString()
      console.log("gc date",dtstr.substring(25,33));
      settmzone(dtstr.substring(25,33));
    }
    if(!langLocal) {
      // setLangLocal('hi');
      setLangLocal(window.navigator.language.toLowerCase())
    }
    console.log("langlocal",langLoc);

    console.log("--T--");
    console.log(t);
    console.log("--");
    console.log(t('Share'));
    if(transData<=0){
    debugger
    getlangTransData(langLocal?langLocal:window.navigator.language.toLowerCase(),(res)=>{     
      if (res && res.data) {
        // setTransData("lang res",res.data['translatedValues']);
        // debugger;
        console.log("lang trans val",res.data['translatedValues'][0]);
        //formatting the data of lang translation
        let langjson:any={};
        let transjson:any={};
        transjson['translation']=res.data['translatedValues'][0];
        langjson[langLocal]=transjson;
        // console.log("lang trans",transjson);

        // const validLangLocal = new RegExp('/(en-[A-Za-z])\w+/gm');
       
        // const result = validLangLocal.exec(langLocal);
       
        // console.log("regular exp",result);

        if(langLocal==='en-us' || langLocal==='en-usa' || langLocal==='en-USA' || langLocal === '' || langLocal==='es-es' || langLocal==='es-ES'){
          langjson['en']=transjson;
        }
        else{
          langjson[langLocal]=transjson;
        }

       
        console.log("lang",langjson);
        // console.log(langjson);
        setTransData(langjson);
        transLocData=langjson;
        // debugger
        console.log("lang before trans function",transLocData,"lang loc",langLocal);
        // console.log("lang before trans lang function",translang,"lang loc",langLocal);
        // debugger;


        
          
          console.log("to lang local data ::::::",transLocData);
          console.log("to lang local  ::::::",langLocal);

          i18n
          .use(initReactI18next) // passes i18n down to react-i18next
          .init({
            // the translations
            // (tip move them in a JSON file and import them,
            // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
            // resources: transData[0],
            resources: transLocData,

            lng: langLocal, // if you're using a language detector, do not define the lng option
            

            interpolation: {
              escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
            }
          });
        }
      })
  }
  };

  useEffect(() => {
    console.log(langLocal)
    if(langLocal === '' || langLocal === 'en-us' || langLocal === 'en-USA' || langLocal === 'es-ES'){
      
      setupLanguage();

      if(window.location.pathname == '/Home') {
        const pageName = document.getElementById("page-name");
        pageName?.setAttribute('data-content', t('New Chat'));
      }

    }

    let doc:any=document;
      let headerVal = doc.getElementById('headerVal');
      while (headerVal.classList.contains("hide-header")) {
        headerVal.classList.remove("hide-header");
      }
  
  }, [langLocal])
  
 
    
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSetLang=(e)=>{
    const lang=e.target.value;
    setLang(e.target.value);
  }
  const handleSetLangLocal=(e)=>{
    const langLocal=e.target.value;
    setLangLocal(e.target.value);
  }
  const handleSetTmZone=(e)=>{
    const TmZone=e.target.value;
    settmzone(e.target.value);
  }
  const handleEdit=(val) =>{
    setEditProfile(val);
  }
  const handleSetcalltype=(e)=>{
    console.log(e.target.value)
    const lang=e.target.value;
    setEmpCallType(e.target.value);
    dispatch(setUserPersonalisationDeatils({
      callType:e.target.value}))
  }
  const handleProfileDetials = () =>{
    setProfileSidebarOpen(!profileSidebarOpen);
  }

  const handleClose = () => {
    setOpen(false);
  };
  // const handleLogoutPopup=(torf)=>{
  //   handleClose();
  //   if(torf){
  //     logout();
  //   }
  // }
  const logout=()=>{
    if(config.REACT_APP_IS_CROSS_PLATFORM) {
      updatePushToken({
        "DeviceToken_Val": sessionStorage.getItem("pushToken"),
        "Device_Typ": "iOS",
        "is_active": 'N'
      },(res)=>{       
      })
      if(config.REACT_APP_AUTH_TYPE=="AD"){
        capacitormsal.signOut({
          authority: config.REACT_APP_AD_AUTHORITY as string,
          clientId: config.REACT_APP_AD_CLIENTID as string,
          redirectUri: config.REACT_APP_CROSS_REDIRECT_URI as string,
          scope: config.REACT_APP_CROSS_SCOPE as string,
          })
          .then(async (response) => {
            sessionStorage.setItem("login", "");
            deleteUserDetails().then(() => {
              window.location.href = "/"  
            });                
          })
          .catch(error => {
            sessionStorage.setItem("login", "");
            deleteUserDetails().then(() => {
              window.location.href = "/"  
            });
          })
      } else {
        let browser = InAppBrowser.create(config.REACT_APP_PING_LOGOUT_URL, '_blank', 'location=no');
        browser.on('loadstop').subscribe(async event => {
          browser.close();
        });
        sessionStorage.setItem("login", "");
        deleteUserDetails().then(() => {
          window.location.href = "/"  
        });
      }

    } else {
      if(config.REACT_APP_AUTH_TYPE=="AD"){
          let account=sessionStorage.getItem('loginName') as string
          dispatch({ type: 'RESET_APP' })
          msalInstance.logoutRedirect({
            account: msalInstance.getAccountByUsername(account)
          }).catch((e:any) => {
            console.log(e);
          });
       }
       else
        {
            sessionStorage.clear();
            localStorage.clear();
            var logoutUrl = config.REACT_APP_PING_LOGOUT_URL;
            window.location.href = logoutUrl
             window.location.href =config.REACT_APP_PING_REDIRECT_URL;
          //window.open(config.REACT_APP_PING_REDIRECT_URL, "_blank")
        }
    }
  }

  const handleLogoutPopup =(e:any) =>{
    // console.log(e)
    if(e=== true){
      logout();
    } 
  }
  useEffect(() => {

   
    
      let device = getDeviceType()
      if(device=="mobile" || device=="tablet" ||  config.REACT_APP_IS_CROSS_PLATFORM){
      setIsMobile(true);
    } else setIsMobile(false);
    window.addEventListener(
      "resize",
      function (event) {
        
        let device = getDeviceType()
      if(device=="mobile" || device=="tablet" ||  config.REACT_APP_IS_CROSS_PLATFORM){
          setIsMobile(true);
        } else setIsMobile(false);
        let ht = (window.innerHeight
          - (10 * window.innerHeight /100)
        )
        setProfileHt(ht);
      },
      true
    );
    
  }, [window.innerWidth])
  useEffect(()=>{
    let ht = (window.innerHeight
      - (10 * window.innerHeight /100)
    )
    setProfileHt(ht);
  }, [])
  const emailHandleSelect=()=>{    
      setEmailNotificationSelect(!emailnotificationSelect)   
  }
  const pushHandleSelect =()=>{
    if(editProfile)
    setPushNotificationSelect(!pushnotificationSelect)
  }
  const msghandleSelect=()=>{
    if(editProfile)
    setMsgNotificationSelect(!msgnotificationSelect)    
  }
  const getNotificationData=(e)=>{
    console.log(e)
    setNotificationTypeList(e);
    if(e.indexOf("push") >= 0){
      setPushNotificationSelect(true)
    } else {
      setPushNotificationSelect(false)
    }
     
    if(e.indexOf("sms") >=0){
      setMsgNotificationSelect(true)
    } else{
      setMsgNotificationSelect(false)
    }
  }
  useEffect(() => {
    // debugger
    const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
    cluster: config.REACT_APP_PUSHER_CLUSTER,
  });

  const channel = pusher.subscribe("botOnOff");
  channel.bind("RecipientId"+virtualUserId,(data:any)=>{
    console.log('data_______>',data)
    if(data)
    {
      if(data.Body==true)
      dispatch(setAutoPilot(true))
      else if(data.Body==false)
      dispatch(setAutoPilot(false))
    }
   
  })
    setLangList(datalist["language"]);
    setTmzoneList(datalist["timezone"]);
    setLangLocalList(datalist["langLocal"])
    setCallTypeList(datalist["callType"])
    
    //  if(lang<=0 && langLocal<=0 && tmzone<=0){
    getPersonalizationData((res) => {
      
      debugger
        if (res) {
      debugger
          setSettingData(res);
          
          console.log("Mobile Nav",res['EmployeePersonalizationBottomNav_Flg']);

          setEmpId(res['EmployeePersonalisation_Id']);

          setLang(res['EmployeePersonalizationPref_Lng']);

          setLangLocal(res['EmployeePersonalizationLocale_Nm']);

          settmzone(res['EmployeePersonalizationWork_TmZn']);

          setEmpCallType(res['EmployeePersonalizationCall_Typ']);



          getNotificationData(res['EmployeePersonalizationNotifcation_Typ'] ? res['EmployeePersonalizationNotifcation_Typ'] : '')

          // debugger;

          console.log("gc pers", res)
          dispatch(setUserTerritoryId(res['TerritoryAlgnTerr_Id']))

          dispatch(setUserPersonalisationDeatils({

            callType: res['EmployeePersonalizationCall_Typ'],

            language: res['EmployeePersonalizationPref_Lng'],

            locale: res['EmployeePersonalizationLocale_Nm'],

            personalisationId: res['EmployeePersonalisation_Id'],

            timeZone: res['EmployeePersonalizationWork_TmZn'],

            notificationType: res['EmployeePersonalizationNotifcation_Typ'] ? res['EmployeePersonalizationNotifcation_Typ'] : '',

            campaignName:res['CampaignName'],

            bottomNavBarFlag:res['EmployeePersonalizationBottomNav_Flg']
          }))
          let OOKeywords = res.KeywordsOptOut.map(({ Keyword_Nm }) => Keyword_Nm);
          dispatch(setOptOutKeywords(OOKeywords));
          let OIKeywords = res.KeywordsOptIn.map(({ Keyword_Nm }) => Keyword_Nm);
          dispatch(setOptInKeywords(OIKeywords));
        }  
      })
      updateEmailDetails()
    
  },[])

  const updateEmailDetails=() =>{
    getEmailDetails((res) => {
      if (res && res.data && res.data.email) {
        setEmailId(res.data.email);
      } else {
        setEmailId("")
      }
    })
  }
  
  useEffect(() => {

    if((window.location.pathname.toLowerCase()=="/chat"|| window.location.pathname=='/') && config.REACT_APP_AUTOPILOT_FLAG=="Y" && config.REACT_APP_OOO_TYPE === "TOGGLE")
    {
      console.log('in')
      setShowAutopilot(true)
      setVisibilityAutoPilot('visible')
    }else{
      console.log('out')
      setShowAutopilot(false)
      setVisibilityAutoPilot('hidden')
    }
    
  }, [window.location.pathname])
  useEffect(()=>{
    if(config.REACT_APP_AUTOPILOT_FLAG=="Y"){
      getAutoPilot((res)=>{
        console.log(res)
        dispatch(setAutoPilot(res))
        setOutOfOffice(res);
      })
    }
   
  },[])


  const handleQR = () => {
    setModalShow(true);
    dispatch(setProfileSidebarView(false))
  }

  const download = (url, name) => {
    if (!url) {
      throw new Error("Resource URL not provided! You need to provide one");
    }
        fetch(url)
      .then(response => response.blob())
      .then(blob => {
              const blobURL = URL.createObjectURL(blob);
        const a:any = document.createElement("a");
        a.href = blobURL;
        a.style = "display: none";

        if (name && name.length) a.download = name;
        document.body.appendChild(a);
        a.click();
      })
      .catch((error) => console.log(error));
  };

  
  return (

    <div className='header-div'  id="headerVal">
         {profileSidebar && 
            <div className='profile-sidebar-container' style={{height: profileHt}}>
                <div className='profile-header-right'>
                  <h1>{t('Profile')}</h1> 
                  
                  <span onClick={()=>{dispatch(setProfileSidebarView(false))}}>×</span>
                </div>
                
                {/* <div className='settings'>
                  language : EN<br/>
                  TimeZone : +05:30<br/>
                  Local : US
                </div> */}
                <div>
                <div className='profile-avatar-right mb-3'>
                  <Avatar {...stringProfileSidebarAvatar(userName)}/>
                  {config.REACT_APP_PROFILE_CUSTOMIZATION === "Y" || config.REACT_APP_SHOW_NOTIFICATION_ICON === "Y" ? 
                      <div className='editsave'>
                      { 
                        editProfile?
                        <Tooltip title={t('Save')} arrow>
                        <div className='editicon' onClick={()=>{handleSave()}}><i className="fa fa-solid fa-check"></i></div>
                        </Tooltip>
                        :
                        <Tooltip title={t('Edit')} arrow>
                          <div className='editicon' onClick={()=>{setEditProfile(true)}}><Edit/></div>
                        </Tooltip>
                      }
                      </div>:null
                  }
                  
                </div>
                
                <div className='profile-detials-right'>
                  <label className='user-bold-font'>{userName}</label>
                  <label> {getFormatedPhoneNumber(userPhno)}</label>
                  <label>{userEmail}</label>
                </div>
                  {
                    config.REACT_APP_PROFILE_CUSTOMIZATION === "Y" ? 
                    <>
                    {!editProfile?
                      <div className='settingsDisplay'>
                        <div className='singlerow'><span className='icon'><LangIcon/></span> <span className='dataSpan'>{lang}</span></div>
                        <div className='singlerow'><span className='icon'><LangLocIcon/></span> <span className='dataSpan'>{langLocal}</span></div>
                        <div className='singlerow'><span className='icon'><TimeZoneIcon/></span> <span className='dataSpan'>{tmzone}</span></div>
                        {empShowCallType === true && config.REACT_APP_SHOW_BRIDGECALL_CALLMODAL==="Y"?
                       <div className='singlerow'><span className='icon calltype'><i className="fas fa-phone-alt i-2p5 callIcon"></i></span>  <span>{t(empPersonalisationCallType.callType)}</span></div>: null}                  
                      </div>
                      :
                      <div className='settings'>
                        <div className='settrow'>
                          <div className='dropdownsicon'><LangIcon/></div>
                          <div>
                          <select value={lang} className='dropdowns' onChange={handleSetLang}>
                            {langList.map(item=><option key={item}>{item}</option>)}
                          </select>
                        </div>
                        </div>
                        <div className='settrow'>
                          <div className='dropdownsicon'><LangLocIcon/></div>
                          <div>
                          <select value={langLocal} className='dropdowns' onChange={handleSetLangLocal}>
                            {langLocalList.map(item=><option key={item}>{item}</option>)}
                          </select>
                          </div>
                        </div>
                        
                        <div className='settrow'>
                          <div className='dropdownsicon'><TimeZoneIcon/></div>
                          <div>
                          <select value={tmzone} className='dropdowns' onChange={handleSetTmZone}>
                              {tmzoneList.map(item=><option key={item}>{item}</option>)}
                          </select>
                        </div>
                        </div>
                        {/* <div className='settrow'> */}
                    
                  {/* call type */}
                  {empShowCallType === true && config.REACT_APP_SHOW_BRIDGECALL_PROFILESIDEBAR === "Y"?
                  <div className='settrow'>
                    <div className='dropdownsicon dropdownCalltype'><i className="fas fa-phone-alt i-2p5 callIcon"></i></div>
                    <div>
                    <select value={empPersonalisationCallType.callType} className='dropdowns' onChange={handleSetcalltype}>
                      {callTypeList.map(item=><option key={item}>{t(item)}</option>)}
                    </select>
                  </div>
                  </div>: null}
                 
                {/* </div> */}
                      </div>
                      
                    }
                    </>
                    :null
                  }
                  {config.REACT_APP_SHOW_NOTIFICATION_ICON === "Y"?
                  <>
                  {/* {!editProfile? */}
                  <div className='singlerow align-notification-iocn'>
                  <span className='icon'><CircleNotificationsIcon/></span>
                  <span className={pushnotificationSelect === true?'select_icon':'unselect_icon'} onClick={pushHandleSelect}><AdUnitsIcon className='unselect_icon'/></span>
                  <span className={msgnotificationSelect === true?'select_icon':'unselect_icon'} onClick={msghandleSelect}><MessageIcon/></span>
                  {/* <span className={emailnotificationSelect === true?'select_icon':'unselect_icon'}><EmailIcon/></span> */}
                  {/* <span className=''><i className="fas fa-phone-alt i-2p5 callIcon"></i></span> */}
                  </div>
                  {/* // : */}
                  {/* <div className='singlerow align-notification-iocn'>
                   <div className='settrow'>
                   <div className='dropdownsicon'><CircleNotificationsIcon/></div>
                   <div className='notification_icon_box'>
                     <span className={pushnotificationSelect === true?'select_icon':'unselect_icon'} onClick={pushHandleSelect}><AdUnitsIcon /></span>
                     <span className={msgnotificationSelect === true?'select_icon':'unselect_icon'} onClick={msghandleSelect}><MessageIcon/></span>
                   </div>
                   </div>
                   </div> */}
                  {/* //  } */}
                  </>:null}
                  
                  <>
               <div className="showEmailButton">
                 <button onClick={()=>{setShowEmailModal(true)}}>{emailId == '' ? "Add Email Details" : "Email Details"}</button>
               </div>
               </>
              
               {config.REACT_APP_OOO_TYPE === "TIMESLOT"?
                  <>
               <div className="showEmailButton mt-2">
                 <button onClick={()=>{setShowOutOfOfficeModal(true)}}>Set Out Of Office</button>
               </div>
               </>:null
                }
                
                {/* <div className='profile-detials-email'>
                  <label className='user-bold-font'>{userName}</label>
                  <label>{userPhno}</label>
                  <label>{userEmail}</label>
                </div> */}
                </div>
                {config.REACT_APP_PROFILE_QR === "Y" ? 
                <>
                  <div className='profile-qrcode-right'>
                    <img src={userQR} className='qr-code-img'/>
                  </div>
                  { userQR && <div className='profile-btns-right'>
                   <button onClick={handleQR}><Tooltip title={t('Share')} arrow><i className="fa fa-share icons-sizing" aria-hidden="true"></i></Tooltip></button>
                   <button onClick={()=> download(userQR, "qr-code.png")}> <Tooltip title={t('Download')} arrow><i className="fa fa-download icons-sizing" aria-hidden="true"></i></Tooltip></button>  
                  </div> }
                </>:null
                }
                
                {/* <div className='profile-footer-right'>
                  <img src={poweredByP360} className='poweredBy-img'></img>
                </div> */}
                <footer className='profile-footer-right'>
                <img src={poweredByP360} className='poweredBy-img'></img>
                </footer>
            </div>
        }

        <div className='page-name-div'>
          {/* H3 tag will be empty ,it has been made dynamic using CSS */}
          <h3  id="page-name" data-content="New Chat"></h3> 
          
        </div>
        {
          isMobile && showAutopilot && config.REACT_APP_OOO_TYPE === "TOGGLE" ?
          <div className='profile-btn-div'>
          
            <div className='headerright' >
           
            <label className="autopilot-label">{t('Out of Office')}</label>
            <label className="autopilot-switch font-sm me-3">
                <input
                    disabled={changingAuto}
                    checked={outOfOffice}
                    type="checkbox"
                    onClick={()=>{
                      setChangingAuto(true);
                    }}
                    onChange={(e)=>{
                     setOutOfOffice(e.target.checked);
                    autopilotApi({Id:chatId,state:e.target.checked},(res:any)=>{
                      setChangingAuto(false);
                      if(res){
                        getAutoPilot((res)=>{
                          dispatch(setAutoPilot(res))
                        })
                      }})
                    }
                    }
                    className="font-sm"
                    id="switchChechedId"
              
                />
                <span id="msg-wtsapptoggle" className="b-slider b-round"></span>
            </label>
          
        </div>
          
          </div>
          
          :null
          }
          {
          !isMobile?
          <div className='profile-btn-div'>
            
          <div className='profile-inner-div'>

            <label className="autopilot-label" style={{visibility:visisbileAutoPilot}} >{t('Out of Office')}</label> 
            <label className="autopilot-switch font-sm"  style={{visibility:visisbileAutoPilot}}>

                  <input
                  disabled={changingAuto}
                  checked={outOfOffice}
                  onClick={()=>{
                    setChangingAuto(true);
                  }}
                  onChange={(e)=>{
                    setOutOfOffice(e.target.checked);
                    autopilotApi({Id:chatId,state:e.target.checked},(res:any)=>{
                      setChangingAuto(false);
                      if(res){
                        getAutoPilot((res)=>{
                          dispatch(setAutoPilot(res))
                        })
                      }
                        });
                  }}
                          type="checkbox"
                          className="font-sm"
                          id="switchChechedId"
                          
                  />
                
                  <Tooltip title={autopilot=== true?t('Click to toggle off'):t('Click to toggle on')} arrow>
                  <span className="b-slider b-round"></span>
                  </Tooltip>
            </label>

         
          
                  
          

<Tooltip title={t('View Profile')} arrow>
          <Avatar {...stringAvatar(userName)} onClick={()=>{dispatch(setProfileSidebarView(true))}}/>
          </Tooltip>

          <Tooltip title={t('LogOut')} arrow>
            <i
              // onClick={handleClickOpen}
              onClick={()=>{setShowDeleteOrNoModal(true)}}
              id="desktoplogoutBtn"
              className="fas fa-power-off">
            </i>
          </Tooltip>


         
         
          
    <LogoutModal
      show={showDeleteOrNoModal}
      yorn={handleLogoutPopup}
      modalText={t('Are you sure you want to Logout ?')}
      // data={this.state.callDetails}
      handleCloseParent={(e)=>{
        console.log(e);
        setShowDeleteOrNoModal(false)
      }} />
      {
        changingAuto?<BlockUI show={changingAuto} />:null
      }
      
 

      </div>
      </div>:null
        }
        {showEmailModal?
        <EmailDetailsModal email = {emailId} show={showEmailModal} handleClose={(email)=>{
          setEmailId(email)
          updateEmailDetails()
          setShowEmailModal(false)}} />: null}
        {showOutOfOfficeModal &&
        <OutOfOfficeModal  show={showOutOfOfficeModal} handleClose={()=>{
          setShowOutOfOfficeModal(false)}} />}

         {modalShow && <QRModal show={modalShow}
        handleCloseParent={(e) => { setModalShow(false) }} /> }
   </div>
 
  )
}

export default Header