import React, { useState, useEffect } from 'react'
import { Routes, Route, Outlet, Link, useLocation } from "react-router-dom";
import Sidebar from "../../layouts/sidebar/Sidebar"
import Header from "../../layouts/header/Header"
import "./Home.css"
import Chat from '../chat/Chat'
import MobileHeader from '../../layouts/mobileHeader/MobileHeader';
import Contacts from '../contacts/Contacts';
import VideoFloater from '../../components/video/videoFloater/VideoFloater';
import Snackbar from '@mui/material/Snackbar';
import { setVideoCallDetails, setVideoCallShowFloater, setVideoCallRoom, setVideoOtherParticipants, setVideoLocalParticipant, setVideoCallCallStarted, setVideoCallEnded, setVideoCallScreenTrack, setVideoCallPdfTronStarted, setVideoCallChats, setVideoCallRecordingStarted, setVideoCallRecordingStartedTime } from '../../app/slice/videoCallSlice';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import {setShowSuccessSnackBar,setShowErrorSnackBar} from '../../app/slice/personalisationSlice';


import { useDispatch, useSelector } from 'react-redux';
import poweredByP360 from "../../assets/images/powered_by_p360.png"
//import MobileChatComponent from '../../components/chat/MobileChatComponent';
import TestMobileChat from '../../components/chat/MobileChat';
import {getDeviceType} from '../../utils/deviceTypeUtil';
import  config  from '../../env.json';
import axios from "axios";
import { setUserMsgChannelType } from '../../app/slice/authApiSlice';
import { useTranslation } from 'react-i18next';
import { setAllPermissions, setTitle } from '../../app/slice/sideBarSlice';
import { setPage } from '../../app/slice/chatSlice';

import ChatMi from '../../assets/icons/chat_mi.svg'
import ContactMi from '../../assets/icons/contacts_mi.svg'
import CalllogMi from '../../assets/icons/calllog_mi.svg'
import TemplateMi from '../../assets/icons/templates_mi.svg'
import ProfileMi from '../../assets/icons/profile_mi.svg'
import MenuVertical from '../../assets/icons/menu-vertica.svg'
import { useStopwatch } from 'react-timer-hook';

function Home() {
  const { t } = useTranslation();

  const {pathname} = useLocation();
  const locationPath=useLocation()
  const bottomNavBar=useSelector((state: any) => state.personalisation.bottomNavBarFlag);
  
  
  const [modalHeight, setModalHeight] = useState(0);
  const [mobileStyle, setMobileStyle] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showSideBar,setShowSideBar ]=useState(false);
  const [showOptionsMenu,setShowOptionsMenu]=useState(false);
  const [menuItems,setMenuItems] = useState<any[]>([]);
  const [optionsMenuItem,setOptionsMenuItem] = useState<any[]>([]);

  const dispatch = useDispatch();
  const room = useSelector((state: any) => state.floaterVideoCall.videoCallRoom)
  const videoCallRecordingStarted=useSelector((state:any)=>state.floaterVideoCall.videoCallRecordingStarted);
  const videoCallRecordingStartedTime=useSelector((state:any)=>state.floaterVideoCall.videoCallRecordingStartedTime)
  const { showChat } = useSelector((state: any) => state.chat);
  const { showSuccessSnackBar,showErrorSnackBar } = useSelector((state: any) => state.personalisation);
  const allPermissions=useSelector((state: any) => state.sideBar.allPermissions);
  const timerPlugin:any= useStopwatch({ autoStart: false });

const OptionMenuStyles = {
    height: 'auto',
    width: '14rem',
    background: 'rgb(255, 255, 255)',
    position: 'absolute',
    bottom: '8%',
    right: '10px',
    zIndex: '999',
    border: '2px solid var(--grey-100)',
    borderRadius: '10px',
    padding:'0.5rem'
}as React.CSSProperties;


  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  // const empPersonalisationCallType=useSelector((state: any) => state.personalisation);
  const endCall = () => {

    room.localParticipant.tracks.forEach(publication => {


      if (publication.kind == "video" || publication.kind == "audio") {
        const attachedElements = publication.track.detach();
        attachedElements.forEach(element => element.remove());
        publication.track.stop();
      }
    });

    console.log('before call')
    dispatch(setVideoCallEnded(true))
    dispatch(setVideoCallCallStarted(false))
    dispatch(setVideoCallShowFloater(false))
    dispatch(setVideoCallRecordingStarted(false))
    dispatch(setVideoCallRecordingStartedTime(null))
    room.disconnect();
    debugger
    dispatch(setVideoCallRoom(null))
    
    //setRoom(null)
  }


  const formatTime = (time) => {
    return String(time).padStart(2, "0");
  };

  useEffect(()=>{

    if(videoCallRecordingStarted){
      timerPlugin.start();
      console.log(timerPlugin)
      dispatch(setVideoCallRecordingStartedTime(0))
    }
    else{
      timerPlugin.reset();
      console.log(timerPlugin.seconds)
      dispatch(setVideoCallRecordingStartedTime(0))
    }
  },[videoCallRecordingStarted])
  // useEffect(()=>{
  //   console.log(timerPlugin)
  // },[timerPlugin])
  useEffect(()=>{
    console.log("kikikif", showSuccessSnackBar,showChat)
  }, [showSuccessSnackBar])

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(setShowSuccessSnackBar(false));
    dispatch(setShowErrorSnackBar(false));
  };

  useEffect(() => {

    getMenuItems();

      let device = getDeviceType()
      if(device=="mobile" || device=="tablet" ||  config.REACT_APP_IS_CROSS_PLATFORM){
      setIsMobile(true);
    } else setIsMobile(false);
    window.addEventListener(
      "resize",
      function (event) {
        
      let device = getDeviceType()
      if(device=="mobile" || device=="tablet" ||  config.REACT_APP_IS_CROSS_PLATFORM){
          setIsMobile(true);
        } else setIsMobile(false);
      },
      true
    );
    window.addEventListener('beforeunload', (e) => {

      if (room) {
        endCall()
      }
    });
  }, [])

  useEffect(()=>{
    console.log('handle route change here', locationPath,allPermissions)
    let activeTab=document.querySelector('.sideBarNav.active')
    if(activeTab)
    activeTab.classList.remove('active');

    if(locationPath){
      let location:any=locationPath.pathname.split('/')
      location=location.length>1?location[1]:'';
      let index:any=allPermissions.filter(x=>x.Route_Nm.replace(/ /g,"").toLowerCase()==location.toLowerCase());
      debugger
      if(index.length>0){
       
       console.log(activeTab)
      //  if(activeTab){
      //   let path:any=index[0].Route_Nm.replace(/ /g,"")
      //   let doc:any=document;
      //   doc.querySelector('.sideBarNav[href="/'+path+'"]')?.classList.add('active')
      //   dispatch(setTitle(index[0].Display_Nm))
      //  }else{
         let path:any=index[0].Route_Nm.replace(/ /g,"")
         let doc:any=document;
         doc.querySelector('.sideBarNav[href="/'+path+'"]')?.classList.add('active')
         dispatch(setTitle(index[0].Display_Nm))
       //}
      }else{
         let doc:any=document;
         doc.querySelector('.sideBarNav[href="/Chat"]')?.classList.add('active')
         let name:any='Chat'
         dispatch(setTitle(name))
      }
    }
    
  },[locationPath])
 const getMenuItems = async()=>{
 
   // let token = sessionStorage.getItem("login");
   // axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
 
     console.log(axios.defaults.headers.common)
     const menuItems =  axios.get(`${config.REACT_APP_AUTH_API_BASE}/auth/getuserpermissions`,{ headers: {"type":config.REACT_APP_AUTH_TYPE} })
     .then(response => {
       response.data.sort((a, b) => parseFloat(a.Display_Or) - parseFloat(b.Display_Or));
       //response.data = response.data.filter(item => item.Display_Or != -1);
       // response.data.map(d => d.Display_Nm === "WHATSAPP",dispatch(setUserMsgChannelType(true)));
       dispatch(setAllPermissions(response.data))

       let x = response.data.filter(d => d.Display_Nm === "WHATSAPP")
         if(x.length>0){
           console.log(x);
           dispatch(setUserMsgChannelType(true))
        } else{
         dispatch(setUserMsgChannelType(false))
        }
         let y=  response.data.filter(d => d.Display_Or >= 0);

        
         console.log("MENU ITEMSSS ----------------",y);

         let mainItemsArr = y.filter(item => item.Display_Or >=1 && item.Display_Or <=4)

         let optionsMenuItems = y.filter(item => item.Display_Or > 4)

         console.log("MAIN MENU ITEMSSS FOR MOBILE----------------",mainItemsArr);
         console.log("OPTIONS MAIN MENU ITEMSSS FOR MOBILE----------------",optionsMenuItems);

         mainItemsArr.push({
            "ClassNameForUI": MenuVertical,
            "IconStoredUrl":MenuVertical,
            "Display_Nm": "",
            "Display_Or": 20,
            "PermissionsId": 1011,
            "Permissions_Nm": "Options"
         })

       setOptionsMenuItem(optionsMenuItems)
       setMenuItems(mainItemsArr);
       
     })
     .catch(error => {
         console.error('There was an error!', error);
     });
 
     
 }

 useEffect(()=>{
    <MobileHeader showSidebar={showSideBar}/>
 },[showSideBar])


  return (
    <div id="main">
      {
        showChat && isMobile ? <TestMobileChat messageData={[]} showActiveChatPage={showChat} setShowActiveChatPage={() => { }} /> :

          <div className="home-container" id="home" >

         {showOptionsMenu && 
         <div style={OptionMenuStyles}>
          <ul>
              {

                optionsMenuItem.map((item: any) => (
                  <Link to={item.Display_Nm.replace(/ /g,"")}  style={{display:'flex',gap:'1rem',textDecoration:'none',justifyContent:'flex-start',alignItems:'center'}}
                  
                  onClick={()=>{
                      
                          dispatch(setTitle(item.Display_Nm == 'Templates'?t('Templates'):item.Display_Nm)) 
                          dispatch(setPage("/"+ item.Display_Nm.replace(/ /g,"")))
                          showSideBar&&setShowSideBar(!showSideBar)
                          setShowOptionsMenu(!showOptionsMenu)
                    }}

                  >
                  <img className="newmobileSideBarIcons" style={{ fill: "#ffffff",width:'2rem' }} src={item.IconStoredUrl} alt="menu-icon"/>
                    <li style={{fontSize:'1.2rem'}}>{item.Display_Nm}</li>
                  </Link>
                ))

              }
          </ul>
         </div>
         }

            <MobileHeader showSidebar={showSideBar}/>
            <Sidebar />

            <div className='main-content-div' >
              <Header />
              {/* Common Components */}

              <Outlet />
              {/* Common Components */}

            </div>
            
            {(bottomNavBar == 'N' || config.REACT_APP_MOBILE_BOTTOM_NAV_FLAG == 'Y') ? //'N' means No Bottom Navigation 

            (<div className="mobileSideBar_footer" id="footer">

           
            
            { menuItems.map((x: any) => (
                  
                  x.Display_Nm!="Forms" && x.Display_Nm!= "Dynamic Template"?
                  <div className='bn-tab' key={x.PermissionsId}>

                     <Link className={(pathname === `/${x.Display_Nm.replace(/ /g,"")}`) ? 'activepage' : ''} to={(x.Display_Nm == 'Profile' || x.Permissions_Nm == 'Options')?"#":"/"+ x.Route_Nm.replace(/ /g,"")}  style={{textDecoration:'none'}}
                     onClick={()=>{
                      
                      x.Display_Nm === 'Profile'? setShowSideBar(!showSideBar)
                      :

                        x.Permissions_Nm === 'Options'? setShowOptionsMenu(!showOptionsMenu):
                            dispatch(setTitle(x.Display_Nm == 'Templates'?t('Templates'):x.Display_Nm)); 
                            dispatch(setPage("/"+ x.Display_Nm.replace(/ /g,"")))
                            showSideBar&&setShowSideBar(!showSideBar)
                        
                      }}>
                          
                          <div style={{display:'flex',rowGap: '0.3rem',flexDirection:'column',fontSize:'.5rem',justifyContent:'center',alignItems:'center',width:'100%'}}>

                              {/* <i className={"newmobileSideBarIcons " + x.ClassNameForUI}></i> */}
                      <img className="newmobileSideBarIcons" style={{ fill: "#ffffff" }} src={x.IconStoredUrl} alt="menu-icon"/>
                      <b className='menu-item-text'>{t(x.Display_Nm)}</b>

                          </div>

                    </Link>
                    
                  </div>:null
                
                  
                ))

                    }
              
            

              {/* <footer className='footerMob' id="footerVal"><img src={poweredByP360} alt="" /></footer> */}
            </div>)
            
            :<div className="mobileSideBar_footer" id="footer" style={{display:'none'}}></div>
  }

          </div>
      }
      <Snackbar 
        open={showSuccessSnackBar} 
        autoHideDuration={2500} 
        onClose={handleClose}
        anchorOrigin={{ vertical:'top', horizontal:'right' }}
      >
        <Alert  severity="success" 
          sx={{ width: '50vw !important' }}>
          {t("Success")}!
        </Alert>
      </Snackbar>
      <Snackbar 
        open={showErrorSnackBar} 
        autoHideDuration={2500} 
        onClose={handleClose}
        anchorOrigin={{ vertical:'top', horizontal:'right' }}
      >
        <Alert 
          severity="error"
          sx={{ width: '50vw !important' }}
        >
          {t("An error has occurred")}!
        </Alert>
      </Snackbar>
      <VideoFloater />
      {
         videoCallRecordingStarted?
        <div id='Videotimer'>
         &#9679; {formatTime(timerPlugin.hours)}:{formatTime(timerPlugin.minutes)}:{formatTime(timerPlugin.seconds)}
        </div>
        :null
        }
    </div>
  )
}

export default Home