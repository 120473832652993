import  React,{useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import { useTranslation } from "react-i18next";
import './BlockUI.css'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '10%',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius:'1vw',
  height:'100%',
  textAlign:'center',
  // paddingTop: '20rem',
  focus:{
    outine:'none'
  }
  
};

export default function BlockUI({show}) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    
    if(show){
        handleOpen();
    }
    else
    console.log()
    // handleClose();
  }, [show])
  
  return (
    <div>
      
      <Modal
        open={true}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{padding:'2rem'}}
      >
        <Box sx={style}  className="loding-modal">
         {/* <div > */}
          <CircularProgress className='progress_svg' />
          <p className='ProgressVal'> {t('Please Wait')+"..."}</p>

         {/* </div> */}
        </Box>
      </Modal>
    </div>
  );
}
