
// var tokenMixin = Vue.mixin({
//     methods: {
import config from '../env.json';
import { formatPhoneNumberIntl, formatPhoneNumber, parsePhoneNumber, getCountryCallingCode, isPossiblePhoneNumber } from 'react-phone-number-input'
import { Contacts } from '@capacitor-community/contacts';

export const pickContact = async () => {
  const result = await Contacts.pickContact({
    projection: {
      name: true,
      phones: true,
      postalAddresses: true,
    },
  })
  return result;
};

export function wformatPhoneNumber(str) {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");
  console.log(cleaned)
  console.log("cleaned")

  //Check if the input is of correct
  let match = cleaned.match(/^(1|91|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    console.log(match);
    console.log("match")
    //Remove the matched extension code
    //Change this to format for any country code.
    let intlCode = match[1] ? "+1 " : "+";
    // +config.REACT_APP_COUNTRY_CODE;
    return [intlCode, "(", match[2], ")", match[3], "-", match[4]].join("");
  } else {
    return null;
  }


};

export function getTenDigitPhoneNumber(phone) {
  if (phone.length == 11) return phone.substring(1, 11);
  else if (phone.length == 12) return phone.substring(2, 12);
  else if (phone.length == 10) return phone;
  else return phone
};
//     },
//   });
  
export function getFormatedPhoneNumber(number){
  if(!number)
  return '';
  if(isPossiblePhoneNumber(number)){
    let c:any=parsePhoneNumber(number)
    if(c){
      let formattedNumber = formatPhoneNumber(number);
      // Remove leading zeros after formatting using regex
      formattedNumber = formattedNumber.replace(/^0+/, '');
      return (config.REACT_APP_REM_CNTRY_CD_TEXT == "N"?"+"+c.countryCallingCode+" " + formattedNumber:formattedNumber)
    }
    else 
    return formatPhoneNumberIntl(number);
  }
  else
     return number
    //return (config.REACT_APP_REM_CNTRY_CD_TEXT == "N"?formatPhoneNumber(number): number)
}

