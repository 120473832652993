import React,{useEffect,useState} from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import Radio, { RadioProps } from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Tooltip } from "@mui/material";
// import Avatar from '@mui/material/Avatar';
import { useGetContactDetailsQuery } from '../../utils/ApiContacts';
import MessageIcon from '@mui/icons-material/Message';
import CallIcon from '@mui/icons-material/Call';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockUI from '../../layouts/bockUi/BlockUI';
import EditContact from './EditContact';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import config from '../../env.json';
import moment from 'moment';
import { contactDetails,deleteContactApi } from '../../apis/contacts/contactsApi';
import { useSelector, useDispatch } from 'react-redux';
import { setChatDetails, setChatShowChat, setChatType, setRefreshChatComponent, setRefreshChatList, setNumberAssigned } from '../../app/slice/chatSlice';
import {getFormatedPhoneNumber} from '../../utils/phoneNumberUtil'
import { setCallModalView, setCallModalDetails } from '../../app/slice/callModalSlice';
import { useTranslation } from "react-i18next";
import LogoutModal from '../../components/logoutModal/LogoutModal';
import { setActiveTab, setTitle } from "../../app/slice/sideBarSlice";
import {setShowSuccessSnackBar,setShowErrorSnackBar} from '../../app/slice/personalisationSlice';
import {getDeviceType} from '../../utils/deviceTypeUtil';
import { Keyboard } from '@capacitor/keyboard';
import { useNavigate} from 'react-router-dom';

import Avatar from '../Avatar/Avatar';
import './ContactDetialsModal.css'
import { optInOutStatusUpdate, getOptInOutStatus } from "../../apis/optinoutApi/optinoutApi";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';


const OptInOptOutSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 36,
  padding: 0,
  margin: '0px !important',
  marginBottom: '2px !important',
  '& .MuiSwitch-switchBase': {
    padding: 4,
    margin: 0,
    color: '#B0B0B0',
    transitionDuration: '300ms',

    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(25px)',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#734BD1' : '#734BD1',
        opacity: 1,
        border: 0,
      }
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 28,
    height: 28,
  },
  '& .MuiSwitch-track': {
    borderRadius: 36 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#734BD1' : '#734BD1',
    opacity: 1,
    '& .Mui-checked.Mui-checked + &': {
      // Controls checked color for the track
      opacity: 1,
      backgroundColor: "green"
    }
  },
  '& .MuiSwitch-input':{
    left: '0px !important',
    width: '100% !important',
  }
}));

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '26%',
  borderRadius:'1rem',
  padding:'.5rem',
  bgcolor: 'white',
  border: 'none',
};

export default function ContactDetialsModal(props) {
 const {showDetails,contact,handleCloseParent,fetchDetails,showCallMakerModal,showAddContactModal, showChatIcon, funcForEdited}=props
  const { t } = useTranslation();
  const dispatch=useDispatch()
  // const dispatch=useDispatch();
  // const callModal=useSelector((state: any) => state.callModal.showCallModal);
  const [show,setShow]=useState(showDetails)
  const [name,setName]=useState('');
  const [type,setType]=useState('');
  const [phone,setPhone]=useState('');
  const [association,setAssociation]=useState([]);
  const [blockUI,setBlockUI]=useState(false);
  const [showEditModal,setShowEditModal]=useState(false);
  const [post,setPost]=useState<any>()
  const [deleteModal,setDeletModal]=useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [showCallModal, setshowCallModal] = useState(false);
  const [showDelete,setShowDelete]=useState(false);
  const [disableIcons, setDisableIcons] = useState(true);

  const [redirectPage, setRedirectPage] = useState('')

  const [modalHeight, setModalHeight] = useState(0);
  const [modalWidth, setModalWidth] = useState(0);
  const [mobileStyle, setMobileStyle] = useState(false);
  const { chatId } = useSelector((state: any) => state.chat);
  const Chattype = useSelector((state: any) => state.chat.type);
  const chatPermId = useSelector((state: any) =>state.sideBar.chatPermId);
  const [optInOptOut, setOptInOptOut] = useState(false);
  const [conformOptInOptOut, setConformOptInOptOut] = useState(false);
  const [optInOutUser, setOptInOutUser] = useState<any>([]);
  const [optInOutDate, setOptInOutDate] = useState("");
  const [optInOutMethodType, setOptInOutMethodType] = useState("");
  const userName=useSelector((state: any) => state.authApi.EmployeeFull_Nm);

  const navigate = useNavigate();
  // const { data: post, isFetching, isSuccess } = useGetContactDetailsQuery({
  //       id:contact.Id,
  //       IsPrescriber:contact.Is_Prescriber==="N"?false:true
  //     })
  const handleClickOpen = () => {
    setDeletModal(true);
  };

  const handleClose = () => {
    setDeletModal(false);
  };

  const handleOptInOptOut = (event) => {
    debugger;
    if (!event.target.checked) {
      //setOptInOptOut(event.target.checked);
      setConformOptInOptOut(true);
    } else {
      //setOptInOptOut(event.target.checked);
      setConformOptInOptOut(true);
    }
  }

  const handleConformOptInOptOut = (status) => {
    console.log("optInOutUser====>"+JSON.stringify(optInOutUser))
    if(status){
      setOptInOptOut(optInOptOut == false ? true : false);
      setConformOptInOptOut(false);
      updateOptInOutStatus(optInOptOut == false ? "Y" : "N");
    } else {
      setOptInOptOut(optInOptOut == true ? true : false);
      setConformOptInOptOut(false);
    }
  }

  useEffect(() => {
    //console.log("contact===============>"+JSON.stringify(contact))
    getOptInOutUser();
  },[contact]);

  useEffect(() => {
    calcHeightWidth();
  },[mobileStyle, modalHeight, modalWidth])

  useEffect(() => {
    if(config.REACT_APP_IS_CROSS_PLATFORM) {
       Keyboard.addListener('keyboardDidShow', info => {
        calcHeightWidth();
       });
 
       Keyboard.addListener('keyboardDidHide', () => {
        calcHeightWidth();
       });
     }
 },[])

  const calcHeightWidth=()=>{
    let doc:any=document
    let div:any=document.querySelector('#main') 
    let divpaddingTop:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
    let divpaddingbottom:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
    let divpaddingleft:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-left'));
    let divpaddingright:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-right'));

    // console.log("gc modal toppad",divpaddingTop);
    // console.log("gc modal bottompad",divpaddingbottom);
    // console.log("gc modal leftpad",divpaddingleft);
    // console.log("gc modal rightpad",divpaddingright);
    // console.log("gc modal height",window.innerHeight-divpaddingTop-divpaddingbottom);
    // console.log("gc modal width",window.innerWidth-divpaddingleft-divpaddingright);
    // console.log("gc modal div height",doc.getElementsByClassName('modal-box')[0].offsetHeight)
    // console.log("gc modal div width",doc.getElementsByClassName('modal-box')[0].offsetWidth)
    // setModalHeight(doc.getElementsByClassName('modal-box')[0].offsetHeight);
    //   setModalWidth(doc.getElementsByClassName('modal-box')[0].offsetWidth);
    
      let device = getDeviceType()
      if(device=="mobile" || device=="tablet" ||  config.REACT_APP_IS_CROSS_PLATFORM){
      setMobileStyle(true);
      setModalHeight(window.innerHeight-divpaddingTop-divpaddingbottom);
      setModalWidth(window.innerWidth-divpaddingleft-divpaddingright);
      // doc.getElementsById('modalOuter').offsetHeight=window.innerHeight-divpaddingTop-divpaddingbottom;
      // doc.getElementsById('modalOuter').offsetWidth=window.innerWidth-divpaddingleft-divpaddingright;

    }
   
  }
  
 
 const getNumberFromItem=(item)=>{
    if(item.AcctPh_Nbr){
      if(item.AcctPh_Nbr.indexOf("+")>=0){
        return getFormatedPhoneNumber(item.AcctPh_Nbr)
      }else
      return getFormatedPhoneNumber('+'+item.IntlCallPfx_Nbr+item.AcctPh_Nbr)
    }
    else return ""
  }
  const getContactDetails=async (contact)=>{
    debugger
    if(!fetchDetails){
         setPost(contact);
         setName(contact.AcctFull_Nm);
         setType(contact.Is_Prescriber==="N"?"Other Staff":"HCP");
        //  {contact.Is_Prescribe? setType(contact.Is_Prescriber==="N"?"Other Staff":"HCP"):null}
         setPhone(getNumberFromItem(contact));
         dispatch(setCallModalDetails({
          AcctFull_Nm:contact.AcctFull_Nm,
          AcctFull_Nbr: getNumberFrom(contact)
                
         }))
         debugger
         if(contact.Prescribers){
           setAssociation(contact.Prescribers);
         }
    }else{

      setBlockUI(true);
        contactDetails({Id:contact.Id,Is_Prescriber:contact.Is_Prescriber=="Y"},(res)=>{
        debugger

        if(res){
          if(!res.message){
            setDisableIcons(false);
            let data=res;
            dispatch(setCallModalDetails({
              AcctFull_Nm:data.AcctFull_Nm,
              AcctFull_Nbr: getNumberFrom(data)                  
             }))
             setPost(data);
             setName(data.AcctFull_Nm?data.AcctFull_Nm:'');
             setType(contact.Is_Prescriber==="N"?"Other Staff":"HCP")
            //  {contact.Is_Prescribe? setType(contact.Is_Prescriber==="N"?"Other Staff":"HCP"):null}
             
             getNumberFromItem(data)
             setPhone(getNumberFromItem(data));
             if(data.Prescribers){
               setAssociation(data.Prescribers)
             }
            } else {
              setDisableIcons(true);
            }
          }
          setBlockUI(false);
       
      })
      // await axios.get(config.REACT_APP_CONTACTS_API_BASE+"/contact/"+contact.Id,{
      //    headers:{
      //      'Authorization':'Bearer '+sessionStorage.getItem('login')
      //    },
      //    params:{
      //      "IsPrescriber":contact.Is_Prescriber==="Y",
      //    }
      //  }).then(res=>{
      //   let data=res.data.data;
      //   setBlockUI(false);
      //    setPost(data);
      //    setName(data.AcctFull_Nm);
      //    setType(contact.Is_Prescriber==="N"?"Other":"HCP");
      //    setPhone(data.AcctPh_Nbr);
      //    if(data.Prescriber){
      //      setAssociation(data.Prescriber.AcctFull_Nm)
      //    }
      //  }).catch(()=>{
      //    setBlockUI(false);
      //  })
    }
    

  }

      // useEffect(() => {
      //  console.log(post);
      //  if(isSuccess)
      //  {
      //   setBlockUI(false)
      //   if(post){
      //     setName(post.data.AcctFull_Nm);
      //     setType(contact.Is_Prescriber==="N"?"Other":"HCP");
      //     setPhone(post.data.AcctPh_Nbr);
      //     if(post.data.Prescriber){
      //       setAssociation(post.data.Prescriber.AcctFull_Nm)
      //     }
          
      //   }
      //  }
      // }, [isSuccess])
      // useEffect(() => {
      //   setBlockUI(true)
      // }, [isFetching])
      
      const getNumberFrom=(item)=>{
        if(item.AcctPh_Nbr){
          if(item.AcctPh_Nbr.indexOf("+")>=0){
            return item.AcctPh_Nbr;
          }else
          return '+'+item.IntlCallPfx_Nbr+item.AcctPh_Nbr;
        }
        else return ""
      }

      useEffect(() => {
        window.addEventListener('orientationchange', calcHeightWidth);
        return () => {
          window.removeEventListener('orientationchange', calcHeightWidth)
        }
      },[])

      useEffect(() => {
        getContactDetails(contact);
        debugger
        setShow(showDetails);
        calcHeightWidth();

      }, [showDetails])
      
      const deleteContact=(e:any)=>{
        //  axios.delete(config.REACT_APP_CONTACTS_API_BASE+"/contact/"+contact.Id,{
        //   headers:{
        //     'Authorization':'Bearer '+sessionStorage.getItem('login')
        //   }
        // }).then(res=>{
        //   handleCloseParent();
        // })
        debugger
        if(e === true){
          setShowDelete(true)
          deleteContactApi(contact.Id,(res)=>{
            
            if(res ){
              debugger
               if(chatId==contact.Id && Chattype=='ASSPRS'){
    //             dispatch(setChatType('UNKN'))
    // dispatch(setChatDetails({
    //     chatId:contact.Id,
    //     chatName:getFormatedPhoneNumber(phone),
    //     chatIsPrescriber:false,
    //     showChat:false
    // }))
    

    dispatch(setRefreshChatComponent(false))
    dispatch(setShowSuccessSnackBar(true));
               }
              dispatch(setRefreshChatList(true))
            }
            handleCloseParent();
            debugger
          })
        }
        
      }
      const getNumber=(number)=>{
        if(!number)
        return '';
        if(number.indexOf("+")>=0)
        return formatPhoneNumberIntl(number);
        else
          return number
      }
      const updateContactAfterEdit=(contact)=>{
        setPost(contact);
        getContactDetails(contact)
      }    
      const setChat=(post:any)=>{
          dispatch(setChatDetails(
          {
            chatId:post.Id,
            chatIsPrescriber:post.Is_Prescriber=="Y"?true:false,
            showChat:true,
            chatName:post.AcctFull_Nm
          }))
          dispatch(setNumberAssigned(true))
          // dispatch(setChatDetails(
          //   {
          //     chatId:post.Id,
          //     chatIsPrescriber:post.Is_Prescriber=="Y"?true:false,
          //     showChat:true,
          //     chatName:post.AcctFull_Nm
          //   }))
       
          dispatch(setChatType(post.Is_Prescriber=="Y"? "PRESCR":"ASSPRS")) 
          // dispatch(setTitle('Chat'))      
          navigate("/chat");
          dispatch(setActiveTab(chatPermId));
      }

      const updateOptInOutStatus = (flg) => {
        console.log("-------------------->"+flg);
        let payload:any={
          UserId:contact.Id,
          UserType:contact.Is_Prescriber == "N" ? "ASSPRS" : "PRESCR",
          OptInOutMethod_Typ:"rep_opted_out",
          Is_Opted_Out: flg,
        }
        optInOutStatusUpdate(payload,async (res)=>{
          if(res){
            console.log("update res===>"+JSON.stringify(res));
          }
          else{             
             dispatch(setShowErrorSnackBar(true));
          }
        })
      }

      const getOptInOutUser = () => {
        //console.log("====***post***===>"+contact.Id);
        let payload:any={
          UserId: contact.Id,
          UserType: contact.Is_Prescriber == "N" ? "ASSPRS" : "PRESCR",
        };
        getOptInOutStatus(payload,async (res)=>{
          if(res){
            setOptInOutUser(res);
            setOptInOutDate(res?.data?.length != 0 ? moment(res?.data[0]?.UpdatedBy_DtTm).local().format("LL") : "");
            setOptInOutMethodType(res?.data?.length != 0 ? res?.data[0]?.OptInOutMethod_Typ : "");
            if(res?.data?.length != 0 && res?.data[0]?.Active_Flg == 'Y' && res?.data[0]?.OptInOutAll_Flg == 'N'){
              setOptInOptOut(true);
            } else if(res?.data?.length == 0 || (res?.data[0]?.Active_Flg == 'Y' && res?.data[0]?.OptInOutAll_Flg == 'Y') || res?.data[0]?.Active_Flg == 'N'){
              setOptInOptOut(false);
            }
          }
          else{             
             dispatch(setShowErrorSnackBar(true));
          }
        })
      }

      function truncate(source, size) {
        return source.length > size ? source.slice(0, size - 1) + "…" : source;
      }
  return (
    <div>
    {/* <Button onClick={handleOpen}>Open modal</Button> */}
  
    <EditContact 
    show={showEditModal}
    handleCloseParent={(e,contact:any)=>{
      setShowEditModal(false);
      
      let path:any=window.location.pathname.toLowerCase();
      if(path != "/chat" && path.length>1 ){
        dispatch(setChatShowChat(false));
      }
    if(e){
      funcForEdited && funcForEdited(contact)
      updateContactAfterEdit(contact);
    }
   }}
    onChangefunction={(e)=>{console.log(e); setShow(e)}}
    contact={post}
    />
      {/* <CallModal
    show={showCallModal}
    handleCloseParent={(e)=>{
      console.log(e);
      debugger;
      setshowCallModal(false)}}
    /> */}
    <div className='customParentDivModal'>
    <Modal
      
      open={show}
      // onClose={handleCloseParent}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      // onClose={(e:any,r:any)=>{
      //   if(r!="backdropClick")
      //   handleCloseParent()      

      // }}
      
      
    >
      <Fade in={show}>
        <Box>
         
            <div id='modalOuter' className='modal-box' style={mobileStyle?{height:modalHeight+'px',width:modalWidth+'px'}:{}}>
            <div className="modal-header d-flex">
               <h1 className="font-createcontact">{t('Contact Details')}</h1>
               <span onClick={()=>{handleCloseParent(post,showDelete)}} className="close">×</span>
             </div>
               
             <div>
              { optInOptOut && optInOutMethodType.includes("rep_op") ? <ul className="opt-out-message optOut font-md-normal">
                  <li>
                    {t(userName + ' Opted out on ' + optInOutDate)}.
                  </li>
                </ul> : null
              }

              { optInOptOut && optInOutMethodType.includes("contac") ? <ul className="opt-out-message optOut font-md-normal">
                  <li>
                    {t('Recipient Opted out on ' + optInOutDate)}.
                  </li>
                </ul> : null
              }

              { optInOptOut && optInOutMethodType.includes("admin") ? <ul className="opt-out-message optOut font-md-normal">
                  <li>
                    {t('Administrator Opt Out on ' + optInOutDate)}.
                  </li>
                </ul> : null}
             </div>

             <div className="modal-body modal-view">
              <div className='name-avatar-div modal-body'>
                 <div className='contact-avatar-div'>
                     {/* <Avatar {...stringAvatar(name?name:'')} /> */}
                     <Avatar
                     imageType={contact.Is_Prescriber === "Y"?"prescriber":'prescriberassociates'}
                    avatarHeight="3.5rem" avatarWidth="3.5rem"  presOrAssoc={name} />
                 </div>
               
                   <h1 className='font-md-title'>{truncate(name, 80)}</h1>

                   {type?<h1 className='font-md-caps'>{type=='Other Staff'?'Others':t("Prescriber")}</h1>:null}
              
                 <div className='buttons-div contactDetailsButton'>
                 
                 {
                     showChatIcon === true ?
                     // <Tooltip title="Message" arrow>
                          <button disabled={disableIcons} onClick={() => { (phone.length > 0? setChat(post) : showAddContactModal(post, true) )}}><Tooltip title={t('Message')} arrow><MessageIcon /></Tooltip> </button> 
                //   null
                     : null
                   }
                 
                  
                 <Tooltip title={t('Call')} arrow>
                 <button disabled={disableIcons} onClick={()=>{
                       (phone.length > 0? showCallMakerModal(post): showAddContactModal(post, false))}
                       }><CallIcon/></button>
                 </Tooltip>
                     
                 <Tooltip title={t('Edit')} arrow>
                 <button disabled={disableIcons} onClick={()=>{setShowEditModal(true);setShow(false)}}><ModeEditOutlineOutlinedIcon/></button>
 
                 </Tooltip>
                 
                     {
                       (contact.Is_Prescriber=="N" && config.REACT_APP_DELETE_OTHERS_ENABLE =="Y")?
                        <button disabled={disableIcons} onClick={handleClickOpen}> <Tooltip title={t('Delete')} arrow><DeleteIcon /></Tooltip></button> : null
                     }
                     {
                      optInOptOut && (optInOutMethodType.includes("contac") || optInOutMethodType.includes("admin")) ?
                      null :
                      <div>
                        <OptInOptOutSwitch sx={{ m: 1 }} checked={optInOptOut} onChange={handleOptInOptOut} />
                        <h1 className='opt-out-in-label'>{optInOptOut ? t('Opt Out') : t('Opt In')}</h1>
                      </div>
                     }
                  
                    
                 </div>
              </div>

              { conformOptInOptOut === true ? 
                <div className='opt-out-in-avatar-div'>
                  <div className='opt-out-in-label-msg font-md-normal'>
                  { optInOptOut ? "Are you sure you want to Opt In the Recipient?" : "Are you sure you want to Opt Out the Recipient?"}
                  </div>
                  <div className='opt-out-in-avatar-div'>
                  <div className='buttons-div contactDetailsButton'>
                    <button onClick={()=>handleConformOptInOptOut(true)}><CheckCircleRoundedIcon /></button>
                    <button onClick={()=>handleConformOptInOptOut(false)}><CancelOutlinedIcon /></button>
                  </div>
                  </div>
                </div>
                : null
              }

              <div className='name-contact-details-div modal-body'>
                   <label className='font-md-title margin-top'>{t('Information')}</label>
                   <hr/>
                   <div className='contacts-detials-info'>
                     <label className='font-md'>{t('Name')}</label>
                     {
                       name.length>0?<label className='font-md-normal'>{name}</label>:<br />
                     }
                     
                     <label className='font-md'>{t('Contact')}</label>
                     {
                       phone.length>0?<label className='font-md-normal'>{getFormatedPhoneNumber(phone)}</label>:<br />
                     }
                     
                     {
                      contact.Is_Prescriber==="N"? <label className='font-md'>{t('Association')}</label>:null
                     }
                     {
                       association.length>0 ?
                       <label className='font-md-normal'>
                         {association.map((e:any)=>(<div>{e.AcctFull_Nm}</div>))}
                       </label>:<br />
                     }
                     
                   </div>
              </div>
                
             </div>
            </div>
          

        </Box>
      </Fade>
    </Modal>
    </div>
    {/* <Dialog
        fullScreen={fullScreen}
        open={deleteModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
        Delete Contact
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          Are you sure want to Delete ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={()=>{deleteContact()}}>
            Yes
          </Button>
          <Button onClick={()=>{handleClose()}} autoFocus>
            No
          </Button>
        </DialogActions>
    </Dialog> */}
    <LogoutModal
      show={deleteModal}
      yorn={deleteContact}
      modalText={t('Are you sure you want to delete ?')}
      // data={this.state.callDetails}
      handleCloseParent={(e)=>{
        console.log(e);
        setDeletModal(false);
      }} />
      
    {
      blockUI?<BlockUI show={true}/>:null
    }

  </div>
  )
}
