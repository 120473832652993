import React, { useEffect, useState } from 'react';
import '../../components/errors/Error404.css';
import erroricon from '../../assets/icons/erroricon.svg'
import { useTranslation } from "react-i18next";

export default function Error404(props) {
    const { t } = useTranslation();

    return (
        <>
            <div className='errordiv'>
                <div className='error'>
                    <img src={erroricon} />
                    <h1>{t('ERROR - 404')}</h1>
                    <p>{t('page not found')}</p>
                </div>
            </div>
        </>
    )
}


