import {useState,useEffect,useRef} from 'react'
import TextField from "@mui/material/TextField";

import Paper from '@mui/material/Paper';
import './AssociatedPresList.css'
import config1 from "../../env.json";
import { useDebounce } from 'usehooks-ts'
import axios from "axios";
import {getPrescriberOnlyContacts} from '../../apis/contacts/contactsApi'
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from "react-i18next";
import { config } from 'process';
import {useSelector} from 'react-redux'

function AssociatedPresList(props) {
  const camp=useSelector((state: any) => state.personalisation.campaignName);
  const { t } = useTranslation();
  const {setAssoPresc,label, styleClass,clearDataOnDelete,placeholder,clearDataOnSelect,disable,onInputValue, onChangeInputValue,SelectedValue,isclear} = props
    const [IsPrescriber,setIsPrescriber]=useState(false)
    const [disabled,setDisabled]=useState(true);
    const [post, setPost] = useState([]);

    const [assoPrescName, setAssoPrescName] = useState("");
    const debouncedValue = useDebounce<string>(assoPrescName, 280);
    const [cancelToken,setCancelToken]=useState<any>()
    const [hiddenPresList, setHiddenPresList] = useState(true);
    const [typing,setTyping]=useState(false);
    const cancelTokenSource:any = useRef();
    const [loading,setLoading]=useState(false);
  const setPrescriber=(x)=>{
    debugger
    if(clearDataOnSelect)
    setAssoPrescName('');
    else
    debugger
    setAssoPrescName(x.AcctFull_Nm);
    setHiddenPresList(true);
    setAssoPresc(x);
    setTyping(false);
  }
  
  useEffect(()=>{

    if(clearDataOnDelete == true){
      setAssoPrescName('');
    }

  },[clearDataOnDelete])
  useEffect(()=>{
    if(disable === true){
      setHiddenPresList(true);
    } else {
      setHiddenPresList(false);
    }
    console.log(disable);
  },[disable])
//var cancelToken:any; 
  const getContactsData = async (value) => {
    console.log(value)
     // useGetContactsQuery({
     //         page:this.state.page,
     //         pageSize:this.state.pageSize,
     //         search:this.state.search
     //       })
     // const DATATEMP = useGetContactsQuery({
     //       page:this.state.page,
     //       pageSize:this.state.pageSize,
     //       search:this.state.search
     //     })
     //     console.log(DATATEMP);
     let can=cancelToken;
     if(value && typing){
       
       if (value.length >= 2) {
debugger
        // if (typeof can != typeof undefined) {
          
        //   can.cancel("Operation canceled due to new request.")
        // }
    
        // if( cancelTokenSource.current){
        //   cancelTokenSource.current.cancel("Operation canceled due to new request.");
        // }
        // //Save the cancel token for the current request
        // setCancelToken(axios.CancelToken.source())
        cancelTokenSource.current = axios.CancelToken.source() as any;
        // try {
        //   const results = await axios.get(
        //     config.REACT_APP_CONTACTS_API_BASE+"/contact?IsPrescriberOnly=true&Page=" +
        //        0 +
        //        "&PageSize=5&Search=" +
        //        value,
        //     { cancelToken: cancelTokenSource.current.token } //Pass the cancel token to the current request
        //   )
        //   console.log("Results for  + ", results.data)
        // } catch (error) {
        //   console.log(error)
        // }
        setLoading(true)
        let type;
        if(config1.REACT_APP_ENABLE_HOSPITAL_CONTACT && config1.REACT_APP_CAMP_LIST_ENABLE_HOSPITAL_CONTACT==camp)
          type='H'
        else
          type='P'
        
        getPrescriberOnlyContacts({page:0,pageSize:5,search:value,type:type},(res)=>{
          setLoading(false)
          setPost(res);
          setHiddenPresList(false);

        },{cancel:cancelTokenSource.current.token})
        //  axios
        //    .get(
        //      config.REACT_APP_CONTACTS_API_BASE+"/contact?IsPrescriberOnly=true&Page=" +
        //        0 +
        //        "&PageSize=5&Search=" +
        //        value,
        //      {
        //        headers: {
        //          Authorization: "Bearer " + sessionStorage.getItem("login"),
        //        },
        //      }
        //    )
        //    .then((res) => {
        //      setPost(res.data.data);
        //      setHiddenPresList(false);
        //    });
       }else{
         setPost([]);
         setHiddenPresList(true);
       }
     }
     else{
       setPost([]);
       setHiddenPresList(true);
     }
     
   };
  useEffect(() => {
    // Do fetch here...
    // Triggers when "debouncedValue" changes
    getContactsData(debouncedValue);
  }, [debouncedValue])
  useEffect(()=>{
    if(SelectedValue){
      setTyping(false);
      setAssoPrescName(SelectedValue)
      
    }
  },[SelectedValue])

 
  return (
    <div className="asso-prescriber-div createContactAssociate">
                  
                  <div>
                  <TextField
                    onChange={(e) => {
                        setTyping(true);
                        const result = e.target.value.replace(/[^A-Za-z0-9 _.]/gi, '');
                        setAssoPrescName(result)
                        onChangeInputValue(result)
                     
                     
                      // console.log(e.target.value)
                    }}
                    onInput={(e) => {
                      if(onInputValue !== undefined) { 
                        onInputValue(e);
                      }
                    }}
                    onFocus={()=>{if(props.falsifyShowOnce) props.falsifyShowOnce()}} 
                    label={label} 
                    disabled={disable ? true : false}
                    //disabled={disable}
                    focused
                    //required
                    value={assoPrescName}
                    defaultValue={""}
                    placeholder={placeholder}
                    // autoComplete="off"
                    color="secondary"
                    id="messageBox"
                    aria-expanded="true"
                    aria-controls="presList"
                    InputProps={
                      loading?{
                      endAdornment: <InputAdornment position="end"><CircularProgress  color={'inherit'} size={'1rem'}/></InputAdornment>,
                    }:undefined
                    }
                    className={'associateInput '+styleClass + (disable ? ' inputDisable' + ' disabled-label' : '')}
                  />
                  {
                    typing?<Paper  hidden={hiddenPresList}>
                      

                    <div id="presList" >

                      {post.length > 0 ? (
                         post.map((x: any) => <div key={x.Id} onClick={()=>{
                          setPrescriber(x)
                          // clearDataOnSelect && setAssoPrescName('')
                          if(isclear){setAssoPrescName('')} 
                         
                        }}>{x.AcctFull_Nm}</div>)
                      ) : (
                        <div>{t('No suggestion list is displayed')}</div>
                      )}
                    </div>
                  </Paper>:null
                  }
                  
                  </div>
                </div>
  )
}

export default AssociatedPresList