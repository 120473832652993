import React, { useEffect, useState } from "react";
import "./MobileSideBar.css";
import Avatar from "@mui/material/Avatar";
import logout from "../../assets/icons/logout.png";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from '@mui/material/styles';
import qrcode from "../../assets/images/qr-code.svg"
import { msalInstance } from "../../index";
import { setProfileSidebarView, setMobileProfileSidebarView } from '../../app/slice/loginSlice';
import poweredByP360 from "../../assets/images/powered_by_p360.png"
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Link } from 'react-router-dom';
import DialogTitle from '@mui/material/DialogTitle';
import config from '../../env.json'
import axios from "axios";
import HomeIcon from '@mui/icons-material/Home';
import LogoutModal from "../../components/logoutModal/LogoutModal";
import { deleteUserDetails } from '../../apis/dbApi/dbApi';
// import MobileProfileSidebar from '../../components/mobileProfileSidebar';
import MobileProfileSidebar from '../../components/mobileProfileSidebar/MobileProfileSidebar'
// import { isMobile } from 'react-device-detect';
import { useTranslation } from "react-i18next";
import { width } from "@mui/system";
import { getFormatedPhoneNumber } from "../../utils/phoneNumberUtil";
import { setUserMsgChannelType } from "../../app/slice/authApiSlice";
import { capacitormsal } from "capacitormsal";
import { Keyboard } from '@capacitor/keyboard';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { setEmailInboxView, setEmailDraftView, setComposeEmailView, setEmailSubTitle, setSentEmailView } from '../../app/slice/emailSubMenuSlice';

import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import SendIcon from '@mui/icons-material/Send';
import { setTitle,setAllPermissions } from "../../app/slice/sideBarSlice";
import { PushNotifications } from '@capacitor/push-notifications';
import { updatePushToken } from '../../apis/notificationApi/notificationApi';
import {
  setPage
} from "../../app/slice/chatSlice";
import supportIcon from '../../assets/images/support.svg'

import sidebarHeaderBg from '../../assets/images/mobilesidebar_headerbg.jpg'
import BackBtn from '../../assets/icons/backbtn.png'

const Background:any = config.REACT_APP_PROFILE_BG_IMG;
//const Background:any = sidebarHeaderBg;


function stringAvatar(name: string) {
  let tempname: any = name ? name.split(" ") : "U K".split(" ");
  let shortName = tempname[0][0];
  if (tempname[1]) shortName += tempname[1][0];

  return {
    sx: {
      bgcolor: "#FFFFFF",
      color: document.documentElement.style.getPropertyValue('--primary-color'),
      fontSize: "2rem",
      fontWeight: "regular",
      width: "100%",
      height: "100%",
      border: "1px solid #734BD14D",
    },
    children: shortName,
  };
}

function MobileSideBar({ show, hideSideBar }) {
  const { t } = useTranslation();
  const dispatch=useDispatch();

  const bottomNavBar=useSelector((state: any) => state.personalisation.bottomNavBarFlag);
  // console.log(bottomNavBar)
  
  const [heightOfMain, setHeightOfMain] = useState(0);
  const [MainDivHeight, setMainHeight] = useState(0);
  const [open, setOpen] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const [widthOfMain, setWidthofMain] = useState(0);
  const [paddingValTop, setPaddingValTop] = useState(0);
  const mobileProfileSidebar = useSelector((state: any) => state.login.mobileProfileSidebar);
  const userPhno = useSelector((state: any) => state.authApi.EmployeeV_Nbr);
  const userEmail = useSelector((state: any) => state.authApi.EmployeeEmail_Adr);
  const userQR = useSelector((state: any) => state.authApi.EmployeeQR_Cd);
  const userName = useSelector((state: any) => state.authApi.EmployeeFull_Nm);
  const [paddingValBottom, setPaddingValBottom] = useState(0);
  const [showProfileSidebar, setShowProfileSidebar] = useState<any>(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [showEmailSubMenu, setShowEmailSubMenu] = useState('false');
  const [emailPermission, setEmailPermission] = useState<any>();
  const [activeLink, setActiveLink] = useState('false');






  const handleClickOpen = () => {
    setOpen(true);
  };

  const [menuItems, setMenuItems] = useState<any[]>([]);

  const getMenuItems = async () => {

    // let token = sessionStorage.getItem("login");
    // axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

    console.log(axios.defaults.headers.common)
    const menuItems =  axios.get(`${config.REACT_APP_AUTH_API_BASE}/auth/getuserpermissions`,{ headers: {"type":config.REACT_APP_AUTH_TYPE} })
    .then(response => {
      response.data.sort((a, b) => parseFloat(a.Display_Or) - parseFloat(b.Display_Or));
      dispatch(setAllPermissions(response.data))
      response.data = response.data.filter(item => item.Display_Or != -1);
      // response.data.map(d => d.Display_Nm === "WHATSAPP",dispatch(setUserMsgChannelType(true)));

        let x = response.data.filter(d => d.Display_Nm === "WHATSAPP")
        if (x.length > 0) {
          console.log(x);
          dispatch(setUserMsgChannelType(true))
        } else {
          dispatch(setUserMsgChannelType(false))
        }
        let y = response.data.filter(d => d.Display_Or >= 0);
        setMenuItems(y);

      })
      .catch(error => {
        console.error('There was an error!', error);
      });


  }

  const handleClose = () => {
    setOpen(false);
  };
  // const handleLogoutPopup=(torf)=>{
  //   handleClose();
  //   if(torf){
  //     logoutFunction();
  //   }
  // }
  const logoutFunction=()=>{
    
    if(config.REACT_APP_IS_CROSS_PLATFORM) {
      updatePushToken({
        "DeviceToken_Val": sessionStorage.getItem("pushToken"),
        "Device_Typ": "iOS",
        "is_active": 'N'
      },(res)=>{       
      })
      if(config.REACT_APP_AUTH_TYPE=="AD"){
      capacitormsal.signOut({
        authority: config.REACT_APP_AD_AUTHORITY as string,
        clientId: config.REACT_APP_AD_CLIENTID as string,
        redirectUri: config.REACT_APP_CROSS_REDIRECT_URI as string,
        scope: config.REACT_APP_CROSS_SCOPE as string,
        })
        .then(async (response) => {
          dispatch({ type: 'RESET_APP' })
          sessionStorage.setItem("login", "");
          deleteUserDetails().then(() => {
            window.location.href = "/"  
          });              
        })
        .catch(error => {
          dispatch({ type: 'RESET_APP' })
          sessionStorage.setItem("login", "");
          deleteUserDetails().then(() => {
            window.location.href = "/"  
          });
        })
          .then(async (response) => {
            dispatch({ type: 'RESET_APP' })
            sessionStorage.setItem("login", "");
            deleteUserDetails().then(() => {
              window.location.href = "/"
            });
          })
          .catch(error => {
            dispatch({ type: 'RESET_APP' })
            sessionStorage.setItem("login", "");
            deleteUserDetails().then(() => {
              window.location.href = "/"
            });
          })
      } else {
        let browser = InAppBrowser.create(config.REACT_APP_PING_LOGOUT_URL, '_blank', 'location=no');
        browser.on('loadstop').subscribe(async event => {
          browser.close();
        });
        sessionStorage.setItem("login", "");
        deleteUserDetails().then(() => {
          window.location.href = "/"
        });
      }
    } else {
      if (config.REACT_APP_AUTH_TYPE == "AD") {
        let account = sessionStorage.getItem('loginName') as string
        dispatch({ type: 'RESET_APP' })
        msalInstance.logoutRedirect({
          account: msalInstance.getAccountByUsername(account)
        }).catch((e: any) => {
          console.log(e);

        });
      }
      else {
        sessionStorage.clear();
        localStorage.clear();
        var logoutUrl = config.REACT_APP_PING_LOGOUT_URL;
        window.location.href = logoutUrl
        window.location.href = config.REACT_APP_PING_REDIRECT_URL;
        // window.open(config.REACT_APP_PING_REDIRECT_URL, "_blank")
      }

    }
  }

  const handleLogoutPopup = (e: any) => {
    // console.log(e)
    if (e === true) {
      logoutFunction();
    }
  }
  function handleResize() {
    let doc: any = document;
    let height = 0;
    if (
      doc.getElementsByClassName("mobileSideBar_upper")[0] &&
      doc.getElementsByClassName("mobileSideBar_header")[0]
    ) {
      height =
        doc.getElementsByClassName("mobileSideBar_upper")[0].clientHeight -
        doc.getElementsByClassName("mobileSideBar_header")[0].clientHeight -
        70;
      setHeightOfMain(height);
    }
  }

  useEffect(() => {
    if (show) {
      getMenuItems();
      getHeightWidth();
    }
  }, [show]);

  useEffect(() => {
    if (config.REACT_APP_IS_CROSS_PLATFORM) {
      Keyboard.addListener('keyboardWillShow', info => {
        // console.log('keyboard will show with height:', info.keyboardHeight);

      });

      Keyboard.addListener('keyboardDidShow', info => {
        getHeightWidth();
      });

      Keyboard.addListener('keyboardWillHide', () => {
        // console.log('keyboard will hide');
      });

      Keyboard.addListener('keyboardDidHide', () => {
        // console.log('keyboard did hide');
        getHeightWidth();
      });
    }

  }, []);



  // useEffect(() => {
  //   console.log(show);
  //   window.addEventListener('orientationchange', () => {
  //     console.log(show);
  //     // getHeightWidth()
  //     console.log("orientation changed!");
  //   })
  // },[])


  const getHeightWidth = () => {
    let doc: any = document;
    let div: any = document.querySelector('#main');
    let divsidebar: any = document.querySelector('.mobileSideBar_main');
    console.log(divsidebar);

    let divpaddingSidebar: any = parseInt(window.getComputedStyle(divsidebar, '').getPropertyValue('padding-top'));
    let divbottomSidebar: any = parseInt(window.getComputedStyle(divsidebar, '').getPropertyValue('padding-bottom'));
    let divpaddingTop: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
    let divpaddingbottom: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
    let divpaddingleft: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-left'));
    let divpaddingright: any = parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-right'));
    let height = 0;
    let width = 0;
    let mainheight = 0;
    if (
      doc.getElementsByClassName("mobileSideBar_upper")[0] &&
      doc.getElementsByClassName("mobileSideBar_header")[0]
    ) {
      // height =
      //   doc.getElementsByClassName("mobileSideBar_upper")[0].clientHeight -
      //   doc.getElementsByClassName("mobileSideBar_header")[0].clientHeight -
      //   50;
      setPaddingValTop(divpaddingTop);
      setPaddingValBottom(divpaddingbottom);
      debugger;
      console.log("MenuHeader" + doc.getElementsByClassName("mobileSideBar_header")[0].offsetHeight);
      console.log("Menufooter" + doc.getElementsByClassName("mobileSideBar_footer")[0].offsetHeight);
      width = window.innerWidth - (divpaddingright + divpaddingleft);
      mainheight = doc.getElementById("main").offsetHeight - (divpaddingTop + divpaddingbottom) - doc.getElementsByClassName("mobileSideBar_footer")[0].offsetHeight;
      //  mainheight=doc.getElementsByClassName("mobileSideBar_header")[0].offsetHeight+doc.getElementsByClassName("mobileSideBar_main")[0].offsetHeight
      height = window.innerHeight - (doc.getElementsByClassName("mobileSideBar_header")[0].offsetHeight + divpaddingSidebar + divbottomSidebar + doc.getElementsByClassName("mobileSideBar_footer")[0].offsetHeight) - (divpaddingTop + divpaddingbottom)
      setHeightOfMain(height);
      setMainHeight(mainheight);
      setWidthofMain(width);
    }
  }


  if (show) {
    return (

      <div className="mobileSideBar" style={{ width: widthOfMain, top: paddingValTop, bottom: paddingValBottom, height: MainDivHeight }}>

        <div className="mobileSideBar_upper">
          <div
            className="mobileSideBar_header"
            style={{ backgroundImage: `url(${Background})` }}
          >
            <div className="row--one" onClick={()=>{hideSideBar()}}>
              {/* <div className="col-1 content__header__bars" onClick={()=>{hideSideBar()}}>
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
              </div> */}
              <img src={BackBtn} alt="Back" style={{width:'2rem'}} />
            </div>
            <div className="row--five d-flex" onClick={config.REACT_APP_PROFILE_CUSTOMIZATION === 'N'?(()=>{}):()=>{ dispatch(setMobileProfileSidebarView(true))}}>
            <div className="avatar-div">
                <Avatar {...stringAvatar(userName)} />
              </div>
              <div className="user-name">
                <h3 className="font-lg">
                  <b>{userName}</b>
                </h3>
                <h4 className="font-md">{getFormatedPhoneNumber(userPhno)}</h4>
              </div>
             

            </div>
          </div>
          <div
            className="mobileSideBar_main"
            style={{ height: heightOfMain + "px", overflow: 'auto' }}
          >
            <div className="mobileSideBar_main_links">
              <ul className="mob-nav">
              
              {(bottomNavBar == 'N' || config.REACT_APP_MOBILE_BOTTOM_NAV_FLAG == 'Y')?
                        <li className="d-flex" key={1000}>
                        <Link 
                              
                                to={"/"}>
                      <i className="mobileSideBarIcons" style={{marginTop:'-5px'}}>

                      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                          width="2rem" height="2rem" viewBox="0 0 564.969 560.75" enable-background="new 0 0 564.969 560.75"
                          >
                        <g>
                          <path fill="var(--primary-color)" d="M430.604,286.957c0,16.514-2.87,32.584-8.36,47.65l94.021,34.043c9.275-25.488,14.34-53,14.34-81.693
                            c0-62.477-23.996-119.342-63.261-161.908l-72.965,68.373C417.785,219.051,430.604,252.018,430.604,286.957z"/>
                          <path fill="var(--primary-color)" d="M193.53,188.758c20.688-20.688,46.756-34.037,75.047-38.758l-21.655-97.703c-77.735,14.754-142.129,67.164-173.488,137.549
                            l93.583,35.873C173.668,212.201,182.565,199.723,193.53,188.758z"/>
                          <path fill="var(--primary-color)" d="M291.729,425.83c-37.095,0-71.97-14.445-98.199-40.676c-7.684-7.684-14.348-16.113-19.942-25.119l-81.659,57.861
                            c42.687,65.002,116.225,107.934,199.801,107.934c8.546,0,16.983-0.457,25.298-1.332l-7.171-99.84
                            C303.893,425.43,297.842,425.83,291.729,425.83z"/>
                          <path fill="var(--primary-color)" d="M291.729,58.082c32.53,0,63.953,6.684,93.396,19.867c25.433,11.387,48.263,27.084,67.986,46.73l-58.423,54.746
                            c-27.84-26.686-64.274-41.344-102.96-41.344c-5.123,0-10.268,0.264-15.376,0.787l-17.388-78.443
                            C269.811,58.869,280.79,58.082,291.729,58.082 M291.729,48.082c-15.317,0-30.292,1.459-44.808,4.215L268.577,150
                            c7.578-1.266,15.314-1.918,23.152-1.918c37.095,0,71.969,14.445,98.199,40.676c1.524,1.525,3.005,3.082,4.449,4.664l72.965-68.373
                            C423.696,77.73,361.179,48.082,291.729,48.082L291.729,48.082z"/>
                          <path fill="var(--primary-color)" d="M427.908,347.295l75.326,27.273c-14.932,36.02-38.887,67.695-69.723,92.061c-31.238,24.686-68.132,40.686-107.268,46.607
                            l-5.758-80.17c29.008-5.625,55.302-19.629,76.513-40.84C410.153,379.072,420.521,363.994,427.908,347.295 M422.243,334.607
                            c-6.844,18.777-17.762,35.994-32.314,50.547c-21.908,21.908-49.848,35.588-80.072,39.504l7.171,99.84
                            c92.116-9.699,168.629-71.744,199.236-155.848L422.243,334.607L422.243,334.607z"/>
                          <path fill="var(--primary-color)" d="M78.891,202.646l74.688,28.631c-7.122,17.617-10.725,36.297-10.725,55.68c0,24.656,6.033,48.73,17.521,70.186
                            l-65.583,46.469c-20.927-35.24-31.938-75.352-31.938-116.654C62.854,257.768,68.245,229.451,78.891,202.646 M73.434,189.846
                            c-13.22,29.672-20.579,62.529-20.579,97.111c0,48.35,14.379,93.336,39.074,130.939l81.659-57.861
                            c-13.503-21.736-20.733-46.85-20.733-73.078c0-21.588,4.901-42.42,14.162-61.238L73.434,189.846L73.434,189.846z"/>
                        </g>
                        </svg>

                      </i>

                        <h4 className="">
                          <b>{t('Support')}</b>

                          <span style={{textAlign:'start'}}>Got questions? Email us at <a onClick={() => window.location.href = 'mailto:support@abbvie.com'} style={{color:'var(--primary-color)'}} href="mailto:support@abbvie.com">support@abbvie.com</a> or contact the field helpdesk at <a onClick={() => window.location.href = 'tel:+8003446776'} style={{color:'var(--primary-color)',textDecoration:'none'}}  href="tel:+8003446776">(800) 344-6776</a></span>
                        </h4>
                                  
                          </Link> 
                      
                      </li> : (
            
                menuItems.map((x: any) => (
                  
                  x.Display_Nm!="Forms" && x.Display_Nm!= "Dynamic Template"?
                  <li className="d-flex" key={x.PermissionsId}>
                      
                    <Link 
                           onClick={()=>{hideSideBar();  dispatch(setTitle(x.Display_Nm == 'Templates'?t('Templates'):x.Display_Nm)); dispatch(setPage("/"+ x.Display_Nm.replace(/ /g,"")))}}
                            to={"/"+ x.Route_Nm.replace(/ /g,"")}>

{/* <i
                      className={"mobileSideBarIcons " + x.ClassNameForUI}
                    ></i> */}
                      <img style={{marginTop:'-5px'}} className="mobileSideBarIcons" src={x.IconStoredUrl?x.IconStoredUrl:x.ClassNameForUI} alt="menu-icon"/>

                    <h4 className="">
                      {/* <b>{t(x.Display_Nm == 'Templates'?t('Pre-Approved Templates'):x.Display_Nm)}</b> */}
                      <b>{t(x.Display_Nm)}</b>
                    </h4>
                              
                      </Link> 
                   
                  </li>:null
                
                  
                ))
                  
                      )
                
                }

                 


              </ul>
            </div>
            <div className="mobileSideBar_main_logout d-flex" onClick={()=>{setShowLogoutModal(true)}}>
              
              

              <i className="mobileSideBarIcons">
              <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                          width="2rem" height="2rem" viewBox="0 0 91.000000 91.000000"
                          preserveAspectRatio="xMidYMid meet">

                          <g transform="translate(0.000000,91.000000) scale(0.100000,-0.100000)"
                          fill="var(--primary-color)" stroke="none">
                          <path fill="var(--primary-color)" d="M16 894 c-14 -13 -16 -64 -16 -403 0 -316 3 -391 14 -400 7 -6 102
                          -29 211 -51 214 -43 225 -43 225 11 l0 29 103 0 c67 0 107 4 115 12 8 8 12 54
                          12 140 0 112 -2 128 -18 137 -11 6 -24 6 -35 0 -14 -9 -17 -26 -17 -114 l0
                          -105 -80 0 -80 0 -2 317 -3 318 -69 22 c-38 12 -71 25 -74 28 -3 3 65 5 151 5
                          l156 0 3 -106 c2 -87 6 -109 20 -117 12 -8 21 -7 32 2 13 11 16 37 16 142 0
                          117 -2 129 -19 139 -12 6 -134 10 -325 10 -264 0 -307 -2 -320 -16z"/>
                          <path fill="var(--primary-color)" d="M732 628 c-17 -17 -15 -23 19 -63 l31 -35 -110 0 c-87 0 -114 -3
                          -126 -16 -12 -12 -13 -20 -6 -35 10 -17 22 -19 126 -19 l116 0 -32 -36 c-18
                          -21 -29 -43 -26 -51 3 -8 6 -17 6 -19 0 -2 12 -4 26 -4 28 0 139 97 150 132 5
                          16 -9 36 -62 89 -68 69 -89 80 -112 57z"/>
                          </g>
                 </svg>
              </i>

              <h4 className="">
                      <b>{t('Logout')}</b>
              </h4>
             

              

            </div>

            <div style={{textAlign:'center',padding: '4px 0'}}>
                <img src={poweredByP360} style={{width:'8rem'}} alt="" />
            </div>

          </div>
        </div>

        {/* <div className="mobileSideBar_footer">
          <footer className="mob-footer col-4">
            <img src={poweredByP360} alt="" />
          </footer>
        </div> */}
        {/* <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
        Logout
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          Are you sure want to Logout ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={()=>handleLogoutPopup(true)}>
            Yes
          </Button>
          <Button onClick={()=>handleLogoutPopup(false)} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog> */}
        <LogoutModal
          show={showLogoutModal}
          yorn={handleLogoutPopup}
          modalText={t('Are you sure you want to Logout ?')}
          // data={this.state.callDetails}
          handleCloseParent={(e) => {
            console.log(e);
            setShowLogoutModal(false)
          }} />
      </div>

    );
  } else {
    return null;
  }
}

export default MobileSideBar;
