import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { textAlign } from "@mui/system";
import { Box, Container, ListItemIcon, ListSubheader, TextField } from "@mui/material";
import { AccountCircle, BoltOutlined } from "@mui/icons-material";
import { useEffect } from "react";
import config from "../../env.json";
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import ShortcutIcon from "@mui/icons-material/Shortcut";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import ReplyIcon from "@mui/icons-material/Reply";
// import  Editor from "react-draft-wysiwyg";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useState, useRef, useMemo } from 'react';
import draftToHtml from 'draftjs-to-html';
import { useDispatch,useSelector } from 'react-redux';
import htmlToDraft from 'html-to-draftjs';
import "./DraftEmail.css";
import { EditorState , convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import Button from "../../layouts/button/Button";
import Autocomplete from '@mui/material/Autocomplete';
import { createMail, updateMail } from "../../apis/Email/Email";
import { useTranslation } from "react-i18next";


import { convertToHTML } from 'draft-convert';
import { setComposeEmailView, setEmailContentView, setEmailDraftView, setEmailInboxView, setEmailSubTitle, setSentEmailView } from "../../app/slice/emailSubMenuSlice";
import { getAllContacts } from "../../apis/contacts/contactsApi";
import { getEmailIdContact } from '../../apis/Email/Email';

import axios from "axios";
import { getToken } from "../../apis/contentSharingApi/contentsharing";
import uploadFileToBlob from "../azureUploads/azureBlob";
import BlockUI from '../../layouts/bockUi/BlockUI';
let cancelToken: any = undefined;

export default function AlignItemsList({ email }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1
    }/${current.getFullYear()}`;

  const editor = useRef(null);
  const [content, setContent] = useState('');
  const [toEmailId, setToEmailId] = useState<any>([])
  const [ccEmailId, setCcEmailId] = useState<any>([])
  const [bccEmailId, setBccEmailId] = useState<any>()
  const [cc, setCC] = useState(false)
  const [bcc, setBCC] = useState(false)
  const [emailId, setEmailId] = useState<any>()
  const [page, setPage] = useState<any>(0)
  const [pageSize, setPageSize] = useState<any>(25);
  const [isHospitalContact, setIsHospitalContact] = useState<any>();
  const [contactDetail, setContactDetail] = useState<any>([]);
  const [currentFile, setCurrentFile] = useState<any>([]);
  const [errorMessages, setErrorMessages] = useState<any>([]);
  const [folderName, setFolderName] = useState('');

  const [editorState, setEditorState] = useState<any>(EditorState.createEmpty());
  const [emailSubject, setEmailSubject] = useState<any>()
  const fileType = config.REACT_APP_FILESUPPORTED_TYPE;
  const fileSupportedExt = config.REACT_APP_FILESUPPORTED_EXT.split(',');
  const [toEmail, setToEmail] = useState<any>('')
  const [ccEmail, setCcEmail] = useState<any>('')
  const [progress, setProgress] = useState(0);
  const [blockUI,setBlockUI]=useState(false);
  const [files,setFiles]=useState<any>([]);

  // const sampleEditorContent = ContentState.createFromBlockArray(editorState);
  useEffect(()=>{
    setToEmailId([]);
    setCcEmail([]);
  },[email.EmailTo_Email, email.EmailCC_Email])
  useEffect(()=>{
    console.log("------------------------Change Draft email")
    setBlockUI(true)
    setToEmailId(toEmailId=>[]);
    setCcEmail(ccEmailId=>[]);
    const contentBlock = htmlToDraft(email?.Email_Bdy)
    setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlock.contentBlocks)))
    console.log(email)
    
    setEmailId(email?.EmailConversation_Id)
    if(email){
      // const myArray = email?.EmailTo_Email.split(",",2);
      // console.log(myArray)
      // // var myObj = JSON.parse('{"Eamil_id": 5,"Actual_Nm":"Abigail Allan"}');
      // console.log(myObj)
      // setToEmailId(email?.EmailTo_Email)
      // setCcEmailId(email?.EmailCC_Email)
      getEmailDataAsArray(email)
      setEmailSubject(email?.EmailConversation_Subject)
      // setBccEmailId()

const temp:Array<any> =[];
let Attachments: Array<any> = [{
  "ContentType": "image/png",
  "Display_Nm": "app latest.png",
  "EmailAttach_Url": "https://p360zingforms.blob.core.windows.net/shared-content/2023-05-04-6:41applatest.png",
  "File_Nm": "2023-05-04-6:41applatest.png",
  "AttachmentId": 0
},
{
  "ContentType": "application/pdf",
  "Display_Nm": "29298-P360-Employee-Handbook-V1.1-India.pdf",
  "EmailAttach_Url": "https://p360zingforms.blob.core.windows.net/shared-content/2023-05-15-3:3929298-P360-Employee-Handbook-V1.1-India.pdf",
  "File_Nm": "2023-05-15-3:3929298-P360-Employee-Handbook-V1.1-India.pdf",
  "Id": 0
}]

      Attachments.map((file)=>{
        let blobtoFile = new File([file.EmailAttach_Url],file.DisplayName, { lastModified: new Date().getTime(), type:file.ContentType })
        console.log("::::::::::::::::::::::::::::",blobtoFile);
        temp.push(blobtoFile)
      })
     setCurrentFile(temp);
    }
  },[email.EmailConversation_Id])

  const getEmailDataAsArray=(value)=>{
    
    console.log(value)
    let emailString = value.EmailTo_Email+','+value.EmailCC_Email
    console.log(emailString)
    let emailPayloadArray:any = emailString.split(',');
    // emailPayloadArray= new Set(emailPayloadArray)
    const newArray=  new Set(emailPayloadArray)
    console.log(newArray)
    console.log(emailPayloadArray);
    let payload= {
      "Emails":Array.from(newArray)
    }

    console.log(payload)
    console.log(emailString.length)
    console.log(emailString.length)
    if(emailString.length === 1){
      setTimeout(() => {
        setToEmailId([]);
        setCcEmail([]);
        setBlockUI(false)
      }, 5000);
      
    }
    if(emailString.length>1){
      getEmailIdContact(payload, (res)=>{
        console.log(res);
        // respData.push(res);
        debugger
        let getToArray:any = [];
        let getCcArray:any = [];

       if(res){
        res.map((x)=>{  
          debugger     
          if(value.EmailTo_Email.indexOf(x.AcctEmail_Adr)>=0){
           if(getToArray.includes(x.AcctEmail_Adr)== false){                
            getToArray.push(x)
            //  console.log(getToArray)            
            }           
          } 
          if(value.EmailCC_Email.indexOf(x.AcctEmail_Adr)>=0){
            if(getCcArray.includes(x.AcctEmail_Adr)== false){           
                           getCcArray.push(x)             
            }            
          }
        })
       }
        setToEmailId(getToArray)
        selectedtoEmails(getToArray)
        setCcEmailId(getCcArray)
        selectedCCEmails(getCcArray)
        setBlockUI(false)
      })
      
    } 
    

    console.log(toEmailId)
  }
  const sendMail = () => {
    let html = convertToHTML(editorState.getCurrentContent());
    let updatedEmailBody= html
    let data = {
      "EmailId":emailId,
    "ContactId":1000,
    "ContactType":"PRES",
    "EmailStatus":"SENT",
    "EmailConversation_Id":email.EmailConversation_Id,
    "EmailBody":updatedEmailBody,
    "EmailSubject":emailSubject,
    "ContentType":"HTML",
    "ToEmail":toEmail,
    "CCEmail":ccEmail,
    "Attachments":files
    }
    // console.log(data)
    updateMail(data, (resp) => {
      if (resp === 200) {
        dispatch(setEmailInboxView(true));          
        dispatch(setEmailSubTitle('Inbox'));      
      dispatch(setEmailDraftView(false)); 
      dispatch(setEmailContentView(false));
     

      }
    });
  }

  const saveasDraft = () => {

    let html = convertToHTML(editorState.getCurrentContent());
    let updatedEmailBody= html
    console.log(emailId)
    let data = {
      "EmailId":emailId,
    "ContactId":1000,
    "ContactType":"PRES",
    "EmailStatus":"DRAFT",
    "EmailBody":updatedEmailBody,
    "EmailConversation_Id":email.EmailConversation_Id,
    "EmailSubject":emailSubject,
    "ContentType":"HTML",
    "ToEmail":toEmail,
    "CCEmail":ccEmail,
    "Attachments":files
    }
    console.log(data)
    updateMail(data, (resp) => {
      console.log(resp);
      if (resp === 200) {  
        dispatch(setEmailInboxView(true));          
          dispatch(setEmailSubTitle('Inbox'));      
        dispatch(setEmailDraftView(false)); 
        dispatch(setEmailContentView(false));
      }
    });
  }
  const handleInputChange = (e) => {
    console.log(e)
    // setTyping(true)
    if (cancelToken) {
      console.log('hi')
      cancelToken.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source()
    // if(state.To.length>2)
    getAllContacts({
      page: page, pageSize: pageSize, search: e.target.value,
      CancelToken: cancelToken.token, contactType: isHospitalContact ? "HA" : "All",
    },
      (res: any) => {
        console.log(res)
        setContactDetail(res)
      })
  };
  
  // const validateGetfiles = (files) => {
  //   //   let outputFiles = [];
  //   //   let fileSize = 0;
  //   // setDisableSendButton(false)
  //   let errorMesg = "";
  //   files = Array.from(files);
  //   let file;
  //   for (var i = 0; i < files.length; i++) {
  //     file = files[i];
  //     let fileExt = file.name
  //       .split(".")
  //       .pop()
  //       .toLowerCase();
  //     // console.log(this.fileType.includes(file.type), this.fileSupportedExt.includes(fileExt))
  //     console.log("fileExt : ", fileExt);
  //     console.log("fileExt : ", fileSupportedExt);
  //     console.log("include file ext : ", fileSupportedExt.includes(fileExt));

  //     console.log("filetype : ", file.type);
  //     console.log("filetype : ", fileType);
  //     console.log("file type list : ", fileType.includes(file.type))
  //     if (
  //       fileType.includes(file.type) &&
  //       fileSupportedExt.includes(fileExt)
  //     ) {
  //       // check non image file size if its greater that 675000b throw a error and remove the file;
  //       // files.push(file);
  //       // fileSize += file.size;
  //     }
  //     else if (file.type === "image/tiff" || file.type === "audio/amr" || fileExt === "amr" || file.type === "") {


  //       errorMesg = file.name + " is not supported";
  //       // setDisableSendButton(true)
  //     }
  //     else {
  //       // this.isFileTypeSupported= false;
  //       errorMesg = file.name + " is not supported";
  //       // errorMessages.push(file.name + " is not supported")
  //       // let t = errorMessages;
  //       // t.push("filetype is not supported");
  //       // setErrorMessages(t)
  //     }
  //   }
  //   return { errorMesg };
  // }
  // const removeFile = (i) => {
  //   console.log("functioin called for ", i);
  //   let attfile = currentFile.filter((x, index) => i != index);
  //   setCurrentFile(attfile);
  //   validateGetfiles(attfile);
  //   handleUpload(attfile)
  //   // this.fileSize = 0;
  //   // for (let i = 0; i < this.files.length; i++) {
  //   //     this.fileSize += parseInt(this.files[i].size);
  //   // }
  //   // if (this.files.length <= 10) {
  //   //     this.footerErrorMsg = [];
  //   // }
  // }
  // const selectFile = (e) => {
  //   console.log(e)
  //   const files: any = Array.from(e.target.files)
  //   setCurrentFile(files)
  //   let temp: any = [];
  //   currentFile.map(x => {
  //     temp.push(x)
  //     console.log("old attach file content", temp)
  //   })
  //   files.map((e: any) => {
  //     e.testTime = new Date();
  //     temp.push(e)
  //     console.log("file name", e.name)
  //     console.log(temp)

  //   })
  //   // temp=temp.concat(files);
  //   //debugger
  //   // let { errorMesg } = validateGetfiles(e.target.files);

  //   //debugger
  //   if (temp.length > 10) {
  //     // footerErrorMsg.push('Max 10 files are allowed');
  //     let errmsg = "Max 10 files are allowed";
  //     // errorMessages.push("Max 10 files are allowed");
  //     // console.log('Max 10 files are allowed');
  //     let t: any = [];
  //     errorMessages.map((x: any) => {
  //       t.push(x);
  //       return x;
  //     })

  //     t.push('Max 10 files are allowed');
  //     setErrorMessages(t)
  //     setTimeout(
  //       function () {
  //         setErrorMessages([])
  //       }
  //       ,
  //       2000
  //     );

  //   }
  //   else {
  //     setCurrentFile(temp)

  //     console.log("attachedFiles : ", currentFile)
  //     console.log("hello world : ", currentFile.length);
  //   }
  //   if (false) {
  //     //debugger
  //     // this.footerErrorMsg.push(errorMesg);
  //     let t: any = [];
  //     errorMessages.map((x: any) => {
  //       t.push(x);
  //       return x;
  //     })
  //     t.push(false);
  //     setErrorMessages(t)
  //     setTimeout(
  //       function () {
  //         setErrorMessages([])
  //       }
  //       ,
  //       2000
  //     );
  //   }

  //   console.log(files);
  //   // const selectedFiles:any = Array.from(files);
  //   // const fileListAsArray = Array.from(selectedFiles)
  //   // console.log(fileListAsArray)

  //   handleUpload(files)
  //   // setProgress(0);
  // };

  // const handleUpload = (files) => {
  //   debugger
  //   console.log(currentFile)
  //   getToken((res) => {
  //     if (res) {
  //       const temp = res.data.storageAccessToken;
  //       console.log("token get api", temp);
  //       onUpload(temp,files);
  //     }
  //   });
  // }

  // const onUpload = async (res,files) => {
  //   //  const timer = setInterval(() => {
  //     setProgress((oldProgress) => {
  //       // if (oldProgress === 100) {
  //       //   return 0;
  //       // }
  //       const diff = Math.random() * 10;
  //       return Math.min(oldProgress + diff);
  //     });
  //   // }, 500);
  //   // return () => {
  //   //   clearInterval(timer);
  //   // };
  //   console.log(res);
  //   console.log(currentFile)
  //   const blobsInContainer: any = await uploadFileToBlob(currentFile, 'email',folderName, res);
  //   console.log(blobsInContainer)

  //   if (blobsInContainer.length > 0) {
  //     const timer = setInterval(() => {
  //       setProgress((oldProgress) => {
  //         // if (oldProgress === 100) {
  //         //   return 0;
  //         // }
  //         const diff = Math.random() * 10;
  //         return Math.min(100);
  //       });
  //     }, 500);
  //   }
  //   setFiles(blobsInContainer)
 
  // }
  





  const handleUpload = (files) => {
    debugger
    console.log(currentFile)
    getToken((res) => {
      if (res) {
        const temp = res.data.storageAccessToken;
        console.log("token get api", temp);
        onUpload(temp, files);
      }
    });
    // const timer = setInterval(() => {
    // setProgress((oldProgress) => {
    //   // if (oldProgress === 100) {
    //   //   return 0;
    //   // }
    //   const diff = Math.random() * 10;
    //   return Math.min(oldProgress + diff);
    // });
    // }, 500);
    // return () => {
    //   clearInterval(timer);
    // };
  }

  const onUpload = async (res, files) => {
    //  const timer = setInterval(() => {
    setProgress((oldProgress) => {
      // if (oldProgress === 100) {
      //   return 0;
      // }
      const diff = Math.random() * 10;
      return Math.min(oldProgress + diff);
    });
    // }, 500);
    // return () => {
    //   clearInterval(timer);
    // };
    console.log(res);
    console.log(currentFile)
    const blobsInContainer: any = await uploadFileToBlob(files, folderName, 'email', res);
    console.log(blobsInContainer)

    if (blobsInContainer.length > 0) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          // if (oldProgress === 100) {
          //   return 0;
          // }
          const diff = Math.random() * 10;
          return Math.min(100);
        });
      }, 500);
    }setFiles(blobsInContainer);
  }

  const validateGetfiles = (files) => {
    //   let outputFiles = [];
    //   let fileSize = 0;
    // setDisableSendButton(false)
    console.log(files)
    let errorMesg = "";
    files = Array.from(files);
    let file;
    for (var i = 0; i < files.length; i++) {
      file = files[i];
      let fileExt = file.name
        .split(".")
        .pop()
        .toLowerCase();
      // console.log(this.fileType.includes(file.type), this.fileSupportedExt.includes(fileExt))
      console.log("fileExt : ", fileExt);
      console.log("fileExt : ", fileSupportedExt);
      console.log("include file ext : ", fileSupportedExt.includes(fileExt));

      console.log("filetype : ", file.type);
      console.log("filetype : ", fileType);
      console.log("file type list : ", fileType.includes(file.type))
      if (
        fileType.includes(file.type) &&
        fileSupportedExt.includes(fileExt)
      ) {
        // check non image file size if its greater that 675000b throw a error and remove the file;
        // files.push(file);
        // fileSize += file.size;
      }
      else if (file.type === "image/tiff" || file.type === "audio/amr" || fileExt === "amr" || file.type === "") {


        errorMesg = file.name + " is not supported";
        // setDisableSendButton(true)
      }
      else {
        // this.isFileTypeSupported= false;
        errorMesg = file.name + " is not supported";
        // errorMessages.push(file.name + " is not supported")
        // let t = errorMessages;
        // t.push("filetype is not supported");
        // setErrorMessages(t)
      }
    }
    return { errorMesg };
  }

  const removeFile = (i) => {
    console.log("functioin called for ", i);
    let attfile = currentFile.filter((x, index) => i != index);
    setCurrentFile(attfile);
    validateGetfiles(attfile);
    handleUpload(attfile)
    // this.fileSize = 0;
    // for (let i = 0; i < this.files.length; i++) {
    //     this.fileSize += parseInt(this.files[i].size);
    // }
    // if (this.files.length <= 10) {
    //     this.footerErrorMsg = [];
    // }
  }
  const selectFile = (e) => {
    console.log(e)
    const files: any = Array.from(e.target.files)
    console.log(files)
    let newArray: any = [];
    newArray.push(files)
    setCurrentFile(newArray)
    let temp: any = [];
    currentFile.map(x => {
      temp.push(x)
      console.log("old attach file content", temp)
    })
    files.map((e: any) => {
      e.testTime = new Date();
      temp.push(e)
      console.log("file name", e.name)
      console.log(temp)

    })
    // temp=temp.concat(files);
    //debugger
    // let { errorMesg } = validateGetfiles(e.target.files);

    //debugger
    if (temp.length > 10) {
      // footerErrorMsg.push('Max 10 files are allowed');
      let errmsg = "Max 10 files are allowed";
      // errorMessages.push("Max 10 files are allowed");
      // console.log('Max 10 files are allowed');
      let t: any = [];
      errorMessages.map((x: any) => {
        t.push(x);
        return x;
      })

      t.push('Max 10 files are allowed');
      setErrorMessages(t)
      setTimeout(
        function () {
          setErrorMessages([])
        }
        ,
        2000
      );

    }
    else {
      setCurrentFile(temp)

      console.log("attachedFiles : ", currentFile)
      console.log("hello world : ", currentFile.length);
    }
    // if (false) {
    //   //debugger
    //   // this.footerErrorMsg.push(errorMesg);
    //   let t: any = [];
    //   errorMessages.map((x: any) => {
    //     t.push(x);
    //     return x;
    //   })
    //   t.push(false);
    //   setErrorMessages(t)
    //   setTimeout(
    //     function () {
    //       setErrorMessages([])
    //     }
    //     ,
    //     2000
    //   );
    // }



    console.log(files);
    // const selectedFiles:any = Array.from(files);
    // const fileListAsArray = Array.from(selectedFiles)
    // console.log(fileListAsArray)

    handleUpload(files)
    // setProgress(0);
  };

  const selectedCCEmails = (value) => {
    let data:any=value;
    let contactInfoIsArray = Array.isArray(value);
    if(contactInfoIsArray === false){
      data= data.push(value)
    }
    // data.map((item: any, i) => {
    //   value.map((data, index) => {
    //     if (i === index) {
    //       item.email = data.email;

    //     }

    //   })
    // })
    console.log("ccarray")
    getemails('cc', data);
  }

  const selectedtoEmails = (value) => {
    let data:any=value;
    let contactInfoIsArray = Array.isArray(value);
    if(contactInfoIsArray === false){
      data= data.push(value)
    }
    console.log(contactInfoIsArray,value)
    
    // console.log(toEmailId)
    
    console.log(data)
    // data.map((item: any, i) => {
    //   value.map((data, index) => {
    //     if (i === index) {
    //       item.email = data.email;

    //     }
    //   })
    // })
    console.log("toarray====================", data)
    getemails('to',data);
  }
  
  function getemails(name, value){
    console.log(name, value)
    if(name === 'to'){
      let to:any="";

     value.map((item:any)=>{
    console.log(item, "//////////")
    if (to !== undefined) {
      console.log(item, "....")
      if(to.length >0){
        to =to+","+item.AcctEmail_Adr
      }
      if(to.length<=0){
        console.log(item, "+++")
        to= item.AcctEmail_Adr
      }
    }
     
     })
     console.log("emailsto:::::::::::::::::",to)
     setToEmail(to)
    }


    if(name === 'cc'){
      let cc="";
     value.map((item:any)=>{
      if (cc !== undefined) {
      if(cc.length >0){
      cc =cc+","+item.AcctEmail_Adr}
      if(cc.length<=0){
        cc= item.AcctEmail_Adr
      }
    }
     })
     console.log("emailsto:::::::::::::::::",cc)
     setCcEmail(cc)
    }
    // if(name === 'bcc'){
    //   let bcc="";
    //  value.map((item:any)=>{
    //   if(bcc.length >0){
    //   bcc =bcc+","+item.email}
    //   if(bcc.length<=0){
    //     bcc= item.email
    //   }
    //  })
    //  console.log("emailsto:::::::::::::::::",bcc)
    //  setBccMails(bcc)
    // }

  }

  return (


    <div className="jodit">
    
      <div className="form-field" >
        <label>From:</label>
        <input type="email" value={email?.EmailFrom_Email} />
      </div>
      <div className="form-field draft-to-input">
        <label>To:</label>
        {/* <input type="email"  value={toEmailId} onChange={(e)=>{setToEmailId(e.target.value)}}/> */}
        <Autocomplete
          multiple
          id="tags-standard"
          options={contactDetail}
          getOptionLabel={(option:any) => option.AcctFull_Nm}
          // defaultValue={toEmailId}

          onChange={(e, value: any) => {
            console.log("=================================", value)
            // setToArray(value);
            selectedtoEmails(value)
          }
          }
          value={toEmailId}
          onInputChange={handleInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
            // label="Multiple values"
            // placeholder="Favorites"
            />
          )}
        />


        <p style={{margin:'0px'}} onClick={() => {
          setCC(!cc);
        }}>CC</p>
        <p onClick={() => {
          setBCC(!bcc);
        }}>BCC</p>
      </div>
      {cc ? <div className="form-field draft-to-input" style={{ display: 'flex' }} >
        <label>CC:</label>
        {/* <input type="email" value={ccEmailId} onChange={(e)=>{setCcEmailId(e.target.value)}}/> */}
        <Autocomplete
          multiple
          id="tags-standard"
          options={contactDetail}
          getOptionLabel={(option:any) => option.AcctFull_Nm}
          // defaultValue={ccEmailId}
          value={ccEmailId}
          onChange={(e, value: any) => {
            console.log("=================================", value)
            // setCCArray(value);
            selectedCCEmails(value)
          }
          }
          onInputChange={handleInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
            />
          )}
        />
      </div> : null}

      {bcc ? <div className="form-field" >
        <label>BCC:</label>
        <input type="email" value={bccEmailId} onChange={(e)=>{setBccEmailId(e.target.value)}}/>       
        
      </div> : null}
      <div className="form-field">
        <label>Subject:</label>
        <input type="text" value={emailSubject} onChange={(e)=>{setEmailSubject(e.target.value)}} />
      </div>
      {currentFile.length > 0 ?
        <div className='attached-media grid-container'>

          {currentFile?.map((file: any, index) => {
            return (
              <div key={file.name + "-" + file.type + "-" + index + '-' + file.testTime} id={file.name + "-" + file.type + "-" + index + '-' + file.testTime}>
                <div className='file-wrapper'>
                  <div className='media-tb'>
                    <div className="media-left d-flex">
                      <i className="far fa-file-alt"></i>
                      {/* <p>{(file.size / 1e6).toFixed(2)}</p> */}
                    </div>

                    <div>{file.name}</div>

                    <div >
                      <i className="fas fa-times" onClick={(() => removeFile(index))} />

                    </div>

                  </div>
                  <div><LinearProgress variant="determinate" value={progress} /></div>
                </div>
              </div>)
          })}
        </div>
        : null}
         <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={(e)=>{setEditorState(e)}}
          // wrapperClassName="wrapper-class"
          // editorClassName="editor-class"
          toolbarClassName="toolbar-class"
        />
       
       {/* {currentFile.length > 0 ?
        <div className='attached-media grid-container'>

          {currentFile?.map((file: any, index) => {
            return (
              <div key={file.name + "-" + file.type + "-" + index + '-' + file.testTime} id={file.name + "-" + file.type + "-" + index + '-' + file.testTime}>
                 <div className='file-wrapper'>
                  <div className='media-tb'>
                  <div className="media-left d-flex">
                    <i className="far fa-file-alt"></i>
              <p>{(file.size / 1e6).toFixed(2)}</p> 
                  </div>

                  <div>{file.name}</div>

                  <div >
                    <i className="fas fa-times" onClick={(() => removeFile(index))} />

                  </div>

                  </div>
                  <div><LinearProgress variant="determinate" value={progress} /></div>
                </div>
              </div>)
          })}
        </div>
        : null} */}

      <div className="send-btn-wrraper">
      <Button type="button" onClick={() => { saveasDraft() }} label="Save as Draft"  className="margin-right"/>
      <Button type="button" onClick={() => { sendMail() }} label="Send" />
      {
                        config.REACT_APP_ATTACHMENT_ENABLED=="Y" &&
                        <Tooltip title={t('Attach Files')} arrow>
                            <button
                                className="btn-emailattachment"
                                type='button'
                                onClick={() => { let doc: any = document; doc.getElementById('attachedfiles').click() }}
                            >

                                <i className="fas fa-paperclip i-2" style={{ color: '#734bd1' }}>
                                </i>

                            </button>
                        </Tooltip>
                    }
                    {/* <label > */}
                        <input
                            type="file"
                            id="attachedfiles"
                            name="attachedfiles"
                            className="custom-file-input"
                            multiple
                            accept="application/pdf, application/vcard, text/vcard, text/x-vcard, text/csv, text/rtf, text/richtext, text/calendar, text/directory, image/bmp, image/tiff, image/jpeg, image/jpg, image/gif, image/png, video/H264, video/H263-2000, video/H263-1998, video/H263, video/H261, video/3gpp-tt, video/3gpp2, video/3gpp, video/webm, video/quicktime, video/mp4, video/mpeg, audio/basic, audio/L24, audio/mp4, audio/mpeg, audio/ogg, audio/vnd.rn-realaudio, audio/vnd.wave, audio/3gpp, audio/3gpp2, audio/ac3, audio/vnd.wave, audio/webm, audio/amr-nb, audio/amr"
                            style={{ display: 'none' }}
                            onChange={selectFile}
                        // onClick={getFiles}

                        />
      </div>
      {
      blockUI?<BlockUI show={true}/>:null
    }
     
    {/* {JSON.stringify(editorState)} */}
    </div>

  );
}




// function dispatch(arg0: { payload: any; type: "emailView/setSentEmailView"; }) {
//   throw new Error("Function not implemented.");
// }

